import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, InputGroup, Row, Toast} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {auth, logInWithEmailAndPassword} from "../core/Firebase/firebase";
import {authError, authNone, authSuccess, handleMdpInput, selectAuthState} from '../core/Redux/features/user';

//import Cookies from 'js-cookie';

function Login() {
    const mounted = useRef();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validated, setValidated] = useState(false);
    const authState = useSelector(selectAuthState);
    const dispatch = useDispatch();
    //console.log(authState);


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            if (email && password) {
                logInWithEmailAndPassword(email, password);
            }
        }
        setValidated(true);

    };

    const handleEye = useCallback(() => {
        if (authState.showMdp === true) {
            document.getElementById('password').setAttribute("type", "text");
            document.getElementById('password').setAttribute("placeholder", "Mot de passe");
        } else {
            document.getElementById('password').setAttribute("type", "password");
            document.getElementById('password').setAttribute("placeholder", "*********");
        }

    }, [authState.showMdp]);

    useEffect(() => {
        if (!mounted.current) {
            //did mounted
            document.title = "Bilal";
            mounted.current = true;
        } else {
            //did update
        }
    });


    useEffect(() => {
        handleEye();
        auth.onIdTokenChanged((user) => {
            //console.log(user);
            if (user) {
                user.getIdToken(true).then((tk)=>{
                    dispatch(authSuccess({user: {id: user.uid, email: user.email , token:tk}}));
                })
            } else {
                dispatch(authNone())
            }
        });
    }, [dispatch, handleEye]);


    return (
        <div style={{height: '100vh', width: '100vw'}} className='body_login'>
            <div className="login-accueil">
                <img alt="" src="/assets/images/imgs/img_accueil_login.svg"/>
            </div>
            <div className="row  h-100">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-flex col-card-login" style={{marginLeft: "auto"}}>
                    <div className="login-card-global w-100">
                        <div className="card-login">
                            <div className="card-login1 c-flexed jc-center">

                                <div className="align-self-center circle-login-card mb-30">
                                    <img alt="" src="/assets/images/logo/logo.svg"/>
                                </div>

                                <div className="mrpx-20 mlpx-20 form-login">
                                    <Form /* noValidate */ validated={validated} onSubmit={handleSubmit}>
                                        <Row className="row-gap-30 no-gutters mb-30">
                                            <Form.Group as={Col} className="col-12">
                                                <InputGroup className="w-100" hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend" className='icon-group'><img
                                                        alt="" className="img-70"
                                                        src="/assets/images/ico/ic_email_login.svg"/></InputGroup.Text>
                                                    <Form.Control value={email}
                                                                  onChange={(e) => setEmail(e.target.value)}
                                                                  className="login-form" required type="email"
                                                                  style={{borderRadius: "0px 6px 6px 0px"}}
                                                                  placeholder='Email' name="username" autoComplete='off'
                                                                  aria-describedby='inputGroupPrepend'/>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} className="col-12">
                                                <InputGroup className="w-100" hasValidation>
                                                    <InputGroup.Text id="inputGroupEnd" className='icon-group'><img
                                                        alt=""
                                                        className="img-70"
                                                        src="/assets/images/ico/ic_password.svg"/></InputGroup.Text>
                                                    <Form.Control id="password" className="login-form" required
                                                                  type="password"
                                                                  placeholder='***************'
                                                                  name="current-password" autoComplete='off'
                                                                  aria-describedby='inputGroupEnd' value={password}
                                                                  onChange={(e) => setPassword(e.target.value)}/>
                                                    <InputGroup.Text onClick={() => {
                                                        dispatch(handleMdpInput())
                                                    }} className="icon-group-end cursor-pointer">
                                                        <span
                                                            className={authState.showMdp ? 'fa fa-eye' : 'fa fa-eye-slash'}/>
                                                    </InputGroup.Text>

                                                </InputGroup>
                                            </Form.Group>

                                            <Toast autohide delay={3000} onClose={() => {
                                                dispatch(authError({error: false, message: ""}))
                                            }} className="w-100" show={authState.error === true}>
                                                <Toast.Body>
                                                    <div style={authState.error ? {transition: "0.5s"} : {}}
                                                         className="bg-dark w-100 p-10 text-light rounded fsize-14">
                                                        {authState.message}
                                                    </div>
                                                </Toast.Body>
                                            </Toast>

                                            <Button bsPrefix="login-button" className="col-12"
                                                    type="submit">
                                                <div className="r-flexed column-gap-10 jc-center">
                                                    Connexion
                                                    {authState.loading && <div className="spinner-container">
                                                        <div className="loading-spinner"></div>
                                                    </div>}
                                                </div>
                                            </Button>


                                        </Row>

                                    </Form>
                                </div>

                                <div className="text-center">
                                    <Link to="/resetpwd" className="text-light fsize-18">Mot de passe oublié ?</Link>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )

}

export default Login;
