import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveData } from "../../../core/Firebase/services/FirebaseService";
import * as Rb from "react-bootstrap";
import { CustomAlert, customToast } from "../../../core/js/backoffice";
import { closeModal, closeModalAdd } from "../../../core/Redux/features/user";
import { doc } from "firebase/firestore";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { ModeModel } from "../../../core/models/Mode-Model";

export const ModalCouleur = (props) => {
  const [validated, setValidated] = useState(false);
  const [id, setId] = useState(null);
  const [designation, setDesignation] = useState("");
  const [isDefault, setIsdefault] = useState(false);
  const [couleurHEX, setCouleurHEX] = useState("#000000");
  const [bottombackgroundColor, setBottombackgroundColor] = useState("#000000");
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [unSelectedColor, setUnSelectedColor] = useState("#000000");
  const [onPrimary, setOnPrimary] = useState("#000000");
  const [secondary, setSecondary] = useState("#000000");
  const [isUpdate] = useState(props.isAdd === false);
  const dispatch = useDispatch();

  useEffect(() => {
    //console.log(props.profils);
    if (isUpdate) {
      if (props.itemToUpdate) {
        // setItemToUpdate(props.itemToUpdate);
        let b = props.itemToUpdate;
        setDesignation(b.designation);
        setCouleurHEX(b.couleurHEX);
        setBottombackgroundColor(b.bottombackgroundColor)
        setSelectedColor(b.selectedColor)
        setUnSelectedColor(b.unSelectedColor)
        setOnPrimary(b.onPrimary)
        setSecondary(b.secondary)
        setIsdefault(b.isDefault);
        setId(b.id);
        //console.log(b.id);
      }
    }
  }, []);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    //console.log(prenom,nom,adresse,telephone,genre,email,password,password1);
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === true) {
        saveData({
          dataType: "mode",
          dataToSave: new ModeModel({
            id: id,
            designation: designation,
            couleurHEX: couleurHEX,
            bottombackgroundColor: bottombackgroundColor,
            selectedColor: selectedColor,
            unSelectedColor: unSelectedColor,
            onPrimary: onPrimary,
            secondary: secondary,
            isDefault: isDefault,
          }),
        }).then((data) => {
          console.log(data);
        });
        console.log(id,designation,couleurHEX,isDefault);
    }
    setValidated(true);
  };
  
  return (
    <div>
      <Rb.Modal.Body>
        <div className="container-fluid">
          <Rb.Form
            className="mt-20"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Rb.Row>
              <Rb.Col xs={12} sm={12} md={12} lg={6}>
                <Rb.Form.Group className="form-group" controlId="designationMode">
                  <Rb.Form.Label className="textClassInit">
                    Désignation mode <span className="text-danger">*</span>
                  </Rb.Form.Label>
                  <Rb.Form.Control
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    required
                    className="modal-form"
                    type="text"
                    placeholder="Désignation du mode"
                  />
                  <Rb.Form.Control.Feedback className="" type="invalid">
                    Champ Obligatoire
                  </Rb.Form.Control.Feedback>
                </Rb.Form.Group>
              </Rb.Col>
              <Rb.Col xs={12} sm={12} md={12} lg={6}>
                <Rb.Form.Group className="form-group" controlId="statutMode">
                  <Rb.Form.Label className="textClassInit">
                    Définir comme mode par défaut
                  </Rb.Form.Label>
                  <div id="switch-component">
                    <input
                      type="checkbox"
                      id="switch-fl"
                      onChange={(e) => setIsdefault(e.target.checked)}
                      value={isDefault}
                      checked={isDefault}
                    />
                    <label htmlFor="switch-fl">Toggle</label>
                  </div>
                </Rb.Form.Group>
              </Rb.Col>
              <Rb.Col xs={12} sm={12} md={12} lg={6}>
                <Rb.Form.Group className="form-group" controlId="couleurMode">
                  <div className="r-flexed column-gap-10 mb-1">
                    Couleur du mode
                    <div
                      className="w-10 h-10"
                      style={{
                        borderRadius: "4px",
                        background: couleurHEX,
                      }}
                    ></div>
                  </div>
                  <div className="c-flexed w-100 row-gap-10">
                    <HexColorPicker
                      className="w-100"
                      color={couleurHEX}
                      onChange={setCouleurHEX}
                    />
                    <HexColorInput
                      className="form-control w-100"
                      prefixed={true}
                      color={couleurHEX}
                      onChange={setCouleurHEX}
                    />
                  </div>
                </Rb.Form.Group>
              </Rb.Col>
              {/* <Rb.Col xs={12} sm={12} md={12} lg={6}>
                <Rb.Form.Group className="form-group" controlId="couleurMode">
                  <div className="r-flexed column-gap-10 mb-1">
                    Couleur d'arrière plan pied de page
                    <div
                      className="w-10 h-10"
                      style={{
                        borderRadius: "4px",
                        background: bottombackgroundColor,
                      }}
                    ></div>
                  </div>
                  <div className="c-flexed w-100 row-gap-10">
                    <HexColorPicker
                      className="w-100"
                      color={bottombackgroundColor}
                      onChange={setBottombackgroundColor}
                    />
                    <HexColorInput
                      className="form-control w-100"
                      prefixed={true}
                      color={bottombackgroundColor}
                      onChange={setBottombackgroundColor}
                    />
                  </div>
                </Rb.Form.Group>
              </Rb.Col>
              <Rb.Col xs={12} sm={12} md={12} lg={6}>
                <Rb.Form.Group className="form-group" controlId="couleurMode">
                  <div className="r-flexed column-gap-10 mb-1">
                    Couleur de selection
                    <div
                      className="w-10 h-10"
                      style={{
                        borderRadius: "4px",
                        background: selectedColor,
                      }}
                    ></div>
                  </div>
                  <div className="c-flexed w-100 row-gap-10">
                    <HexColorPicker
                      className="w-100"
                      color={selectedColor}
                      onChange={setSelectedColor}
                    />
                    <HexColorInput
                      className="form-control w-100"
                      prefixed={true}
                      color={selectedColor}
                      onChange={setSelectedColor}
                    />
                  </div>
                </Rb.Form.Group>
              </Rb.Col>
              <Rb.Col xs={12} sm={12} md={12} lg={6}>
                <Rb.Form.Group className="form-group" controlId="couleurMode">
                  <div className="r-flexed column-gap-10 mb-1">
                    Couleur de déselection
                    <div
                      className="w-10 h-10"
                      style={{
                        borderRadius: "4px",
                        background: unSelectedColor,
                      }}
                    ></div>
                  </div>
                  <div className="c-flexed w-100 row-gap-10">
                    <HexColorPicker
                      className="w-100"
                      color={unSelectedColor}
                      onChange={setUnSelectedColor}
                    />
                    <HexColorInput
                      className="form-control w-100"
                      prefixed={true}
                      color={unSelectedColor}
                      onChange={setUnSelectedColor}
                    />
                  </div>
                </Rb.Form.Group>
              </Rb.Col>
              <Rb.Col xs={12} sm={12} md={12} lg={6}>
                <Rb.Form.Group className="form-group" controlId="couleurMode">
                  <div className="r-flexed column-gap-10 mb-1">
                    Couleur primaire
                    <div
                      className="w-10 h-10"
                      style={{
                        borderRadius: "4px",
                        background: onPrimary,
                      }}
                    ></div>
                  </div>
                  <div className="c-flexed w-100 row-gap-10">
                    <HexColorPicker
                      className="w-100"
                      color={onPrimary}
                      onChange={setOnPrimary}
                    />
                    <HexColorInput
                      className="form-control w-100"
                      prefixed={true}
                      color={onPrimary}
                      onChange={setOnPrimary}
                    />
                  </div>
                </Rb.Form.Group>
              </Rb.Col>
              <Rb.Col xs={12} sm={12} md={12} lg={6}>
                <Rb.Form.Group className="form-group" controlId="couleurMode">
                  <div className="r-flexed column-gap-10 mb-1">
                   Couleur secondaire
                    <div
                      className="w-10 h-10"
                      style={{
                        borderRadius: "4px",
                        background: secondary,
                      }}
                    ></div>
                  </div>
                  <div className="c-flexed w-100 row-gap-10">
                    <HexColorPicker
                      className="w-100"
                      color={secondary}
                      onChange={setSecondary}
                    />
                    <HexColorInput
                      className="form-control w-100"
                      prefixed={true}
                      color={secondary}
                      onChange={setSecondary}
                    />
                  </div>
                </Rb.Form.Group>
              </Rb.Col> */}
            </Rb.Row>
            <div className="float-right r-flexed column-gap-20 mb-20 mt-20">
              <Rb.Button
                onClick={() => {
                  let msg =
                    "Si vous fermez le modal, vous perderez les données déjà enregistrées !!!";
                  CustomAlert({
                    message: msg,
                    yesBtn: () => {
                      if (props.isDashboardProfil === true) {
                        dispatch(closeModal());
                      } else {
                        dispatch(closeModalAdd());
                      }
                    },
                  });
                }}
                bsPrefix="btn btn-cancel"
              >
                Annuler
              </Rb.Button>
              <Rb.Button type="submit" bsPrefix="btn btn-save">
                Enregistrer
              </Rb.Button>
            </div>
          </Rb.Form>
        </div>
      </Rb.Modal.Body>
    </div>
  );
};
