export class ModeModel {
  constructor({
    id = null,
    createdAt,
    designation,
    couleurHEX,
    bottombackgroundColor,
    selectedColor,
    unSelectedColor,
    onPrimary,
    secondary,
    isDefault,
  }) {
    this.id = id;
    this.designation = designation;
    this.couleurHEX = couleurHEX;
    this.bottombackgroundColor = bottombackgroundColor;
    this.selectedColor = selectedColor;
    this.unSelectedColor = unSelectedColor;
    this.onPrimary = onPrimary;
    this.secondary = secondary;
    this.isDefault = isDefault;
    this.createdAt = createdAt;
  }

  toString() {
    return this.designation;
  }

  toJson() {
    let obj = {
      id: this.id,
      designation: this.designation,
      couleurHEX: this.couleurHEX,
      isDefault: this.isDefault,
    };
    console.log(obj);

    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  }
}

export const mapObjectToMode = {
  toFirestore: (m) => {
    return {
      id: m.id,
      designation: m.designation,
      couleurHEX: m.couleurHEX,
      bottombackgroundColor: m.bottombackgroundColor,
      selectedColor: m.selectedColor,
      unSelectedColor: m.unSelectedColor,
      onPrimary: m.onPrimary,
      secondary: m.secondary,
      isDefault: m.isDefault,
      createdAt: m.createdAt,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new ModeModel({
      id: data.id,
      designation: data.designation,
      couleurHEX: data.couleurHEX,
      bottombackgroundColor: data.bottombackgroundColor,
      selectedColor: data.selectedColor,
      unSelectedColor: data.unSelectedColor,
      onPrimary: data.onPrimary,
      secondary: data.secondary,
      isDefault: data.isDefault,
      createdAt: data.createdAt,
    });
  },
};
