import React, { useState } from 'react'
import * as B from 'react-bootstrap';
import Filtres from '../partials/FrequentlyUsed/Filtres';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { motion } from 'framer-motion';
import BarSection from './components/BarSection';
import { customWhereBetweenDate } from '../core/js/backoffice';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const mapFiltersTab = [
    { isDate: true, name: "Date Début", key: "createdAtBegin" },
    { isDate: true, name: "Date Fin", key: "createdAtEnd" },
];



function DashboardV2() {
    document.title = "Dashboard";
    let filters = ["Jour", "Semaine", "Mois", "Année"];
    const defaultGraphQueries = customWhereBetweenDate({ whereToLookInTheCollection: "createdAt", whereToLookValue: new Date(), whereToLookValue1: new Date() });
    const [graphicQueries, setGraphicQueries] = useState([]);


    const cancelFilter = () => {
        setGraphicQueries(defaultGraphQueries);
    }
    const handleFilter = (mapValues) => {
        const createdAtBegin = mapValues.createdAtBegin;
        const createdAtEnd = mapValues.createdAtEnd;
        const tempQueries = customWhereBetweenDate({ whereToLookInTheCollection: "createdAt", whereToLookValue: createdAtBegin, whereToLookValue1: createdAtEnd });
        setGraphicQueries(tempQueries);
    }


    let appPlayStoreInfos = [
        { name: "Installation", count: 1000, cardColor: '#7294F7', circleColor: '#95B0FE', img: 'installation' },
        { name: "Désinstallation", count: 80, cardColor: '#5CC2BC', circleColor: '#81D8D3', img: 'desinstallation' },
    ];

    let postInfos = [
        { name: "Favoris", count: 100 },
        { name: "Clique sur le détail", count: 250 },
        { name: "commentaires", count: 45 },
    ]

    let users = [
        { nom: "diop", nbFav: 5, nbComs: 10, actif: true },
        { nom: "thiam", nbFav: 3, nbComs: 8, actif: false },
        { nom: "fall", nbFav: 1, nbComs: 1, actif: true },
    ];


    let styles = {
        container: {
            /* color:'#337F77', */
            color: '#337F77',
            alignSelf: 'center'

        },
        arrow: {
            filter: 'invert(58%) sepia(0%) saturate(1%) hue-rotate(140deg) brightness(96%) contrast(95%)'
        }
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            x: {
                //display: false,
            },
            y: {
                //display: false,

            }
        }
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Nbre Théme',
                //data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                data: [584, 507, 171, 226, 494, 388, 873],
                backgroundColor: '#cbc87b',
            },
            {
                label: 'Nbre poste',
                data: [915, 326, 39, 586, 996, 560, 749],
                backgroundColor: '#9ab57e',
            },
        ],
    };


    return (
        /*  <motion.div initial={{width:0}} animate={{width:"100%"}} exit={{x :window.innerWidth , transition:{duration:0.4}}} > */
        <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
        >
            <section>
                <div className="title-dash-2 r-flexed">
                    <div className='ml-20'>
                        <span className="text-uppercase textClassInit">Indicateurs</span>
                    </div>
                </div>
            </section>

            <section className="mt-30 indicateur-card">
                <div className="r-flexed">
                    <div className='ml-20'>
                        <span className="text-uppercase textClassInit">Indicateurs</span>
                    </div>
                </div>

                <div className="indicateur-content p-30 m-20">
                    <div className='d-flex'>
                        <B.Row className="m-auto">
                            {
                                filters.map((item, index) => {
                                    return (
                                        <B.Col className="mb-lg-0 mb-md-0 mb-10" key={index} xs={12} sm={6} md={3}
                                            lg={3}>
                                            <B.Button
                                                className="w-100 indic-filter p-20 jc-center align-items-center d-flex btn btn-success">{item}</B.Button>
                                            {/*   <div style={styles.col} className="indic-filter cursor-pointer d-flex">
                                                <span className="m-auto textClassInit" style={styles.container}>{item}</span>
                                            </div> */}

                                        </B.Col>
                                    );
                                })
                            }
                        </B.Row>
                    </div>

                    <div className="mt-30">
                        <B.Row>
                            {
                                appPlayStoreInfos.map((item, index) => {
                                    return (
                                        <B.Col className="mb-lg-0 mb-10" key={index} xs={12} sm={12} md={12}
                                            lg={6}>
                                            <div style={{ background: item.cardColor }} className="storeCard">
                                                <div className="r-flexed jc-btwn h-100 pl-30 pr-30 align-items-center">
                                                    <div className="jc-btwn-col">
                                                        <span className="text-white fsize-22">{item.name}</span>
                                                        <span
                                                            className="text-white fsize-20 font-weight-bold mt-30">{item.count}</span>
                                                    </div>

                                                    <div style={{ background: item.circleColor }}
                                                        className="storeCircleCard">
                                                        <img src={"assets/images/imgs/" + item.img + ".svg"} alt="" />
                                                    </div>

                                                </div>
                                            </div>

                                        </B.Col>
                                    );
                                })
                            }
                        </B.Row>
                    </div>

                    <div className="mt-30">
                        <div className="r-flexed jc-btwn align-items-center p-20 dashSearchBar"
                            style={styles.searchBar}>
                            <span className="textClassInit">Recherche Publication</span>
                            <div>
                                <img style={styles.arrow} src="assets/images/ico/ic_arrow_down.svg" alt="" />
                            </div>
                        </div>
                        <div className="mt-10">
                            <B.Row className="">
                                {
                                    postInfos.map((item, index) => {
                                        return (
                                            <B.Col className="mb-lg-0 mb-10" key={index} xs={12} sm={12} md={12}
                                                lg={4}>
                                                <div className="d-flex border-r-6 p-10 card-post-infos"
                                                    style={{ background: 'white' }}>
                                                    <span
                                                        className="text-uppercase m-auto textClassInit">{item.name}</span>
                                                </div>
                                                <div className="d-flex border-r-6 p-50 mt-10 card-post-infos"
                                                    style={{ background: 'white' }}>
                                                    <span
                                                        className="text-uppercase m-auto textClassInit">{item.count}</span>
                                                </div>
                                            </B.Col>
                                        );
                                    })
                                }
                            </B.Row>
                        </div>
                    </div>


                </div>
            </section>

            <section className="mt-30">
                <div className="title-dash-2 r-flexed">
                    <div className='ml-20'>
                        <span className="text-uppercase textClassInit">Graphics</span>
                    </div>
                </div>
            </section>

            <Filtres
                cancelFilter={cancelFilter}
                tabFilters={mapFiltersTab}
                handleSubmit={handleFilter}
                marginTop="mt-30"
            />

            <BarSection
                queries={graphicQueries}
            />

            <div className="mt-50 text-center dash-user-title">
                <span className='fsize-18 textClassInit' style={{ color: '#337F77' }}>Utilisateurs</span>
            </div>
            <div className="mt-10 overflow-x-auto dash-table">
                <table
                    className="table basic-table dash-table table-borderless table-responsive">
                    <thead className="dash-thead">
                        <tr>
                            <th className="text-uppercase fsize-14">Nom</th>
                            <th className="text-uppercase fsize-14">Nbre de favoris</th>
                            <th className="text-uppercase fsize-14">Nbre de commentaires</th>
                            <th className="text-uppercase fsize-14">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((item, index) => {
                                return (
                                    <tr key={index} className="mb-20 dash-table-tr">
                                        <td className="text-uppercase fsize-14">{item.nom}</td>
                                        <td className="text-uppercase fsize-14">{item.nbFav}</td>
                                        <td className="text-uppercase fsize-14">{item.nbComs}</td>
                                        <td className="d-flex">
                                            <div className="text-uppercase m-auto fsize-14 textClassInit"
                                                style={item.actif ? {
                                                    background: 'rgba(51, 127, 119, 0.45)',
                                                    color: '#337F77',
                                                    width: '100px',
                                                    borderRadius: '6px',
                                                    padding: '5px'
                                                } : {
                                                    background: 'rgba(223, 29, 29, 0.45)',
                                                    color: '#DF1D1D',
                                                    width: '100px',
                                                    borderRadius: '6px',
                                                    padding: '5px'
                                                }}>
                                                {item.actif ? "activé" : "désactivé"}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

        </motion.div>
    )
}

export default DashboardV2;
