import React from 'react'

function BlockUI(props) {
    if (!props.blocking) {
        return "";
      } else {
        return (
          <div className="block-ui-container">
            <div className="block-ui-overlay" />
            <div className="block-ui-message-container">
              <div className="block-ui-message">
                {/* <h4>{props.title}</h4> */}
                <div className="loading-indicator">
                    <div className="spinner-container" style={{margin:'auto'}}>
                        <div className="block-ui-spinner"></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
}

BlockUI.defaultProps = {
    blocking: false,
    title: "Loading"
  };

export default BlockUI ;
