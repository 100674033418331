
export class UsersBackModel{
    constructor({id = null,eraseImg,prenom,nom,displayName,adresse,imgProfil,telephone,genre,actif,email,password=null,profilId,profil,permissions= [],token,notifNewPost,notifNewPostWaiting,notifNewComment}){
        this.id = id;
        this.prenom = prenom;
        this.nom = nom;
        this.eraseImg =  eraseImg;
        this.displayName = displayName;
        this.adresse = adresse;
        this.telephone = telephone;
        this.genre = genre;
        this.actif = actif;
        this.email = email;
        this.password = password;
        this.imgProfil = imgProfil;
        this.profil = profil;
        this.profilId = profilId;
        this.permissions = permissions;
        this.token = token;
        this.notifNewPost = notifNewPost;
        this.notifNewPostWaiting = notifNewPostWaiting;
        this.notifNewComment = notifNewComment;
    }

    toString(){
        return this.displayName + ' , ' +  this.permissions ;
    }

    toJson(){
        return {
            id:this.id,
            prenom:this.prenom,
            nom:this.nom,
            displayName:this.displayName,
            adresse:this.adresse,
            telephone:this.telephone,
            genre:this.genre,
            email:this.email,
            profil:this.profil,
            notifNewPost:this.notifNewPost,
            notifNewPostWaiting:this.notifNewPostWaiting,
            notifNewComment:this.notifNewComment,
        }
    }
}

export const mapObjectToUserBack = {
    toFirestore: (u)=>{
        let mTmp = Object.fromEntries(Object.entries(u))

        mTmp = {...mTmp,password:undefined,usedCount:undefined,permissions:undefined}
        Object.keys(mTmp).forEach(key =>
        {
            if (mTmp[key] === undefined)
            {
                delete mTmp[key];
            }
        });
        
        return mTmp;
        /* return {
        id : u.id,
        prenom : u.prenom,
        nom : u.nom,
        displayName : u.displayName,
        adresse : u.adresse,
        telephone : u.telephone,
        genre : u.genre,
        email : u.email,
        //password : u.password,
        imgProfil:u.imgProfil,
        profil : u.profil,
        notifNewPost:u.notifNewPost,
        notifNewPostWaiting:u.notifNewPostWaiting,
        notifNewComment:u.notifNewComment,
        //permissions : u.permissions
        }; */
    },
    fromFirestore:(snapshot, options)=>{
        const data = snapshot.data(options);
        //let newFileUrl =data.imgProfil !== undefined ?  String(data.imgProfil): data.imgProfil;
        let newFileUrl = String(data.imgProfil ?? "/assets/images/imgs/upload.png");
        // newFileUrl = newFileUrl.replace("localhost",host)
        return new UsersBackModel({notifNewPost:data.notifNewPost,notifNewPostWaiting:data.notifNewPostWaiting,notifNewComment:data.notifNewComment,token:data.token,imgProfil:data.imgProfil ? newFileUrl:data.imgProfil,id:data.id, prenom:data.prenom, nom:data.nom, displayName:data.displayName ?? data.prenom + ' ' + data.nom, adresse:data.adresse, telephone:data.telephone, genre:data.genre,actif:data.disabled === false ? true : false, email:data.email,profilId:data.profil["id"] ?? "",profil:data.profil,permissions:[]});
    }
}