import {initializeApp} from "firebase/app" ;
import {getAuth,signInWithEmailAndPassword,sendPasswordResetEmail,signOut,connectAuthEmulator} from "firebase/auth" ;
import {getFirestore,connectFirestoreEmulator, doc, updateDoc, collection, setDoc} from "firebase/firestore";
import {connectFunctionsEmulator, getFunctions, httpsCallable} from 'firebase/functions';
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { CustomAlert } from "../js/backoffice";
import { authError, authLoading, authNone} from "../Redux/features/user";
import { store } from "../Redux/store";
import { getError} from "./errors";
import { fetchDataWhere} from "./services/FirebaseService";
import { getMessaging ,getToken, onMessage,deleteToken,isSupported} from "firebase/messaging";
import toast from "react-hot-toast";
import * as Rb from "react-bootstrap";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const selectedBase = process.env.REACT_APP_SELECTED_BASE ;
const currentEnv = process.env.NODE_ENV ;

console.log("current selected base",selectedBase);
console.log("current env",currentEnv);


//Guindy Firebase
// const firebaseConfig = {
//     apiKey: "AIzaSyBLPYtlHpusr8eAGXnvl1oOKn2GF6AjrpI",
//     authDomain: "bilal-back.firebaseapp.com",
//     projectId: "bilal-back",
//     storageBucket: "bilal-back.appspot.com",
//     messagingSenderId: "189889332205",
//     appId: "1:189889332205:web:d85e61080123ebf1d91de4",
//     measurementId: "G-PTTV7E5G5S"
// };  


  // Bilal Firebase
  const firebaseConfig = {
    apiKey: "AIzaSyDtRpLGTnhdY2Fkln3WwDCO9IWZx0rIpGg",
    authDomain: "bilal-5b432.firebaseapp.com",
    projectId: "bilal-5b432",
    storageBucket: "bilal-5b432.appspot.com",
    messagingSenderId: "46869232580",
    appId: "1:46869232580:web:94bebfd5901fd118e90291"
  };

   // Initialize Firebase
   const app = initializeApp(firebaseConfig);
   const auth = getAuth(app) ;
   const functions = getFunctions(app);
   const firestore = getFirestore(app);
   const db = getFirestore(app);
   //const messaging = getMessaging(app);
   const messaging = (async () => {
    try {
        const isSupportedBrowser = await isSupported();
        if (isSupportedBrowser) {
            return getMessaging(app);
        }
        console.log('Firebase not supported this browser');
        return null;
    } catch (err) {
        console.log(err);
        return null;
    }
    })();
   export const storage = getStorage(app);

   //De base la methode register de firebase apres success remplace le user connected au new user created donc la solution est d'utiliser une variable app secondaire qui nous permettra de save de news users.... (sakhewar diop solutions)
   //Ou Balancer l'enregistrement de new users vers les fonctions cloud (jacques dembi solutions)
   //const authSecondary = getAuth(secondaryApp) ;
   //const secondaryApp = initializeApp(firebaseConfig, "Secondary");



   //export const host = "192.168.1.11";

   // Use firebase local

  /* export const host = "localhost";
  connectAuthEmulator(auth, "http://"+host+":9099")
  connectFirestoreEmulator(firestore, host,8080);
  connectFunctionsEmulator(functions , host, 5001);
  connectStorageEmulator(storage,host, 9199); */

   export const pushNotifications = httpsCallable(functions,'pushNotifications');
   export const userTableSeeder = httpsCallable(functions,'userTableSeeder');
   export const createAuthUser = httpsCallable(functions,'createAuthUser');
   export const updateAuthUser = httpsCallable(functions,'updateAuthUser');
   export const deleteAuthUser = httpsCallable(functions,'deleteAuthUser');
   export const deleteItemFirebase = httpsCallable(functions ,'deleteItem' );
   export const replicateData = httpsCallable(functions ,'replicateData' );
   
   function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

   //Login with email and pasword
   const logInWithEmailAndPassword = async (email, password) => {
    console.log("LOGGIIn ");
    try {
      store.dispatch(authLoading(true));
      let aaa = await fetchDataWhere({dataType:"users",whereToLookInTheCollection:"email",whereToLookValue:email});
      //console.log(aaa);
      // await signInWithEmailAndPassword(auth, email, password);
      if(aaa.length === 1){
        await signInWithEmailAndPassword(auth, email, password);
      }else if(aaa.length === 0){
        store.dispatch(authError({error:true, message:"Utilisateur non trouvé"}));
      }
      store.dispatch(authLoading(false));
    } catch (err) {
      store.dispatch(authLoading(false));
      //console.log(err);
      store.dispatch(authError({error:true, message:getError(err.message)}));
      //await sleep(3500) ;
      //store.dispatch(authError({error:false, message:""}));
    }
  };

  //Reset password
  const sendPasswordReset = async (email) => {
    try {
      store.dispatch(authLoading(true));
      await sendPasswordResetEmail(auth, email);
      store.dispatch(authLoading(false));
      store.dispatch(authError({error:true, message:"Nous vous avons envoyé un email avec un lien pour récupérer votre compte."}));
      await sleep(5000) ;
      store.dispatch(authError({error:false, message:""}));
    } catch (err) {
      store.dispatch(authLoading(false));
      //console.error(err);
      store.dispatch(authError({error:true, message:getError(err.message)}));
      await sleep(3500) ;
      store.dispatch(authError({error:false, message:""}));
    }
  };

  //logout
  const logout = () => {
    const func = async () => {
      signOut(auth);
      store.dispatch(authNone());
      let yy = await messaging;
      await deleteToken(yy)
    }
    CustomAlert({message: "Se déconnecter ?", yesBtn: func});
   
    
  }

  let api_key = "BMRUKGSMt01Kna9dnhQw_k-hZgMGxSOgZ1Tz1soZFwKXIvXTRJcf111eD9Vo0YV9VRykt9tYyFL0bzRW2vDsMno"
  export const requestForToken = async () => {
    let ref;
    if(store.getState().authState["value"]["currentUser"]){
      if(store.getState().authState["value"]["currentUser"]["id"]){
        ref = doc(db,"users_"+selectedBase,store.getState().authState["value"]["currentUser"]["id"]);
        let yy = await messaging;
        return getToken(yy, { vapidKey: api_key })
      .then((currentToken) => {
       
        if (currentToken) {
          console.log(currentToken);
          // Perform any other neccessary action with the token
          //console.log(store.getState().authState["value"]["currentUser"]["id"]);
          let ref = doc(db,"users_"+selectedBase,store.getState().authState["value"]["currentUser"]["id"])
          updateDoc(ref,{
            "token":currentToken
          })
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          //Notification.requestPermission()
          updateDoc(ref,{
            "token":null
          })
          Notification.requestPermission().then((result)=>{
            if(result === "denied") {
             alertToastNotif()
            }
           })
        }
      })
      .catch((err) => {
        updateDoc(ref,{
          "token":null
        })
        Notification.requestPermission().then((result)=>{
         if(result === "denied") {
          alertToastNotif()
         }
        })
        console.log('An error occurred while retrieving token. ', err);

      });
      }
     
    }
    
  };

  export const onMessageListener = () =>
  new Promise(async (resolve) => {
    let yy = await messaging;
    onMessage(yy, (payload) => {
      resolve(payload);
    });
    
});

function alertToastNotif() {
  let toastId = toast["success"](
    <Rb.Stack className='w-100' direction="horizontal">
      <span className="fsize-15" style={{whiteSpace: 'nowrap'}}>Activer les notifications pour BILAL dans les paramètres du site !!!</span>
      <span className="fsize-18 ml-auto">
          <FontAwesomeIcon className="cursor-pointer " onClick={()=>{toast.dismiss(toastId)}} icon={faTimesCircle} />
        </span>
    </Rb.Stack>,
    {
      duration: 7000,
      position: 'bottom-left',
      style:{
        marginBottom:"20px",
        minWidth:"600px",
        width:"100%"
      },
      icon: <img width="40" alt="" height="40" src="/assets/images/logo/logo.svg" />
    }

  );
}

export const sendNotifToAdmin = async ({notification={title:"",body:"..."},tokens=[], usersId=[],isClient=false,clientBody={}}) =>
{
  let v = {...notification, tokens:tokens}
  pushNotifications(v).then((res)=>{
    console.log(res);
  })
  let nn = "users_";

  if(isClient === true)
  {
    nn = "clients_"
  }

  let dataToSave = {};
  if(isClient === true)
  {
    dataToSave = {...clientBody , isViewed:false}
  }
  else
  {
    dataToSave = {...notification , isViewed:false,isArchived:false}
  }


  usersId.forEach(async (i)=>
  {
    let dataType = nn + selectedBase + "/" + i + "/notifications/";
    const collectionRef = collection(db, dataType);
    let ref = doc(collectionRef);
    setDoc(ref, dataToSave)/* .then(()=>{
      updateDoc(ref,{id:ref.id,createdAt:serverTimestamp()})
    }) */
  })

}


  
export {auth,db,logInWithEmailAndPassword,sendPasswordReset,logout,selectedBase,currentEnv}