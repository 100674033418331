import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {db, selectedBase} from "../../../core/Firebase/firebase";
import {UsersBackModel} from "../../../core/models/UsersBack-Model";
import * as Rb from 'react-bootstrap';
import {CustomAlert, customToast} from "../../../core/js/backoffice";
import {closeModal, closeModalAdd} from "../../../core/Redux/features/user";
import {doc} from "firebase/firestore";
import {
    fetchforeignDataTab,
    saveUserToDocsAndStorage,
    updateUserToDocsAndStorage
} from "../../../core/Firebase/services/FirebaseService";

export const ModalBodyUsers = (props) => {
    const [validated, setValidated] = useState(false);
    const [isErase, setIsErase] = useState(false);
    const [id, setId] = useState(null);
    const [prenom, setPrenom] = useState("");
    const [nom, setNom] = useState("");
    const [adresse, setAdresse] = useState("");
    const [telephone, setTelephone] = useState("");
    const [genre, setGenre] = useState("");
    const [profil, setProfil] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password1, setPassword1] = useState("");
    const [file, setFile] = useState();
    const [itemToUpdate, setItemToUpdate] = useState();
    const [fileUrl, setFileUrl] = useState();
    const [isUpdate] = useState(props.isAdd === false);
    const dispatch = useDispatch();

    useEffect(() => {
        //console.log(props.profils);
        if (isUpdate) {
            if (props.itemToUpdate) {
                setItemToUpdate(props.itemToUpdate);
                let b = props.itemToUpdate;
                setPrenom(b.prenom);
                setNom(b.nom);
                setAdresse(b.adresse);
                setTelephone(b.telephone);
                setGenre(b.genre);
                setProfil(b.profilId);
                setEmail(b.email);
                setFileUrl(b.imgProfil);
                setId(b.id);
                //console.log(b.id);
            }
        }

    }, []);


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        //console.log(prenom,nom,adresse,telephone,genre,email,password,password1);
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            if (!profil || profil === 'default') {
                customToast({message: "Renseignez le champ profil",type:"error"});
            } else {
                let tabProfils = Array.from(props.profils ?? []);
                let k = tabProfils.filter((u) => (u["id"] === profil));
                let hh = [];
                if (k.length === 1) {
                    hh = await fetchforeignDataTab({tab: k[0]["permissions"] ?? []});
                }

                let v = {
                    prenom: prenom,
                    nom: nom,
                    displayName: prenom + ' ' + nom,
                    adresse: adresse,
                    telephone: telephone,
                    genre: genre,
                    email: email,
                    profil: doc(db, "profils_" + selectedBase, profil),
                    notifNewPost: hh.includes("list-posts") && hh.includes("detail-posts"),
                    notifNewPostWaiting: hh.includes("list-posts") && hh.includes("can-validate-posts"),
                    notifNewComment: hh.includes("list-posts") && hh.includes("detail-posts") && hh.includes("list-commentaires"),
                };

                if (!isUpdate) {
                    if (email && password && password1) {
                        //console.log(profil);

                        if (password !== password1) {
                            customToast({message: "Les 2 mots de passe doivent être identiques !",type:"error"});
                        } else {
                            //dispatch(blockUIAction(true));
                            const user = new UsersBackModel({
                                ...v,
                                password: password,
                                imgProfil: null,
                            });
                            await saveUserToDocsAndStorage({dataType: "users", jsonData: user, file: file,isDashboardProfil:props.isDashboardProfil});
                        }
                    }
                } else {
                    let vv = {
                        eraseImg: isErase,
                        id: id
                    };
                    v = {...v, ...vv}

                    if (password && password !== '') {
                        if (password !== password1) {
                            customToast({message: "Les 2 mots de passe doivent être identiques !",type:"error"});
                        } else {
                            const user = new UsersBackModel({...v, password: password});
                            await updateUserToDocsAndStorage({dataType: "users", jsonData: user, file: file,isDashboardProfil:props.isDashboardProfil})
                        }
                    } else {
                        const user = new UsersBackModel(v);
                        //console.log(user);
                        updateUserToDocsAndStorage({dataType: "users", jsonData: user, file: file, isDashboardProfil:props.isDashboardProfil})
                    }

                }
            }

        }
        setValidated(true);

    };
    const handleFileChange = (event) => {
        //console.log(event.target.files);
        let filesTab = event.target.files;
        if (filesTab.length > 0) {
            setFile(filesTab[0]);
            setIsErase(false);
            setFileUrl(URL.createObjectURL(event.target.files[0]))
        }

    }
    const eraseImage = () => {
        if (isUpdate === true) {
            console.log(itemToUpdate.imgProfil);
            if (itemToUpdate.imgProfil) {
                setIsErase(true);
            }

        }
        setFileUrl(undefined);
    }
    return (
        <div>
            <Rb.Modal.Body>
                <div className="container-fluid">
                   <div className="d-flex">
                        <div className="c-flexed m-auto">
                            <label className="cursor-pointer">
                                <input type="file" accept="image/*" onChange={handleFileChange}/>
                                {!fileUrl && <div className="doted-circle cursor-pointer">
                                    <img alt="" src="/assets/images/ico/upload-pub.svg"/>
                                    <span className="text-indic text-center">Ajouter<br/>votre image ici</span>

                                </div>}

                                {fileUrl && <span className="avatar avatar-online d-flex box-shadow-1 hpx-130 wdpx-130">
                                                    <img alt="" className="m-auto hpx-130 wdpx-130"
                                                         src={fileUrl}/>
                                                </span>}


                            </label>
                            {fileUrl && <div className="d-flex">
                                <button style={{padding: '5px 10px'}} onClick={eraseImage}
                                        className="btn btn-danger m-auto"><i className='fa fa-trash'/></button>
                            </div>}
                        </div>


                    </div>

                    <Rb.Form className="mt-20" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Rb.Row>
                            <Rb.Col xs={12} sm={12} md={12} lg={6}>
                                <Rb.Form.Group className="form-group" controlId="prenom">
                                    <Rb.Form.Label className="textClassInit">Prénom <span
                                        className="text-danger">*</span></Rb.Form.Label>
                                    <Rb.Form.Control value={prenom} onChange={(e) => setPrenom(e.target.value)} required
                                                     className="modal-form" type="text" placeholder="Prénom"/>
                                    <Rb.Form.Control.Feedback className="" type="invalid">Prénom
                                        Obligatoire</Rb.Form.Control.Feedback>
                                </Rb.Form.Group>
                            </Rb.Col>
                            <Rb.Col xs={12} sm={12} md={12} lg={6}>
                                <Rb.Form.Group className="form-group" controlId="nom">
                                    <Rb.Form.Label className="textClassInit">Nom <span className="text-danger">*</span></Rb.Form.Label>
                                    <Rb.Form.Control value={nom} onChange={(e) => setNom(e.target.value)} required
                                                     className="modal-form" type="text" placeholder="Nom"/>
                                    <Rb.Form.Control.Feedback className="" type="invalid">Nom
                                        Obligatoire</Rb.Form.Control.Feedback>
                                </Rb.Form.Group>
                            </Rb.Col>
                            <Rb.Col xs={12} sm={12} md={12} lg={6}>
                                <Rb.Form.Group className="form-group" controlId="adresse">
                                    <Rb.Form.Label className="textClassInit">Adresse</Rb.Form.Label>
                                    <Rb.Form.Control value={adresse} onChange={(e) => setAdresse(e.target.value)}
                                                     className="modal-form" type="text" placeholder="Adresse"/>
                                </Rb.Form.Group>
                            </Rb.Col>
                            <Rb.Col xs={12} sm={12} md={12} lg={6}>
                                <Rb.Form.Group className="form-group" controlId="tel">
                                    <Rb.Form.Label className="textClassInit">Téléphone</Rb.Form.Label>
                                    <Rb.Form.Control value={telephone} onChange={(e) => setTelephone(e.target.value)}
                                                     className="modal-form" type="number" placeholder="Téléphone"/>
                                </Rb.Form.Group>
                            </Rb.Col>
                            <Rb.Col xs={12} sm={12} md={12} lg={6}>
                                <Rb.Form.Group className="form-group" controlId="genre">
                                    <Rb.Form.Label className="textClassInit">Genre</Rb.Form.Label>
                                    <Rb.Form.Control as="select" type="select" value={genre}
                                                     onChange={(e) => setGenre(e.target.value)}
                                                     className="modal-form" placeholder="Genre">
                                        <option value="" disabled>Choisir Genre</option>
                                        <option value="Homme">Homme</option>
                                        <option value="Femme">Femme</option>
                                    </Rb.Form.Control>
                                </Rb.Form.Group>
                            </Rb.Col>
                            <Rb.Col xs={12} sm={12} md={12} lg={6}>
                                <Rb.Form.Group className="form-group" controlId="profil">
                                    <Rb.Form.Label className="textClassInit">Profil <span
                                        className="text-danger">*</span></Rb.Form.Label>
                                    <Rb.Form.Control value={profil} as="select" type="select"
                                                     onChange={function (e) {
                                                         setProfil(e.target.value)

                                                         //console.log(props.profils[e.target.value]);
                                                     }} required>
                                        <option value="default">Choisir Profil</option>
                                        {props.profils?.map((p, index) => {
                                            return (
                                                <option key={index} value={p.id}>{p.nomProfil}</option>
                                            );
                                        })}
                                    </Rb.Form.Control>
                                </Rb.Form.Group>
                            </Rb.Col>
                            <Rb.Col xs={12} sm={12} md={12} lg={12}>
                                <Rb.Form.Group className="form-group" controlId="formBasicEmail">
                                    <Rb.Form.Label className="textClassInit">Email <span
                                        className="text-danger">*</span></Rb.Form.Label>
                                    <Rb.Form.Control value={email} min='6' onChange={(e) => setEmail(e.target.value)}
                                                     required className="modal-form" type="email" autoComplete='off'
                                                     placeholder="Email"/>
                                    <Rb.Form.Control.Feedback className="" type="invalid">Entrez une adresse email
                                        correcte</Rb.Form.Control.Feedback>
                                </Rb.Form.Group>
                            </Rb.Col>
                            <Rb.Col xs={12} sm={12} md={12} lg={6}>
                                <Rb.Form.Group className="form-group" controlId="mdp">
                                    <Rb.Form.Label className="textClassInit">Mot de passe {!isUpdate && <span
                                        className="text-danger">*</span>}</Rb.Form.Label>
                                    <Rb.Form.Control value={password} onChange={(e) => setPassword(e.target.value)}
                                                     required={!isUpdate} className="modal-form" type="password"
                                                     autoComplete="new-password"
                                                     placeholder="Mot de passe"/>
                                    <Rb.Form.Control.Feedback className="" type="invalid">Mot de passe
                                        Obligatoire</Rb.Form.Control.Feedback>
                                </Rb.Form.Group>
                            </Rb.Col>
                            <Rb.Col xs={12} sm={12} md={12} lg={6}>
                                <Rb.Form.Group className="form-group" controlId="confirm-mdp">
                                    <Rb.Form.Label className="textClassInit">Confirmer Mot de passe {!isUpdate && <span
                                        className="text-danger">*</span>}</Rb.Form.Label>
                                    <Rb.Form.Control value={password1} onChange={(e) => setPassword1(e.target.value)}
                                                     required={!isUpdate} className="modal-form" type="password"
                                                     autoComplete="off"
                                                     placeholder="Confirmer Mot de passe"/>
                                    <Rb.Form.Control.Feedback className="" type="invalid">Confirmer le mot de
                                        passe</Rb.Form.Control.Feedback>
                                </Rb.Form.Group>
                            </Rb.Col>
                        </Rb.Row>
                        <div className="float-right r-flexed column-gap-20 mb-20 mt-20">
                            <Rb.Button onClick={() => {
                                let msg = "Si vous fermez le modal, vous perderez les données déjà enregistrées !!!";
                                CustomAlert({
                                    message: msg, yesBtn:() => {
                                        if(props.isDashboardProfil === true){
                                            dispatch(closeModal())
                                        }else{
                                            dispatch(closeModalAdd())
                                        }
                                        
                                    }
                                })
                            }} bsPrefix="btn btn-cancel">
                                Annuler
                            </Rb.Button>
                            <Rb.Button type="submit" bsPrefix="btn btn-save">
                                Enregistrer
                            </Rb.Button>
                        </div>
                    </Rb.Form>
                </div>
            </Rb.Modal.Body>


        </div>
    );
}