import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, InputGroup, Row, Toast} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {auth, sendPasswordReset} from "../core/Firebase/firebase";
import {authError, authSuccess, selectAuthState} from '../core/Redux/features/user';

function ResetPwd() {
    const mounted = useRef();
    const authState = useSelector(selectAuthState);
    const [email, setEmail] = useState("");
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((tk)=>{
                    dispatch(authSuccess({user: {id: user.uid, email: user.email , token:tk}}));
                })
            }
        });
    });


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            if (email) {
                sendPasswordReset(email);
            }
        }
        setValidated(true);

    };

    useEffect(() => {
        if (!mounted.current) {
            //did mounted
            document.title = "Bilal";
            mounted.current = true;
        } else {
            //did update
        }
    });



    return (
        <div style={{height: '100vh', width: '100vw'}} className='body_login'>
            <div className="login-accueil">
                <img alt="" src="/assets/images/imgs/img_accueil_login.svg"/>
            </div>
            <div className="row  h-100">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-flex col-card-login" style={{marginLeft: "auto"}}>
                    <div className="login-card-global w-100">
                        <div className="card-login">
                            <div className="card-login1 c-flexed jc-center">

                                <div className="align-self-center circle-login-card mb-30">
                                    <img style={{transition: '18s !important'}} alt=""
                                         src="/assets/images/logo/logo.svg"/>
                                </div>

                                <div className="mrpx-20 mlpx-20 form-login">
                                    <Form /* noValidate */ validated={validated} onSubmit={handleSubmit}>
                                        <Row className="row-gap-30 no-gutters mb-30">
                                            <Form.Group as={Col} className="col-12">
                                                <InputGroup className="w-100" hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend" className='icon-group'><img
                                                        alt="" className="img-70"
                                                        src="/assets/images/ico/ic_email_login.svg"/></InputGroup.Text>
                                                    <Form.Control value={email}
                                                                  onChange={(e) => setEmail(e.target.value)}
                                                                  className="login-form" required type="email"
                                                                  style={{borderRadius: "0px 6px 6px 0px"}}
                                                                  placeholder='Email' name="username"
                                                                  autoComplete='username'
                                                                  aria-describedby='inputGroupPrepend'/>
                                                    {/* <InputGroup.Text className="icon-group-end"/> */}
                                                    {/* <Form.Control.Feedback className="text-light fsize-6"type="invalid">Entrez une adresse email correcte</Form.Control.Feedback> */}
                                                </InputGroup>
                                            </Form.Group>


                                            <Toast autohide delay={3000} onClose={() => {
                                                dispatch(authError({error: false, message: ""}))
                                            }} className="w-100" show={authState.error === true}>
                                                <Toast.Body>
                                                    <div style={authState.error ? {transition: "0.5s"} : {}}
                                                         className="bg-dark w-100 p-10 text-light rounded fsize-14">
                                                        {authState.message}
                                                    </div>
                                                </Toast.Body>
                                            </Toast>

                                            <Button bsPrefix="login-button" className="col-12"
                                                    type="submit">
                                                <div className="r-flexed column-gap-10 jc-center">
                                                    Envoyer
                                                    {authState.loading && <div className="spinner-container">
                                                        <div className="loading-spinner"></div>
                                                    </div>}
                                                </div>
                                            </Button>


                                        </Row>

                                    </Form>
                                </div>

                                <div className="text-center">
                                    <Link to="/login" className="text-light fsize-18">Se connecter</Link>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ResetPwd;
