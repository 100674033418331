export class PermissionsModel{
    constructor(id, designation, description,createdAt){
        this.id = id ;
        this.designation = designation ;
        this.description = description ;
        this.createdAt = createdAt
    }

    toString(){
        return this.designation + ' , ' + this.description ;
    }
}

export const mapObjectToPermissions = {
    toFirestore: (permission)=>{
        return {
            id:permission.id,
            designation:permission.designation,
            description:permission.description
        };
    },
    fromFirestore: (snapshot, options)=>{
        const data = snapshot.data(options);
        return new PermissionsModel(data.id, data.designation, data.description,data.createdAt);
    }
}