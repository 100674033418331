import React from 'react';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {canModule, canSousModule} from '../../core/js/backoffice';
import {/* selectAuthState, */ selectDataApiState} from '../../core/Redux/features/user';

const mainMenu = React.createRef();
const firstMenu = React.createRef();

function MenuWeb() {
    const location = useLocation();
    var currentUrl = location.pathname;
    const dataApiState = useSelector(selectDataApiState);
    //const authState = useSelector(selectAuthState);
    //console.log(authState.userInfos);
    //(currentUrl);
    const getCurrentPage = (value) => {
        return currentUrl.indexOf(value) !== -1;
    }


    return (
        <div className="content-menu">
            {/* <!-- Include Menu Bar from Partials --> */}
            <div className="main-menu menu-accordion header-0" ref={mainMenu}
                /* onClick="closeNav()" */
                 style={{width: "200px !important"}}>
                <div className="main-menu-content">

                    <div className="main-menu-scroll">
                        <div data-component="sidebar" className='h-100'>
                            <div className="sidebar h-100">
                                <ul className="list-group first-menu"
                                    ref={firstMenu}>
                                    {
                                        dataApiState.modules.map((m, index) => {
                                            return (<div key={index}>
                                                {
                                                    (m.modules.length === 0 && canModule(m.keyPage)) &&
                                                    <li className="list-group-item px-1">
                                                        <Link className="item-menu-mobile" to={m.link}>
                                                            <img alt="" title={m.nom}
                                                                 className={(getCurrentPage(m.link) ? 'activeImg' : '') + ' menu-img'}
                                                                 src={"/assets/images/imgs/" + m.img}/>
                                                            <div
                                                                className={(getCurrentPage(m.link) ? 'activeTitle' : '') + ' menu-title text-uppercase'}
                                                                id="menu-title">
                                                                {m.nom}
                                                            </div>
                                                        </Link>
                                                    </li>
                                                }
                                                {/* <!-- Sous Menu --> */}
                                                {
                                                    (m.modules.length > 0 && canModule(m.keyPage)) &&
                                                    <li className="list-group-item px-1">
                                                        {/* <!-- item menu --> */}
                                                        <div className="item-menu-mobile cursor-pointer">
                                                            <img alt="" title={m.nom}
                                                                 className={(getCurrentPage(m.link) ? 'activeImg' : '') + ' menu-img'}
                                                                 src={"/assets/images/imgs/" + m.img}/>
                                                            <div
                                                                className={(getCurrentPage(m.link) ? 'activeTitle' : '') + ' menu-title text-uppercase'}
                                                                id="menu-title">{m.nom}</div>

                                                        </div>

                                                        {/* <!-- sous menu --> */}
                                                        <ul className="flex-column d-inline-block submenu pl-1">

                                                            <li className="list-group-item w-100">
                                                                <div className="row no-gutters pl-0 mt-30 submenu-item-content">
                                                                    {
                                                                        m.modules.map((mm) => {

                                                                            return (
                                                                                canSousModule(mm.keyPage) &&
                                                                                <div key={mm.order}
                                                                                     className={"col-6 w-100 submenu-item"} style={{marginBottom:"22px"}}>
                                                                                    <Link to={mm.link}>
                                                                                        <div className="submenu-card">
                                                                                            <img alt=""
                                                                                                 className="img-before"
                                                                                                 src={"/assets/images/imgs/" + mm.img}/>
                                                                                            <img alt=""
                                                                                                 className="img-after"
                                                                                                 src={"/assets/images/imgs/" + mm.img1}/>
                                                                                            <span>{mm.nom}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            );

                                                                        })

                                                                    }
                                                                </div>

                                                                <div className="submenu-item-mobile">
                                                                    {
                                                                        m.modules.map((mm) => {
                                                                            //console.log(mm);
                                                                            return (
                                                                                <Link key={mm.order}
                                                                                      className="item-mobile-list mb-2"
                                                                                      to={mm.link}>
                                                                                    <img alt=""
                                                                                         className="menu-img-mobile"
                                                                                         src={"/assets/images/imgs/" + mm.img1}/>
                                                                                    <div
                                                                                        className="menu-title-mobile text-uppercase">{mm.nom}
                                                                                    </div>
                                                                                </Link>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>


                                                            </li>
                                                        </ul>

                                                    </li>
                                                }
                                            </div>)
                                        })
                                    }
                                </ul>

                                {(2 - 1) !== 1 &&
                                <ul className="list-group flex-column d-inline-block first-menu" ref={firstMenu}>

                                    {/*  <!-- DashBoard --> */}
                                    <li className="list-group-item py-4 px-1">
                                        <Link className="item-menu-mobile" to="/dashboard">
                                            <img alt=""
                                                 className={(getCurrentPage('dashboard') ? 'activeImg' : '') + ' menu-img'}
                                                 src="/assets/images/imgs/dashboard.png"/>
                                            <div
                                                className={(getCurrentPage('dashboard') ? 'activeTitle' : '') + ' menu-title text-uppercase'}
                                                id="menu-title">
                                                Dashboard
                                            </div>

                                        </Link>

                                    </li>

                                    {/* <!-- Back Office --> */}
                                    <li className="list-group-item py-4 px-1">
                                        {/* <!-- item menu --> */}
                                        <div className="item-menu-mobile">
                                            <img alt=""
                                                 className={(getCurrentPage('/backoffice') ? 'activeImg' : '') + ' menu-img'}
                                                 src="/assets/images/imgs/backoffice.png"/>
                                            <div
                                                className={(getCurrentPage('/backoffice') ? 'activeTitle' : '') + ' menu-title text-uppercase'}
                                                id="menu-title">BACK OFFICE
                                            </div>

                                        </div>

                                        {/* <!-- sous menu --> */}
                                        <ul className="flex-column d-inline-block submenu pl-1">

                                            <li className="list-group-item pl-1">
                                                <div className="row submenu-item-content">
                                                    <div className="col-6 submenu-item mb-2 mt-2">
                                                        <Link to="/backoffice/clients">
                                                            <div className="submenu-card">
                                                                <img alt="" className="img-before"
                                                                     src="/assets/images/imgs/usersgroup.png"/>
                                                                <img alt="" className="img-after"
                                                                     src="/assets/images/imgs/usersgroup1.png"/>
                                                                <span>Clients</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 submenu-item mb-2 mt-2">
                                                        <Link to="/backoffice/themes">
                                                            <div className="submenu-card">
                                                                <img alt="" className="img-before"
                                                                     src="/assets/images/imgs/theme.png"/>
                                                                <img alt="" className="img-after"
                                                                     src="/assets/images/imgs/theme1.png"/>
                                                                <span>Théme</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 submenu-item mb-2 mt-2">
                                                        <Link to="/backoffice/sousthemes">
                                                            <div className="submenu-card">
                                                                <img alt="" className="img-before"
                                                                     src="/assets/images/imgs/sous_theme.png"/>
                                                                <img alt="" className="img-after"
                                                                     src="/assets/images/imgs/sous_theme1.svg"/>
                                                                <span>Sous Théme</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 submenu-item mb-2 mt-2">
                                                        <Link to="/backoffice/soussousthemes">
                                                            <div className="submenu-card">
                                                                <img alt="" className="img-before"
                                                                     src="/assets/images/imgs/sous_sous_theme.png"/>
                                                                <img alt="" className="img-after"
                                                                     src="/assets/images/imgs/sous_sous_theme.svg"/>
                                                                <span>Sous Sous Théme</span>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                </div>

                                                <div className="submenu-item-mobile">
                                                    <Link className="item-mobile-list mb-2"
                                                          to="/backoffice/clients">
                                                        <img alt="" className="menu-img-mobile"
                                                             src="/assets/images/imgs/usersgroup1.png"/>
                                                        <div className="menu-title-mobile text-uppercase">Clients
                                                        </div>
                                                    </Link>
                                                    <Link className="item-mobile-list mb-2" to="/backoffice/themes">
                                                        <img alt="" className="menu-img-mobile"
                                                             src="/assets/images/imgs/theme1.png"/>
                                                        <div className="menu-title-mobile text-uppercase">Théme</div>
                                                    </Link>
                                                    <Link className="item-mobile-list mb-2" to="/backoffice/sousthemes">
                                                        <img alt="" className="menu-img-mobile"
                                                             src="/assets/images/imgs/sous_theme1.svg"/>
                                                        <div className="menu-title-mobile text-uppercase">Sous Théme
                                                        </div>
                                                    </Link>
                                                    <Link className="item-mobile-list mb-2"
                                                          to="/backoffice/soussousthemes">
                                                        <img alt="" className="menu-img-mobile"
                                                             src="/assets/images/imgs/sous_sous_theme.svg"/>
                                                        <div className="menu-title-mobile text-uppercase">Sous Sous
                                                            Théme
                                                        </div>
                                                    </Link>
                                                </div>


                                            </li>
                                        </ul>

                                    </li>

                                    {/* <!-- Publications --> */}
                                    <li className="list-group-item py-4 px-1">
                                        <Link className="item-menu-mobile" to="/publications">
                                            <img alt=""
                                                 className={(getCurrentPage('publications') ? 'activeImg' : '') + ' menu-img'}
                                                 src="/assets/images/imgs/publication.png"/>
                                            <div
                                                className={(getCurrentPage('publications') ? 'activeTitle' : '') + ' menu-title text-uppercase'}>Publications
                                            </div>

                                        </Link>

                                    </li>

                                    {/* <!-- Comment ca marche --> */}
                                    <li className="list-group-item py-4 px-1">
                                        <Link to="" className="item-menu-mobile">
                                            <img alt="" className="menu-img" src="/assets/images/imgs/ccm.png"/>
                                            <div className="menu-title text-uppercase">Comment ça marche</div>

                                        </Link>

                                    </li>

                                    {/* <!-- Outils Admin --> */}
                                    <li className="list-group-item py-4 px-1">
                                        <div className="item-menu-mobile">
                                            <img alt=""
                                                 className={(getCurrentPage('/outilsadmin/') ? 'activeImg' : '') + ' menu-img'}
                                                 src="/assets/images/imgs/outiladmin.png"/>
                                            <div
                                                className={(getCurrentPage('/outilsadmin/') ? 'activeTitle' : '') + ' menu-title text-uppercase'}>Outils
                                                admin
                                            </div>
                                        </div>

                                        <ul className="list-group flex-column d-inline-block submenu pl-1">

                                            <li className="list-group-item pl-1">
                                                <div className="row submenu-item-content">
                                                    <div className="col-6 submenu-item mb-2 mt-2">
                                                        <Link className="" to="/outilsadmin/utilisateurs">
                                                            <div className="submenu-card">
                                                                <img alt="" className="img-before"
                                                                     src="/assets/images/imgs/usersgroup.png"/>
                                                                <img alt="" className="img-after"
                                                                     src="/assets/images/imgs/usersgroup1.png"/>
                                                                <span>Gestion des Utilisateurs</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 submenu-item mb-2 mt-2">
                                                        <Link to="/outilsadmin/profilsperms">
                                                            <div className="submenu-card">
                                                                <img alt="" className="img-before"
                                                                     src="/assets/images/imgs/profil et permissions.svg"/>
                                                                <img alt="" className="img-after"
                                                                     src="/assets/images/imgs/profil_permissions1.svg"/>
                                                                <span>Profils et Permissions</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    {/* <div className="col-6 submenu-item mb-2 mt-2">
                                                    <div className="submenu-card">
                                                        <img alt="" className="img-before"
                                                             src="/assets/images/imgs/theme.png">
                                                        <img alt="" className="img-after"
                                                             src="/assets/images/imgs/theme1.png">
                                                        <span>Préférences</span>
                                                    </div>
                                                </div> */}
                                                </div>

                                                <div className="submenu-item-mobile">
                                                    <Link className="item-mobile-list mb-2"
                                                          to="/outilsadmin/utilisateurs">
                                                        <img alt="" className="menu-img-mobile"
                                                             src="/assets/images/imgs/usersgroup1.png"/>
                                                        <div className="menu-title-mobile text-uppercase">Gestion<br/>des
                                                            Utilisateurs
                                                        </div>
                                                    </Link>
                                                    <Link className="item-mobile-list mb-2"
                                                          to="/outilsadmin/profilsperms">
                                                        <img alt="" className="menu-img-mobile"
                                                             src="/assets/images/imgs/profil_permissions1.svg"/>
                                                        <div className="menu-title-mobile text-uppercase">Profils<br/>et
                                                            Permissions
                                                        </div>
                                                    </Link>
                                                    {/* <Link className="item-mobile-list mb-2">
                                                    <img alt="" className="menu-img-mobile"
                                                         src="/assets/images/imgs/theme1.png"/>
                                                    <div className="menu-title-mobile text-uppercase">Préférences</div>
                                                </Link> */}
                                                </div>
                                            </li>
                                        </ul>

                                    </li>


                                </ul>}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export {MenuWeb, mainMenu, firstMenu};


