import {
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAt,
    updateDoc,
    where
} from "firebase/firestore";
import { mapDataToJson } from "../../Attributes";
import { blockUIAction, closeModal, closeModalAdd, storeDataApi } from "../../Redux/features/user";
import { store } from "../../Redux/store";
import { createAuthUser, db, deleteAuthUser, deleteItemFirebase, replicateData, selectedBase, storage, updateAuthUser } from "../firebase";
import { CustomAlert, formatDate } from "../../js/backoffice";
import { toast as toastNew } from "react-hot-toast";
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { mapPrefsDescription } from "../../localization/map_langues";

let optionsToast = {
    position: "top-center",
    duration: 3500
}


//Request to get data with custom queries
export const fetchDataWithQueries = async ({ dataType, queries }) => {
    const nameCollection = dataType + "_" + selectedBase;
    var data = [];
    let q = query(collection(db, nameCollection));
    for(let i = 0; i < queries.length; i++){
        q = query(q, queries[i]);
    }
    q = q.withConverter(mapDataToJson[dataType]);
    const docs = await getDocs(q);
    console.log(docs.docs.length);
    if (docs.docs.length > 0) {
        console.log(docs.docs.length);
        data = await Promise.all(docs.docs.map(async (a) => {
            //console.log(await a.data());
            var element = await a.data();
            return element
        }));
        console.log("ALL FETCHED DATA ", data);
    }
    return data;

}


//When I need to returns a table imediately genre c'est utilie pour les Select 2 deh  !!!!
export const fetchData = async ({ dataType }) => {
    const nameCollection = dataType + "_" + selectedBase;
    var data = [];
    const q = query(collection(db, nameCollection).withConverter(mapDataToJson[dataType]));
    const docs = await getDocs(q);
    if (docs.docs.length > 0) {
        //console.log(docs.docs.length);
        data = await Promise.all(docs.docs.map(async (a) => {
            //console.log(await a.data());
            var element = await a.data();
            return element

        }));
    }
    return data;

}



//When I need to returns a table imediately genre c'est utilie pour les Select 2 deh  !!!!
export const fetchDataWhere = async ({ dataType, whereToLookInTheCollection, whereToLookValue }) => {
    const nameCollection = dataType + "_" + selectedBase;
    var data = [];
    const q = query(collection(db, nameCollection).withConverter(mapDataToJson[dataType]), where(whereToLookInTheCollection, "==", whereToLookValue));
    const docs = await getDocs(q);
    if (docs.docs.length > 0) {
        //console.log(docs.docs.length);
        data = await Promise.all(docs.docs.map(async (a) => {
            if (a.exists) {
                var element = await a.data();
                return element
            }
        }));
    }
    return data;
}


//Quand je retourne un single element je requete sur l'id
export const fetchUniqueData = async ({ dataType, id }) => {
    const nameCollection = dataType + "_" + selectedBase;
    var data = [];
    //const q = query(doc(collection(db, nameCollection)).withConverter(mapDataToJson[dataType]));
    const q = doc(db, nameCollection, id).withConverter(mapDataToJson[dataType]);
    const docs = await getDoc(q);
    if (docs.exists()) {
        var element = docs.data();

        if (dataType === "users") {
            if (typeof element["profil"] === "object") {
                let x = await getDoc(element["profil"]);
                if (x.exists()) {
                    //console.log(x.data());
                    let p = x.data().permissions;
                    let pp = [];
                    if (Array.isArray(p)) {
                        if (p.length > 0) {
                            for (const i of p) {
                                if (typeof i !== 'string') {
                                    let vv = await getDoc(i);
                                    //console.log(x.data());
                                    if (vv.exists()) {
                                        pp.push(vv.data().designation)
                                    }
                                }
                            }
                        }
                    }
                    //console.log(pp);
                    data.push({ ...element, permissions: pp, nomProfil: x.data()["nomProfil"] ?? "" })
                }
            }

        } else {
            data = [...data, element];
        }

    }
    //console.log(data);
    return data;
}


//Le nombre total d'items pour chaque page
export const getTotalData = async ({ dataType }) => {
    const nameCollection = dataType + "_" + selectedBase;
    const q = query(collection(db, nameCollection).withConverter(mapDataToJson[dataType]));

    const unsub = onSnapshot(q, (docs) => {
        if (docs.docs.length > 0) {
            store.dispatch(storeDataApi({ totalItems: docs.docs.length }));
        } else {
            store.dispatch(storeDataApi({ resetTotalItems: -1 }));
        }

    });
    return unsub;
}


//Le nombre total des items dans le dashboard accueil
export const getTotalDataDashboard = async ({ dataType, queries  }) => {
    const nameCollection = dataType + "_" + selectedBase;
    let total = 0;

    let q = query(collection(db, nameCollection),...hideUsers({dataType:dataType}));
    for(let i = 0; i < queries.length; i++){
        q = query(q, queries[i]);
    }
    q = q.withConverter(mapDataToJson[dataType]);
    const docs = await getDocs(q);

    if (docs.docs.length > 0) {
        total = docs.docs.length;
    }
    return total;


}

export const hideUsers = ({dataType})=>
{
    let queryConstraints = [];
    if (dataType === "users")
    {
        queryConstraints.push(where("email", '!=', "guindytechnology@gmail.com"))
    }
    return queryConstraints;
}

export const fetchDataPaginated11 = async ({ dataType, page = 1, count = 10 }) => {
    //store.dispatch(blockUIAction(true));
    //console.log(page);
    const nameCollection = dataType + "_" + selectedBase;
    var data = [];
    /* console.log('page '+page);
    console.log('count '+count); */


    const q = query(collection(db, nameCollection), orderBy("id"), startAt(page), limit(count));

    const unsub = onSnapshot(q, (docs) => {
        if (docs.docs.length > 0) {
            data = [];
            //console.log(docs.docs);
            docs.docs.map((a) => {
                //console.log(a.data());
                var element = a.data();
                data = [...data, element];
                //console.log(data);
                store.dispatch(storeDataApi({ items: data }));
                //store.dispatch(blockUIAction(false));
                return [];

            });
        }
    });

    //console.log(unsub);;

    return unsub;
}

export const fetchDataBasic = async ({ dataType, page = 1, count = 10, sort = "desc" }) => {
    const nameCollection = dataType + "_" + selectedBase;
    var data = [];
    //console.log("fecth data basic ",dataType);
    let q = query(collection(db, nameCollection).withConverter(mapDataToJson[dataType]), orderBy("createdAt", sort), limit(count));

    const unsub = onSnapshot(q, async (docs) => {
        if (docs.docs.length > 0) {
            data = [];
            data = await Promise.all(docs.docs.map(async (a) => {
                if (a.exists) {
                    //console.log(await a.data());
                    return await a.data();
                    //on a la clé await car pour les utilisateurs on retourne un promise
                    /*    data = [...data, el];
                       //console.log(data);
                       store.dispatch(storeDataApi({items: data , dataType:dataType})); */
                }
                return [];

            }));
            //console.log(data);
        }
        //console.log(data);
        store.dispatch(storeDataApi({ items: data, dataType: dataType }));

    }, (error) => {
        console.log(error);
    });
    return unsub;
}

export const fetchDataPreferences = async ({ dataType = "preferences" }) => {
    const nameCollection = dataType + "_" + selectedBase;
    var data = [];
    let q = query(collection(db, nameCollection), where("isPrefs", "==", true));
    let docs = await getDocs(q);
    if (!docs.empty) {
        if (docs.docs[0].exists) {
            let dataPrefs = docs.docs[0].data();
            if (dataPrefs['contents'] !== undefined) {
                let tabContents = dataPrefs['contents'];
                if (Array.isArray(tabContents)) {
                    let y = Array.from(tabContents);
                    data = await Promise.all(y.map(async (u) => {
                        //console.log(docs.docs[0].ref.id);
                        const d = query(collection(db, nameCollection, docs.docs[0].ref.id, u));
                        let ww = await getDocs(d);
                        let res = await Promise.all(ww.docs.map(async (a) => {
                            if (a.exists) {
                                return { ...a.data(), 'idPrefs': docs.docs[0].ref.id };
                            }
                            return [];

                        }));
                        return { [u]: [res, mapPrefsDescription[u]] };
                    }))
                }
            }
        }
    }
    //console.log(data);
    return data;

}

export const updatePreferences = async ({ dataType = "preferences", dataToSave, idPrefs, pathName }) => {
    try {
        store.dispatch(blockUIAction(true));
        const nameCollection = dataType + "_" + selectedBase;
        const collectionRef = collection(db, nameCollection, idPrefs, pathName);
        if (dataToSave.id !== null && dataToSave.id !== undefined) {
            if (dataToSave['idPrefs'] !== undefined) {
                delete dataToSave['idPrefs'];
            }
            let ref = doc(collectionRef, dataToSave.id)
            await updateDoc(ref, dataToSave);
        }
        store.dispatch(blockUIAction(false));
        store.dispatch(closeModalAdd());
        window.location.reload();
        console.log("Transaction successfully committed!");

    } catch (error) {
        store.dispatch(blockUIAction(false));
        console.log("Transaction failed: ", error);
    }


}

export const uploadFileToStorage = async ({ dataType = "preferences", dataToSave, linkPath = "banniere_app/welcome", pathName = "banierre_app" }) => {
    store.dispatch(blockUIAction(true));
    const storageRef = ref(storage, linkPath);
    const uploadTask = uploadBytesResumable(storageRef, dataToSave['value']);
    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // update progress
            console.log(percent);
        },
        (err) => console.log(err),
        () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                dataToSave = { ...dataToSave, 'value': url };
                console.log(dataToSave);
                updatePreferences({ dataToSave: dataToSave, idPrefs: dataToSave['idPrefs'], pathName: pathName });
            });
        }
    );
}


//Chercher des donneis pagineis
export const fetchDataPaginated = async ({ dataType, page = 1, count = 10 }) => {
    const nameCollection = dataType + "_" + selectedBase;
    var data = [];
    console.log(nameCollection);

    //store.dispatch(blockUIAction(true));
    const q = query(collection(db, nameCollection).withConverter(mapDataToJson[dataType]), orderBy("createdAt", "desc"), limit(page));
    const docs = await getDocs(q);
    if (docs.docs.length > 0) {
        let p = docs.docs[docs.docs.length - 1];
        if (p.exists) {
            let dt = await p.data();
            const r = query(collection(db, nameCollection).withConverter(mapDataToJson[dataType]), orderBy("createdAt", "desc"), startAt(dt.createdAt), limit(count));
            const unsub = onSnapshot(r, async (docs) => {
                if (docs.docs.length > 0) {
                    data = [];
                    data = await Promise.all(docs.docs.map(async (a) => {
                        if (a.exists) {
                            return await a.data();
                            /*  data = [...data, element];
                             //console.log(data);
                             store.dispatch(storeDataApi({items: data})); */
                        }
                        return [];

                    }));
                    //store.dispatch(blockUIAction(false));
                }
                store.dispatch(storeDataApi({ items: data, dataType: dataType }));
            });
            return unsub;
        }
    }
}


//Recuperation des modules pour le menu
export const fetchDataModules = async ({ dataType, page = 1, count = 10 }) => {
    const nameCollection = dataType + "_" + selectedBase;
    var data = [];

    const q = query(collection(db, nameCollection).withConverter(mapDataToJson[dataType]), orderBy("order"), limit(count));
    const unsub = onSnapshot(q, (docs) => {
        if (docs.docs.length > 0) {
            data = [];
            docs.docs.map((a) => {
                if (a.exists) {
                    var element = a.data();
                    data = [...data, element];
                    store.dispatch(storeDataApi({ modules: data }));
                }
                return [];

            });
        } else {
            store.dispatch(storeDataApi({ items: data }));
        }
    });

    //console.log(unsub);;

    return unsub;
}


export const saveData = async ({ dataType, dataToSave }) => {

    try {
        store.dispatch(blockUIAction(true));
        const nameCollection = dataType + "_" + selectedBase;
        const collectionRef = collection(db, nameCollection).withConverter(mapDataToJson[dataType]);
        let ref = doc(collectionRef).withConverter(mapDataToJson[dataType]);
        if (dataToSave.id !== null) {
            ref = doc(collectionRef, dataToSave.id)
            //if(dataToSave.toJson){
            await updateDoc(ref, dataToSave.toJson()).then(() => {
                toastNew.success("Modification",optionsToast)
                if (dataType === "profils") {
                    window.location.reload()
                }
            });
            //}

        } else {
            dataToSave = treatmentBefore({dataType:dataType, ref:ref, jsonData:dataToSave});
            await setDoc(ref, dataToSave);
            toastNew.success("Ajout", optionsToast)
        }
        //let newDataToSave = {...dataToSave, id: ref.id, createdAt: serverTimestamp()};


        store.dispatch(blockUIAction(false));
        store.dispatch(closeModalAdd());
        //toastNew.success((dataToSave.id && dataToSave.id !== null) ? "Modification" : "Ajout", optionsToast)
        console.log("Transaction successfully committed!");
        return dataToSave;
    } catch (e) {
        store.dispatch(blockUIAction(false));
        console.log("Transaction failed: ", e);
    }
}

export const userToDocsAndStorage = async ({ dataType, jsonData, file = undefined, authUserId, isDashboardProfil }) => {
    try {
        store.dispatch(blockUIAction(true));
        const nameCollection = dataType + "_" + selectedBase;
        const collectionRef = collection(db, nameCollection).withConverter(mapDataToJson[dataType]);
        let ref;
        if (authUserId) {
            ref = doc(collectionRef, authUserId).withConverter(mapDataToJson[dataType]);
        }

        if (jsonData.id && jsonData.id !== null) {
            ref = doc(collectionRef, jsonData.id).withConverter(mapDataToJson[dataType]);
            Object.keys(jsonData).forEach(key => {
                if (jsonData[key] === undefined) {
                    jsonData[key] = null;
                }
            });

            await updateDoc(ref, jsonData.toJson()).then(async () => {
                if (file) {
                    await pushFileToStorage({ dataType: dataType, file: file, refDoc: ref, fieldUrl: "imgProfil" });
                } else if (!file && jsonData["eraseImg"] === true) {
                    deleteFileToStorage({ dataType: dataType, refDoc: ref, fieldUrl: "imgProfil" });
                }

                toastNew.success("Modification", optionsToast);

            });



        } else {
            jsonData = treatmentBefore({dataType:dataType, ref:ref, jsonData:jsonData});
            await setDoc(ref, jsonData);
            toastNew.success("Ajout", optionsToast);

            if (file) {
                await pushFileToStorage({ dataType: dataType, file: file, refDoc: ref, fieldUrl: "imgProfil" });
            }

            updateSomeField({dataType: dataType, jsonData:jsonData})
        }

        store.dispatch(blockUIAction(false));
        //toastNew.success((jsonData.id && jsonData.id !== null) ? "Modification" : "Ajout", optionsToast)
        if (isDashboardProfil === true) {
            store.dispatch(closeModal());
        } else {
            store.dispatch(closeModalAdd());
        }

        console.log("Transaction successfully committed!");

    } catch (e) {
        store.dispatch(blockUIAction(false));
        console.log("Transaction failed: ", e);
        if (authUserId) {
            await deleteAuthUser({ id: authUserId }).then((data) => {
                console.log(data);
            });
        }
    }
}

export const saveDataToDocsAndStorage = async ({ dataType, jsonData, file = undefined, fieldUrl = "fileUrl" }) => {
    try {
        store.dispatch(blockUIAction(true));
        const nameCollection = dataType + "_" + selectedBase;
        const collectionRef = collection(db, nameCollection).withConverter(mapDataToJson[dataType]);
        let ref = doc(collectionRef).withConverter(mapDataToJson[dataType]);
        //console.log("diop log",jsonData);
        if (jsonData.id && jsonData.id !== null) {
            ref = doc(collectionRef, jsonData.id).withConverter(mapDataToJson[dataType]);

            Object.keys(jsonData).forEach(key => {
                if (jsonData[key] === undefined) {
                    //jsonData[key] = null ;
                    delete jsonData[key];
                }
            });

            await updateDoc(ref, jsonData.toJson());
           
            if (file) {
                await pushFileToStorage({ dataType: dataType, file: file, refDoc: ref });
            } else if (!file && jsonData["eraseImg"] === true) {
                console.log(file, jsonData["eraseImg"]);
                deleteFileToStorage({ dataType: dataType, refDoc: ref, fieldUrl: fieldUrl });
            }

            toastNew.success("Modification", optionsToast);


        }
        else
        {
            jsonData = treatmentBefore({dataType:dataType, ref:ref, jsonData:jsonData});

            await setDoc(ref, jsonData)

            toastNew.success("Ajout", optionsToast)
            updateSomeField({dataType: dataType, jsonData:jsonData})

            if (file)
            {
                await pushFileToStorage({ dataType: dataType, file: file, refDoc: ref });
            }
        }

        treatmentAfter({dataType:dataType, ref:ref,jsonData:jsonData});


        store.dispatch(blockUIAction(false));
        store.dispatch(closeModalAdd());
        //toastNew.success((jsonData.id && jsonData.id !== null) ? "Modification" : "Ajout", optionsToast)
        console.log("Transaction successfully committed!");
        return ref.id;
    } catch (e) {
        store.dispatch(blockUIAction(false));
        console.log("Transaction failed: ", e);
    }
}

const treatmentBefore = ({ dataType, ref, jsonData}) => {

    let value = {};
    value['usedCount'] = 0 ;
    if (dataType === "publications")
    {
        value['likedBy'] = [] ;
    }
    if (dataType === "clients")
    {
        value['disabled'] = false ;
        value['notifNewPost'] = true ;
    }
    if (dataType === "users")
    {
        value['disabled'] = false ;
    }

    if (dataType === "commentaires")
    {
        value['disabled'] = false ;
    }

    if (dataType === "themes")
    {
        value['level'] = 0 ;
        value['nb_sous_themes'] = 0 ;
    }
    if (dataType === "sous_themes")
    {
        value['level'] = 1 ;
        value['nb_sous_sous_themes'] = 0 ;

        if (jsonData && jsonData["theme"] && typeof jsonData["theme"] === "string")
        {
            jsonData["theme"] = transformIdToRef({dataType: "themes",valueId:jsonData["theme"]})
        }
    }
    if (dataType === "sous_sous_themes")
    {
        value['level'] = 2 ;

        if (jsonData && jsonData["sousTheme"] && typeof jsonData["sousTheme"] === "string")
        {
            jsonData["sousTheme"] = transformIdToRef({dataType: "sous_themes",valueId:jsonData["sousTheme"]})
        }
    }

    value = {...value, id:ref.id, createdAt: new Date(), createdAtString:formatDate(new Date())};

    return {...jsonData, ...value}
}

const updateSomeField = async ({dataType,jsonData}) =>
{
    if (dataType === "sous_themes")
    {
        makeIncField({ref:jsonData["theme"], keyToInc: ["usedCount", "nb_sous_themes"]});
    }
    if (dataType === "sous_sous_themes")
    {
        makeIncField({ref:jsonData["sousTheme"], keyToInc: ["usedCount", "nb_sous_sous_themes"]});
    }
    if (dataType === "users")
    {
        makeIncField({ref:jsonData["profil"], keyToInc: ["usedCount"]});
    }
}

const makeIncField = async ({ref, keyToInc = []}) =>
{
    
    if (ref && typeof ref === "object")
    {
        let v = {};

        await Promise.all(keyToInc.map(async(i)=>
        {
            let u = await fetchAnotherData({references: [ref], fieldName:i});
            if (u.length === 1)
            {
              v[i] = u[0] + 1 ; 
            }
            return v;
        }));
        
        updateDoc(ref,v); 
    }
    
}


const treatmentAfter = async ({ dataType, ref, jsonData}) =>
{
    let n = "replication_themes" ;

    if (dataType === "themes" || dataType === "sous_themes" || dataType === "sous_sous_themes")
    {
        replicateData({id:ref.id, replications:n, collection:dataType});
    }
    
}



function transformIdToRef({dataType, valueId})
{
    return doc(db, dataType +"_" + selectedBase, valueId) ;
}

const pushFileToStorage = async ({ dataType, file, refDoc, fieldUrl = "fileUrl" }) => {
    let path = dataType + "_" + selectedBase + "/" + refDoc.id + "/" + refDoc.id;
    console.log(path);

    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
        "state_changed",
        (snapshot) => {
            /* const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            ); */

            // update progress
            //console.log(percent);
        },
        (err) => {
            console.log(err);
            //deleteDoc(refDoc);
        },
        () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                if (refDoc) {
                    updateDoc(refDoc, {
                        [fieldUrl]: url
                    })
                }
            });
        }
    );
}

const deleteFileToStorage = async ({ dataType, refDoc, fieldUrl = "fileUrl" }) => {
    let path = dataType + "_" + selectedBase + "/" + refDoc.id + "/" + refDoc.id;
    console.log(path);

    const storageRef = ref(storage, path);
    deleteObject(storageRef).then(() => {
        updateDoc(refDoc, {
            [fieldUrl]: null
        })
    }).catch(() => {
        console.log("Impossible de supprimer le fichier");
    })
}

export const saveUserToDocsAndStorage = async ({ dataType, jsonData, file = undefined, isDashboardProfil }) => {
    try {
        store.dispatch(blockUIAction(true));
        createAuthUser({
            email: jsonData["email"],
            password: jsonData["password"],
            displayName: jsonData["displayName"],
        }).then((resAuth) => {
            store.dispatch(blockUIAction(false));
            //console.log(resAuth);
            let bool = resAuth["data"]["success"];
            console.log(bool);
            if (bool === false) {
                toastNew.error(resAuth["data"]["message"] ?? "Une erreur est survenue", { ...optionsToast, "position": "top-right" })
            } else if (bool === true) {
                userToDocsAndStorage({ dataType: dataType, jsonData: jsonData, file: file, authUserId: resAuth["data"]["id"], isDashboardProfil: isDashboardProfil });
            }

        })
    } catch (e) {
        store.dispatch(blockUIAction(false));
    }
}

export const updateUserToDocsAndStorage = async ({ dataType, jsonData, file = undefined, isDashboardProfil }) => {
    try {
        store.dispatch(blockUIAction(true));
        let v = { id: jsonData["id"] };
        if (jsonData["email"]) {
            v["email"] = jsonData["email"]
        }
        if (jsonData["password"]) {
            v["password"] = jsonData["password"]
        }
        updateAuthUser(v).then((resAuth) => {
            store.dispatch(blockUIAction(false));
            //console.log(resAuth);
            let bool = resAuth["data"]["success"];
            //console.log(resAuth["data"]);
            if (bool === false) {
                toastNew.error(resAuth["data"]["message"] ?? "Une erreur est survenue", { ...optionsToast, "position": "top-right" })
            } else if (bool === true) {
                //console.log(jsonData);
                userToDocsAndStorage({ dataType: dataType, jsonData: jsonData, file: file, isDashboardProfil: isDashboardProfil });
            }

        })
    } catch (e) {
        store.dispatch(blockUIAction(false));
        toastNew.error("Une erreur est survenue", { ...optionsToast, "position": "top-right" })
    }
}
export const enableAuthUser = async ({ isEnable, id, dataType, toastRef }) => {

    const func = () => {
        try {
            let ref = doc(db, dataType + "_" + selectedBase, id);
            store.dispatch(blockUIAction(true));
            let v = {
                id: id,
                disabled: isEnable
            };
            updateAuthUser(v).then((resAuth) => {
                store.dispatch(blockUIAction(false));
                //console.log(resAuth);
                let bool = resAuth["data"]["success"];
                //console.log(resAuth["data"]);
                if (bool === false) {
                    toastNew.error(resAuth["data"]["message"] ?? "Une erreur est survenue", optionsToast)
                } else if (bool === true) {
                    store.dispatch(blockUIAction(true));
                    updateDoc(ref, {
                        disabled: isEnable
                    }).then(() => {
                        store.dispatch(blockUIAction(false));
                        toastNew.success("Utilisateur " + (isEnable ? "désactivé" : "activé") + " avec succés", optionsToast)
                    }).catch((error) => {
                        console.log(error);
                    });
                }

            });

        } catch (error) {
        }
    }
    CustomAlert({ message: (isEnable === true ? 'Désactiver' : 'Activer') + " cet utilisateur ?", yesBtn: func })
}


export const deleteData = async ({ dataType, id, toastId = "toastIndexCore" }) => {

    const func = () => {
        store.dispatch(blockUIAction(true));
        deleteItemFirebase({ item: { dataType: dataType, id: id } }).then(async (value) => {
            store.dispatch(blockUIAction(false));
            console.log(value);
            if (value.data.success !== null) {
                let bool = value.data.success;
                if (bool) {
                    /* fetchDataBasic({dataType: dataType, count: store.getState().dataApi.value.itemsCountPerPage}).then(async (value) => {
                        if(dataType === "themes"){
                            window.location.reload();
                        }
                    }) */
                    toastNew.success(value.data.message ?? "Suppression effectuée", optionsToast)
                } else {
                    toastNew.error(value.data.message ?? "Impossible de le supprimer", optionsToast)
                }

            }
        }).catch((error) => {
            store.dispatch(blockUIAction(false));
            toastNew.error(error.message ?? "Une erreur est survenue", optionsToast)
        });
    }
    CustomAlert({ message: "Voulez-vous vraiment confirmer cette suppression ?", yesBtn: func })

}

export const enableItem = async ({ bool, fieldToSet, id, dataType, message = "cet élément ?", messageSuccess = "Cet élément a été désactivé avec succés !" }) => {

    let optionsToast = {
        position: "top-center",
        duration: 4000
    }

    async function newFunction() {
        try {
            store.dispatch(blockUIAction(true));
            const nameCollection = dataType + "_" + selectedBase;
            const collectionRef = collection(db, nameCollection);
            const ref = doc(collectionRef, id);
            await updateDoc(ref, { [fieldToSet]: bool }).then(() => {
                toastNew.success(messageSuccess, optionsToast)
            }).catch(() => {
                toastNew.error("Une erreur est survenue", optionsToast)
            });
            store.dispatch(blockUIAction(false));

        } catch (e) {
            store.dispatch(blockUIAction(false));
            toastNew.error("Une erreur est survenue", optionsToast)
            console.log("Transaction failed: ", e);
        }
    }
    CustomAlert({ message: (bool === true ? 'Désactiver ' : 'Activer ') + message, yesBtn: newFunction })
}



export const fetchForeignData = async ({ reference, uniqueField = false, langue = "fr_FR", field }) => {
    let returnValue = "";
    if (typeof reference === "object") {
        let doc = await getDoc(reference);
        if (doc.exists()) {
            if (doc.data()[field]) {

                //console.log(doc.data()[field]);
                if (uniqueField) {
                    returnValue = doc.data()[field] ?? "";
                } else {
                    returnValue = doc.data()[field][langue] ?? "";
                }

            }
        }
    }
    return returnValue;
}

export const fetchAnotherData = async ({references, fieldName = "designation", allField = false}) =>{
    try {
      const data = await Promise.all(
        references.map(async (ref) => {
          const docSnapshot = await getDoc(ref);
          if (docSnapshot.exists) {
            return docSnapshot.data()[fieldName];
          } else {
            console.error(`Document does not exist: ${ref.path}`);
            return null;
          }
        })
      );
      //console.log("diop log",data);
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
export const fetchforeignDataTab = async ({tab, field = "designation",allField= false}) => {
    let hh = [];
    if (Array.isArray(tab))
    {
        for (const i of tab)
        {
            if (typeof i !== 'string')
            {
                let x = await getDoc(i);
                if (x.exists())
                {
                    if(allField === true)
                    {
                        hh.push(x.data());
                    }
                    else if(field)
                    {
                        hh.push(x.data()[field])
                    }

                }
            }
            else
            {
                hh.push(i)
            }
        }
    }
    return hh
}

