import moment from "moment";

export class CommentaireModel {
    constructor({
                    id = null,
                    fromFront = false,
                    replyToClient,
                    commentBy,
                    parentId,
                    commentId,
                    replyTo,
                    sousCommentId,
                    sousSousCommentId,
                    content,
                    createdAt,
                    date,
                    disabled,
                    postId,
                    likedBy = []
                }) {
        this.id = id;
        this.commentId = commentId;
        this.parentId = parentId;
        this.replyTo = replyTo;
        this.sousCommentId = sousCommentId;
        this.sousSousCommentId = sousSousCommentId;
        this.replyToClient = replyToClient;
        this.commentBy = commentBy;
        this.content = content;
        this.createdAt = createdAt;
        this.date = date;
        this.disabled = disabled;
        this.postId = postId;
        this.likedBy = likedBy;
        this.fromFront = fromFront;
    }

    toJson() {
        let obj = {
            id: this.id,
            parentId: this.parentId,
            commentId: this.commentId,
            sousCommentId: this.sousCommentId ?? "",
            sousSousCommentId: this.sousSousCommentId ?? "",
            commentBy: this.commentBy,
            content: this.content,
            postId: this.postId,
            fromFront: this.fromFront

        }
        let u = this.replyTo;
        let uuu = this.replyToClient;
        

        if (u) {
            obj = {...obj, "replyTo": u}
        }
        if (uuu) {
            obj = {...obj, "replyToClient": uuu}
        }

        return obj;

    }
}

export const mapObjectToCommentaires = {
    toFirestore: (m) => {

        let mTmp = Object.fromEntries(Object.entries(m))
        mTmp = {...mTmp,usedCount:undefined,commentId: m.commentId ?? "",sousCommentId: m.sousCommentId ?? "",sousSousCommentId: m.sousSousCommentId ?? ""}
        Object.keys(mTmp).forEach(key => {
            if (mTmp[key] === undefined) {
                delete mTmp[key];
            }
        });
        
        return mTmp;
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        //console.log(data);
        const startDate = moment(new Date(data.createdAt?.seconds * 1000));
        const timeEnd = moment(Date.now());
        const diff = timeEnd.diff(startDate);
        const diffDuration = moment.duration(diff);
        let date = "";
        if (diffDuration.years() > 0) {
            date = diffDuration.years() + " ans"
        } else if (diffDuration.months() > 0) {
            date = diffDuration.months() + " mois";
        } else if (diffDuration.days() > 0) {
            date = diffDuration.days() + " jrs";
        } else if (diffDuration.hours() > 0) {
            date = diffDuration.hours() + " h";
        } else if (diffDuration.minutes() > 0) {
            date = diffDuration.minutes() + " mn";
        } else if (diffDuration.seconds() > 0) {
            date = diffDuration.seconds() + " s";
        }

        return new CommentaireModel({
            id: data.id,
            fromFront: data.fromFront,
            sousSousCommentId: data.sousSousCommentId,
            sousCommentId: data.sousCommentId,
            commentId: data.commentId,
            parentId: data.parentId,
            replyTo: data.replyTo,
            replyToClient:data.replyToClient,
            commentBy: data.commentBy,
            content: data.content,
            createdAt: data.createdAt,
            date: date,
            disabled: data.disabled,
            postId: data.postId,
            likedBy: data.likedBy ?? []
        });
    }
}