import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import {persistor, store} from './core/Redux/store';
import {PersistGate} from 'redux-persist/integration/react';
import {BrowserRouter as Router} from 'react-router-dom';
import { currentEnv, selectedBase } from './core/Firebase/firebase';


const root = ReactDOM.createRoot(document.getElementById('root'));

//console.log(window.location.href);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {/* <Router basename={ currentEnv === "production" ? (selectedBase === "test" ? "bilal_app_test" : selectedBase === "prod" ? "" : null) : ""}> */}
                <Router>
                    <App/>
                </Router>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
