import {useEffect, useState} from "react";
import {db} from "../../../core/Firebase/firebase";
import {useDocs, useWhereDocs} from "../../../core/Firebase/FirebaseHooks";
import * as Rb from 'react-bootstrap';
import {CheckboxIns} from "../../../partials/FrequentlyUsed/Chechbox";
import {fetchAnotherData, fetchforeignDataTab} from "../../../core/Firebase/services/FirebaseService";
import {useParams} from "react-router-dom";
import TitleBtnAdd from "../../../partials/FrequentlyUsed/TitleBtnAdd";
import {motion} from 'framer-motion';
import moment from "moment";
import { customDateFormat } from "../../../core/js/backoffice";
import { Tooltip } from "primereact";
import { useDispatch } from "react-redux";
import { blockUIAction } from "../../../core/Redux/features/user";

export const DetailProfil = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [item, setItem] = useState({});
    const [permsTab, setpermsTab] = useState([]);
    const [pages, setPages] = useState([]);
    const {data} = useDocs({db: db, converterName: "pages_permissions"});
    const {data: permissionsModal} = useDocs({db, converterName: "permissions"});

    const {data: profil} = useWhereDocs({
        db: db,
        collectionName: "profils",
        whereToLookInTheCollection: "id",
        whereToLookValue: id
    });

    useEffect(() => {
        if (profil.length === 1) {
            setItem(profil[0])
        }
      
    }, [profil])

    useEffect(() => {
        //setpermsTab(item["permissionsModal"]);

       
       async function fetchData() {
        //
           /*  let hh = await fetchforeignDataTab({tab: item["permissions"]});
            setpermsTab(hh); */

            fetchAnotherData({references:item["permissions"] ?? []}).then((hh)=>{
                setpermsTab(hh);
                //dispatch(blockUIAction(false))
            });
        }

        fetchData();
 

    }, [item]);

    useEffect(() => {
        dispatch(blockUIAction(true))
        if (data.length === 1)
        {
            dispatch(blockUIAction(false))
            setPages(data[0].pages ?? [])
        }
    }, [data, pages, permissionsModal]);


    const hasOncePerms = (item) => {
        let itemPerms = ['list-' + item.key, 'create-' + item.key, 'can-create-' + item.key, 'update-' + item.key, 'delete-' + item.key, 'detail-' + item.key, 'can-validate-' + item.key];
        if (!item.canList) {
            itemPerms = itemPerms.filter((i) => i !== 'list-' + item.key);
        }
        if (!item.canCreate) {
            itemPerms = itemPerms.filter((i) => i !== 'create-' + item.key);
        }
        if (!item.canCreateValidated) {
            itemPerms = itemPerms.filter((i) => i !== 'can-create-' + item.key);
        }
        if (!item.canUpdate) {
            itemPerms = itemPerms.filter((i) => i !== 'update-' + item.key);
        }
        if (!item.canDelete) {
            itemPerms = itemPerms.filter((i) => i !== 'delete-' + item.key);
        }
        if (!item.canSeeDetail) {
            itemPerms = itemPerms.filter((i) => i !== 'detail-' + item.key);
        }
        if (!item.canValidated) {
            itemPerms = itemPerms.filter((i) => i !== 'can-validate-' + item.key);
        }
        return itemPerms.map((u) => {
            return (permsTab ?? []).includes(u) === true;
        }).includes(true);
    }

    const getDescriptionPermission = (value) => {
        let res = "";
        let aaa = permissionsModal.filter((i) => (i.designation.indexOf(value) !== -1))

        if (aaa && aaa.length > 0) {
            res = aaa[0].description ?? "";
        }
        return res;
    }


    return (
        <motion.div
        initial={{opacity: 0, scale: 0.9}}
        animate={{opacity: 1, scale: 1}}
        transition={{duration: 0.3}}
    >
            <TitleBtnAdd showBtn={false} marginTop="mt-20"
                         icon="/assets/images/ico/ic_users_pub.svg" itemsName="Detail Profil"/>

            <div className="mt-30 tab-card">
                <Rb.Tabs className="mb-2 mt-20" defaultActiveKey="detail" justify={true} fill={true} id="tab-detail">
                    <Rb.Tab eventKey="detail" title="Détails" tabClassName='tabHeader textClassInit'>
                        <Rb.ListGroup variant='flush'>
                            <Rb.ListGroupItem className="list-item textClassInit">Nom du profil: {item["nomProfil"]}</Rb.ListGroupItem>
                            <Rb.ListGroupItem className="list-item textClassInit">Nbr de permissions: {(item["permissions"] ?? []).length}</Rb.ListGroupItem>
                            <Rb.ListGroupItem className="list-item textClassInit">Nb d'utilisation: {item["usedCount"]}</Rb.ListGroupItem>
                            <Rb.ListGroupItem className="list-item textClassInit">Date de création: {customDateFormat({date:item.date})}</Rb.ListGroupItem>
                        </Rb.ListGroup>
                    </Rb.Tab>

                    <Rb.Tab eventKey="permissions" title="Permissions" tabClassName='tabHeader textClassInit'>
                        <Rb.Form noValidate>
                        <div className="modal-table-card mt-40">
                            <table
                                className="table perms-table table-bordered mb-30 table-responsive table-hover">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>

                                            <span
                                                className="cursor-pointer align-self-center">Lister</span>
                                        </Rb.Stack>

                                    </th>
                                    <th>
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                            <span
                                                className="cursor-pointer align-self-center">
                                        Créer</span>
                                        </Rb.Stack>
                                    </th>
                                    <th>
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                            <span
                                                className="cursor-pointer align-self-center">
                                                Modifier</span>
                                        </Rb.Stack>
                                    </th>
                                    <th>
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                            <span
                                                className="cursor-pointer align-self-center">
                                                Supprimer</span>
                                        </Rb.Stack>
                                    </th>
                                    <th>
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                        <span
                                            className="cursor-pointer align-self-center">
                                                Voir Détail</span>
                                        </Rb.Stack>

                                    </th>
                                    <th>
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                            <span
                                                className="cursor-pointer align-self-center">
                                                Peut Valider</span>
                                        </Rb.Stack>

                                    </th>
                                    <th>
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                            <span
                                                className="cursor-pointer align-self-center">
                                                Créer sans validation</span>
                                        </Rb.Stack>

                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    pages.map((item,index) => {
                                        return (hasOncePerms(item) && <tr key={item.id}>
                                            <td>{item.nom}</td>
                                            <td>{(item.canList && permsTab.includes('list-' + item.key)) &&
                                            <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                                <div style={{marginTop: "10px"}}>
                                                    <CheckboxIns value="list-"
                                                                checked={permsTab.includes('list-' + item.key)}/>
                                                </div>

                                                <span className={"cursor-pointer align-self-center"}>Liste</span>
                                                <span><i data-pr-tooltip={getDescriptionPermission('list-' + item.key)}
                                                        className={"cursor-pointer text-primary fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12" target={".tooltip"+item.key} />
                                            </Rb.Stack>
                                            }
                                            </td>
                                            <td>{(item.canCreate && permsTab.includes('create-' + item.key)) &&
                                            <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                                <div style={{marginTop: "10px"}}>
                                                    <CheckboxIns checked={permsTab.includes('create-' + item.key)}
                                                                value="create-" type="checkbox"/>
                                                </div>

                                                <span className="cursor-pointer align-self-center">Création</span>
                                                <span><i data-pr-tooltip={getDescriptionPermission('create-' + item.key)}
                                                        className={"cursor-pointer text-success fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12" target={".tooltip"+item.key} />
                                            </Rb.Stack>
                                            }</td>
                                            <td>{item.canUpdate && permsTab.includes('update-' + item.key) &&
                                            <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                                <div style={{marginTop: "10px"}}>
                                                    <CheckboxIns checked={permsTab.includes('update-' + item.key)}
                                                                value="update-" type="checkbox"/>
                                                </div>

                                                <span className="cursor-pointer align-self-center">Modification</span>
                                                <span><i data-pr-tooltip={getDescriptionPermission('update-' + item.key)}
                                                        className={"cursor-pointer text-warning fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12" target={".tooltip"+item.key} />
                                            </Rb.Stack>
                                            }</td>
                                            <td>{item.canDelete && permsTab.includes('delete-' + item.key) &&
                                            <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                                <div style={{marginTop: "10px"}}>
                                                    <CheckboxIns checked={permsTab.includes('delete-' + item.key)}
                                                                value="delete-" type="checkbox"/>
                                                </div>

                                                <span className="cursor-pointer align-self-center">Suppression</span>
                                                <span><i data-pr-tooltip={getDescriptionPermission('delete-' + item.key)}
                                                        className={"cursor-pointer text-danger fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12" target={".tooltip"+item.key} />
                                            </Rb.Stack>
                                            }</td>
                                            <td>{item.canSeeDetail && permsTab.includes('detail-' + item.key) &&
                                            <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                                <div style={{marginTop: "10px"}}>
                                                    <CheckboxIns checked={permsTab.includes('detail-' + item.key)}
                                                                value="detail-" type="checkbox"/>
                                                </div>

                                                <span className="cursor-pointer align-self-center">Détail</span>
                                                <span><i data-pr-tooltip={getDescriptionPermission('detail-' + item.key)}
                                                        className={"cursor-pointer text-primary fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12" target={".tooltip"+item.key} />
                                            </Rb.Stack>
                                            }</td>

                                            <td>{item.canValidate && permsTab.includes('can-validate-' + item.key) &&
                                            <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                                <div style={{marginTop: "10px"}}>
                                                    <CheckboxIns checked={permsTab.includes('can-validate-' + item.key)}
                                                                value="detail-" type="checkbox"/>
                                                </div>

                                                <span className="cursor-pointer align-self-center">Peut valider</span>
                                                <span><i data-pr-tooltip={getDescriptionPermission('can-validate-' + item.key)}
                                                        className={"cursor-pointer text-success fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12" target={".tooltip"+item.key} />
                                            </Rb.Stack>
                                            }</td>

                                            <td>{item.canCreateValidated && permsTab.includes('can-create-' + item.key) &&
                                            <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                                <div style={{marginTop: "10px"}}>
                                                    <CheckboxIns checked={permsTab.includes('can-create-' + item.key)}
                                                                value="detail-" type="checkbox"/>
                                                </div>

                                                <span className="cursor-pointer align-self-center">Créer sans validation</span>
                                                <span><i data-pr-tooltip={getDescriptionPermission('can-create-' + item.key)}
                                                        className={"cursor-pointer text-warning fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12" target={".tooltip"+item.key} />
                                            </Rb.Stack>
                                            }</td>
                                        </tr>);
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        </Rb.Form>
                    </Rb.Tab>

                </Rb.Tabs>
                
            </div>
        </motion.div>
    );
}
