import {collection, getDocs, limit, onSnapshot, orderBy, query, startAt, where} from "firebase/firestore";
import {useEffect, useState} from "react";
import {getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage';
import {mapDataToJson} from "../Attributes";
import {selectedBase} from "./firebase";
import {mapPrefsDescription} from "../localization/map_langues";
import {createMultipleQuery, engineQuery} from "../js/backoffice";
import { hideUsers } from "./services/FirebaseService";

// get Total Docs
const useTotalSize = ({db, collectionName}) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [numberOfData, setNumberOfData] = useState(0);
    const nameCollection = collectionName + "_" + selectedBase;

    useEffect(() => {
        const getDocs = async () => {
            let q = query(collection(db, nameCollection),...hideUsers({dataType:collectionName}));
            onSnapshot(q, (querySnapshot) => {
                setNumberOfData(querySnapshot.size);
                setDataLoading(false);
            });
        };
        getDocs();
    }, [db, collectionName, nameCollection]);

    return {numberOfData, dataLoading};
}

// get All Docs
function usePreferencesDocs({
                                db,
                                isPaginated = false,
                                prefDoc,
                                tabContents,
                                converterName,
                                count = 10,
                                sort = "desc",
                                whereToOrderBy
                            }) {
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [numberOfData, setNumberOfData] = useState(0);
    let nameCollection = converterName + "_" + selectedBase;

    useEffect(() => {
        if (isPaginated === false) {
            //console.log("not paginated data");
            const getDocs = async () => {

                if (Array.isArray(tabContents)) {
                    let y = Array.from(tabContents);
                    setData([]);
                    y.map(async (u) => {
                        let yy = nameCollection + "/" + u;
                        //console.log(yy);
                        let q = query(collection(db, yy).withConverter(mapDataToJson[converterName]), orderBy(whereToOrderBy, sort), limit(count));
                        onSnapshot(q, (querySnapshot) => {
                            setNumberOfData(querySnapshot.size);
                            const allData = [];
                            querySnapshot.forEach((doc) => {
                                allData.push({...doc.data(), id: doc.id, idPrefs: u.split("/")[0]});
                            });

                            setData(p => p.concat({[u.split("/")[1]]: [allData, mapPrefsDescription[u.split("/")[1]]]}));
                            setDataLoading(false);
                        })

                    })
                }
            };
            getDocs().then((value) => {
                //value();
            });
        } else {

        }
    }, [db, converterName, sort, count, isPaginated, nameCollection, whereToOrderBy, prefDoc, tabContents]);
    return {data, numberOfData, dataLoading};
}

// get All Docs
function useDocs({
                     db,
                     isPaginated = false,
                     collectionName,
                     converterName,
                     count,
                     sort = "desc",
                     whereToOrderBy,
                     page
                 }) {
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [numberOfData, setNumberOfData] = useState(0);
    const nameCollection = converterName + "_" + selectedBase;

    //console.log(collectionName);

    useEffect(() => {
        if (converterName === "users")
        {
            whereToOrderBy = "email";
        }
        
        if (isPaginated === false) {
            console.log("not paginated data");
            const getDocs = async () => {
                let q;
                if(count !== undefined) {
                    if (whereToOrderBy === undefined) {
                        q = query(collection(db, collectionName ?? nameCollection).withConverter(mapDataToJson[converterName]),...hideUsers({dataType:converterName}), limit(count));
                    } else {
                        q = query(collection(db, collectionName ?? nameCollection).withConverter(mapDataToJson[converterName]),...hideUsers({dataType:converterName}), orderBy(whereToOrderBy, sort), limit(count));

                    }
                }else{
                    if (whereToOrderBy === undefined) {
                        q = query(collection(db, collectionName ?? nameCollection).withConverter(mapDataToJson[converterName]),...hideUsers({dataType:converterName}));
                    } else {
                        q = query(collection(db, collectionName ?? nameCollection).withConverter(mapDataToJson[converterName]),...hideUsers({dataType:converterName}), orderBy(whereToOrderBy, sort));

                    }
                }
               
                return onSnapshot(q, (querySnapshot) => {
                    setNumberOfData(querySnapshot.size);
                    const allData = [];
                    querySnapshot.forEach(/* async */(doc) => {
                        let element = /* await */ doc.data()
                        allData.push({...element, id: doc.id});
                        //setData([...allData]);
                    });
                    setData([...allData]);
                    setDataLoading(false);
                });
            };
            getDocs().then((value) => {
                //value();
            });
        } else {
            console.log("paginated data");
            const r = query(collection(db, collectionName ?? nameCollection).withConverter(mapDataToJson[converterName]),...hideUsers({dataType:converterName}), orderBy(whereToOrderBy, sort), limit(page));
            getDocs(r).then(async (docs) => {
                if (docs.size > 0) {
                    let p = docs.docs[docs.size - 1];
                    if (p.exists) {
                        let dt = p.data();
                        //console.log(dt);
                        const unsub = async () => {
                            let q = query(collection(db, collectionName ?? nameCollection).withConverter(mapDataToJson[converterName]),...hideUsers({dataType:converterName}), orderBy(whereToOrderBy, sort), startAt(dt[whereToOrderBy]), limit(count));
                            return onSnapshot(q, (querySnapshot) => {
                                const allData = [];
                                querySnapshot.forEach((doc) => {
                                    let element = /* await */ doc.data()
                                    allData.push({...element, id: doc.id});
                                   
                                });
                                setData([...allData]);
                                setDataLoading(false);
                            });
                        }
                        unsub().then((value) => {
                            //value()
                        });

                    }
                }
            })
        }
    }, [db, collectionName, converterName, sort, count, isPaginated, page, nameCollection, whereToOrderBy]);
    return {data, numberOfData, dataLoading};
}

// get Docs With Where()
const useWhereDocs = (
    {
        db,
        collectionName,
        operator = "==",
        whereToLookInTheCollection,
        whereToLookValue,
        isTwoWhere = false,
        secondField,
        secondValue,
        operator_2 = "==",
        sort="desc",
        transformValueToArray = false,
        whereToOrderBy="createdAt"
    }
) => {
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [numberOfData, setNumberOfData] = useState(0);
    const nameCollection = collectionName + "_" + selectedBase;

    useEffect(() => {
        const getWhereDocs = async () => {
            if (!whereToLookValue) return;
            let q;
            if(isTwoWhere && secondField && secondValue) {
                let queryConstraints = createMultipleQuery({firstField:whereToLookInTheCollection,firstValue:whereToLookValue,secondField:secondField,secondValue:secondValue,operator_1:operator,operator_2:operator_2});
                q = query(
                    collection(db, nameCollection).withConverter(mapDataToJson[collectionName]),
                    ...queryConstraints , orderBy(whereToOrderBy,sort)
                );
            }else{
                if (transformValueToArray === true)
                {
                    whereToLookValue = [whereToLookValue];
                }
                
                q = query(
                    collection(db, nameCollection).withConverter(mapDataToJson[collectionName]),
                    where(whereToLookInTheCollection, operator, whereToLookValue)
                );
            }
           
            onSnapshot(q, (querySnapshot) => {
                setNumberOfData(querySnapshot.size);
                const allData = [];
                querySnapshot.forEach((doc) => {
                    allData.push({...doc.data(), id: doc.id});
                });
                setData([...allData]);
                setDataLoading(false);
            });
        };
        getWhereDocs();
    }, [whereToLookValue, db, collectionName, whereToLookInTheCollection]);

    return {data, numberOfData, dataLoading};
};

// get Docs With Where()
const useWhereDocs_1 = (
    {
        db,
        collectionName,
        mapValues = {}
    }
) => {
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [numberOfData, setNumberOfData] = useState(0);
    const nameCollection = collectionName + "_" + selectedBase;

    useEffect(() => {
        const getWhereDocs = async () => {
            let queryConstraints = engineQuery({mapValues: mapValues})
            
            queryConstraints = [...queryConstraints, ...hideUsers({dataType:collectionName})];
           // console.log(...queryConstraints);
            const q = query(
                collection(db, nameCollection).withConverter(mapDataToJson[collectionName]),
                ...queryConstraints
            );
            onSnapshot(q, (querySnapshot) => {
                setNumberOfData(querySnapshot.size);
                const allData = [];
                querySnapshot.forEach(async (doc) => {
                    allData.push({...doc.data(), id: doc.id});
                });
                //console.log(allData);
                setData([...allData]);
                setDataLoading(false);
            });
        };
        getWhereDocs();
    }, [db, collectionName, mapValues]);

    return {data, numberOfData, dataLoading};
};

// get image url
const useImgUrl = (storage, file) => {
    const [percentage, setPercentage] = useState(null);
    const [imgUrl, setImgUrl] = useState('')
    useEffect(() => {
        const uploadFile = () => {
            const name = new Date().getTime() + file.name;

            const storageRef = ref(storage, name);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setPercentage(progress);
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setImgUrl(downloadURL)
                    });
                }
            );
        };
        file && uploadFile();
    }, [file, storage]);

    return {imgUrl, setImgUrl, percentage}
}


export {useDocs, useWhereDocs, useWhereDocs_1, useImgUrl, useTotalSize, usePreferencesDocs};
