export const getError =  function (value ){
    if(value.indexOf( 'user-not-found') !== -1){
        return "Utilisatueur non trouvé" ;
    }else  if(value.indexOf( 'wrong-password') !== -1){
        return "Mot de passe incorrect" ;
    }else if(value.indexOf( 'user-disabled') !== -1){
        return "Votre compte a été désactivé" ;
    } else if(value.indexOf( 'too-many-requests') !== -1){
        return "L'accès à ce compte a été temporairement désactivé due à de nombreuses tentatives incorrectes. \n Restaurer votre mot de passe ou réessayer plus tard." ;
    }else if(value.indexOf( 'network-request-failed') !== -1){
        //return "Aucun accès à internet";
        return "Un probléme est survenu";
    }else if(value.indexOf( 'invalid-email') !== -1){
        return "Adresse email invalide";
    }
    else if(value.indexOf('invalid-login-credentials') !== -1){
        return "Email ou mot de passe incorrecte";
    }
    return "Une erreur est survenue";
}