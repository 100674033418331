import React, { useState, useEffect } from 'react'
import { fetchDataWithQueries } from '../../core/Firebase/services/FirebaseService'

import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, } from 'chart.js';
import { buildGraphicDataSet } from '../../core/js/backoffice';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
    },
    scales: {
        x: {
            //display: false,
        },
        y: {
            //display: false,

        }
    }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'august', 'september', 'october', 'november', 'december'];

const defaultData = {
    labels,
    datasets: [
        {
            label: 'Nbre Théme',
            //data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            data: [],
            backgroundColor: '#cbc87b',
        },
        {
            label: 'Nbre poste',
            data: [],
            backgroundColor: '#9ab57e',
        },
    ],
};


function BarSection(props) {
    const { queries } = props;
    const [data, setData] = useState(defaultData);

    useEffect(() => {
        console.log("NEW QUERIES ", queries);
        const fetchData = async () => {
            let firstData = await fetchDataWithQueries({ dataType: "themes", queries });
            let secondData = await fetchDataWithQueries({ dataType: "publications", queries });
            const tempData = {
                labels,
                datasets: [
                    buildGraphicDataSet({ data: firstData, labelName: "Nbre Théme", backgroundColor: "#cbc87b" }),
                    buildGraphicDataSet({ data: secondData, labelName: "Nbre Poste", backgroundColor: "#9ab57e" })
                ]
            };
            setData(tempData);
        };

        fetchData();
    }, [queries]);

    return (
        <section className="mt-30 mb-30 graphic-card">

            <div className="mt-50">
                <Bar options={options} data={data} />
            </div>

        </section>
    );
}

export default BarSection;