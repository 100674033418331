import { doc, getDoc } from "firebase/firestore";
import { db, host, selectedBase } from "../Firebase/firebase";

export class SousSousThemeModel {
  constructor({
    id = null,
    designation,
    date,
    createdAt,
    description,
    sousTheme,
    usedCount,
    sousThemeId,
    descriptionMap,
    designationMap,
    ordreMap,
    keywords,
    keywordsMap,
    ordre,
    fileUrl,
    eraseImg,
    tokens,
    likedBy,
  }) {
    this.id = id;
    this.date = date;
    this.createdAt = createdAt;
    this.designation = designation;
    this.description = description;
    this.usedCount = usedCount;
    this.sousTheme = sousTheme;
    this.sousThemeId = sousThemeId;
    this.designationMap = designationMap;
    this.descriptionMap = descriptionMap;
    this.ordreMap = ordreMap;
    this.keywords = keywords;
    this.keywordsMap = keywordsMap;
    this.fileUrl = fileUrl;
    this.eraseImg = eraseImg;
    this.tokens = tokens;
    this.likedBy = likedBy;
    this.ordre = ordre;
  }

  toString() {
    return this.designation + " , " + this.description;
  }

  toJson() {
    const nameCollection = "sous_themes_" + selectedBase;
    let obj = {
      id: this.id,
      date: this.date,
      designation: this.designation,
      description: this.description,
      sousTheme: doc(db, nameCollection, this.sousTheme),
      usedCount: this.usedCount,
      keywords: this.keywords,
      ordre: this.ordre,
    };
    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  }
}

/* export const mapObjectToSousSousThemes = {
    toFirestore: (m)=>{
        return {
            id: m.id,
            sousTheme:m.sousTheme,
            designation: m.designation,
            description: m.description,
        };
    },
    fromFirestore:async (snapshot, options)=>{
        const data = snapshot.data(options);
        //console.log(typeof data.theme);
        let p;
        if(data.sousTheme && typeof data.sousTheme !== 'string'){
           
            let x = await getDoc(data.sousTheme) ;
            if(x.exists()){
                p = x.data();
            }
        }
        //console.log(p); 
        return new SousSousThemeModel({id:data.id,createdAt:data.createdAt,date:new Date(data.createdAt.seconds * 1000),sousThemeId:p !== undefined ? p.id : "",sousTheme:p !== undefined ? p.designation['fr_FR'] ?? "" : "",designationMap:data.designation,descriptionMap:data.description, designation:data.designation ? data.designation['fr_FR'] ?? "" : "",description:data.description ? data.description['fr_FR'] ?? "" : "" });
    }
} */

export const mapObjectToSousSousThemes = {
  toFirestore: (m) => {
    let mTmp = Object.fromEntries(Object.entries(m));

    Object.keys(mTmp).forEach((key) => {
      if (mTmp[key] === undefined) {
        delete mTmp[key];
      }
    });

    return mTmp;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    let tmp = Object.fromEntries(Object.entries(data.keywords ?? {}));
    if (data.keywords) {
      Object.keys(tmp).forEach((key) => {
        tmp[key] = tmp[key].join(" ; ");
      });
    }
    //let newFileUrl = String(data.fileUrl);
    let newFileUrl = String(data.fileUrl ?? "/assets/images/imgs/upload.png");
    // newFileUrl = newFileUrl.replace("localhost",host)
    return new SousSousThemeModel({
      tokens: data.tokens ?? [],
      likedBy: data.likedBy ?? [],
      fileUrl: newFileUrl,
      id: data.id,
      keywords: tmp,
      keywordsMap: data.keywords,
      createdAt: data.createdAt,
      date: new Date(data.createdAt?.seconds * 1000),
      sousThemeId: data.sousTheme["id"] ?? "",
      sousTheme: data.sousTheme,
      designationMap: data.designation,
      descriptionMap: data.description,
      ordreMap: data.ordre,
      designation: data.designation ? data.designation["fr_FR"] ?? "" : "",
      description: data.description ? data.description["fr_FR"] ?? "" : "",
      ordre:data.ordre
    });
  },
};
