import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {db} from '../../core/Firebase/firebase';
import {useDocs, useTotalSize, useWhereDocs_1} from '../../core/Firebase/FirebaseHooks';
import {deleteData} from '../../core/Firebase/services/FirebaseService';
import {canModule} from '../../core/js/backoffice';
import {closeModalAdd, selectShowModal, showModalAdd} from '../../core/Redux/features/user';
import Filtres from '../../partials/FrequentlyUsed/Filtres';
import Paginations from '../../partials/FrequentlyUsed/Paginations';
import TitleBtnAdd from '../../partials/FrequentlyUsed/TitleBtnAdd';
import {ModalProfil} from './modals/ModalProfil';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';
import {SpeedDial, Tooltip} from 'primereact';
import LinesEllipsis from 'react-lines-ellipsis';

export default function ProfilPerms() {
    const mounted = useRef();
    const boolSpeed = useRef([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const show = useSelector(selectShowModal);
    const [isAdd, setIsAdd] = useState(true);
    const [showPerms, setShowPerms] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPage, setNextPage] = useState(0);
    const [selectedSize, setSelectedSize] = useState(0);
    const [isPaginated, setIsPaginated] = useState(false);
    const [itemToUpdate, setItemToUpdate] = useState(undefined);
    const [itemDetail, setItemDetail] = useState(undefined);
    const [count, setCount] = useState(12);
    const {numberOfData: totalSizePerms} = useTotalSize({db: db, collectionName: "permissions"});
    const {numberOfData: totalSizeProfils} = useTotalSize({db: db, collectionName: "profils"});
    const [page, setPage] = useState(0);
    const [foreignMap, setForeignMap] = useState({});
    const {data: profilsTab, numberOfData: totalProfils} = useDocs({
        db: db,
        converterName: "profils",
        sort: "desc",
        count: count,
        whereToOrderBy: "createdAt",
        isPaginated: isPaginated,
        page: nextPage
    });
    const {data: permissionsTab, numberOfData: totalPermissions} = useDocs({
        db: db,
        converterName: "permissions",
        sort: "desc",
        count: count,
        whereToOrderBy: "createdAt",
        isPaginated: isPaginated,
        page: nextPage
    });

    useEffect(() => {
        if (!mounted.current) {
            //did mounted
            document.title = "Profils-Permissions";
            dispatch(closeModalAdd())
            if (!canModule(['list-profil']) && canModule(['list-permissions'])) {
                switchTable(true);
            }
            mounted.current = true;
        } else {
            //did update
        }
    });
    useEffect(() => {
        setForeignMap({})
    }, [profilsTab])


    const mapFiltersTab = [
        {isField: true, name: "Profil", key: "nomProfil"}
    ];
    const mapFiltersTabPerms = [
        {isField: true, name: "Désignation", key: "designation"}
    ];
    //const [tabValues, setTabValues] = useState([]);
    const [isFiltering, setIsFiltering] = useState(false);
    const [mapValues, setMapValues] = useState({});
    var {data: tabValues} = useWhereDocs_1({
        db: db,
        collectionName: showPerms ? "permissions" : "profils",
        mapValues: mapValues
    });

    /*  useEffect(() => {
         if(itemToUpdate !== undefined && isFiltering) {
             let c = profilsTab.filter((i)=> i['id'] === itemToUpdate['id']);
             let cc = tabValues.findIndex((i)=> i['id'] === itemToUpdate['id']);
             if(c.length > 0 && cc !== -1){
                 tabValues[cc] = c[0];
                 setTabValues([...tabValues]);
             }

         }

     },[profilsTab]) */

    const handleFilter = (mapValues) => {
        /*  let cc = [];
         cc = genericFilter({tabItems: showPerms ? permissionsTab : profilsTab, mapValues: mapValues, mapFiltersTab: showPerms ? mapFiltersTabPerms : mapFiltersTab});
        //console.log(cc);
         setTabValues(cc); */
        setMapValues(mapValues);
        setIsFiltering(true)
    }
    const cancelFilter = () => {
        // setTabValues([]);
        setIsFiltering(false)
    }

    const handleSelectChangeFilter = (value) => {
        setCurrentPage(1);
        if (value > 0) {
            setCount(value);
        } else if (value === "0") {
            setCount(10)
        }
    }
    const handlePageChangeFilter = (pageNumber) => {
        setCurrentPage(pageNumber);
        let maxPage = Math.ceil(tabValues.length / count);
        //console.log(pageNumber,maxPage);
        setPage((pageNumber - 1) % maxPage)
    }

    useEffect(() => {
        setSelectedSize(0);
        handleSelectChange("0");
        cancelFilter();
    }, [showPerms])

    const switchTable = (bool) => {
        setShowPerms(bool);
    }
    const handleSelectChange = (value) => {
        setIsPaginated(false);
        setCurrentPage(1);
        if (value > 0) {
            setCount(value);
        } else if (value === "0") {
            setCount(10)
        }
    }
    const handlePageChange = (pageNumber) => {
        if (((showPerms ? totalSizePerms : totalSizeProfils) / count) >= 1) {
            setCurrentPage(pageNumber);
            setIsPaginated(true);
            setNextPage((pageNumber - 1) * ((showPerms ? totalPermissions : totalProfils)) + 1);
        }

    }

    const redirectToDetail = (value) => {
        navigate('/outilsadmin/profilsperms/detailProfil/' + value["id"]);
    }


    return (
        canModule(['list-profil', 'list-permissions']) && <motion.div
            initial={{opacity: 0, scale: 0.9}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.3}}>
            <TitleBtnAdd marginTop="mt-20" showBtn={canModule(['create-profil']) && !showPerms}
                         icon="/assets/images/imgs/profil et permissions.svg" btnClick={() => {
                setIsAdd(true);
                setItemToUpdate(undefined);
                //setIsPaginated(false);
                dispatch(showModalAdd())
            }}
                         itemsName={showPerms ? "Permissions" : "Profils"}
                         count={showPerms ? isFiltering ? tabValues.length : totalSizePerms : isFiltering ? tabValues.length : totalSizeProfils}/>

            {showPerms &&
            <Filtres cancelFilter={cancelFilter} tabFilters={mapFiltersTabPerms} handleSubmit={handleFilter}
                     marginTop="mt-30"/>}

            {!showPerms && <Filtres cancelFilter={cancelFilter} tabFilters={mapFiltersTab} handleSubmit={handleFilter}
                                    marginTop="mt-30"/>}

            <section className="mt-20">
                <div className="column-gap-10 r-flexed">
                    {canModule(['list-profil']) && <button
                        className={(showPerms ? 'btn-profilperms1' : 'btn-profilperms') + ' textClassInit'}
                        onClick={() => {
                            switchTable(false)
                        }}>Profils
                    </button>}
                    {canModule(['list-permissions']) && <button
                        className={(showPerms ? 'btn-profilperms' : 'btn-profilperms1') + ' textClassInit'}
                        onClick={() => {
                            switchTable(true)
                        }}>Permissions
                    </button>}
                </div>
            </section>

            <section className="mt-30">
                <div className="card-table pt-20 pb-20 pl-20 pr-20">
                    {(profilsTab.length > 0 || permissionsTab.length > 0) &&
                    <table
                        className="table basic-table table-borderless table-striped table-responsive table-hover">
                        <thead className="thead-table">
                        {
                            showPerms ? (
                                permissionsTab.length > 0 && <tr>
                                    <th style={{textAlign: "start"}}>Designation</th>
                                    <th style={{textAlign: "start"}}>Description</th>
                                    {/*  <th><img alt="" src="/assets/images/ico/ic_settings.png"/></th> */}
                                </tr>) : (

                                profilsTab.length > 0 && <tr>
                                    <th>Nom Profil</th>
                                    <th>Nbre de permissions</th>
                                    {canModule(['detail-profil', 'update-profil', 'delete-profil']) &&
                                    <th>
                                        <div className="d-flex jc-center">
                                            <img className="" alt="" src="/assets/images/ico/ic_settings.png"/>
                                        </div>
                                    </th>}
                                </tr>)
                        }
                        </thead>
                        <tbody>
                        {
                            showPerms ?
                                !isFiltering ? permissionsTab.map((item) => {
                                    return (
                                        newFunction_1(item)
                                    );
                                }) : tabValues.slice(page * count, count * (page + 1)).map((item) => {
                                    return (
                                        newFunction_1(item)
                                    );
                                })
                                :
                                !isFiltering ? profilsTab.map((item) => {
                                        return (
                                            newFunction(item)
                                        );
                                    }) :
                                    tabValues.map((item) => {
                                        return (
                                            newFunction(item)
                                        );
                                    })
                        }

                        </tbody>
                    </table>
                    }
                    {
                        (showPerms && permissionsTab.length < 1) &&
                        <div className='text-center'>Aucune données</div>
                    }
                    {
                        (!showPerms && profilsTab.length < 1) &&
                        <div className='text-center'>Aucune données</div>
                    }
                </div>

            </section>

            <section className="mt-30">
                {!isFiltering && <Paginations activePage={currentPage} selectedSize={selectedSize}
                                              totalItemsCount={(showPerms ? totalSizePerms : totalSizeProfils)}
                                              itemsCountPerPage={(showPerms ? totalPermissions : totalProfils)}
                                              handleSelectChange={(event) => {
                                                  handleSelectChange(event.target.value);
                                                  setSelectedSize(event.target.value);
                                              }} handlePageChange={handlePageChange}/>}

                {/*  {isFiltering&&<Paginations activePage={currentPage} selectedSize={count}
                             totalItemsCount={tabValues.length}
                             itemsCountPerPage={count}
                             handleSelectChange={(event) => {
                                 handleSelectChangeFilter(event.target.value);
                                 setSelectedSize(event.target.value);
                             }} handlePageChange={handlePageChangeFilter}/>} */}
            </section>

            {show &&
            <ModalProfil titleIcon="/assets/images/imgs/profil et permissions.svg" modalTitle="Profil" show={show}
                         handleClose={() => {
                             dispatch(closeModalAdd())
                         }}
                         permissionsModal={permissionsTab} isAdd={isAdd}
                         itemToUpdate={itemToUpdate}/>}

        </motion.div>


    )

    function newFunction_1(item) {
        return <tr className="w-100" key={item.id}>
            <td style={{textAlign: "left"}} className="w-50">{item.designation}</td>
            <td style={{textAlign: "start"}} data-pr-tooltip={item.description}
                className={"longText w-50 cursor-pointer longText" + item.id}>
                <LinesEllipsis maxLine={1} text={item.description}/>
                {item.designation === "can-create-posts" &&
                <Tooltip position="top" className="fsize-14" target={".longText" + item.id}/>}
            </td>
        </tr>;
    }


    function newFunction(item) {
        if (!foreignMap[item.id]) {
            setForeignMap({...foreignMap, [item.id]: "refresh"})
        }
        return <tr key={item.id}>
            <td>{item.nomProfil}</td>
            <td>{item.permissions ? item.permissions.length : ''}</td>
            {canModule(['detail-profil', 'update-profil', 'delete-profil']) && <td>
                <div className="d-flex jc-center">
                    <SpeedDial
                        direction='left'
                        showIcon="pi pi-bars"
                        hideIcon="pi pi-times"
                        buttonClassName='wdpx-35 hpx-35 p-button-outlined p-button-success align-self-center'
                        buttonStyle={{border: "1px solid #337f77"}}
                        id={item.id}
                        ref={(el) => boolSpeed.current[item.id] = el}
                        style={{position: "relative"}}
                        className={boolSpeed.current[item.id]?.props?.id === item.id ? "myCustomHamburger1" : "myCustomHamburger"}
                        model={getMenuItem({data: item})}/>
                </div>
            </td>}
        </tr>;
    }


    function getMenuItem({data}) {
        let result = [];
        let items = [
            {
                id: 0,
                label: 'Détails',
                icon: 'pi pi-info',
                command: () => {
                    redirectToDetail(data);
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-primary wdpx-35 hpx-35 cursor-pointer myTooltip0"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip0"/>
                        </div>
                    );
                },

            },
            {
                id: 1,
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: () => {
                    setIsAdd(false);
                    setItemToUpdate(data);
                    setIsPaginated(false);
                    dispatch(showModalAdd());
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-secondary wdpx-35 hpx-35 cursor-pointer myTooltip1"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip1"/>
                        </div>
                    );
                },
            },
            {
                id: 2,
                label: 'Supprimer',
                icon: 'pi pi-trash',
                command: () => {
                    deleteData({dataType: "profils", id: data.id});
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-danger wdpx-35 hpx-35 cursor-pointer myTooltip3"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip3"/>
                        </div>
                    );
                },
            }
        ];
        if (canModule(['detail-profil'])) {
            result.push(items.filter((i) => (i.id === 0))[0])
        }
        if (canModule(['update-profil'])) {
            result.push(items.filter((i) => (i.id === 1))[0])
        }
        if (canModule(['delete-profil'])) {
            result.push(items.filter((i) => (i.id === 2))[0])
        }
        return result;
    }
}
