/* Card utilise pour afficher les publications */

import {faComment, faHeart} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SpeedDial, Tooltip} from 'primereact';
import React, {useRef, useState} from 'react';
import {Stack} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {db} from '../../core/Firebase/firebase';
import {useWhereDocs} from '../../core/Firebase/FirebaseHooks';
import {deleteData, enableItem} from '../../core/Firebase/services/FirebaseService';
import {c, canModule, customDateFormat} from '../../core/js/backoffice';
import FsLightbox from 'fslightbox-react';

function CardPublications({item, onUpdate, isForDetail = false,isFiltering}) {

    const navigate = useNavigate();
    const speedRef = useRef();
    const [boolSpeed, setBoolSpeed] = useState(false);
    const [toggler, setToggler] = useState(false);
    const redirectToDetail = (value, bool) => {
        navigate('/publications/detailPost/' + value["id"] + (bool ? "/comments" : ""));
    }
    const {data: commentaires} = useWhereDocs({
        db: db,
        collectionName: "commentaires",
        whereToLookInTheCollection: "postId",
        whereToLookValue: item["id"]
    });

    const getMenuItem = ({data}) => {
        let result = [];
        let items = [
            {
                id: 0,
                label: 'Commentaires',
                icon: 'pi pi-comments',
                command: () => {
                    redirectToDetail(data, true)
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-warning wdpx-35 hpx-35 cursor-pointer myTooltip0"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip0"/>
                        </div>
                    );
                },

            },
            {
                id: 1,
                label: 'Détails',
                icon: 'pi pi-info',
                command: () => {
                    redirectToDetail(data, false)
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-primary wdpx-35 hpx-35 cursor-pointer myTooltip0"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip0"/>
                        </div>
                    );
                },

            },
            {
                id: 2,
                label: !data.disabled ? "Désactiver ce post" : "Activer ce post",
                icon: 'pi pi-thumbs-' + (!data.disabled ? 'down' : 'up'),
                command: () => {
                    enableItem({
                        bool: !data.disabled,
                        fieldToSet: "disabled",
                        dataType: "publications",
                        messageSuccess: "Publication " + (!data.disabled ? "désactivée" : "activée") + " avec succés !",
                        message: "cette publication ?",
                        id: data.id
                    })
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-" + (!data.disabled ? "danger" : "success") + " wdpx-35 hpx-35 cursor-pointer myTooltip2"}
                             target={item.target} onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip2"/>
                        </div>
                    );
                },
            },
            {
                id: 3,
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: () => {
                    onUpdate()
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-secondary wdpx-35 hpx-35 cursor-pointer myTooltip1"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip1"/>
                        </div>
                    );
                },
            },
            {
                id: 4,
                label: 'Supprimer',
                icon: 'pi pi-trash',
                command: () => {
                    deleteData({dataType: "publications", id: data.id})
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-danger wdpx-35 hpx-35 cursor-pointer myTooltip3"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip3"/>
                        </div>
                    );
                },
            }
        ];
        if (data.type !== "doua" && canModule(['list-commentaires']) && data.disabled === false) {
            result.push(items.filter((i) => (i.id === 0))[0])
        }
        if (canModule(['detail-posts'])) {
            result.push(items.filter((i) => (i.id === 1))[0])
        }
        if (canModule(['can-validate-posts']) && !isForDetail) {
            result.push(items.filter((i) => (i.id === 2))[0])
        }
        if (canModule(['update-posts']) && !isForDetail) {
            result.push(items.filter((i) => (i.id === 3))[0])
        }
        if (canModule(['delete-posts']) && !isForDetail) {
            result.push(items.filter((i) => (i.id === 4))[0])
        }
        return result;
    }

    return (
        <div className="w-100 card-pub-item">
            <Stack className='p-10' direction='horizontal'>
                <div className="column-gap-7 r-flexed align-items-center" style={{cursor:"pointer"}} onClick={()=>{
                    if(item.type !== "doua")
                    {
                        c("here");
                        setToggler(!toggler);
                    }
                }}>
                    {(item.type === "doua") && <span className="avatar avatar-online box-shadow-1 wdpx-70 hpx-70">
                        <img alt="" src="/assets/images/ico/doua.svg"/>
                    </span>}
                    {(item.type !== "doua") && <div   className="card-circle-generic">
                        {(item.type === "audio") && <img alt="" src="/assets/images/ico/ic_audio.svg"/>}
                        {(item.type === "video") && <img alt="" src="/assets/images/ico/ic_video.svg"/>}
                        {(item.type === "image") && <img alt="" src="/assets/images/ico/ic_image.svg"/>}
                    </div>}

                    <FsLightbox
                        toggler={toggler}
                        disableLocalStorage={true}
                        type="image"
                        svg={""}
                        sources={
                            item.type === "image" ? [<img alt="" src={item.fileUrl} className="w-100 h-100"/>] :
                            item.type === "video" ? [<iframe title="Video" src={item.fileUrl} width="1920px" height="1080px" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>] :
                            item.type === "audio" ? [<iframe title="Audio" src={item.fileUrl} width="1920px" height="1080px" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>] :
                            [<span className="text-white">Impossible de charger le fichier</span>]
                        }
                     />

                    <div style={{background: !item.disabled ? "#337F77" : "#8E191D"}} className="user-badge"/>
                </div>

                <div className="c-flexed w-100">
                    <span className="pub-title textClassInit">{item.titre?.fr_FR ? item.titre?.fr_FR : item?.titre}</span>
                    <span className="pub-subtitle">{customDateFormat({date: item?.date})}</span>
                    <div className="r-flexed column-gap-10">
                        {item["likedBy"]?.length > 0 &&
                        <span className="textClassInit pub-subtitle">{item["likedBy"]?.length} <FontAwesomeIcon
                            className="text-danger"
                            icon={faHeart}/></span>}
                        {commentaires?.length > 0 &&
                        <span className="textClassInit pub-subtitle">{commentaires?.length} <FontAwesomeIcon
                            className="text-secondary"
                            icon={faComment}/></span>}
                    </div>

                </div>


                {canModule(['detail-posts', 'update-posts', 'delete-posts', 'can-validate-posts']) &&
                <div className="d-flex justify-content-end align-items-center" style={{marginLeft: "auto"}}>
                    <SpeedDial
                        direction='left'
                        showIcon="pi pi-bars"
                        hideIcon="pi pi-times"
                        buttonClassName={(boolSpeed ? "wdpx-35 hpx-35" : "wdpx-35 hpx-35") + " p-button-outlined p-button-success align-self-center"}
                        buttonStyle={{border: "1px solid #337f77", transition: "0.6s"}}
                        id={item?.id}
                        ref={(el) => speedRef.current = el}
                        style={{position: "relative"}}
                        className={speedRef.current?.props?.id === item?.id ? "myCustomHamburger1" : "myCustomHamburger"}
                        onShow={() => {
                            setBoolSpeed(true)
                        }}
                        onHide={() => {
                            setBoolSpeed(false)
                        }}
                        model={getMenuItem({data: item})}/>
                </div>}

            </Stack>
        </div>
    );
}

export default CardPublications;
