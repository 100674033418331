import {doc} from 'firebase/firestore';
import moment from 'moment';
import React, {useEffect,useState} from 'react';
import * as Rb from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {db, selectedBase} from '../../../core/Firebase/firebase';
import {useWhereDocs} from '../../../core/Firebase/FirebaseHooks';
import {fetchForeignData, fetchforeignDataTab} from '../../../core/Firebase/services/FirebaseService';
import {mapLanguesToName} from '../../../core/localization/map_langues';
import CardPublications from '../../../partials/FrequentlyUsed/CardPublications';
import TitleBtnAdd from '../../../partials/FrequentlyUsed/TitleBtnAdd';
import {motion} from 'framer-motion';
import { c, customDateFormat } from '../../../core/js/backoffice';
import CardUsersBo from '../../../partials/FrequentlyUsed/CardUsersBo';

const DetailSousTheme = () => {

    const {id} = useParams();
    const [item, setItem] = useState({});
    const [sousThemeDoc, setSousThemeDoc] = useState();
    const [foreignMap, setForeignMap] = useState({});
    const [listClients, setListClients] = useState([]);


    const {data: sousTheme} = useWhereDocs({
        db: db,
        collectionName: "sous_themes",
        whereToLookInTheCollection: "id",
        whereToLookValue: id
    });

    const {data} = useWhereDocs({
        db: db,
        collectionName: "sous_sous_themes",
        whereToLookInTheCollection: "sousTheme",
        whereToLookValue: sousThemeDoc
    })

    const {data: publications} = useWhereDocs({
        db: db,
        collectionName: "publications",
        transformValueToArray: true,
        operator: "array-contains-any",
        whereToLookInTheCollection: "sousTheme",
        whereToLookValue: sousThemeDoc
    })
    useEffect(() => {
        if (item["id"] && !sousThemeDoc) {
            setSousThemeDoc(doc(db, "sous_themes_test", item["id"]))
        }

        fetchForeignData({reference: item.theme, field: "designation"}).then((value) => {
            if (!foreignMap[item.id]) {
                setForeignMap({...foreignMap, [item.id]: value})
            }
        })
    }, [data, item, sousThemeDoc, publications, foreignMap]);

    useEffect(() => {
        if (sousTheme.length === 1) {
            setItem(sousTheme[0]);
        }
    }, [sousTheme]);

    useEffect(() => {
        fetchforeignDataTab({
            tab:(item["likedBy"]?? []).map((u)=>{
                return doc(db, "clients_"+selectedBase, u)
            }),
            allField:true
        }).then((value)=>{
            c(value);
            setListClients(value);
        })
    },[item])

    return (
        <motion.div
        initial={{opacity: 0, scale: 0.9}}
        animate={{opacity: 1, scale: 1}}
        transition={{duration: 0.3}}
    >
            <TitleBtnAdd showBtn={false} marginTop="mt-20"
                         icon="/assets/images/imgs/sous_theme.png" itemsName="Détail Sous Thème"/>
            <div className="tab-card mt-30">
                <Rb.Tabs className="mb-2" defaultActiveKey="detail" justify={true} fill={true} id="tab-detail-theme">
                    <Rb.Tab eventKey="detail" title="Détails" tabClassName="tabHeader textClassInit">
                        <Rb.ListGroup variant='flush'>
                            <Rb.ListGroupItem
                                className="list-item textClassInit">Thème: {foreignMap[item.id] ?? ""}</Rb.ListGroupItem>
                            <Rb.ListGroupItem
                                className="list-item textClassInit">Designation: {item["designation"] ?? "..."}</Rb.ListGroupItem>
                            <Rb.ListGroupItem
                                className="list-item textClassInit">Description: {item["description"] ?? "..."}</Rb.ListGroupItem>
                            <Rb.ListGroupItem className="list-item textClassInit">Date de création: {customDateFormat({date:item.date})}</Rb.ListGroupItem>
                            <Rb.ListGroupItem className="list-item textClassInit">
                                {Object.keys(item['keywordsMap'] ?? {}).length > 0 && <p>Mots clés :</p>}
                                {
                                    Object.keys(item['keywordsMap'] ?? {}).map((u, i) => {
                                        //console.log(item['keywordsMap'][u]);
                                        return <div key={i}>
                                            <span>{mapLanguesToName[u]}</span>

                                            <Rb.Row className="no-gutters mt-1">
                                                {
                                                    item['keywordsMap'][u].map((u, index) => {
                                                        return <Rb.Col style={{padding: "4px", borderRadius: "5px"}}
                                                                       className="bg-warning text-white text-center mrpx-10 mb-1"
                                                                       lg={2} mg={2} xs={4} key={index}>{u}</Rb.Col>
                                                    })
                                                }
                                            </Rb.Row>
                                        </div>
                                    })
                                }
                            </Rb.ListGroupItem>
                        </Rb.ListGroup>


                    </Rb.Tab>
                    <Rb.Tab eventKey="sous_themes" title={
                        <Rb.Stack
                            className="w-100 textClassInit"
                            direction="horizontal"
                            gap={2}>Sous sous thème <Rb.Badge
                            pill
                            bg='success'>{data.length}</Rb.Badge>
                        </Rb.Stack>
                    } tabClassName="tabHeader textClassInit">
                        {data.length <= 0 && <p className="text-center">Pas de sous sous thèmes</p>}
                        {data.length > 0 && <div className="card-table pt-20 pb-20 pl-20 pr-20 mt-20">
                            <table
                                className="table basic-table table-borderless table-striped table-responsive table-hover">
                                <thead className="thead-table">
                                <tr>
                                    <th>Date</th>
                                    <th>Désignation</th>
                                    <th>Description</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    data.map((i) => {
                                        return (
                                            newFunction(i)
                                        );
                                    })
                                }
                                </tbody>
                            </table>
                        </div>}

                    </Rb.Tab>
                    <Rb.Tab eventKey="post" title={
                        <Rb.Stack
                            className="w-100 textClassInit"
                            direction="horizontal"
                            gap={2}>Publications <Rb.Badge
                            pill
                            bg='success'>{publications.length}</Rb.Badge>
                        </Rb.Stack>
                    } tabClassName="tabHeader textClassInit">
                        {publications.length <= 0 && <p className="text-center">Pas de publications</p>}
                        {
                            publications.map((u, index) => {
                                return <div className="mb-2 mt-2" key={index}>
                                    <CardPublications isForDetail={true} item={u}/>
                                </div>
                            })
                        }
                    </Rb.Tab>
                    <Rb.Tab eventKey="notif" tabClassName='tabHeader textClassInit' title="Notifications Clients">
                    <Rb.ListGroup variant='flush'>
                        <Rb.ListGroupItem className="list-item textClassInit">
                            Liste des clients ayant activé l'option de recevoir des notifications à chaque fois qu'une publication est lié à ce thème !
                        </Rb.ListGroupItem>
                        <Rb.ListGroupItem className="list-item textClassInit">
                            Nombre Total : {item["likedBy"]?.length}
                        </Rb.ListGroupItem>
                        <Rb.ListGroupItem className="list-item textClassInit">
                            <Rb.Row>
                                {
                                    listClients.map((item,index)=>{
                                        return <Rb.Col md={6} lg={4} key={index}>
                                                <CardUsersBo isForDetail item={item} />
                                            </Rb.Col>
                                    })
                                }
                            </Rb.Row>
                        </Rb.ListGroupItem>
                    </Rb.ListGroup>
                    </Rb.Tab>
                </Rb.Tabs>
            </div>
        </motion.div>

    );

    function newFunction(item) {
        return <tr key={item.id}>
            <td>{moment(item.date).format("DD.MM.YYYY - HH[h] mm")}</td>
            <td>{item.designation}</td>
            <td>{item.description}</td>
        </tr>;
    }
}

export default DetailSousTheme;
