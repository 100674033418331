import {createSlice} from '@reduxjs/toolkit';



const initialStateValue = {name: "Sakhewar", age: 0, email: "diop@gmail.com"};
const initialModalValue = {show: false};
const menuBarReducedInitialState = {isReduced: false};
const blockUIInitialState = {blocking: false};
const authStateInitial = {error: false, isLoggedIn: false,theme: false,loading: false, message: "", showMdp: false};
const dataApiInitialState = {items: [],modules:[], totalItems: 0, itemsCountPerPage: 10};

export const dataApiSlice = createSlice({
    name: "dataApiRedux",
    initialState: {value: dataApiInitialState},
    reducers: {
        storeDataApi: (state, action) => {
            if (action.payload.dataType !== undefined) {
                //console.log(action.payload.items);
                state.value[action.payload.dataType] = action.payload.items ?? [];
            }
            if (action.payload.modules) {
                state.value.modules = action.payload.modules;
            }
            else if (action.payload.totalItems) {
                state.value.totalItems = action.payload.totalItems;
            }
            else if (action.payload.resetTotalItems) {
                state.value.totalItems =0;
            }
            else if (action.payload.itemsCountPerPage) {
                state.value.itemsCountPerPage = action.payload.itemsCountPerPage;
            }

        },
        resetDataApi: (state) => {
            state.value = dataApiInitialState;
        },
       
    }
});

export const blockUISlice = createSlice({
    name: "blockUIRedux",
    initialState: {value: blockUIInitialState},
    reducers: {
        blockUIAction: (state, action) => {
            state.value.blocking = action.payload;
        }
    }
});
export const menuBarReducedSlice = createSlice({
    name: "menuBarReduced",
    initialState: {value: menuBarReducedInitialState},
    reducers: {
        switchMenuBar: (state, action) => {
            state.value.isReduced = action.payload;
        }
    }
});


export const userSlice = createSlice({
    name: "user",
    initialState: {value: initialStateValue},
    reducers: {
        login: (state, action) => {
            state.value = action.payload;
        },
        logout: (state, action) => {
            state.value = initialStateValue;
        }
    }
});

export const modalsProfilSlice = createSlice({
    name: "modal",
    initialState: {value: initialModalValue},
    reducers: {
        showModal: (state, action) => {
            state.value = {show: true};
        },
        closeModal: (state, action) => {
            state.value = initialModalValue;
        }
    }
});

export const modalItemAddSlice = createSlice({
    name: "modal_add",
    initialState: {value: initialModalValue},
    reducers: {
        showModalAdd: (state) => {
            state.value = {show: true};
        },
        closeModalAdd: (state) => {
            state.value = initialModalValue;
        }
    }
});

export const authStateSlice = createSlice({
    name: "authState",
    initialState: {value: authStateInitial},
    reducers: {
        authLoading: (state, actions) => {
            state.value.loading = actions.payload
        },
        authError: (state, action) => {
            state.value.error = action.payload.error;
            state.value.message = action.payload.message;
        },
        authInit: (state) => {
            state.value = authStateInitial
        },
        authSuccess: (state, action) => {
            state.value.isLoggedIn = true;
            if (action.payload) {
                if(action.payload.user){
                state.value.currentUser = action.payload.user
                }
                if(action.payload.userHeader)
                {
                    state.value.userHeader = action.payload.userHeader
                }
                if(action.payload.userInfos){
                    state.value.userInfos = action.payload.userInfos;
                    state.value.tabPerms = action.payload.userInfos.permissions ; 
                }
            }

        },
        authNone: (state) => {
            state.value.isLoggedIn = false;
            if(state.value.currentUser){
            delete(state.value['currentUser']);
            }
            if(state.value.UserInfos){
            delete(state.value['UserInfos']);
            }
        },
        handleMdpInput: (state) => {
            state.value.showMdp = !state.value.showMdp
        },
        switchTheme:(state) => {
            state.value.theme = !state.value.theme;
        }
    }
});

export const {login, logout} = userSlice.actions;
export const {showModal, closeModal} = modalsProfilSlice.actions;
export const {showModalAdd, closeModalAdd} = modalItemAddSlice.actions;
export const {authLoading, authError, authInit, handleMdpInput, authSuccess, authNone,switchTheme} = authStateSlice.actions;
export const {switchMenuBar} = menuBarReducedSlice.actions;
export const {blockUIAction} = blockUISlice.actions;
export const {storeDataApi, resetDataApi,resetDataApiItems,resetTotalItems} = dataApiSlice.actions;

export const selectAuthState = (state) => state.authState.value;
export const selectBlockUIState = (state) => state.blockUIState.value.blocking;
export const selectModalProfilState = (state) => state.modal.value;
export const selectModalItemAddState = (state) => state.modal_item_add.value;
export const selectDataApiState = (state) => state.dataApi.value;
export const selectDataTypeItems = (state,dataType) => state.dataApi.value[dataType];
export const selectItemCountPerPage = (state) => state.dataApi.value.itemsCountPerPage;
export const selectShowModal = (state) => state.modal_item_add.value.show;

export const mapStateToProps = (state) => {
    return {
        items: state.dataApi.value.items,
        itemsData: state.dataApi.value,
        authState: state.authState.value,
        totalItems: state.dataApi.value.totalItems,
        itemsCountPerPage: state.dataApi.value.itemsCountPerPage,
        show: state.modal_item_add.value.show
    }

};


export const mapDispatchToProps = (dispatch) => {
    return ({
        storeDataApi: (p) => {
            dispatch(storeDataApi(p))
        },
        resetDataApiItems: (p) => {
            dispatch(resetDataApiItems())
        },
        resetDataApi: (p) => {
            dispatch(resetDataApi())
        },
        showModalAdd: () => {
            dispatch(showModalAdd())
        },
        closeModalAdd: () => {
            dispatch(closeModalAdd())
        },
    });
}


export default userSlice.reducer;