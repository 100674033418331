/* Card utilise pour afficher les publications */

import { faComment, faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpeedDial, Tooltip } from 'primereact';
import React, { useRef, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { db } from '../../core/Firebase/firebase';
import { useWhereDocs } from '../../core/Firebase/FirebaseHooks';
import { deleteData, enableItem } from '../../core/Firebase/services/FirebaseService';
import { c, canModule, customDateFormat } from '../../core/js/backoffice';
import FsLightbox from 'fslightbox-react';

function CardTheme({ item, getMenuItem }) {

    const navigate = useNavigate();
    const speedRef = useRef([]);


    return (
        <div className="w-100 card-pub-item-fit-content p-1">
            <div className="d-flex justify-content-start">
                <div className='mr-1 avatar avatar-online box-shadow-1 wdpx-70 hpx-70'>
                    <img alt=""
                        src={item?.fileUrl ?? "/assets/images/imgs/upload.png"} />
                </div>
                <div>
                    <div className='ellipsis-1'>
                        {item?.designation}
                    </div>
                    <div className='ellipsis-1 pub-subtitle'>
                        {item?.description ?? "-"}
                    </div>
                    <div className='ellipsis-1 pub-subtitle'>
                        Ordre : &nbsp;
                        <span>
                            {item?.ordre}
                        </span>
                    </div>
                    <div className='pub-subtitle'>
                    {customDateFormat({ date: item.date })}
                    </div>
                </div>
                <div className='d-flex justify-content-end' style={{marginLeft: "auto"}}>
                    {(canModule(['detail-sous-theme', 'update-sous-theme', 'delete-sous-theme'])) &&
                        <td>
                            <div className="d-flex jc-center">
                            <SpeedDial
                                direction="left"
                                showIcon="pi pi-bars"
                                hideIcon="pi pi-times"
                                buttonClassName="wdpx-35 hpx-35 p-button-outlined p-button-success align-self-center"
                                buttonStyle={{ border: "1px solid #337f77" }}
                                id={item.id}
                                ref={(el) => (speedRef.current[item.id] = el)}
                                style={{ position: "relative" }}
                                className={"myCustomHamburger1"}
                                model={getMenuItem({ data: item })}
                            />
                            </div>
                        </td>}
                </div>
            </div>
            <div>

            </div>
        </div>
    );
}

export default CardTheme;
