import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updatePreferences, uploadFileToStorage } from "../../../core/Firebase/services/FirebaseService";
import { closeModalAdd } from "../../../core/Redux/features/user";
import { store } from "../../../core/Redux/store";
import * as Rb from 'react-bootstrap';
import {HexColorInput, HexColorPicker} from "react-colorful"
import { mapLanguesToFlag, mapLanguesToName } from "../../../core/localization/map_langues";
import { CustomAlert, customToast } from "../../../core/js/backoffice";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectedBase } from "../../../core/Firebase/firebase";

export const ModalPrefs = (props) => {
    const dispatch = useDispatch();
    const [data] = useState(Array.from(props.itemToUpdate))
    //const [isColor, setIsColor] = useState(false);
    const [oneTime, setOneTime] = useState(false);
    const [isAppImg, setIsAppImg] = useState(false);
    const [isAppVideo, setIsAppVideo] = useState(false);
    const [isLangues, setIsLangues] = useState(false);
    const [isAppTextes, setIsTextes] = useState(false);
    const [scope, setScope] = useState({});
    const [lng, setLng] = useState({});
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [texteApropos, setTexteApropos] = useState(null);


    useEffect(() => {
        //("couleurs_front".indexOf(props.titleModal) !== -1);
        //setIsAppImg("banierre_app".indexOf(props.titleModal) !== -1);
        //setIsAppImg("video_apropos".indexOf(props.titleModal) !== -1);
       //setIsLangues("langues".indexOf(props.titleModal) !== -1);
    }, []);

    useEffect(()=>{
        if (!!data && Array.isArray(data))
        {
            if (data.length > 0 && !!data[0].type)
            {
                setIsAppVideo(data[0].type === "video");
                setIsAppImg(data[0].type === "image");
                setIsLangues(data[0].type === "langue");
                setIsTextes(data[0].type === "text");
            }
            
            
        }
        
    },[data])

    const handleSubmit = () => {
        //console.log(scope);
        //console.log(data);

        if (isAppImg === true)
        {
            if (file === null && fileUrl === null)
            {
                  dispatch(closeModalAdd())
                /* toast.error("Choisissez une image !", {
                    position: toast.POSITION.TOP_RIGHT,
                    containerId: "toastModal",
                    toastId: "toastId",
                    autoClose: 2000
                }); */
            }
            else
            {
                let b = data.map((item, index) =>
                {
                    item['value'] = file;
                    if (item['id'] !== undefined)
                    {
                        return item;
                    }
                })
                b.forEach((p) =>
                {
                    if (p)
                    {
                        uploadFileToStorage({dataToSave: p, linkPath:"preferences_" + selectedBase + "/images/banierre_app"});
                    }

                })
            }
        }

        if (isAppVideo === true)
        {
            if (file === null && fileUrl === null)
            {
                  dispatch(closeModalAdd())
            }
            else
            {
                let b = data.map((item, index) =>
                {
                    item['value'] = file;
                    if (item['id'] !== undefined)
                    {
                        return item;
                    }
                })
                b.forEach((p) =>
                {
                    if (p)
                    {
                        uploadFileToStorage({dataToSave: p, linkPath:"preferences_" + selectedBase + "/videos/video_apropos", pathName:"video_apropos"});
                    }

                })
            }
        }

        if(isLangues === true)
        {
            //console.log(lng);
            let val = {} ;
            let a = Object.keys(lng);
            a.map((k) => {
            if(lng[k] !== null){
            val ={...val,[k.slice(0,-1)]:lng[k]}
            }
            })
        
            if(Object.keys(val).length <= 0)
            {
                customToast({
                    message:"Acitvez au moins une langue",
                    type:"error",
                    icon:<span className="text-warning"><FontAwesomeIcon icon={faWarning} /></span>
                })
            }
            else
            {
                let b = data.map((item, index) =>
                {
                    item['value'] = val;
                    if (item['id'] !== undefined)
                    {
                        return item;
                    }
        
                })
                console.log(b);
                b.forEach((p) =>
                {
                    if (p) {
                        updatePreferences({dataToSave: p, idPrefs: p['idPrefs'], pathName: props.titleModal})
                    }
        
                })
            }
      
        }


        if (isAppTextes === true)
            {
                if (texteApropos === null)
                {
                      dispatch(closeModalAdd());
                }
                else
                {
                    let b = data.map((item, index) =>
                    {
                        item['value'] = texteApropos;
                        if (item['id'] !== undefined)
                        {
                            return item;
                        }
                    })
                    b.forEach((p) =>
                    {
                        if (p)
                        {
                            updatePreferences({dataToSave: p, idPrefs: p['idPrefs'], pathName: props.titleModal})
                        }
                    })
                }
            }

    }

    const handleFileChange = (event) =>
    {
        let filesTab = event.target.files;
        if (filesTab.length > 0)
        {
            setFile(filesTab[0]);
            setFileUrl(URL.createObjectURL(event.target.files[0]))
        }
    }

    const handleTexteChange = (event) =>
    {
        setTimeout(() => {
            console.log(event.target.value);
        //setTexteApropos(event.target.value);
        }, 5000);
    }

    return (
        <div className="m-10">
            <Rb.Modal.Body>
                <div className="container-fluid">
                    <div className="card-table pt-50 pb-50 pl-20 pr-20">
                        <table
                            className="table basic-table table-borderless table-responsive table-striped table-hover">
                            <thead className="thead-table">
                            <tr>
                                <th>Nom</th>
                                <th>Valeur</th>
                                {isLangues&&<th>Statut</th>}
                                {isLangues&&<th><img alt="" src="../../assets/images/ico/ic_settings.png"/></th>}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                isAppImg && data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.nom}</td>
                                            <td className="c-flexed jc-center">
                                                <label className="cursor-pointer">
                                                    <input type="file" accept="image/*" onChange={handleFileChange}/>
                                                    <span className="avatar avatar-online box-shadow-1 hpx-100 wdpx-100">
                                                        {fileUrl === null && <img onError={({currentTarget}) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = "/assets/images/imgs/upload.png";
                                                        }} alt="" className="w-100 h-100" src={item.value}></img>}

                                                        {fileUrl !== null && <img onError={({currentTarget}) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = "/assets/images/imgs/upload.png";
                                                        }} alt="" className="w-100 h-100" src={fileUrl}></img>}
                                                    </span>
                                                </label>

                                                {fileUrl !== null && <button onClick={() => {
                                                    setFileUrl(null);
                                                    setFile(null)
                                                }} className="btn w-20 m-auto btn-danger"><i
                                                    className="fa fa-trash"></i></button>}
                                            </td>

                                        </tr>
                                    )

                                })
                            }
                            {
                                isAppVideo && data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {item.nom}
                                                <br />
                                                { (fileUrl === null) &&<a href={item.value} target="_blank">Aperçu</a>}
                                            </td>
                                            <td className="c-flexed jc-center">
                                                <label className="cursor-pointer">
                                                    <input type="file" accept="video/*" onChange={handleFileChange}/>
                                                    {fileUrl === null &&<div className="doted-circle-prefs cursor-pointer">
                                                        <img alt="" src="/assets/images/ico/ic_video_1.svg"/>
                                                    </div>}
                                                    {fileUrl !== null && <video height={250} width={250} controls onError={() => {
                                                        setFileUrl(undefined);
                                                        setFile(undefined)
                                                    }} /* poster="/assets/images/ico/video-poster.jpeg" */>
                                                        <source src={fileUrl}></source>
                                                        <p>Votre navigateur ne prend pas en charge ce type de vidéo</p>
                                                    </video>}
                                                </label>

                                                {fileUrl !== null && <button onClick={() => {
                                                    setFileUrl(null);
                                                    setFile(null)
                                                }} className="btn w-20 m-auto btn-danger"><i
                                                    className="fa fa-trash"></i></button>}
                                            </td>

                                        </tr>
                                    )

                                })
                            }
                            {
                                isLangues && data.map((item, index) => {
                                  ;
                                    return Object.entries(item.defaultValue ?? {}).map((value,o) => {
                                        let key = value[0];
                                        if(!oneTime) {
                                        if (lng[key+o] === undefined) {
                                            if(item.value[key] !== undefined) {
                                                lng[key+o] = item.value[key];
                                               
                                            }else{
                                                lng[key+o] = null;
                                            }
                                            setLng(lng);
                                           
                                             
                                        }
                                        setOneTime(true);
                                    }
                                        
                                        //let elt = item.value[value[0]];
                                      
                                        
                                        return (
                                            <tr key={index+o}>
                                                <td>{mapLanguesToName[key]}</td>
                                                <td><span className={mapLanguesToFlag[key]}></span></td>
                                                <td><button className={"w-100 btn "+(lng[key+o] === null ?"btn-danger":"btn-success")}>{lng[key+o] === null ?"Inactif":"Actif"}</button></td>
                                                <td><button onClick={()=>{
                                                    //console.log(item.defaultValue[key]);
                                                    if(lng[key+o] === null){
                                                        setLng({...lng,[key+o]:item.defaultValue[key]});
                                                    }else{
                                                     setLng({...lng,[key+o]:null});
                                                    }
                                                }
                                                    } className={"btn "+(lng[key+o] !== null ?"btn-danger":"btn-success")} title={lng[key+o] !== null ? "Désactiver cette langue" : "Activer cette langue"}><i className={"fa fa-thumbs-" + (lng[key+o] !== null ? "down" : "up")}></i></button></td>
                                                
                                            </tr>
                                        )
                                    })
                                    
                                })
                            }
                            {
                                isAppTextes && data.map((item, index) => {
                                    console.log("diop log", item, texteApropos);
                                    if (!(!!texteApropos) && !!item.value)
                                    {
                                        setTexteApropos(item.value);
                                    }
                                    return (
                                        <tr key={index}>
                                            <td>{item.nom}</td>
                                            <td className="c-flexed jc-center">
                                                <label className="cursor-pointer">
                                                    {/* <input className="form-control" type="text" value={item.value ?? ""}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        setTexteApropos({ ...texteApropos, [item.value]: value });
                                                      }}
                                                      /> */}
                                                </label>
                                                <Rb.Form.Control as="textarea" value={texteApropos} onChange={(e) => setTexteApropos(e.target.value)}
                                                     className="modal-form" rows={3} placeholder="..."/>

                                                {/* {texteApropos !== null && <button onClick={() => {
                                                    setTexteAppropos(null);
                                                }} className="btn w-20 m-auto btn-danger"><i
                                                    className="fa fa-trash"></i></button>} */}
                                            </td>

                                        </tr>
                                    )

                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="float-right r-flexed column-gap-20 mb-20 mt-20">
                        <Rb.Button onClick={() => {
                            let msg = "Si vous fermez le modal, vous perderez les données déjà enregistrées !!!";
                            CustomAlert({
                                message: msg, yesBtn: () => {
                                    dispatch(closeModalAdd())
                                }
                            })
                        }} bsPrefix="btn btn-cancel">
                            Annuler
                        </Rb.Button>
                        <Rb.Button type="button" onClick={handleSubmit} bsPrefix="btn btn-save">
                            Enregistrer
                        </Rb.Button>
                    </div>
                </div>
            </Rb.Modal.Body>


        </div>
    );
}