//Index Coeur de l'appli content les components header menus raccourcis et contenus

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  auth,
  db,
  onMessageListener,
  requestForToken,
} from "../core/Firebase/firebase";
import BodyContent from "../partials/BodyContent";
import { Header } from "../partials/Header";
import { MenuMobile } from "../partials/menus/MenuMobile";
import { MenuWeb } from "../partials/menus/MenuWeb";
import Shortcuts from "../partials/Shortcuts";
import {
  authNone,
  authSuccess,
  selectAuthState,
  selectBlockUIState,
  storeDataApi,
} from "../core/Redux/features/user";
import { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import BlockUI from "../core/blockUi/BlocKUI";
import { useDocs } from "../core/Firebase/FirebaseHooks";
import { Detector } from "react-detect-offline";
import { makeToast } from "../core/js/backoffice";

export default function IndexCoreApp() {
  const dispatch = useDispatch();

  const blocking = useSelector(selectBlockUIState);
  const authState = useSelector(selectAuthState);
  const [payload,setPayload] = useState();
  const mounted = useRef();

  const { data } = useDocs({
    db: db,
    converterName: "modules",
    whereToOrderBy: "order",
    sort: "asc",
  });

  useEffect(() => {
    dispatch(storeDataApi({ modules: data }));
  }, [data]);

  useEffect(() => {
    onMessageListener()
    .then((value) => {
      setPayload(value);
      //makeToast({payload:payload,type:"success"});
    })
    .catch((err) => console.log("failed: ", err))
  });
  useEffect(() => {
    
    if(payload){
      console.log(payload);
      makeToast({payload:payload,type:"success"});
    }
   
  },[payload]);

  useEffect(() => {
    auth.onIdTokenChanged((user) => {
      if (!user) {
        dispatch(authNone());
      } else {  
        if (!mounted.current) {
          requestForToken();
          mounted.current = true;
      }
        user.getIdTokenResult(true).then((tk) => {
          dispatch(
            authSuccess({
              user: { id: user.uid, email: user.email, token: tk.token },
            })
          );
        });
      }
    });
  }, [dispatch]);



  return (
    <div>
      <div
        className={
          (authState.theme === true ? "dark-mode" : "") +
          " fixed-navbar menu-expanded"
        }
        style={{ overflow: "auto !important" }}
      >
        <div className="app-content content">
          <Header />
          <Detector
            render={({ online }) => {
              if (online) {
                return (
                  <>
                    <MenuWeb />
                    <MenuMobile />
                  </>
                );
              }
            }}
          />
          <BodyContent />
          <Shortcuts />
          <Toaster containerClassName="zi-10090" gutter={10} />
        </div>
      </div>
      <BlockUI blocking={blocking} />
    </div>
  );

 
}
