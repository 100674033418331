import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {
    closeMenuMobile,
    openMenuMobile,
    reduceMenuBar,
    showMyProfil
} from '../core/js/backoffice';
import {Modals} from './modals/Modals';
import {selectAuthState, selectModalProfilState, selectShowModal} from '../core/Redux/features/user';
import {db} from '../core/Firebase/firebase';
import {Detector} from 'react-detect-offline';
import {useDocs} from '../core/Firebase/FirebaseHooks';
import { ModalBodyUsers } from '../pages/admin/modals/ModalUsers';


const btnMenuPng = React.createRef();

function Header() {
    const [showToogle, setShowToogle] = useState(false);
    //const [profils, setProfils] = useState([]);
    const authState = useSelector(selectAuthState);
    const dataToogle = () => {
        setShowToogle(!showToogle);
        closeMenuMobile();
    };
    const dispatch = useDispatch();
    const modalsRedux = useSelector(selectModalProfilState);
    const show = useSelector(selectShowModal);
    //console.log(modalsRedux.show);
    const {data: profils} = useDocs({
        db: db,
        converterName: "profils",
        sort: "desc",
    });

    /*  const { scrollYProgress } = useScroll();
     const scaleX = useSpring(scrollYProgress, {
       stiffness: 100,
       damping: 30,
       restDelta: 0.001
     }); */
    return (
        <div className="content-header row">
            <nav
                className="header-navbar navbar-expand-md navbar navbar-with-menu fixed-top navbar-shadow navbar-brand-center navbar-dark "
                data-nav="brand-center" id="nav-add"
                style={{
                    background: "rgb(255,255,254)",
                    border: "1px solid #337F77",
                    boxShadow: "0 .46875rem 2.1875rem rgba(4,9,20,.03),0 .9375rem 1.40625rem rgba(4,9,20,.03),0 .25rem .53125rem rgba(4,9,20,.05),0 .125rem .1875rem rgba(4,9,20,.03)"
                }}>

                <div className="navbar-wrapper" data-nav="brand-center" id="nav-add1"
                     style={{background: "rgb(255,255,254) !important"}}>
                    <div className="">
                        <ul className="nav navbar-nav r-flexed jc-btwn">
                            <li className="nav-item mobile-menu d-md-none align-self-center">
                                <Detector
                                    render={({online}) =>{
                                       return <div className="cursor-pointer nav-link nav-menu-main hidden-xs is-active"
                                     id="menu-bar-mobile"
                                     onClick={()=>{
                                        if(online){
                                            openMenuMobile()
                                        }
                                       
                                     }} style={{color: "rgb(0,0,0) !important",marginLeft:"7px"}}
                                     target="_self">
                                    <img alt="" className="ml-10"
                                         src="/assets/images/imgs/menu_bar.png"
                                         style={{objectFit: "contain", height: "30.45px"}}></img>
                                </div>
                                    }}
                                />
                                
                            </li>
                            <li className="nav-item mobile-menu d-md-none m-auto align-self-center">
                                <Link to="/" className="nav-link nav-menu-main hidden-xs is-active"
                                      style={{color: "rgb(0,0,0)!important"}}
                                      target="_self">
                                    <img alt="" className="ml-10"
                                         src="/assets/images/logo/logo.svg"
                                         style={{objectFit: "contain", height: "50px", width: "50px"}}></img>
                                </Link>
                            </li>
                            <li className="align-self-center nav-item d-md-none">
                                <div className="nav-link open-navbar-container cursor-pointer" onClick={dataToogle}>
                                    <i className="fa fa-ellipsis-v menu-bars"></i>
                                </div>
                            </li>
                        </ul>
                        {/* <motion.div className="custom-progress-bar" style={{scaleX}} /> */}
                    </div>
                    {/* <div className={(showToogle ? 'show' : '') + ' collapse'} id="navbar-mobile">
                        <ul className="nav navbar-nav float-right w-100 navbar-light">
                            <li className="d-inline-flex nav-item">
                                <div className="nav-link cursor-pointer"  onClick={handleShow}
                                   style={{padding: "0.5rem 1rem !important"}}>
                            <span className="avatar avatar-online box-shadow-1 hpx-50 wdpx-50">
                                <img alt="" className="wdpx-10 hpx-10"
                                     src="/assets/images/imgs/upload.png"></img>
                            </span>
                                </div>
                                <div className="nav-link align-self-center" onClick={()=> setIsLogin(true)}>
                                    <div className="c-flexed">
                                        <span className="user-name text-dark">Bilal</span>
                                        <span className="user-name text-dark">Super Admin</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div> */}
                    <div className="navbar-container content menu-mobile" style={{marginLeft: "0px !important"}}>
                        <div
                            className={(showToogle ? 'show' : '') + ' collapse navbar-collapse'} /* className="collapse navbar-collapse" */>
                            <ul className="nav navbar-nav mr-auto logoMenuBar">
                                <li className="nav-item d-md-block">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Link to="/" className="nav-link nav-menu-main menu-toggle hidden-xs is-active"
                                              style={{color: "rgb(0,0,0) !important"}}
                                              target="_self">
                                            <img alt="" className="wdpx-130"
                                                 src="/assets/images/logo/logo.svg"
                                                 style={{objectFit: "contain", height: "50px", width: "100px"}}></img>
                                        </Link>
                                        <Detector
                                            render={({online}) =>{
                                                if(online) {
                                                    return <div onClick={reduceMenuBar} ref={btnMenuPng}
                                             className="cursor-pointer hidden-xs btn-bar is-active"
                                             style={{color: "rgb(0,0,0) !important"}}>
                                            <img alt="" className="wdpx-130" id="menu-bar"
                                                 src="/assets/images/imgs/menu_bar.png"
                                                 style={{objectFit: "contain", height: "30.45px"}}
                                            ></img>
                                        </div>
                                                }
                                            }}
                                        />
                                        
                                    </div>
                                </li>
                            </ul>
                            <Detector
                                render={({online}) => (<ul className="nav navbar-nav float-right navbar-right-menu"
                                                           onClick={online ? showMyProfil : () => {
                                                           }}
                                    /* onClick={() => dispatch(showModal())} */>
                                    <li className="d-inline-flex nav-item nav-item-mobile">
                                        <div className="nav-link cursor-pointer"
                                             style={{padding: ".5rem 1rem !important"}}>
                                <span className="avatar avatar-online box-shadow-1 hpx-60 wdpx-60">
                                    <img alt="" className="wdpx-60 hpx-60"
                                         src={authState.userHeader ? authState.userHeader.imgProfil ?? "/assets/images/imgs/upload.png" : "/assets/images/imgs/upload.png"}></img>
                                </span>
                                        </div>

                                        <div className="nav-link align-self-center">
                                            <div className="cursor-pointer">
                                                <div className="c-flexed row-gap-5">
                                                    <span
                                                        className="user-name text-dark textClassInit">{authState.userHeader ? authState.userHeader.displayName : ''}</span>
                                                    <span
                                                        className="user-name text-dark textClassInit">{authState.userHeader ? authState.userHeader.nomProfil : ''}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </li>
                                </ul>)}/>

                        </div>
                    </div>
                </div>
            </nav>
            {/* Modals Profil Client */}
             <Modals modalTitle="Utilisateur" titleIcon="/assets/images/ico/usersgroup.png"
                    modalBody={<ModalBodyUsers isAdd={false} profils={profils} isDashboardProfil={true} itemToUpdate={authState.userHeader}/>}
                    show={modalsRedux.show} />
        </div>);

}


export {Header, btnMenuPng};