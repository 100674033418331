import React, { useEffect, useRef, useState } from "react";
import {
  buildKeywordsMap,
  c,
  canModule,
  CustomAlert,
  getKeywordsMap,
} from "../../../core/js/backoffice";
import { useDispatch, useSelector } from "react-redux";
import * as Rb from "react-bootstrap";
import {
  closeModalAdd,
  selectAuthState,
} from "../../../core/Redux/features/user";
import {
  db,
  selectedBase,
  sendNotifToAdmin,
} from "../../../core/Firebase/firebase";
import { useDocs, useWhereDocs } from "../../../core/Firebase/FirebaseHooks";
import {
  dirInput,
  mapClassNameToLangue,
  mapLanguesToFlag,
  mapOriginalLanguesToName,
  mapTranslate,
} from "../../../core/localization/map_langues";
import { motion } from "framer-motion";
import ArabicInput from "../../../partials/FrequentlyUsed/ArabicInput";
import Select from "react-select";
import { saveDataToDocsAndStorage } from "../../../core/Firebase/services/FirebaseService";
import { doc } from "firebase/firestore";
import { PublicationModel } from "../../../core/models/Publication-Model";
import { toast as toastNew } from "react-hot-toast";

export const ModalPubBody = (props) => {
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const titleRef = useRef();
  const descriptionRef = useRef();
  const keywordRef = useRef();
  const sourceRef = useRef();
  const linkRef = useRef();
  const themeRef = useRef();
  const sousThemeRef = useRef();
  const sousSousThemeRef = useRef();
  const [file, setFile] = useState();
  const [id, setId] = useState(null);
  const [fileUrl, setFileUrl] = useState();
  const [isUpdate] = useState(props.isAdd === false);
  const [validated, setValidated] = useState(false);
  const [showArabicInput, setShowArabicInput] = useState(false);
  const [showArabicInput1, setShowArabicInput1] = useState(false);
  const [showArabicInput2, setShowArabicInput2] = useState(false);
  const [showArabicInput3, setShowArabicInput3] = useState(false);
  const [showArabicInput4, setShowArabicInput4] = useState(false);
  const [titreMap, setTitreMap] = useState({});
  const [descMap, setDescMap] = useState({});
  const [sourceMap, setSourceMap] = useState({});
  const [linkMap, setLinkMap] = useState({});
  const [keywordMap, setKeywordMap] = useState({});
  const [keywords] = useState({});
  const [langues, setLangues] = useState([]);
  const [type, setType] = useState();
  const [theme, setTheme] = useState();
  const [valueType, setValueType] = useState(null);
  const [valueTheme, setValueTheme] = useState(null);
  const [valueThemeDoc, setValueThemeDoc] = useState([]);
  const [valueSousTheme, setValueSousTheme] = useState(null);
  const [valueSousThemeDoc, setValueSousThemeDoc] = useState([]);
  const [valueSousSousTheme, setValueSousSousTheme] = useState(null);
  const [valueSousSousThemeDoc, setValueSousSousThemeDoc] = useState([]);
  const [sousTheme, setSousTheme] = useState();
  const [sousSousTheme, setSousSousTheme] = useState();
  const [selectedLng, setSelectedLng] = useState();
  const [dataType] = useState("publications");
  const acceptFile = {
    image: "image/*",
    audio: ".mp3,.m4a,.wav,.wma,.mpa,.aac",
    video: "video/*",
  };

  let thumbnailsUrl =
    "https://firebasestorage.googleapis.com/v0/b/bilal-5b432.appspot.com/o/defaults%2Fimages%2Fpremium_photo-1667509291500-0782494c7695.webp?alt=media&token=4c860959-a07f-46c7-8324-083cd588a0b6&_gl=1*12jgj6*_ga*NDMxODU3NzQzLjE2NDQzNDY0ODE.*_ga_CW55HF8NVT*MTY5NjAxMDkzNS44MC4xLjE2OTYwMTI2MzguMTcuMC4w";

  const optionsTypes = [
    { value: "doua", label: "Doua" },
    { value: "image", label: "Image" },
    { value: "audio", label: "Audio" },
    { value: "video", label: "Vidéo" },
  ];

  const [registrationIds, setRegistrationIds] = useState([]);
  const [usersId, setUsersId] = useState([]);

  const [registrationWaitIds, setRegistrationWaitIds] = useState([]);
  const [usersWaitId, setUsersWaitId] = useState([]);

  //const [registrationClientIds, setRegistrationClientIds] = useState([]);
  //const [clientsId, setClientsId] = useState([]);

  const closeKeyBoardArabic = () => {
    setShowArabicInput(false);
  };
  const closeKeyBoardArabic1 = () => {
    setShowArabicInput1(false);
  };
  const closeKeyBoardArabic2 = () => {
    setShowArabicInput2(false);
  };
  const closeKeyBoardArabic3 = () => {
    setShowArabicInput3(false);
  };
  const closeKeyBoardArabic4 = () => {
    setShowArabicInput4(false);
  };

  const { data } = useWhereDocs({
    db: db,
    collectionName: "preferences",
    whereToLookInTheCollection: "isPrefs",
    whereToLookValue: true,
  });

  const { data: usersToNotif } = useWhereDocs({
    db: db,
    collectionName: "users",
    isTwoWhere: true,
    operator: "!=",
    whereToLookInTheCollection: "id",
    whereToLookValue: authState["currentUser"]["id"],
    secondField: "notifNewPost",
    secondValue: true,
    whereToOrderBy: "id",
  });
  const { data: usersToNotifWaiting } = useWhereDocs({
    db: db,
    collectionName: "users",
    isTwoWhere: true,
    operator: "!=",
    whereToLookInTheCollection: "id",
    whereToLookValue: authState["currentUser"]["id"],
    secondField: "notifNewPostWaiting",
    secondValue: true,
    whereToOrderBy: "id",
  });
  const { data: clientsToNotif } = useWhereDocs({
    db: db,
    collectionName: "clients",
    whereToLookInTheCollection: "notifNewPost",
    whereToLookValue: true,
  });
  const { data: dataLng } = useDocs({
    db: db,
    collectionName:
      "preferences_" + selectedBase + "/" + data[0]?.id + "/langues",
    whereToOrderBy: "id",
  });
  const { data: tabThemes } = useDocs({
    db: db,
    converterName: "themes",
    sort: "desc",
  });
  const { data: tabSousThemes } = useDocs({
    db: db,
    converterName: "sous_themes",
    sort: "desc",
  });
  const { data: tabSousSousThemes } = useDocs({
    db: db,
    converterName: "sous_sous_themes",
    sort: "desc",
  });

  useEffect(() => {
    let u = [];
    let p = [];
    usersToNotif.forEach((item) => {
      if (item["token"] && item["id"]) {
        u.push(item["token"]);
        p.push(item["id"]);
      }
    });
    setRegistrationIds(u);
    setUsersId(p);
  }, [usersToNotif]);

  useEffect(() => {
    let u = [];
    let p = [];
    usersToNotifWaiting.forEach((item) => {
      if (item["token"] && item["id"]) {
        u.push(item["token"]);
        p.push(item["id"]);
      }
    });
    //console.log("users admin waiting",usersToNotifWaiting);
    setRegistrationWaitIds(u);
    setUsersWaitId(p);
  }, [usersToNotifWaiting]);

  /*  useEffect(() => {
        let u = [];
        let p = [];
        clientsToNotif.forEach((item) => {
            if(item["token"] && item["id"]){
                u.push(item["token"]);
                p.push(item["id"])
            }
        });
        setRegistrationClientIds(u);
        setClientsId(p);
    }, [clientsToNotif]) */

  useEffect(() => {
    if (isUpdate && props.itemToUpdate) {
      let mm = props.itemToUpdate;

      setId(mm["id"]);
      setTitreMap(mm["titreMap"]);
      setSourceMap(mm["sourceMap"]);
      setLinkMap(mm["linkMap"]);
      setDescMap(mm["descriptionMap"]);
      setKeywordMap(mm["keywordMap"]);
      setType(mm["type"]);
      setFileUrl(mm["fileUrl"]);
      setValueType(optionsTypes.filter((i) => i.value === mm["type"])[0]);

      setTheme(mm["theme"]);
      let y = [],
        yy = [],
        yyy = [];
      mm["theme"].forEach((p) => {
        let k = tabThemes
          .filter((i) => i["id"] === p)
          .map((item) => {
            return { value: item["id"], label: item["designation"] };
          });
        y.push(k[0]);
      });
      /*  let y = tabThemes.filter((i) => i["id"] === mm["theme"]).map((item) => {
                return {value: item["id"], label: item['designation']}
            }) */

      setValueTheme(y);

      setSousTheme(mm["sousTheme"]);
      mm["sousTheme"].forEach((p) => {
        let k = tabSousThemes
          .filter((i) => i["id"] === p)
          .map((item) => {
            return { value: item["id"], label: item["designation"] };
          });
        yy.push(k[0]);
      });
      /*  let yy = tabSousThemes.filter((i) => i["id"] === mm["sousTheme"]).map((item) => {
                return {value: item["id"], label: item['designation']}
            }) */
      setValueSousTheme(yy);

      setSousSousTheme(mm["sousSousTheme"]);
      mm["sousSousTheme"].forEach((p) => {
        let k = tabSousSousThemes
          .filter((i) => i["id"] === p)
          .map((item) => {
            return { value: item["id"], label: item["designation"] };
          });
        yyy.push(k[0]);
      });
      /* let yyy = tabSousSousThemes.filter((i) => i["id"] === mm["sousSousTheme"]).map((item) => {
                return {value: item["id"], label: item['designation']}
            }) */

      setValueSousSousTheme(yyy);
    }
  }, [tabThemes, tabSousThemes, tabSousSousThemes]);

  useEffect(() => {
    if (dataLng.length > 0) {
      let aaa = Object.keys(dataLng[0].value ?? {});
      aaa.forEach((key) => {
        keywords[key] = [];
      });

      if (isUpdate !== true) {
        aaa.forEach((key) => {
          //titreMap[key] = "";
          descMap[key] = "";
          keywordMap[key] = "";
          sourceMap[key] = "";
          linkMap[key] = "";

          setTitreMap(titreMap);
          setSourceMap(sourceMap);
          setLinkMap(linkMap);
          setDescMap(descMap);
          setKeywordMap(keywordMap);
        });
      }

      let n = aaa.filter((u) => u.indexOf("fr_FR") !== -1);
      if (n.length === 1) {
        setSelectedLng(n[0]);
      } else {
        setSelectedLng(aaa[0]);
      }
      setLangues(aaa);
    }
  }, [dataLng, isUpdate]);

  const handleFileChange = (event) => {
    //console.log(event.target.files);
    let filesTab = event.target.files;
    if (filesTab.length > 0) {
      setFile(filesTab[0]);
      setFileUrl(URL.createObjectURL(event.target.files[0]));
    }
  };
  const eraseImage = () => {
    setFileUrl(undefined);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    //console.log(prenom,nom,adresse,telephone,genre,email,password,password1);
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      function convertStringToMap({ value }) {
        let keywords = String(value);
        let tabKeywords = [];
        let tmpKeywords = keywords?.trim().split(";");
        tabKeywords = tmpKeywords.map((u) => {
          return u.trim();
        });
        tabKeywords = tabKeywords.filter((u) => u.trim().length > 0);
        return tabKeywords;
      }

      let tabKeywords = Object.fromEntries(Object.entries(keywordMap));

      Object.keys(tabKeywords).forEach((key) => {
        tabKeywords[key] = convertStringToMap({ value: tabKeywords[key] });
      });

      //console.log(liaisonMap[liaison]);
      if (!type) {
        toastError({ message: "Type de publication obligatoire", id: "type" });
      } else if (
        Object.keys(titreMap).length < langues.length &&
        Object.values(titreMap).every((u) => u !== "")
      ) {
        toastError({
          message: "Titre Obligatoire pour les " + langues.length + " langues",
          id: "tlo",
        });
      }

      // else if (type === "doua")
      // {
      //   let v = {
      //     titre: titreMap,
      //     description: descMap,
      //     motcles: keywordMap,
      //     source: sourceMap,
      //     keywords: tabKeywords,
      //     type: type,
      //     id: id,
      //     createdBy: authState["currentUser"]["id"],
      //     disabled: !canModule(["can-create-posts"]),
      //   };
      //   //console.log(v);
      //   let publicationModel = new PublicationModel(v);
      //   saveDataToDocsAndStorage({
      //     dataType: dataType,
      //     jsonData: publicationModel,
      //     file: file,
      //   }).then((value) => {
      //     if (!isUpdate) {
      //       let notification = {
      //         title: "Doua du jour",
      //         body: "Un nouveau doua vient d'être créé !",
      //         url: "/publications/detailPost/" + value,
      //       };

      //       if (registrationIds.length > 0) {
      //         sendNotifToAdmin({
      //           notification: notification,
      //           tokens: registrationIds,
      //           usersId: usersId,
      //         });
      //       }

      //       if (
      //         !canModule(["can-create-posts"]) &&
      //         registrationWaitIds.length > 0
      //       ) {
      //         notification = {
      //           title: "Le doua du jour",
      //           body: "est en attente de validation !",
      //           url: "/publications",
      //         };
      //         sendNotifToAdmin({
      //           notification: notification,
      //           tokens: registrationWaitIds,
      //           usersId: usersWaitId,
      //         });
      //       }
      //     }
      //   });
      // }
      else if (!valueTheme && !valueSousTheme && !valueSousSousTheme) {
        toastError({
          //message: "Liaison avec un élément obligatoire",
          message:
            "Selectionner au moins un thème, un sous thème, ou un sous sous thème",
          duration: 4000,
          id: "liaison",
        });
      } else {
        if (!fileUrl && type !== "doua") {
          toastError({
            message: "Fichier obligatoire pour ce type de publication",
            id: "file",
          });
        } else {
          //let themeDoc, sousThemeDoc, sousSousThemeDoc;
          let themeTabDoc = [],
            sousThemeTabDoc = [],
            sousSousThemeTabDoc = [];

          if (Array.isArray(valueTheme)) {
            if (valueTheme.length > 0) {
              themeTabDoc = valueTheme.map((u) => {
                return doc(db, "themes_" + selectedBase, u["value"]);
              });
              //console.log(themeTabDoc);
            }
          }
          if (Array.isArray(valueSousTheme)) {
            if (valueSousTheme.length > 0) {
              sousThemeTabDoc = valueSousTheme.map((u) => {
                return doc(db, "sous_themes_" + selectedBase, u["value"]);
              });
              //console.log(sousThemeTabDoc);
            }
          }
          if (Array.isArray(valueSousSousTheme)) {
            if (valueSousSousTheme.length > 0) {
              sousSousThemeTabDoc = valueSousSousTheme.map((u) => {
                return doc(db, "sous_sous_themes_" + selectedBase, u["value"]);
              });
              //console.log(sousSousThemeTabDoc);
            }
          }

          let v = {
            titre: titreMap,
            description: descMap,
            motcles: keywordMap,
            source: sourceMap,
            link: linkMap && linkMap["fr_FR"] !== "" ? linkMap : null,
            keywords: tabKeywords,
            type: type,
            theme: themeTabDoc,
            sousTheme: sousThemeTabDoc,
            sousSousTheme: sousSousThemeTabDoc,
            createdBy: authState["currentUser"]["id"],
            disabled: !canModule(["can-create-posts"]),
            id: id,
          };

          if (type === "audio") {
            v["thumbnailsUrl"] = thumbnailsUrl;
          }
          let y = getKeywordsMap({
            tabValue: tabThemes,
            fetchedTab: valueTheme,
          });
          let yy = getKeywordsMap({
            tabValue: tabSousThemes,
            fetchedTab: valueSousTheme,
          });
          let yyy = getKeywordsMap({
            tabValue: tabSousSousThemes,
            fetchedTab: valueSousSousTheme,
          });

          Object.keys(keywords).forEach((key) => {
            keywords[key] = [];
          });

          buildKeywordsMap({ objectValue: y[0], objectReturn: keywords });
          buildKeywordsMap({ objectValue: yy[0], objectReturn: keywords });
          buildKeywordsMap({ objectValue: yyy[0], objectReturn: keywords });

          //Ajouter les titres et les descriptions dans les keywords
          Object.keys(keywords).forEach((key) => {
            if (titreMap[key]) {
              keywords[key].push(titreMap[key]);
            }
          });
          Object.keys(keywords).forEach((key) => {
            if (descMap[key]) {
              keywords[key].push(descMap[key]);
            }
          });
          Object.keys(keywords).forEach((key) => {
            if (keywordMap[key]) {
              keywords[key].push(keywordMap[key]);
            }
          });

          v = { ...v, ["keywords"]: keywords };

          let publicationModel = new PublicationModel(v);

          console.log("papa keywords", v, keywords);
          //console.log(finalIds,nomTheme,nomSousTheme,nomSousSousTheme);

          saveDataToDocsAndStorage({
            dataType: dataType,
            jsonData: publicationModel,
            file: file,
          }).then((value) => {
            if (!isUpdate) {
              let notification = {
                title: "Une nouvelle publication de type " + type,
                body: "vient d'être créée !",
                url: "/publications/detailPost/" + value,
              };

              if (registrationIds.length > 0) {
                sendNotifToAdmin({
                  notification: notification,
                  tokens: registrationIds,
                  usersId: usersId,
                });
              }

              if (
                !canModule(["can-create-posts"]) &&
                registrationWaitIds.length > 0
              ) {
                notification = {
                  title: "Une publication de type " + type,
                  body: "est en attente de validation !",
                  url: "/publications",
                };
                sendNotifToAdmin({
                  notification: notification,
                  tokens: registrationWaitIds,
                  usersId: usersWaitId,
                });
              }
            }
          });

          //addPublication({publication: v, isUpdate: isUpdate});
        }
      }
    }
    setValidated(true);

    function toastError({ message = "", id, duration = 3500 }) {
      toastNew.error(message, {
        position: "top-right",
        duration: duration,
      });
    }
  };
  /*  useEffect(() => {
        //console.log(theme,sousTheme,sousSousTheme);
        if (theme && !sousTheme && !sousSousTheme) {
            console.log("Only Theme Selected");
        }
        if (!theme && sousTheme) {
            console.log("Only Sous Theme Selected");
            let oo = tabSousThemes.filter(function (i) {
                let returnValue = true;
                returnValue = sousTheme === i["id"]
                return returnValue;
            })

            if (oo.length === 1) {
                setTheme(oo[0]['themeId'])
                let y = tabThemes.filter((i) => i["id"] === oo[0]['themeId']).map((item) => {
                    return {value: item["id"], label: item['designation']}
                })
                setValueTheme(y[0])
            }
        }
        if (!theme && !sousTheme && sousSousTheme) {
            console.log("Only Sous Sous  Theme Selected");
            let oo = tabSousSousThemes.filter(function (i) {
                //console.log(i);
                let returnValue = true;
                returnValue = sousSousTheme === i["id"]
                return returnValue;
            })
            if (oo.length === 1) {
                setSousTheme(oo[0]['sousThemeId']);
                let y = tabSousThemes.filter((i) => i["id"] === oo[0]['sousThemeId']).map((item) => {
                    return {value: item["id"], label: item['designation']}
                })
                setValueSousTheme(y[0])
            }

        }
    }, [theme, sousTheme, sousSousTheme, tabThemes, tabSousThemes, tabSousSousThemes]); */
  function makeDocRef({ tabData, dataType }) {
    let res = [];
    if (tabData != null && Array.isArray(tabData)) {
      res = Array.from(tabData).map((o) => {
        return doc(db, dataType + "_" + selectedBase, o["value"]);
      });
    }
    return res;
  }

  function resolveVideoIdField(lien_media) {
    if (lien_media === undefined) {
      lien_media = lien_media;
    } else {
      lien_media = lien_media;
    }

    let retour = "";
    // Les différents types de liens qu'on peut avoir
    const array = ["https://youtu.be/", "https://www.youtube.com/watch?v="];
    array.forEach((value) => {
      const debut = lien_media.indexOf(value);
      if (debut !== -1) {
        const longueur = value.length;
        const fin = lien_media.length - debut;
        retour = lien_media.substring(debut + longueur, debut + fin);
        return retour;
      }
    });
    console.log("retour ", retour);
    return retour;
  }

  const filtreOnSelect = (themesSelected, sousThemes, valueKey) => {
    const retour = [];
    themesSelected?.forEach((theme) => {
      sousThemes?.forEach((sousTheme) => {
        if (theme?.value === sousTheme?.[valueKey]) {
          retour.push(sousTheme);
        }
      });
    });
    return retour;
  };

  useEffect(() => {
    //c(valueTheme);
    let themeReferences = [];
    let sousThemeReferences = [];
    let sousSousThemeReferences = [];
    //themeReferences = makeDocRef({tabData:valueTheme,dataType:"themes"});
    //sousThemeReferences = makeDocRef({tabData:valueSousTheme,dataType:"sous_themes"});
    //sousSousThemeReferences = makeDocRef({tabData:valueSousSousTheme,dataType:"sous_sous_themes"});

    setValueThemeDoc(themeReferences);
    setValueSousThemeDoc(sousThemeReferences);
    setValueSousSousThemeDoc(sousSousThemeReferences);
  }, [valueTheme, valueSousTheme, valueSousSousTheme]);

  useEffect(() => {
    c(valueThemeDoc);
    c(valueSousThemeDoc);
    c(valueSousSousThemeDoc);
  }, [valueThemeDoc, valueSousThemeDoc, valueSousSousThemeDoc]);

  return (
    <div
      className="container-fluid"
      style={{ height: "83vh", overflow: "auto", marginBottom: "20px" }}
    >
      {type && type !== "doua" && (
        <div className="d-flex mt-20">
          <div className="c-flexed m-auto">
            <label className="cursor-pointer">
              <input
                type="file"
                accept={acceptFile[type]}
                onError={() => {
                  setFileUrl(undefined);
                  setFile(undefined);
                }}
                onChange={handleFileChange}
              />
              {!fileUrl && (
                <div className="doted-circle cursor-pointer">
                  {type === "audio" && (
                    <img alt="" src="/assets/images/ico/ic_audio_1.svg" />
                  )}
                  {type === "video" && (
                    <img alt="" src="/assets/images/ico/ic_video_1.svg" />
                  )}
                  {type === "image" && (
                    <img alt="" src="/assets/images/ico/ic_image_1.svg" />
                  )}
                  <span className="text-indic text-center">
                    {"Ajouter"}
                    <br />
                    {"votre " + type + " ici"}
                  </span>
                </div>
              )}

              {fileUrl && type === "image" && (
                <span className="avatar avatar-online d-flex box-shadow-1 hpx-150 wdpx-150">
                  <img
                    alt=""
                    className="m-auto hpx-150 wdpx-150"
                    src={fileUrl}
                  />
                </span>
              )}
              {fileUrl && type === "audio" && (
                <audio
                  onError={() => {
                    setFileUrl(undefined);
                    setFile(undefined);
                  }}
                  src={fileUrl}
                  controls
                />
              )}
              {fileUrl && type === "video" && (
                <video
                  height={250}
                  width={250}
                  controls
                  onError={() => {
                    setFileUrl(undefined);
                    setFile(undefined);
                  }} /* poster="/assets/images/ico/video-poster.jpeg" */
                >
                  <source src={fileUrl}></source>
                  <p>
                    Votre navigateur ne prend pas en charge ce type de vidéo
                  </p>
                </video>
              )}
            </label>
            {fileUrl && (
              <div className="d-flex">
                <button
                  style={{ padding: "5px 10px" }}
                  onClick={eraseImage}
                  className="btn btn-danger m-auto"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <Rb.Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="mt-40"
      >
        <Rb.Row>
          {/* {selectedLng && (
            <Rb.Col xs={12}>
              <Rb.Form.Group className="form-group" controlId="link">
                <Rb.Form.Label
                  style={{
                    display: "flex",
                    flexDirection:
                      mapClassNameToLangue["flexDirection"][selectedLng],
                  }}
                  className={
                    "textClassInit column-gap-10 " +
                    mapClassNameToLangue["float"][selectedLng]
                  }
                >
                  {mapTranslate["linkPub"][selectedLng]}
                </Rb.Form.Label>

                <Rb.InputGroup>
                  <Rb.Form.Control
                    placeholder={mapTranslate["linkplaceholder"][selectedLng]}
                    autoComplete="off"
                    value={linkMap}
                    onChange={(e) => {
                      const value = e.target.value;
                      setLinkMap(value);
                    }}
                    // required
                    className="modal-form"
                    type="text"
                  />
                </Rb.InputGroup>
              </Rb.Form.Group>
            </Rb.Col>
          )} */}

          {type === "video" && selectedLng && (
            <Rb.Col xs={12}>
              <Rb.Form.Group className="form-group" controlId="source">
                <Rb.Form.Label
                  style={{
                    display: "flex",
                    flexDirection:
                      mapClassNameToLangue["flexDirection"][selectedLng],
                  }}
                  className={
                    "textClassInit column-gap-10 " +
                    mapClassNameToLangue["float"][selectedLng]
                  }
                >
                  {mapTranslate["linkPub"][selectedLng]}
                </Rb.Form.Label>
                <Rb.InputGroup>
                  <Rb.Form.Control
                    value={linkMap ? linkMap[selectedLng] : ""}
                    placeholder={mapTranslate["linkplaceholder"][selectedLng]}
                    autoComplete="off"
                    onChange={(e) => {
                      const value = e.target.value;
                      setLinkMap({ ...linkMap, [selectedLng]: value });
                    }}
                    onBlur={() => {
                      if (selectedLng.indexOf("en_US") !== -1) {
                        setLinkMap({
                          ...linkMap,
                          ["en_US"]: linkMap[selectedLng],
                        });
                      } else if (selectedLng.indexOf("ar_AE") !== -1) {
                        setLinkMap({
                          ...linkMap,
                          ["ar_AE"]: linkMap[selectedLng],
                        });
                      } else {
                        setLinkMap({
                          ...linkMap,
                          ["en_US"]:
                            linkMap["en_US"] == null || linkMap["en_US"] == ""
                              ? linkMap[selectedLng]
                              : linkMap["en_US"],
                          ["ar_AE"]:
                            linkMap["ar_AE"] == null || linkMap["ar_AE"] == ""
                              ? linkMap[selectedLng]
                              : linkMap["ar_AE"],
                          ["fr_FR"]:
                            linkMap["fr_FR"] == null || linkMap["fr_FR"] == ""
                              ? linkMap[selectedLng]
                              : linkMap["fr_FR"],
                        });
                      }
                    }}
                    className="modal-form"
                    type="text"
                  />
                </Rb.InputGroup>
              </Rb.Form.Group>
            </Rb.Col>
          )}

          <Rb.Col xs={6} md={6} sm={12} lg={6}>
            <Rb.Form.Group className="form-group" controlId="type">
              <Rb.FormLabel className="textClassInit">
                Type <span className="text-danger">*</span>
              </Rb.FormLabel>
              <Select
                placeholder="Type"
                value={valueType}
                isClearable={true}
                onChange={(e) => {
                  if (e?.value !== valueType?.value) {
                    setFile(undefined);
                    setFileUrl(undefined);
                    setType(e?.value);
                    setValueType(e);
                  }
                }}
                options={optionsTypes}
              />
            </Rb.Form.Group>
          </Rb.Col>

          {/* {(type !== "doua") && <Rb.Col xs={6} md={6} sm={12} lg={6}> */}
          <Rb.Col xs={6} md={6} sm={12} lg={6}>
            <Rb.Form.Group className="form-group" controlId="titre">
              <Rb.FormLabel className="textClassInit">Thème</Rb.FormLabel>
              <Select
                placeholder="Thème"
                noOptionsMessage={() => "Aucune donnée"}
                ref={themeRef}
                isMulti
                value={valueTheme}
                isClearable={true}
                onChange={(e) => {
                  //c(e);
                  setValueTheme(e);
                  console.log(valueTheme);

                  /*  if (e?.value !== valueTheme?.value) {
                                        sousThemeRef.current?.clearValue();
                                        setTheme(e?.value);
                                        setValueTheme(e);
                                        setSousTheme(undefined);
                                        setSousSousTheme(undefined);
                                    } */
                }}
                options={tabThemes.map((item, index) => {
                  return { value: item["id"], label: item["designation"] };
                })}
              />
            </Rb.Form.Group>
          </Rb.Col>
          <Rb.Col xs={12} md={12} sm={12} lg={6}>
            {/* {(type !== "doua") && <Rb.Col xs={12} md={12} sm={12} lg={6}> */}
            <Rb.Form.Group className="form-group" controlId="titre">
              <Rb.FormLabel className="textClassInit">Sous Thème</Rb.FormLabel>
              <Select
                ref={sousThemeRef}
                placeholder="Sous Thème"
                noOptionsMessage={() => "Aucune donnée"}
                isMulti
                value={valueSousTheme}
                isClearable={true}
                onChange={(e) => {
                  setValueSousTheme(e);
                  /* if (e?.value !== valueSousTheme?.value) {
                                            sousSousThemeRef.current?.clearValue();
                                            setSousTheme(e?.value);
                                            setValueSousTheme(e);
                                            setSousSousTheme(undefined);
                                        } */
                }}
                options={filtreOnSelect(
                  valueTheme,
                  tabSousThemes,
                  "themeId"
                ).map((item) => {
                  return { value: item["id"], label: item["designation"] };
                })}
              />
            </Rb.Form.Group>
          </Rb.Col>
          {/* {(type !== "doua") && <Rb.Col xs={12} md={12} sm={12} lg={6}> */}
          <Rb.Col xs={12} md={12} sm={12} lg={6}>
            <Rb.Form.Group className="form-group" controlId="titre">
              <Rb.FormLabel className="textClassInit">
                Sous Sous Thème
              </Rb.FormLabel>
              <Select
                ref={sousSousThemeRef}
                placeholder="Sous Sous Thème"
                noOptionsMessage={() => "Aucune donnée"}
                isClearable={true}
                isMulti
                //isDisabled={theme && !sousTheme}
                value={valueSousSousTheme}
                onChange={(e) => {
                  setValueSousSousTheme(e);
                  /* if (e?.value !== valueSousSousTheme?.value) {
                                            setSousSousTheme(e?.value);
                                            setValueSousSousTheme(e);
                                        } */
                }}
                options={filtreOnSelect(
                  valueSousTheme,
                  tabSousSousThemes,
                  "sousThemeId"
                ).map((item) => {
                  return { value: item["id"], label: item["designation"] };
                })}
              />
            </Rb.Form.Group>
          </Rb.Col>

          <div
            className="w-100 mb-20 mt-10"
            style={{ borderTop: "1px solid black" }}
          ></div>
          <Rb.Stack className="m-auto" gap={4} direction="horizontal">
            {langues
              .sort()
              .reverse()
              .map((item, index) => {
                return (
                  <div key={index}>
                    <motion.button
                      onClick={() => {
                        setSelectedLng(item);
                      }}
                      style={{
                        color: selectedLng === item ? "white" : "#337F77",
                        background:
                          selectedLng === item
                            ? "#337F77"
                            : "rgba(51, 127, 119, 0.3)",
                      }}
                      type="button"
                      className="btn btn-lng"
                      whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.5 },
                      }}
                      whileTap={{ scale: 0.9 }}
                    >
                      {mapOriginalLanguesToName[item]}{" "}
                      <i className={mapLanguesToFlag[item]}></i>
                    </motion.button>
                  </div>
                );
              })}
          </Rb.Stack>

          {selectedLng && (
            <Rb.Col xs={12}>
              <Rb.Form.Group className="form-group" controlId="titre">
                <Rb.Form.Label
                  style={{
                    display: "flex",
                    flexDirection:
                      mapClassNameToLangue["flexDirection"][selectedLng],
                  }}
                  className={
                    "textClassInit column-gap-10 " +
                    mapClassNameToLangue["float"][selectedLng]
                  }
                >
                  {mapTranslate["titrePub"][selectedLng]}
                  <span className="text-danger">*</span>
                </Rb.Form.Label>

                <Rb.InputGroup>
                  <Rb.Form.Control
                    ref={titleRef}
                    dir={dirInput[selectedLng]}
                    value={titreMap[selectedLng] ?? ""}
                    placeholder={mapTranslate["titrePub"][selectedLng]}
                    autoComplete="off"
                    onKeyDown={(c) => {
                      if (selectedLng.indexOf("ar_AE") !== -1) {
                        //console.log(c.key);
                        if (c.key === "Backspace") {
                          const value = String(titreMap[selectedLng]).slice(
                            0,
                            -1
                          );
                          setTitreMap({ ...titreMap, [selectedLng]: value });
                        }
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setTitreMap({ ...titreMap, [selectedLng]: value });
                      //   if (selectedLng.indexOf("ar_AE") === -1) {
                      //     setTitreMap({ ...titreMap, [selectedLng]: value });
                      //   }
                    }}
                    onBlur={() => {
                      if (selectedLng.indexOf("en_US") !== -1) {
                        setTitreMap({
                          ...titreMap,
                          ["en_US"]: titreMap[selectedLng],
                        });
                      } else if (selectedLng.indexOf("ar_AE") !== -1) {
                        setTitreMap({
                          ...titreMap,
                          ["ar_AE"]: titreMap[selectedLng],
                        });
                      } else {
                        setTitreMap({
                          ...titreMap,
                          ["en_US"]:
                            titreMap["en_US"] == null || titreMap["en_US"] == ""
                              ? titreMap[selectedLng]
                              : titreMap["en_US"],
                          ["ar_AE"]:
                            titreMap["ar_AE"] == null || titreMap["ar_AE"] == ""
                              ? titreMap[selectedLng]
                              : titreMap["ar_AE"],
                          ["fr_FR"]:
                            titreMap["fr_FR"] == null || titreMap["fr_FR"] == ""
                              ? titreMap[selectedLng]
                              : titreMap["fr_FR"],
                        });
                      }
                    }}
                    required
                    className="modal-form"
                    type="text"
                  />
                  {/* {selectedLng.indexOf("ar_AE") !== -1 && (
                    <Rb.InputGroup.Text
                      onClick={() => {
                        setShowArabicInput(true);
                      }}
                      className="cursor-pointer"
                      title="Ouvrir le clavier"
                      id="basic-addon1"
                    >
                      <i className="fa fa-keyboard"></i>
                    </Rb.InputGroup.Text>
                  )} */}
                </Rb.InputGroup>
              </Rb.Form.Group>
            </Rb.Col>
          )}
          {selectedLng && (
            <Rb.Col xs={12}>
              <Rb.Form.Group className="form-group" controlId="description">
                <Rb.Form.Label
                  style={{
                    display: "flex",
                    flexDirection:
                      mapClassNameToLangue["flexDirection"][selectedLng],
                  }}
                  className={
                    "textClassInit column-gap-10 " +
                    mapClassNameToLangue["float"][selectedLng]
                  }
                >
                  {mapTranslate["description"][selectedLng]}
                </Rb.Form.Label>
                <Rb.InputGroup>
                  <Rb.Form.Control
                    ref={descriptionRef}
                    as="textarea"
                    rows={3}
                    dir={dirInput[selectedLng]}
                    value={descMap[selectedLng] ?? ""}
                    placeholder={mapTranslate["description"][selectedLng]}
                    autoComplete="off"
                    onChange={(e) => {
                      const value = e.target.value;
                      setDescMap({ ...descMap, [selectedLng]: value });
                    }}
                    onBlur={() => {
                      if (selectedLng.indexOf("en_US") !== -1) {
                        setDescMap({
                          ...descMap,
                          ["en_US"]: descMap[selectedLng],
                        });
                      } else if (selectedLng.indexOf("ar_AE") !== -1) {
                        setDescMap({
                          ...descMap,
                          ["ar_AE"]: descMap[selectedLng],
                        });
                      } else {
                        setDescMap({
                          ...descMap,
                          ["en_US"]:
                            descMap["en_US"] == null || descMap["en_US"] == ""
                              ? descMap[selectedLng]
                              : descMap["en_US"],
                          ["ar_AE"]:
                            descMap["ar_AE"] == null || descMap["ar_AE"] == ""
                              ? descMap[selectedLng]
                              : descMap["ar_AE"],
                          ["fr_FR"]:
                            descMap["fr_FR"] == null || descMap["fr_FR"] == ""
                              ? descMap[selectedLng]
                              : descMap["fr_FR"],
                        });
                      }
                    }}
                    className="modal-form"
                    type="text"
                  />
                </Rb.InputGroup>
              </Rb.Form.Group>
            </Rb.Col>
          )}

          {selectedLng && (
            <Rb.Col xs={12}>
              <Rb.Form.Group className="form-group" controlId="motcles">
                <Rb.Form.Label
                  style={{
                    display: "flex",
                    flexDirection:
                      mapClassNameToLangue["flexDirection"][selectedLng],
                  }}
                  className={
                    "textClassInit column-gap-10 " +
                    mapClassNameToLangue["float"][selectedLng]
                  }
                >
                  {mapTranslate["keywordLabel"][selectedLng]}
                </Rb.Form.Label>
                <Rb.InputGroup>
                  <Rb.Form.Control
                    ref={keywordRef}
                    as="textarea"
                    rows={3}
                    dir={dirInput[selectedLng]}
                    value={keywordMap[selectedLng] ?? ""}
                    placeholder={mapTranslate["keyword"][selectedLng]}
                    autoComplete="off"
                    onKeyDown={(key) => {
                      if (key.code === "Enter") {
                        key.preventDefault();
                        if (keywordMap[selectedLng]?.trim().length > 0) {
                          setKeywordMap({
                            ...keywordMap,
                            [selectedLng]: keywordMap[selectedLng] + " ; ",
                          });
                        }
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setKeywordMap({ ...keywordMap, [selectedLng]: value });
                    }}
                    onBlur={() => {
                      if (selectedLng.indexOf("en_US") !== -1) {
                        setKeywordMap({
                          ...keywordMap,
                          ["en_US"]: keywordMap[selectedLng],
                        });
                      } else if (selectedLng.indexOf("ar_AE") !== -1) {
                        setKeywordMap({
                          ...keywordMap,
                          ["ar_AE"]: keywordMap[selectedLng],
                        });
                      } else {
                        setKeywordMap({
                          ...keywordMap,
                          ["en_US"]:
                            keywordMap["en_US"] == null ||
                            keywordMap["en_US"] == ""
                              ? keywordMap[selectedLng]
                              : keywordMap["en_US"],
                          ["ar_AE"]:
                            keywordMap["ar_AE"] == null ||
                            keywordMap["ar_AE"] == ""
                              ? keywordMap[selectedLng]
                              : keywordMap["ar_AE"],
                          ["fr_FR"]:
                            keywordMap["fr_FR"] == null ||
                            keywordMap["fr_FR"] == ""
                              ? keywordMap[selectedLng]
                              : keywordMap["fr_FR"],
                        });
                      }
                    }}
                    className="modal-form"
                    type="text"
                  />
                </Rb.InputGroup>
                {/* {selectedLng.indexOf("ar_AE") === -1 &&
                  keywordMap[selectedLng]?.trim().length > 0 && (
                    <Rb.Form.Control.Feedback
                      className="d-flex fsize-10"
                      type="valid"
                    >
                      {mapTranslate["semiColons"][selectedLng]}
                    </Rb.Form.Control.Feedback>
                  )} */}
              </Rb.Form.Group>
            </Rb.Col>
          )}

          {selectedLng && (
            <Rb.Col xs={12}>
              <Rb.Form.Group className="form-group" controlId="source">
                <Rb.Form.Label
                  style={{
                    display: "flex",
                    flexDirection:
                      mapClassNameToLangue["flexDirection"][selectedLng],
                  }}
                  className={
                    "textClassInit column-gap-10 " +
                    mapClassNameToLangue["float"][selectedLng]
                  }
                >
                  {mapTranslate["sourcePub"][selectedLng]}
                </Rb.Form.Label>
                <Rb.InputGroup>
                  <Rb.Form.Control
                    ref={sourceRef}
                    as="textarea"
                    rows={3}
                    dir={dirInput[selectedLng]}
                    value={sourceMap[selectedLng] ?? ""}
                    placeholder={mapTranslate["sourcePub"][selectedLng]}
                    autoComplete="off"
                    onChange={(e) => {
                      const value = e.target.value;
                      setSourceMap({ ...sourceMap, [selectedLng]: value });
                    }}
                    onBlur={() => {
                      if (selectedLng.indexOf("en_US") !== -1) {
                        setSourceMap({
                          ...sourceMap,
                          ["en_US"]: sourceMap[selectedLng],
                        });
                      } else if (selectedLng.indexOf("ar_AE") !== -1) {
                        setSourceMap({
                          ...sourceMap,
                          ["ar_AE"]: sourceMap[selectedLng],
                        });
                      } else {
                        setSourceMap({
                          ...sourceMap,
                          ["en_US"]:
                            sourceMap["en_US"] == null ||
                            sourceMap["en_US"] == ""
                              ? sourceMap[selectedLng]
                              : sourceMap["en_US"],
                          ["ar_AE"]:
                            sourceMap["ar_AE"] == null ||
                            sourceMap["ar_AE"] == ""
                              ? sourceMap[selectedLng]
                              : sourceMap["ar_AE"],
                          ["fr_FR"]:
                            sourceMap["fr_FR"] == null ||
                            sourceMap["fr_FR"] == ""
                              ? sourceMap[selectedLng]
                              : sourceMap["fr_FR"],
                        });
                      }
                    }}
                    className="modal-form"
                    type="text"
                  />
                </Rb.InputGroup>
              </Rb.Form.Group>
            </Rb.Col>
          )}
        </Rb.Row>
        <div className="row"></div>

        <div className="float-right r-flexed column-gap-20 mb-20 mt-20">
          <Rb.Button
            onClick={() => {
              let msg =
                "Si vous fermez le modal, vous perderez les données déjà enregistrées !!!";
              CustomAlert({
                message: msg,
                yesBtn: () => {
                  dispatch(closeModalAdd());
                },
              });
            }}
            bsPrefix="btn btn-cancel"
          >
            Annuler
          </Rb.Button>
          <Rb.Button type="submit" bsPrefix="btn btn-save">
            Enregistrer
          </Rb.Button>
        </div>
      </Rb.Form>

      {selectedLng?.indexOf("ar_AE") !== -1 && (
        <ArabicInput
          value={selectedLng?.indexOf("ar_AE") !== -1 ? titreMap["ar_AE"] : ""}
          onChange={(v) => {
            setTitreMap({ ...titreMap, [selectedLng]: v });
          }}
          show={showArabicInput}
          handleClose={closeKeyBoardArabic}
        />
      )}
      {
        selectedLng?.indexOf("ar_AE") !== -1 && (
            <ArabicInput
              value={
                selectedLng?.indexOf("ar_AE") !== -1 ? descMap["ar_AE"] : ""
              }
              onChange={(v) => {
                setDescMap({ ...descMap, [selectedLng]: v });
              }}
              show={showArabicInput1}
              handleClose={closeKeyBoardArabic1}
            />
          ) && (
            <ArabicInput
              value={
                selectedLng?.indexOf("ar_AE") !== -1 ? sourceMap["ar_AE"] : ""
              }
              onChange={(v) => {
                setSourceMap({ ...sourceMap, [selectedLng]: v });
              }}
              show={showArabicInput3}
              handleClose={closeKeyBoardArabic3}
            />
          ) && (
            <ArabicInput
              value={
                selectedLng?.indexOf("ar_AE") !== -1 ? keywordMap["ar_AE"] : ""
              }
              onChange={(v) => {
                setKeywordMap({ ...keywordMap, [selectedLng]: v });
              }}
              show={showArabicInput2}
              handleClose={closeKeyBoardArabic2}
            />
          )
        // && (
        //   <ArabicInput
        //     value={
        //       selectedLng?.indexOf("ar_AE") !== -1 ? linkMap["ar_AE"] : ""
        //     }
        //     onChange={(v) => {
        //       setLinkMap({ ...linkMap, [selectedLng]: v });
        //     }}
        //     show={showArabicInput4}
        //     handleClose={closeKeyBoardArabic4}
        //   />
        // )
      }
    </div>
  );
};
