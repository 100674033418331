import React from "react";
import {Modal} from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectAuthState } from "../../core/Redux/features/user";
import ModalTitle from "./ModalTitle";
import { CustomAlert } from "../../core/js/backoffice";

export const Modals = ({show,fullscreen,backdrop = "static",size = "lg", handleClose ,modalTitle,modalBody ,titleIcon}) => {
    const authState = useSelector(selectAuthState);
   
    let msg = "Si vous fermez le modal, vous perderez les données déjà enregistrées !!!";
    return (
        <Modal /* dialogClassName="modal-dialog-full-width" */ fullscreen={fullscreen}  style={{zIndex:'200000 !important'}} backdrop={backdrop} className={(authState.theme === true ? 'dark-mode':'')}  size={!fullscreen ? size:undefined} onHide={()=>{backdrop !== true ? CustomAlert({message:msg,yesBtn:handleClose}) : handleClose()}} show={show}>
            <Modal.Header bsPrefix={'modal-header'} closeButton={false}>
                <ModalTitle titleIcon={titleIcon} title={modalTitle} />
            </Modal.Header>
            {modalBody}


           {/*  <Modal.Footer>
                <Button bsPrefix="btn btn-cancel" onClick={handleClose}>
                    Annuler
                </Button>
                <Button onClick={btnSave} bsPrefix="btn btn-save">
                    Enregistrer
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
};
