import React from 'react';
import {Modal} from "react-bootstrap";

const ModalTitle = (props) => {
    return (
        <div>
            <Modal.Title>
                    <div className="r-flexed column-gap-10 align-items-center">
                        <div className="modal-header-title">
                            <img alt="" style={{width:'30px'}} src={props.titleIcon}/>
                        </div>
                        <span className="textClassInit">{props.title ?? "Title Modal"}</span>
                    </div>
                </Modal.Title>
        </div>
    );
}

export default ModalTitle;
