import {faComment, faInfoCircle, faWarning} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import * as Rb from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {db, sendNotifToAdmin} from '../../../core/Firebase/firebase';
import {useDocs, useWhereDocs, useWhereDocs_1} from '../../../core/Firebase/FirebaseHooks';
import {saveData} from '../../../core/Firebase/services/FirebaseService';
import {canModule, customDateFormat, customToast} from '../../../core/js/backoffice';
import {CommentaireModel} from '../../../core/models/Commentaire-Model';
import {selectAuthState} from '../../../core/Redux/features/user';
import CardCommentaires from '../../../partials/FrequentlyUsed/CardCommentaires';
import Filtres from '../../../partials/FrequentlyUsed/Filtres';
import TitleBtnAdd from '../../../partials/FrequentlyUsed/TitleBtnAdd';
import FsLightbox from 'fslightbox-react';
import {motion} from 'framer-motion';

const DetailPublication = () => {

    const authState = useSelector(selectAuthState);
    const navigate = useNavigate();
    const {id,key} = useParams();
    const [isFiltering, setIsFiltering] = useState(false);
    const [showReply, setShowReply] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [currentKey, setCurrentKey] = useState("detail");
    const [currentUser, setCurrentUser] = useState({});
    const [mapValues, setMapValues] = useState({});
    const [toggler, setToggler] = useState(false);
    const [item, setItem] = useState({});
    const tabKeys = ["detail","content","comments"]

    const [registrationIds, setRegistrationIds] = useState([]);
    const [usersId, setUsersId] = useState([]);
    const [registrationClientIds, setRegistrationClientIds] = useState([]);
    const [clientsId, setClientsId] = useState([]);
    const {data: usersToNotif} = useWhereDocs({
        db: db,
        collectionName: "users",
        isTwoWhere:true,
        operator: "!=",
        whereToLookInTheCollection: "id",
        whereToLookValue: authState["currentUser"]["id"],
        secondField:"notifNewComment",
        secondValue:true,
        whereToOrderBy:"id"
    });

    const {data: clientsToNotif} = useDocs({
        db: db,
        converterName: "clients"
    });

    const {data: post} = useWhereDocs({
        db: db,
        collectionName: "publications",
        whereToLookInTheCollection: "id",
        whereToLookValue: id
    });

    const mapFiltersTab = [
        {
            isDropdown: true,
            key: "disabled",
            name: "Désactivé ",
            placeholder: "Choisir",
            tabDropdown: ["Oui", "Non"]
        },
        {isField: true, name: "Texte", key: "content"},
    ];

    var {data: tabValues} = useWhereDocs_1({
        db: db,
        collectionName: "commentaires",
        mapValues: mapValues
    });

    const {data: commentaires,dataLoading} = useWhereDocs({
        db: db,
        isTwoWhere: true,
        collectionName: "commentaires",
        whereToLookInTheCollection: "postId",
        whereToLookValue: id,
        secondField: "commentId",
        secondValue: ""
    });
    const {data: allCommentaires} = useWhereDocs({
        db: db,
        collectionName: "commentaires",
        whereToLookInTheCollection: "postId",
        whereToLookValue: id
    });

    /*    useEffect(() => {
           let t = commentaires.map((u)=>{
               return {"commentBy":u["commentBy"],"displayName":u["displayName"]};
           })
           if(tabClient.length <= 0){
               setTabClient(t)
           }
           console.log(tabClient);

       },[commentaires,tabClient]); */


    useEffect(() => {
        if (post.length === 1) {
            setItem(post[0])
        }else if(id && dataLoading === false && post.length === 0) {
            navigate("/publications",{replace:true});
            customToast({
                message:"Publication supprimée !!!",
                position:"top-center",
                duration: 4000,
                className:"mt-100",
                icon:<span class="text-warning"><FontAwesomeIcon icon={faWarning} /></span>
            })
        } 
        if(key){
            setCurrentKey(key)
            if(!tabKeys.includes(key)){
                navigate("/publications");
            }
        }
    }, [post,id,key,dataLoading]);

    useEffect(() => {
        if (authState["userInfos"]) {
            setCurrentUser(authState["userInfos"])
        }
    }, [authState, item]);

    useEffect(() => {

        let u = usersToNotif.map((item) => {
            return item["token"] ?? "";
        })
        let p = usersToNotif.map((item) => {
            return item["id"] ?? "";
        })
        //console.log("users admin",usersToNotif);
        setRegistrationIds(u);
        setUsersId(p);
    }, [usersToNotif])

    useEffect(() => {
        let u = [];
        let p = [];
        clientsToNotif.forEach((item) => {
            if(item["token"] && item["id"]){
                u.push(item["token"])
                p.push(item["id"])
            }
        })
        setRegistrationClientIds(u);
        setClientsId(p);
    }, [clientsToNotif])


    const handleFilter = (mapValues) => {
        let newMapValues = Object.fromEntries(Object.entries(mapValues));
        if (newMapValues["disabled"] !== undefined) {
            if (newMapValues["disabled"] === "Non") {
                newMapValues["disabled"] = false;
            } else if (newMapValues["disabled"] === "Oui") {
                newMapValues["disabled"] = true;
            }
        }
        newMapValues["postId"] = item["id"];
        setIsFiltering(true);
        setMapValues(newMapValues);
    }
    const cancelFilter = () => {
        setIsFiltering(false)
    }

    const addNewComment = () => {
        if (inputValue.trim().length > 0) {
            let v = {
                content: inputValue,
                commentBy: authState["currentUser"]["id"],
                postId: id,
                parentId: null,
            };
            let commentModel = new CommentaireModel(v);

            saveData({dataType: 'commentaires', dataToSave: commentModel}).then(() => {
                setShowReply(false);
                setInputValue("");
                let body = "";
                body = (currentUser["displayName"] ?? "Un Admin") + " vient d'ajouter un commentaire à une publication";
                let notification = {
                    title: "Commentaire",
                    body: body,
                    url: "/publications/detailPost/" + id + "/comments"
                }
                if(registrationIds.length > 0)
                {
                    sendNotifToAdmin({notification: notification, tokens: registrationIds, usersId:usersId})
                }
                /* let clientBody = {
                    postId:id,
                    title:body,
                    body: inputValue,
                }
                if(registrationClientIds.length > 0)
                {
                    sendNotifToAdmin({notification:notification,clientBody:clientBody,isClient:true,tokens: registrationClientIds, usersId: clientsId})
                } */

                
            })
        }

    }

    return (
        <motion.div
        initial={{opacity: 0, scale: 0.9}}
        animate={{opacity: 1, scale: 1}}
        transition={{duration: 0.3}}
    >
         <TitleBtnAdd showBtn={false} marginTop="mt-20"
                         icon="/assets/images/ico/pub_modals.svg" itemsName="Informations Publications"/>
        <div className="mt-30 tab-card">
            <Rb.Tabs onSelect={(key) => {
                setCurrentKey(key)
            }} className="mb-2 mt-20" defaultActiveKey={key ?? "detail"} justify={true} fill={true} id="tab-detail-theme">
                <Rb.Tab eventKey="detail" title="Détails" tabClassName='tabHeader textClassInit'>
                    <Rb.ListGroup variant='flush'>
                        <Rb.ListGroupItem
                            className='list-item textClassInit'>Titre: {item["titre"] ?? "..."}</Rb.ListGroupItem>
                        <Rb.ListGroupItem
                            className='list-item textClassInit'>Description: {item["description"] ?? "..."}</Rb.ListGroupItem>
                        <Rb.ListGroupItem
                            className='list-item textClassInit'>Mots cles: {item["motcles"] ?? "..."}</Rb.ListGroupItem>
                        <Rb.ListGroupItem className='list-item textClassInit'>Type de
                            publication: {item["type"] ?? "..."}</Rb.ListGroupItem>
                        <Rb.ListGroupItem className='list-item textClassInit'>Etat: <Rb.Badge
                            bg={item["disabled"] === false ? "success" : "danger"}>{item["disabled"] === false ? "Approuvée" : "Non Approuvée"}</Rb.Badge>
                        </Rb.ListGroupItem>
                        <Rb.ListGroupItem className='list-item textClassInit'>Date de création: {customDateFormat({date:item.date})}</Rb.ListGroupItem>
                    </Rb.ListGroup>
                </Rb.Tab>
                {item.type !== "doua" &&
                <Rb.Tab eventKey="content" title="Fichier Média" tabClassName='tabHeader textClassInit'>
                    <div className="d-flex">
                        {(item.fileUrl && item.type === "image") &&
                        <>
                            <Rb.Stack>
                                <span
                                    className="m-auto fsize-10 text-primary">Cliquer sur l'image pour effectuer un zoom</span>
                                <img onClick={() => {
                                    setToggler(!toggler)
                                }} alt="" className="m-auto cursor-pointer w-13 h-10 p-1" src={item.fileUrl}/>
                            </Rb.Stack>

                            <FsLightbox
                                toggler={toggler}
                                disableLocalStorage={true}
                                type="image"
                                sources={[
                                    <img alt="" src={item.fileUrl} className="w-100 h-100"/>
                                ]}
                            />
                        </>


                        }
                        {(item.fileUrl && item.type === "audio") &&
                        <>
                            {/*  <audio onClick={()=>{setToggler(!toggler)}} className='m-auto w-50' onError={() => {
                        }} src={item.fileUrl} controls/> */}
                            <Rb.Stack>
                                <span
                                    className="m-auto fsize-10 text-primary">Appuyer sur le bouton pour lire l'audio </span>
                                <img onClick={() => {
                                    setToggler(!toggler)
                                }} src="/assets/images/ico/poster.png" alt="" className="cursor-pointer w-30 h-10 m-auto p-1"/>
                            </Rb.Stack>

                            <FsLightbox
                                toggler={toggler}
                                disableLocalStorage={true}
                                sources={[
                                    <iframe title="Audio" src={item.fileUrl} width="1920px" height="1080px"
                                            frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
                                ]}
                            />
                        </>

                        }


                        {(item.fileUrl && item.type === "video") &&
                        <>
                            <Rb.Stack>
                                <span
                                    className="m-auto fsize-10 text-primary">Appuyer sur le bouton pour lire la vidéo </span>
                                <img onClick={() => {
                                    setToggler(!toggler)
                                }} src="/assets/images/ico/poster.png" alt="" className="cursor-pointer w-30 h-10 m-auto p-1"/>
                            </Rb.Stack>
                            <FsLightbox
                                toggler={toggler}
                                disableLocalStorage={true}
                                sources={[
                                    <iframe title="Video" src={item.fileUrl} width="1920px" height="1080px"
                                            frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
                                ]}
                            />
                        </>
                        }
                    </div>

                </Rb.Tab>}
                {(item.type !== "doua" && canModule(['list-commentaires'])) && <Rb.Tab eventKey="comments"
                                                                                       title={<Rb.Stack
                                                                                           className="w-100 textClassInit"
                                                                                           direction="horizontal"
                                                                                           gap={2}>Commentaires <Rb.Badge
                                                                                           pill
                                                                                           bg='success'>{allCommentaires.length}</Rb.Badge>
                                                                                           {currentKey === "comments" && item.disabled === false &&
                                                                                           <span
                                                                                               title="Nouveau commentaire"
                                                                                               style={{padding: "0px 10px 0px 10px"}}
                                                                                               className='ml-auto bg-success text-white rounded'
                                                                                               onClick={() => {
                                                                                                   setShowReply(!showReply)
                                                                                               }}><i className="pi pi-comments m-auto" /></span>}
                                                                                       </Rb.Stack>}
                                                                                       tabClassName='tabHeader'>


                    {showReply && <Rb.Form className="mt-20 mb-60">
                        <Rb.FormControl onChange={(e) => {
                            setInputValue(e.target.value)
                        }} placeholder="Ajouter un commentaire" value={inputValue}
                                        as="textarea"/>
                        <Rb.Button onClick={addNewComment} className="float-right border-0 btn-success bg-theme mt-10"
                                   style={{padding: "5px 10px"}}>Ajouter</Rb.Button>
                    </Rb.Form>}


                    {commentaires.length <= 0 && <p className="text-center">Pas de commentaires</p>}
                    {commentaires.length > 0 &&
                    <Filtres cancelFilter={cancelFilter} tabFilters={mapFiltersTab} handleSubmit={handleFilter}
                             marginTop="mt-30"/>}
                    <Rb.Row className="mt-20">
                        {
                            !isFiltering && commentaires.map((u, index) => {
                                return (
                                    newFunction(index, u,usersToNotif)
                                )

                            })
                        }
                        {
                            isFiltering && tabValues.map((u, index) => {
                                return (
                                    newFunction(index, u,usersToNotif)
                                )

                            })

                        }
                    </Rb.Row>

                </Rb.Tab>}
            </Rb.Tabs>
        </div>
        </motion.div>

    );
}

export default DetailPublication;

function newFunction(index, u,usersToNotif) {
    if(u["commentId"] === "")
    return <Rb.Col key={index} lg={12}>
        <CardCommentaires usersToNotif={usersToNotif} item={u}/>
    </Rb.Col>;
}

