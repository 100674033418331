export const mapLanguesToFlag = {
    "fr_FR":"fi fi-fr",
    "en_US":"fi fi-us",
    //"en_US":"fi fi-gb",
    "ar_AE":"fi fi-sa"
}

export const mapLanguesToName = {
    "fr_FR":"Français",
    "en_US":"Anglais",
    //"en_US":"fi fi-gb",
    "ar_AE":"Arabe"
}

export const mapOriginalLanguesToName = {
    "fr_FR":"Français",
    "en_US":"English",
    //"en_US":"fi fi-gb",
    "ar_AE":"عرب"
}

export const dirInput = {
    "fr_FR":undefined,
    "en_US":undefined,
    //"en_US":"fi fi-gb",
    "ar_AE":"rtl"
}


export const mapPrefsDescription = {
    "couleurs_front":"Permet de modifier les différents thémes de l'application",
    "banierre_app":"Permet de modifier l'image principale de la page d'accueil de l'application",
    "video_apropos":"Permet de modifier la video sur la page a propos de l'application",
    "langues":"Permet d'activer ou de désactiver les langues utilisées dans l'application",
    "texte_apropos":"Permet de modifier la description sur la page a propos de l'application"
}


export const mapClassNameToLangue = {
    "float":{"fr_FR":"float-left", "en_US":"float-left", "ar_AE":"float-right"},
    "flexDirection":{"fr_FR":"row", "en_US":"row", "ar_AE":"row-reverse"}
}

export const mapTranslate = {
    //modal add theme
    "choixTheme":{"fr_FR":"Choisir Thème", "en_US":"Choose Theme", "ar_AE":"اختيار موضوع" },
    "choixSousTheme":{"fr_FR":"Choisir Sous Thème", "en_US":"Choose Sub Theme", "ar_AE":"اختر موضوع فرعي"},
    "nomTheme":{"fr_FR":"Désignation", "en_US":"Designation", "ar_AE":"تعيين" },
    "ordreTheme":{"fr_FR":"Ordre", "en_US":"Order", "ar_AE":"طلب" },
    "descriptionTheme":{"fr_FR":"Description", "en_US":"Description", "ar_AE":"وصف" },
    "description":{"fr_FR":"Description", "en_US":"Description", "ar_AE":"وصف" },
    "placeholderTheme":{"fr_FR":"Entrer le nom du thème", "en_US":"Enter the name of the theme", "ar_AE":"أدخل اسم الموضوع"},
    "ordreplaceholder":{"fr_FR":"Entrer l'ordre du thème", "en_US":"Enter the order of the theme", "ar_AE":"أدخل ترتيب الموضوع"},
    "placeholderDescription":{"fr_FR":"Description...", "en_US":"Description...", "ar_AE":"وصف...." },
    "feedBackTheme":{"fr_FR":"Désignation Obligatoire", "en_US":"Designation Mandatory", "ar_AE":"التعيين إلزامي" },
    "titrePub":{"fr_FR":"Titre", "en_US":"Title", "ar_AE":"عنوان" },
    "sourcePub":{"fr_FR":"Source", "en_US":"Source", "ar_AE":"مصدر" },
    "linkPub":{"fr_FR":"Lien", "en_US":"Link", "ar_AE":"وصلة" },
    "linkplaceholder":{"fr_FR":"Entrer le lien ", "en_US":"Enter the link", "ar_AE":"أدخل ترتيب الموضوع"},
    "keywordLabel":{"fr_FR":"Mots Clés", "en_US":"Keywords", "ar_AE":"الكلمات الدالة" },
    "keyword":{"fr_FR":"Entrer les mots clés séparés par des points virgules ( ; )", "en_US":"Enter keywords separated by semicolons ( ; )", "ar_AE":"أدخل الكلمات الأساسية مفصولة بفواصل منقوطة ( ; )"},
    "semiColons":{"fr_FR":"Appuyer sur Entrée pour des points virgules ( ; ) automatiques", "en_US":"Enter keywords separated by semicolons ( ; )", "ar_AE":"اضغط على Enter للفاصلة المنقوطة التلقائية (؛)"},
}

export const mapDropdownName = {
    //modal add theme
    "theme":(e)=>{return e["designation"]},
    "theme_id":(e)=>{return e["id"]},
    "commentBy":(e)=>{return e["displayName"]},
    "commentBy_id":(e)=>{return e["commentBy"]},
    "genre":(e)=>{return e},
    "disabled":(e)=>{return e},
    "type":(e)=>{return e},
    "sousTheme":(e)=>{return e["designation"]},
    "sousTheme_id":(e)=>{return e["id"]},
    "profil":(e)=>{return e["nomProfil"]},
    "profil_id":(e)=>{return e["id"]},
}