import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useRef, useState} from 'react'
import * as Rb from 'react-bootstrap';
import {useSelector} from 'react-redux';
import KeyboardReact from 'react-simple-keyboard';
import {buttonTheme, customToast, layout} from '../../core/js/backoffice';
import {selectAuthState} from '../../core/Redux/features/user';
import ModalTitle from '../modals/ModalTitle';

export default function ArabicInput({
                                        show,
                                        fullscreen = true,
                                        isForKeyword = false,
                                        backdrop = "static",
                                        size = "lg",
                                        handleClose,
                                        valueToReturn,
                                        onChange,
                                        title="Saisie Text Arabic",
                                        value,
                                        ...props
                                    }) {
    const authState = useSelector(selectAuthState);
    const mounted = useRef();
    const keyboard = useRef();
    const inputRef = useRef();
    const [layoutName, setLayoutName] = useState("default");

    const handleShift = () => {
        const newLayoutName = layoutName === "default" ? "shift" : "default";
        setLayoutName(newLayoutName);
    };

    const onKeyPress = (button) => {
        if (button === "{shift}" || button === "{lock}") handleShift();
    };

    const clipboardPaste = (bool) => {
        if (bool) {
            onChange("")
        } else {
            navigator.permissions.query({name: "clipboard-read"}).then((v) => {
                if (v.state === "granted") {
                    navigator.clipboard.readText().then((v) => {
                        if(value){
                            onChange(value + " " + v)
                        }else{
                            onChange(v)
                        }
                       
                    })
                } else if (v.state === "denied" || v.state === "prompt") {
                    customToast({
                        message:"Autoriser la permission pour coller le texte dans les paramètres du site",
                        type:"error",
                        duration:4000,
                        icon:<span className="text-warning"><FontAwesomeIcon icon={faWarning} /></span>
                    })
                }

            })
        }
    }
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (inputRef.current) {
                inputRef.current.focus();
                inputRef.current.selectionStart = inputRef.current.value.length;
                inputRef.current.selectionEnd = inputRef.current.value.length;
            }
            if (keyboard.current) {
                keyboard.current.setInput(value);
            }

        }
    });
    return (
        <Rb.Modal fullscreen={fullscreen} backdrop={backdrop} show={show} style={{zIndex: '20000000 !important'}}
                  className={(authState.theme === true ? 'dark-mode' : '')}
                  size={!fullscreen ? size : undefined}>
            <Rb.Modal.Header bsPrefix={'modal-header'} closeButton={false}>
                <ModalTitle titleIcon="" title={title}/>
            </Rb.Modal.Header>
            <Rb.ModalBody>
                <Rb.Form noValidate className="mt-40">
                    <Rb.Row className="no-gutters">

                        <Rb.Col xs={12}>
                            <Rb.Form.Group className="form-group" controlId="description">
                                <Rb.Form.Control ref={inputRef} style={{resize: "none", fontSize: "20px"}} as="textarea"
                                                 rows={7} dir="rtl" value={value ?? ""}
                                                 placeholder=""
                                                 onChange={(e) => {

                                                 }}
                                                 className="modal-form" type="text"/>
                            </Rb.Form.Group>

                        </Rb.Col>

                        <Rb.Col className="mb-30 mt-30" xs={12}>
                            <button onClick={() => {
                                clipboardPaste(false)
                            }} type="button" className="btn"><i className='fa fa-paste'></i></button>
                            <button onClick={() => {
                                clipboardPaste(true)
                            }} type="button" className="btn btn-danger float-right">Effacer Tout
                            </button>
                        </Rb.Col>

                        <KeyboardReact
                            keyboardRef={(r) => (keyboard.current = r)}
                            preventMouseDownDefault={true}
                            stopMouseDownPropagation={true}
                            useMouseEvents={true}
                            layoutName={layoutName}
                            onKeyPress={onKeyPress}
                            layout={layout}
                            buttonTheme={buttonTheme}
                            onChange={(e) => {
                                onChange(e)
                            }}/>

                        {isForKeyword&&<Rb.Col className="mb-30 mt-30" xs={12}>
                            <button onClick={() => {
                                if(value.trim().length >0 ){
                                    onChange(value + " ; ")
                                }
                                 
                            }} type="button" className="btn btn-warning float-right">Point virgule (;)
                            </button>
                        </Rb.Col>}

                    </Rb.Row>
                    <div className="row">


                    </div>

                </Rb.Form>
            </Rb.ModalBody>
            <Rb.ModalFooter>
                <Rb.Button onClick={handleClose} bsPrefix="btn btn-cancel">Fermer</Rb.Button>
            </Rb.ModalFooter>
        </Rb.Modal>
    )
}
