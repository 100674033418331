export class ModulesModel{
    constructor(id, nom,img,link,keyPage,order,modules = []){
        this.id = id ;
        this.nom = nom ;
        this.img = img ;
        this.link = link;
        this.keyPage = keyPage ;
        this.order = order ;
        this.modules = modules ;
    }

    toString(){
        return this.nom + ' , ' + this.img ;
    }
}

export const mapObjectToModules = {
    toFirestore: (m)=>{
        return {
            id: m.id,
            nom: m.nom,
            img: m.img,
            link: m.link,
            keyPage: m.keyPage,
            order: m.order,
            modules: m.modules
        };
    },
    fromFirestore: (snapshot, options)=>{
        const data = snapshot.data(options);
        return new ModulesModel(data.id, data.nom, data.img, data.link,data.keyPage,data.order, data.modules);
    }
}