import React, { useEffect, useState } from 'react';
import { getTotalDataDashboard } from '../../core/Firebase/services/FirebaseService'

function DashboardCard(props) {

    const { imgSrc, label, classNames, queries, collection, className } = props;
    const [value, setValue] = useState(null);

    useEffect(() => {
        getTotalDataDashboard({ dataType: collection, queries }).then((res) => {
            console.log("RES DATA ", res);
            setValue(res);
        });
    }, [queries]);

    return (
        <div className="d-flex col-12 col-sm-12 col-md-12 col-lg-4 mb-3 col-dash">
            <div className={classNames}>
                <div className={className}>
                    <img alt="" src={imgSrc} />
                </div>
                <span style={{ fontSize: "30px", fontStyle: "normal", color: "#FFFFFF" }}>{label}</span>
                <span style={{ fontSize: "50px", fontStyle: "normal", color: "#FFFFFF" }}>{value ?? "..."}</span>
            </div>
        </div>
    );
}

export default DashboardCard;