import React, { useState } from "react";
import * as Rb from "react-bootstrap";
import { Button, Collapse } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { mapDropdownName } from "../../core/localization/map_langues";
registerLocale("fr", fr);

const Filtres = ({ tabFilters = [], ...props }) => {
  const [open, setOpen] = useState(false);
  const [mapValues, setMapValues] = useState({});

  const cancelFilter = () => {
    props.cancelFilter();
    setMapValues({});
  };
  return (
    <div>
      <section className={props.marginTop ?? "mt-50"}>
        <div
          className="filter-card r-flexed jc-btwn cursor-pointer"
          onClick={() => {
            setOpen(!open);
            setMapValues({});
          }}
          style={open ? { borderRadius: "6px 6px 0px 0px" } : {}}
        >
          <div>
            <img alt="" src="/assets/images/ico/ic_filter.svg" />
            <span style={{ marginLeft: "5px" }} className="textClassInit">
              Filtres
            </span>
          </div>
          <div>
            <div className="arrow-filter">
              <img
                alt=""
                className={open ? "transformArrowFilter" : ""}
                src="/assets/images/ico/ic_arrow_down.svg"
              />
            </div>
          </div>
        </div>
        <Collapse dimension="height" className="filter-content" in={open}>
          <div id="example-collapse-text">
            <Rb.Form className="form-filter-content">
              <Rb.Row className="pl-20 pr-20 pt-10 pb-10">
                {tabFilters.map((item, index) => {
                  return (
                    <Rb.Col
                      key={index}
                      className="mb-1"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={
                        tabFilters.length % 2 === 1
                          ? index === tabFilters.length - 1
                            ? 12
                            : 6
                          : 6
                      }
                    >
                      <Rb.FormGroup>
                        <Rb.FormLabel>{item.name}</Rb.FormLabel>
                        {item.isDate && (
                          <DatePicker
                            dateFormat="dd-MM-yyyy"
                            locale="fr"
                            placeholderText="Choisir Date"
                            className="form-control modal-form"
                            selected={mapValues[item.key]}
                            onChange={(date) => {
                              let res = date;
                              if (item.key === "createdAt") {
                                res = new Date(date.setHours(0, 0, 0));
                              } else if (item.key === "createdAtEnd") {
                                res = new Date(date.setHours(23, 59, 59));
                              }
                              setMapValues({ ...mapValues, [item.key]: res });
                              //props.handleSubmit(mapValues);
                            }}
                          />
                        )}

                        {item.isField && (
                          <Rb.Form.Control
                            value={mapValues[item.key] ?? ""}
                            onChange={(e) =>
                              setMapValues({
                                ...mapValues,
                                [item.key]: e.target.value,
                              })
                            }
                            className="modal-form"
                            type="text"
                            placeholder={item.name}
                          />
                        )}

                        {item.isFieldNumer && (
                          <Rb.Form.Control
                            value={mapValues[item.key] ?? ""}
                            onChange={(e) =>
                              setMapValues({
                                ...mapValues,
                                [item.key]: e.target.value,
                              })
                            }
                            className="modal-form"
                            type="number"
                            placeholder={item.name}
                          />
                        )}

                        {item.isDropdown && (
                          <Rb.Form.Control
                            placeholder="Choisir Theme"
                            value={mapValues[item.key] ?? ""}
                            as="select"
                            type="select"
                            onChange={(e) => {
                              setMapValues({
                                ...mapValues,
                                [item.key]: e.target.value,
                              });
                            }}
                            required
                          >
                            <option value="default">{item.placeholder}</option>
                            {item.tabDropdown.map((p, index) => {
                              let v = mapDropdownName[item.key]
                                ? mapDropdownName[item.key](p)
                                : "";
                              let vv = mapDropdownName[item.key + "_id"]
                                ? mapDropdownName[item.key + "_id"](p)
                                : undefined;

                              return (
                                <option key={index} value={vv ?? v}>
                                  {v}
                                </option>
                              );
                            })}
                          </Rb.Form.Control>
                        )}
                      </Rb.FormGroup>
                    </Rb.Col>
                  );
                })}
              </Rb.Row>
              {tabFilters.length > 0 && (
                <div className="filter-footer r-flexed column-gap-20 jc-end">
                  <Button onClick={cancelFilter} bsPrefix="btn btn-cancel">
                    Annuler
                  </Button>
                  <Button
                    onClick={() => {
                      if (Object.keys(mapValues).length > 0) {
                        props.handleSubmit(mapValues);
                      }
                    }}
                    bsPrefix="btn btn-save"
                  >
                    Filtrer
                  </Button>
                </div>
              )}
            </Rb.Form>
          </div>
        </Collapse>
      </section>
    </div>
  );
};

export default Filtres;
