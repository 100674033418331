import React, {useEffect, useRef, useState} from 'react';
import * as Rb from 'react-bootstrap';
import {db, sendNotifToAdmin} from '../../core/Firebase/firebase';
import {useWhereDocs} from '../../core/Firebase/FirebaseHooks';
import {deleteData, enableItem, saveData} from '../../core/Firebase/services/FirebaseService';
import {canModule} from '../../core/js/backoffice';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowTurnDown,
    faArrowTurnUp,
    faCaretRight,
    faEdit,
    faHeart,
    faReplyAll,
    faTrashArrowUp
} from '@fortawesome/free-solid-svg-icons';
import Switch from "react-switch";
import {CommentaireModel} from '../../core/models/Commentaire-Model';
import {useSelector} from 'react-redux';
import {selectAuthState} from '../../core/Redux/features/user';
import {useNavigate} from 'react-router-dom';


export default function CardCommentaires({item, isChildren = false, commentId, sousCommentId,usersToNotif = []}) {
    const [commentBy, setCommentBy] = useState({});
    const navigate = useNavigate();
    const [replyTo, setReplyTo] = useState({});
    const authState = useSelector(selectAuthState);
    const inputRef = useRef();
    const [showReply, setShowReply] = useState(false);
    const [showSousComment, setShowSousComment] = useState(false);
    const [isLastComment, setIsLastComment] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [vous, setVous] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [currentId, setCurrentId] = useState("");
    /* const [dataType, setDataType] = useState(""); */
    const [inputValue, setInputValue] = useState({});
    const [registrationIdsAll, setRegistrationIdsAll] = useState([]);
    const [usersIdAll, setUsersIdAll] = useState([]);

    const {data} = useWhereDocs({
        db: db,
        collectionName: item["fromFront"] === true ? "clients" : item["fromFront"] === false ? "users" : "",
        whereToLookInTheCollection: "id",
        whereToLookValue: item["commentBy"]

    });

    const {data: dataReply} = useWhereDocs({
        db: db,
        collectionName: item["replyToClient"] === true ? "clients" : "users",
        whereToLookInTheCollection: "id",
        whereToLookValue: item["replyTo"]

    });

    const {data: sousCommentaires} = useWhereDocs({
        db: db,
        collectionName: "commentaires",
        isTwoWhere: true,
        sort: "asc",
        whereToLookInTheCollection: "commentId",
        whereToLookValue: isChildren ? commentId : item["id"],
        secondField: "sousCommentId",
        whereToOrderBy: "createdAt",
        secondValue: isChildren ? item["id"] : ""

    })
    const {data: allCommentairesSize} = useWhereDocs({
        db: db,
        collectionName: "commentaires",
        isTwoWhere: isChildren,
        whereToLookInTheCollection: "commentId",
        whereToLookValue: isChildren ? commentId : item["id"],
        secondField: "sousCommentId",
        secondValue: isChildren ? item["id"] : ""

    });

    useEffect(() => {

        let u = usersToNotif.map((item) => {
            return item["token"] ?? "";
        })
        let p = usersToNotif.map((item) => {
            return item["id"] ?? "";
        })
        setRegistrationIdsAll(u);
        setUsersIdAll(p);
    },[])

    useEffect(() => {
        if (commentId && sousCommentId && commentId !== "" && sousCommentId !== "") {
            setIsLastComment(true)
        }
    }, [commentId, sousCommentId, isLastComment]);

    useEffect(() => {
        if (authState["userInfos"]) {
            setCurrentUser(authState["userInfos"])
        }
        setVous(authState["currentUser"]["id"] === item["commentBy"]);
    }, [authState, item]);
    useEffect(() => {
        //console.log(isChildren,commentId);
        if (data.length === 1) {
            let p = data[0];
            setCommentBy(p ?? {})
        }
    }, [data, commentBy]);

    useEffect(() => {
        if (dataReply.length === 1) {
            let p = dataReply[0];
            setReplyTo(p ?? {})
        }
    }, [dataReply, replyTo]);

    const showInputReply = () => {
        setIsUpdate(false);
        setCurrentId(item["id"]);
        //setInputValue({...inputValue, [item["id"]]: "@" + commentBy["displayName"] + " "})
        setShowReply(!showReply);


    }
    const updateComment = () => {
        setIsUpdate(true);
        setCurrentId(item["id"]);
        setInputValue({...inputValue, [item["id"]]: item["content"]})
        setShowReply(!showReply);
        if (showReply === true) {
            setIsUpdate(false);
        }
    }

    const replyComment = () => {
        let v = {
            content: inputValue[currentId],
            commentBy: authState["currentUser"]["id"],
            postId: item["postId"],
        };

        if (isChildren)
        {
            v = {
                ...v,
                commentId: commentId,
                "sousCommentId": isLastComment ? sousCommentId : currentId,
                replyToClient: item["fromFront"],
                replyTo: item["commentBy"]
            }
            
            v["parentId"] = v["sousCommentId"];

            if (isLastComment)
            {
                v = {
                    ...v,
                    "sousSousCommentId": currentId,
                    replyTo: item["commentBy"],
                    replyToClient: item["fromFront"]
                }

                v["parentId"] = v["sousSousCommentId"];
            }
        }
        else
        {
            v = {...v, commentId: currentId,parentId:currentId}
        }
        if (isUpdate)
        {
            item["content"] = inputValue[currentId];
        }

        let commentModel = new CommentaireModel(isUpdate ? item : v);

        saveData({dataType: 'commentaires', dataToSave: commentModel}).then((value) => {
            setShowReply(false);
            setIsUpdate(false);
            setInputValue({...inputValue, [currentId]: undefined});
            let body = "";
            let commentIdNotif = "";
            let sousCommentIdNotif = "";
            let sousSousCommentIdNotif = "";
            if(isChildren)
            {
                commentIdNotif = commentId;
                if(isLastComment)
                {
                    sousCommentIdNotif =  sousCommentId
                    sousSousCommentIdNotif = currentId
                }
                else
                {
                    sousCommentIdNotif = currentId;
                }
            }
            else
            {
                commentIdNotif = currentId;
            }
            
            if (!vous)
            {
                body = (currentUser["displayName"] ?? "Un Admin") + " vient de répondre à votre commentaire";
            }
            else
            {
                body = (currentUser["displayName"] ?? "Un Admin") + " vient d'ajouter un commentaire à une publication"; 
            }

            let registrationIds = [];
            let usersId = [];
            let notification = {
                title: "",
                body: body,
                url: "/publications/detailPost/" + item["postId"] + "/comments"
            }

            if (item["fromFront"] === true)
            {
                notification["publicationId"] = item["postId"];
            
                if (!!value && !!value.id)
                {
                    notification["commentaireId"] = value.id;

                }
                
            }
            if (commentBy["token"]) {
                registrationIds.push(commentBy["token"])
            }
            if (commentBy["id"]) {
                usersId.push(commentBy["id"])
            }
            let clientBody = {
                postId:item["postId"],
                title:body,
                body: inputValue[currentId],
            }
            console.log(item["fromFront"]);
            if(registrationIds.length > 0)
            {
                sendNotifToAdmin({notification: notification, tokens:vous ? registrationIdsAll : registrationIds, usersId:vous ? usersIdAll : usersId,isClient:item["fromFront"],clientBody:clientBody})
            }
            
        })

    }

    const showDetail = ({item, isClient}) => {
        if (item && item["id"]) {
            let path = "";
            if (isClient) {
                path = "/backoffice/clients/detailClient/";
            } else {
                path = "/outilsadmin/utilisateurs/detailUser/";
            }
            navigate(path + item["id"]);
        }

    }

    /* const popover = (
        <Rb.Popover id="popover-basic">
          <Rb.Popover.Header as="h3">Popover right</Rb.Popover.Header>
          <Rb.Popover.Body>
            And here's some <strong>amazing</strong> content. It's very engaging.
            right?
          </Rb.Popover.Body>
        </Rb.Popover>
      ); */


    return (
        <div
            className={(isLastComment ? "mb-1" : "mb-2") + " comment-card"}>
            <div className="comment-data">
                <span className="avatar avatar-online box-shadow-1 wdpx-30 hpx-30">
                            <img alt="" src={commentBy["imgProfil"] ?? "/assets/images/imgs/face.png"}/>
                </span>
                <span
                    className={(isLastComment ? "fsize-12" : "fsize-12") + " message-data-name text-uppercase mlpx-7 textClassInit"}>{commentBy["displayName"]}</span>


                {canModule(['update-commentaires']) && <Switch onColor='#337F77' offColor='#da4453' onHandleColor="#fff"
                                                               boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                               handleDiameter={18}
                                                               activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                               height={15}
                                                               width={33} onChange={(checked) => {
                    enableItem({
                        bool: !checked,
                        fieldToSet: "disabled",
                        dataType: "commentaires",
                        id: item.id,
                        message: "ce commentaire ?"
                    })
                }} className="float-right mt-1" checked={!item.disabled}/>}

                {/* replyTo lorsqu'on a des sous commentaires */}
                {isLastComment && item["replyTo"] && item["replyTo"] !== "" &&
                <span><FontAwesomeIcon className="ml-1" icon={faCaretRight}/></span>}

                {/*  <Rb.OverlayTrigger trigger="click" placement="right" overlay={popover}>
                    <Rb.Button variant="danger">Click me to see</Rb.Button>
                </Rb.OverlayTrigger> */}
                {isLastComment && item["replyTo"] && item["replyTo"] !== "" &&
                <span onClick={() => {
                    showDetail({item: replyTo, isClient: item["replyToClient"]})
                }}
                      className="message-data-name cursor-pointer text-uppercase fsize-12 mlpx-7">{replyTo["displayName"]}</span>}

                {isLastComment && item["replyTo"] && item["replyTo"] !== "" &&
                <span style={{marginLeft: "10px"}} className="avatar avatar-online box-shadow-1 wdpx-30 hpx-30">
                            <img alt="" src={replyTo["imgProfil"] ?? "/assets/images/imgs/face.png"}/>
                </span>}

                {/* {vous && <span style={{padding: "1px 4px", borderRadius: "2px"}}
                               className='mlpx-7 fsize-12 text-white bg-theme'>vous</span>} */}


            </div>

            {/* contenu */}
            {/* <div className="fsize-30 d-flex m-auto p-0"><i className="fa fa-caret-up ml-4 p-0 text-theme"></i></div> */}
            <div
                className={(item["fromFront"] === true ? "bg-theme" : "bg-secondary") + " comment"}>{item["content"]}</div>

            {/* Options sur les commentaires  */}
            <Rb.Stack direction='horizontal' style={{marginTop: "7px", marginLeft: "3px", marginRight: "3px"}} gap={3}>
            <span
                className="comment-data-time fsize-12">{String(item.date) ?? "..."}</span>
                {!isLastComment && sousCommentaires.length > 0 && <span onClick={() => {
                    setShowSousComment(!showSousComment)
                }} className="fsize-12 cursor-pointer textClassInit"><FontAwesomeIcon style={{transform: "scaleX(-1)"}}
                                                                                      icon={showSousComment ? faArrowTurnDown : faArrowTurnUp}/> {allCommentairesSize.length} réponse{allCommentairesSize.length <= 1 ? "" : "s"}</span>}
                {canModule(['create-commentaires']) && !item.disabled &&
                <span onClick={showInputReply} className="fsize-12 cursor-pointer text-primary"><FontAwesomeIcon
                    style={{transform: "scaleX(-1)", rotate: "280deg"}}
                    icon={faReplyAll}/> Répondre</span>}

                {<span className="textClassInit fsize-12">{item["likedBy"].length} <FontAwesomeIcon
                    className="text-danger" icon={faHeart}/></span>}


                {canModule(['update-commentaires']) && vous &&
                <span className="fsize-18 text-secondary cursor-pointer ml-auto"
                      onClick={updateComment}><FontAwesomeIcon icon={faEdit}/></span>}

                {canModule(['delete-commentaires']) && sousCommentaires.length <= 0 &&
                <span className={(vous ? "" : "ml-auto") + " fsize-18 text-danger cursor-pointer "} onClick={() => {
                    deleteData({dataType: "commentaires", id: item.id, toastId: "toastDetailPub"})
                }}><FontAwesomeIcon icon={faTrashArrowUp}/></span>}


            </Rb.Stack>

            {(showReply && currentId === item["id"] && !item.disabled) && <Rb.Form className="mt-20 mb-50">
                <Rb.FormControl onChange={(e) => {
                    setInputValue({...inputValue, [currentId]: e.target.value})
                }} placeholder={"Répondre à " + commentBy["displayName"] ?? ""} value={inputValue[currentId]}
                                as="textarea" ref={inputRef}/>
                <Rb.Button onClick={replyComment} className="float-right border-0 btn-success bg-theme mt-10"
                           style={{padding: "5px 10px"}}>{isUpdate ? "Modifier" : "Répondre"}</Rb.Button>
            </Rb.Form>}

            {/* Fonctions recursives pour les sous commentaires */}
            {
                showSousComment && sousCommentaires.map((o, index) => {
                    if (o["sousCommentId"] === "") {
                        return (
                            <div className="mt-1"
                                 style={{marginLeft: "50px"}} key={index}>
                                <CardCommentaires usersToNotif={usersToNotif} isChildren={true} sousCommentId={o["sousCommentId"]}
                                                  commentId={o["commentId"]} item={o}/>

                            </div>

                        )
                    } else if (o["sousCommentId"] !== "" && o["commentId"] === commentId) {
                        return (
                            <div className="mt-1"
                                 style={{marginLeft: "50px"}} key={index}>
                                <CardCommentaires usersToNotif={usersToNotif} isChildren={true} sousCommentId={o["sousCommentId"]}
                                                  commentId={o["commentId"]} item={o}/>

                            </div>

                        )
                    }
                })
            }

            {!isLastComment && <hr/>}


        </div>
    )
}
