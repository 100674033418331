/* Card utilise pour afficher les users front */

import {SpeedDial, Toast, Tooltip} from 'primereact';
import React, {useRef, useState} from 'react'
import {Stack} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {deleteData, enableAuthUser} from '../../core/Firebase/services/FirebaseService';
import {canModule, customDateFormat, customDateFormatTimestamp} from '../../core/js/backoffice';

function CardUsersBo({item, onUpdate, isForDetail = false}) {
    // console.log("item => ", item);
    const toast = useRef();
    const speedRef = useRef();
    const navigate = useNavigate();
    const [boolSpeed, setBoolSpeed] = useState(false);
    const redirectToDetail = (value) => {
        if (value && value["id"]) {
            navigate('/backoffice/clients/detailClient/' + value["id"]);
        }
    }
    const getMenuItem = ({data}) => {
        let result = [];
        let items = [
            {
                id: 0,
                label: 'Détails',
                icon: 'pi pi-info',
                command: () => {
                    redirectToDetail(data);
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-primary wdpx-35 hpx-35 cursor-pointer myTooltip0"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}/>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip0"/>
                        </div>
                    );
                },

            },
            {
                id: 1,
                label: data.actif ? "Désactiver cet utilisateur" : "Activer cet utilisateur",
                icon: 'pi pi-thumbs-' + (data.actif ? 'down' : 'up'),
                command: () => {
                    enableAuthUser({isEnable: data.actif, id: data.id, dataType: "clients", toastRef: toast})
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-" + (data.actif ? "danger" : "success") + " wdpx-35 hpx-35 cursor-pointer myTooltip2"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip2"/>
                        </div>
                    );
                },
            },
            {
                id: 2,
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: () => {
                    onUpdate()
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-secondary wdpx-35 hpx-35 cursor-pointer myTooltip1"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip1"/>
                        </div>
                    );
                },
            },
            {
                id: 3,
                label: 'Supprimer',
                icon: 'pi pi-trash',
                command: () => {
                    deleteData({dataType: "clients", id: data.id})
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-danger wdpx-35 hpx-35 cursor-pointer myTooltip3"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip3"/>
                        </div>
                    );
                },
            }
        ];
        if (canModule(['detail-client'])) {
            result.push(items.filter((i) => (i.id === 0))[0])
        }
        if (canModule(['update-client']) && !isForDetail) {
            result.push(items.filter((i) => (i.id === 1))[0])
        }
        if (canModule(['update-client']) && !isForDetail) {
            result.push(items.filter((i) => (i.id === 2))[0])
        }
        if (canModule(['delete-client']) && !isForDetail) {
            result.push(items.filter((i) => (i.id === 3))[0])
        }
        return result;
    }
    return (
        <div className="w-100 card-user-item">
            <Stack className='p-10' direction='horizontal'>
                <div className="column-gap-7 r-flexed align-items-center">
                    <span className="avatar avatar-online box-shadow-1 wdpx-70 hpx-70">
                        <img alt=""
                             src={item?.imgProfil ?? "/assets/images/imgs/empty-avatar.png"} /* src={item.imgProfil !== null ? item.imgProfil : item.genre === "Femme"? "/assets/images/imgs/face-femme.jpg":"/assets/images/imgs/face.png"} *//>
                        
                    </span>

                    <div style={{background: item.actif ? "#337F77" : "#8E191D"}} className="user-badge"/>
                </div>

                <div className="c-flexed">
                    <span className="pub-title textClassInit">{item.displayName}</span>
                    <span className="pub-subtitle"
                          style={{fontSize: "14px"}}>{item.genre}</span>
                          <div className="pub-subtitle">{customDateFormat({date: item.date})}</div>
                </div>

                {
                    (canModule(['update-client', 'delete-client', 'detail-client'])) &&
                    <div className="d-flex justify-content-end align-items-center" style={{marginLeft: "auto"}}>
                        <SpeedDial
                            direction='left'
                            showIcon="pi pi-bars"
                            hideIcon="pi pi-times"
                            buttonClassName={(boolSpeed ? "wdpx-35 hpx-35" : "wdpx-35 hpx-35") + " p-button-outlined p-button-success align-self-center"}
                            buttonStyle={{border: "1px solid #337f77", transition: "0.6s"}}
                            id={item.id}
                            ref={(el) => speedRef.current = el}
                            style={{position: "relative"}}
                            className={speedRef.current?.props?.id === item.id ? "myCustomHamburger1" : "myCustomHamburger"}
                            onShow={() => {
                                setBoolSpeed(true)
                            }}
                            onHide={() => {
                                setBoolSpeed(false)
                            }}
                            model={getMenuItem({data: item})}/>
                    </div>
                }

            </Stack>

            {!isForDetail && <Stack className="p-10 pl-20 pt-15" gap={3}>
                <div className="r-flexed email-line align-items-center"
                     style={{columnGap: "10px"}}>
                    <div className="card-user-btn-generic">
                        <img alt="" src="/assets/images/imgs/mail.png"/>
                    </div>

                    <span className="textClassInit ellipsis-1">{item.email ? item.email : " ... "}</span>
                </div>

                <div className="r-flexed phone-line align-items-center"
                     style={{columnGap: "10px"}}>
                    <div className="card-user-btn-generic">
                        <img alt="" src="/assets/images/imgs/phone.png"/>
                    </div>

                    <span className="textClassInit ellipsis-1">{ item.telephone ? item.telephone : "... " }</span>
                </div>
            </Stack>}


            <Toast ref={toast}/>
        </div>
    )
}

export default CardUsersBo;
