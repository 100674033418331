
export class ClientModel{
    constructor({id = null,createdAt,eraseImg,displayName,adresse,imgProfil,telephone,genre,actif,email,password=null,token,date}){
        this.id = id;
        //this.prenom = prenom;
        this.createdAt =  createdAt;
        //this.nom = nom;
        this.eraseImg =  eraseImg;
        this.displayName = displayName;
        this.adresse = adresse;
        this.telephone = telephone;
        this.genre = genre;
        this.actif = actif;
        this.email = email;
        this.password = password;
        this.imgProfil = imgProfil;
        this.token = token;
        this.date = date;
    }

    toString(){
        return this.displayName;
    }

    toJson(){
        let obj = {
            id:this.id,
            //prenom:this.prenom,
            //nom:this.nom,
            displayName:this.displayName,
            adresse:this.adresse,
            telephone:this.telephone,
            genre:this.genre,
            email:this.email,
            createdAt:this.createdAt,
        }

        Object.keys(obj).forEach(key => {
            if (obj[key] === undefined) {
                delete obj[key];
              }
        })
        return obj ;
    }
}

export const mapObjectToClient= {
    toFirestore: (u)=>{
        let mTmp = Object.fromEntries(Object.entries(u))

        mTmp = {...mTmp,password:undefined,usedCount:undefined}
        Object.keys(mTmp).forEach(key =>
        {
            if (mTmp[key] === undefined)
            {
                delete mTmp[key];
            }
        });
        
        return mTmp;
       /*  return {
        id : u.id,
        disabled:!u.actif,
        prenom : u.prenom,
        nom : u.nom,
        displayName : u.displayName,
        adresse : u.adresse,
        telephone : u.telephone,
        genre : u.genre,
        email : u.email,
        //password : u.password,
        imgProfil:u.imgProfil
        }; */
    },
    fromFirestore:(snapshot, options)=>{
        const data = snapshot.data(options);
        //let newFileUrl =data.imgProfil !== undefined ?  String(data.imgProfil): data.imgProfil;
        let newFileUrl = String(data.imgProfil ?? "/assets/images/imgs/upload.png");
        // newFileUrl = newFileUrl.replace("localhost",host)
        return new ClientModel({imgProfil:data.imgProfil ? newFileUrl:data.imgProfil,createdAt:data.createdAt,id:data.id,token:data.token,prenom:data.prenom, nom:data.nom, displayName:data.displayName ?? data.prenom + ' ' + data.nom, adresse:data.adresse, telephone:data.telephone, genre:data.genre,actif:data.disabled === false ? true : false, email:data.email,date:new Date(data.createdAt?.seconds * 1000)});
    }
}