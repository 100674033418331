import { mapObjectToClient } from "./models/Client-Model";
import { mapObjectToCommentaires } from "./models/Commentaire-Model";
import { mapObjectToModules } from "./models/Modules-Model";
import { mapObjectToPagesPermissions } from "./models/PagesPermissions-Model";
import { mapObjectToPermissions } from "./models/Permissions-Model";
import { mapObjectToProfil } from "./models/Profil-Model";
import { mapObjectToPublications } from "./models/Publication-Model";
import { mapObjectToSousSousThemes } from "./models/Sous-Sous-Theme-Model";
import { mapObjectToSousThemes } from "./models/Sous-Theme-Model";
import { mapObjectToThemes } from "./models/Theme-Model";
import { mapObjectToUserBack } from "./models/UsersBack-Model";
import { mapObjectToMode } from "./models/Mode-Model";


export const  mapDataToJson = {
    "profils": mapObjectToProfil,
    "mode": mapObjectToMode,
    "permissions": mapObjectToPermissions,
    "modules":mapObjectToModules,
    "users":mapObjectToUserBack,
    "clients":mapObjectToClient,
    "themes":mapObjectToThemes,
    "sous_themes":mapObjectToSousThemes,
    "sous_sous_themes":mapObjectToSousSousThemes,
    "pages_permissions":mapObjectToPagesPermissions,
    "publications":mapObjectToPublications,
    "commentaires":mapObjectToCommentaires,
}

let aaa = {
    'Access-Control-Allow-Origin': '*'
}
export const headersRequest = {
    "headersFormData" :{...aaa ,  'content-type': 'multipart/form-data'},
    "headersJson" :{...aaa ,  'content-type': 'application/json'}
}

//console.log(headersRequest);