import React from 'react'
import {Form, Stack} from 'react-bootstrap';
import Pagination from 'react-js-pagination'


const Paginations = ({pageSize=[0,5,10,25,50,100],...props}) => {
   
    return (
        <div className="paginated-card align-items-center">
            <Stack direction="horizontal" gap={3}>
            <div className=''>
                        <span className="textClassInit">Affichage par</span>
                        <Form.Select className="ml-1" value={props.selectedSize} onChange={(event) => {
                            props.handleSelectChange(event);
                        }} >
                            {
                                pageSize.map((u,index)=>{
                                    return (
                                        <option value={u} key={index}>{u === 0 ? "":u}</option>
                                    )
                                })
                            }
                            
                        </Form.Select>

                    </div>

                    <div style={{marginLeft: 'auto'}}>
                        <Pagination
                            totalItemsCount={props.totalItemsCount ?? 100}
                            activePage={props.activePage}
                            itemsCountPerPage={props.itemsCountPerPage ?? 10} itemClass="page-item"
                            linkClass="page-link"
                            pageRangeDisplayed={10} hideFirstLastPages={true}
                            onChange={props.handlePageChange}
                        >

                        </Pagination>
                    </div>
            </Stack>
           {/*  <div className='row'>


                <div className="r-flexed col-12 col-md-6 col-sm-12 col-lg-6">
                    <div className='w-100 r-flexed align-items-center'>
                        <span className="textClassInit">Affichage par</span>
                        <Form.Select className="ml-1" value={activePage} onChange={(event) => {
                            props.handleSelectChange(event);
                            setactivePage(event.target.value)
                        }} >
                            <option value={0}/>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Form.Select>

                    </div>

                </div>

                <div className="col-12 col-md-6 col-sm-12 col-lg-6 d-flex">
                    <div style={{marginLeft: 'auto'}}>
                        <Pagination
                            totalItemsCount={props.totalItemsCount ?? 100}
                            activePage={props.activePage}
                            itemsCountPerPage={props.itemsCountPerPage ?? 10} itemClass="page-item"
                            linkClass="page-link"
                            pageRangeDisplayed={10} hideFirstLastPages={true}
                            onChange={props.handlePageChange}
                        >

                        </Pagination>
                    </div>
                </div>


            </div> */}


            {/*   <div className="r-flexed jc-btwn align-items-center arrow-paginated" style={{columnGap:"10px"}}>
                        <i className="fa fa-angle-left"></i>
                        <div className="card-number-generic">
                            <span>1</span>
                        </div>
                        <i className="fa fa-angle-right"></i>
                    </div> */}
        </div>
    );

}

export default Paginations;
