import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {
    authStateSlice,
    blockUISlice,
    dataApiSlice,
    menuBarReducedSlice,
    modalItemAddSlice,
    modalsProfilSlice,
    userSlice
} from "./features/user";
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createFilter from "redux-persist-transform-filter";
import { syncHistoryWithStore,routerReducer } from "react-router-redux";

const dataApiFilter = createFilter('dataApi',['value.modules']);
const authFilter = createFilter('value',['isLoggedIn','loading','message', 'showMdp','currentUser','theme','tabPerms']);
const persitConfig = {
    key: 'root',
    storage:storage,
    stateReconciler: autoMergeLevel2,
    whitelist:['dataApi'],
    blacklist: ['modal', 'user', 'authState','foreignDataMap', 'menuBarReducedState', 'blockUIState', 'modal_item_add'],
    transforms:[dataApiFilter]
}

const userPersistConfig = {
    key: 'authState',
    storage: storage,
    //blacklist: ['user'],
    transforms:[authFilter]
}


const rootReducer = combineReducers({
    user: userSlice.reducer,
    modal: modalsProfilSlice.reducer,
    modal_item_add: modalItemAddSlice.reducer,
    authState: persistReducer(userPersistConfig, authStateSlice.reducer),
    menuBarReducedState: menuBarReducedSlice.reducer,
    blockUIState: blockUISlice.reducer,
    dataApi: dataApiSlice.reducer,
    //routing:routerReducer
    //authState:authStateSlice.reducer
});

const persistedReducer = persistReducer(persitConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]

});


export const persistor = persistStore(store);