import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db, selectedBase } from "../../core/Firebase/firebase";
import {
  useDocs,
  useTotalSize,
  useWhereDocs_1,
} from "../../core/Firebase/FirebaseHooks";
import { canModule } from "../../core/js/backoffice";
import {
  closeModalAdd,
  selectShowModal,
  showModalAdd,
} from "../../core/Redux/features/user";
import CardUsersBo from "../../partials/FrequentlyUsed/CardUsersBo";
import Filtres from "../../partials/FrequentlyUsed/Filtres";
import Paginations from "../../partials/FrequentlyUsed/Paginations";
import TitleBtnAdd from "../../partials/FrequentlyUsed/TitleBtnAdd";
import { Modals } from "../../partials/modals/Modals";
import { ModalBodyClients } from "./modals/ModalClient";
import { motion } from "framer-motion";
import algoliaSearch from "algoliasearch";

export default function Utilisateurs() {
  //const mounted = useRef();
  const mounted = useRef();
  const dispatch = useDispatch();
  const show = useSelector(selectShowModal);
  const [isAdd, setIsAdd] = useState(true);
  //const [showModalDetail, setShowModalDetail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(0);
  const [selectedSize, setSelectedSize] = useState(0);
  const [isPaginated, setIsPaginated] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState(undefined);
  //const [itemDetail, setItemDetail] = useState(undefined);
  const [dataType] = useState("clients");
  const [count, setCount] = useState(12);
  const client = algoliaSearch(
    "UXPOT4CE0L",
    "5510ca81827f20f0b8b8fdc155b3853e"
  );
  const index = client.initIndex("clients_" + selectedBase);

  const { numberOfData: totalSize } = useTotalSize({
    db: db,
    collectionName: dataType,
  });
  var { data: clientsTab, numberOfData: totalItems } = useDocs({
    db: db,
    converterName: dataType,
    sort: "desc",
    count: count,
    whereToOrderBy: "createdAt",
    isPaginated: isPaginated,
    page: nextPage,
  });

  const mapFiltersTab = [
    { isDate: true, name: "Date Début", key: "createdAt" },
    { isDate: true, name: "Date Fin", key: "createdAtEnd" },
    { isField: true, name: "Prenom, Nom", key: "displayName" },
    { isField: true, name: "Email", key: "email" },
    { isFieldNumer: true, name: "Téléphone", key: "telephone" },
    {
      isDropdown: true,
      key: "genre",
      name: "Genre",
      placeholder: "Choisir Genre",
      tabDropdown: ["Homme", "Femme"],
    },
  ];
  const [tabValues, setTabValues] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  //   var { data: tabValues } = useWhereDocs_1({
  //     db: db,
  //     collectionName: dataType,
  //     mapValues: mapValues,
  //   });

  useEffect(() => {
    if (!mounted.current) {
      //did mounted
      document.title = "Utilisateurs";
      mounted.current = true;
    } else {
      //did update
    }
  });

  /*  useEffect(() => {
         if(itemToUpdate !== undefined && isFiltering) {
             let c = clientsTab.filter((i)=> i['id'] === itemToUpdate['id']);
             let cc = tabValues.findIndex((i)=> i['id'] === itemToUpdate['id']);
             if(c.length > 0 && cc !== -1){
                 tabValues[cc] = c[0];
                 setTabValues([...tabValues]);
             }

         }

     },[clientsTab]) */

  const execFiltre = (newMapValues, publications) => {
    const pubFiltrer = [];

    publications.forEach((pub) => {
      let test = false;
      //dateDeb & dateFin
      if (
        newMapValues.createdAt !== undefined ||
        newMapValues.createdAtEnd !== undefined
      ) {
        console.log("DATE==>", pub.genre);
        if (
          newMapValues.createdAt !== undefined &&
          newMapValues.createdAtEnd !== undefined
        ) {
          if (
            Date.parse(pub.date) >= newMapValues.createdAt &&
            Date.parse(pub.date) <= newMapValues.createdAtEnd
          ) {
            test = true;
          }
        } else {
          if (
            Date.parse(pub.date) >= newMapValues.createdAt &&
            newMapValues.createdAt !== undefined
          ) {
            test = true;
          }
          if (Date.parse(pub.date) <= newMapValues.createdAtEnd) {
            test = true;
          }
        }
      } else {
        test = true;
        console.log("DATEGOOD==>", pub);
        console.log("DATEGOOD==>", newMapValues.createdAt);
      }

      if (newMapValues["disabled"] === "Oui") {
        console.log("NOT DESI==>", pub.genre);
        if (pub.disabled !== false) {
          test = false;
        }
      } else if (newMapValues["disabled"] === "Non") {
        console.log("DESI==>", pub.genre);
        if (pub.disabled !== true) {
          test = false;
        }
      }

      if (newMapValues["type"] !== undefined) {
        console.log("TYPE==>", pub.genre);

        if (pub.type !== newMapValues["type"]) {
          test = false;
        }
      }

      if (newMapValues["telephone"] != undefined) {
        if (pub.telephone !== null) {
          if (!pub.telephone.startsWith(newMapValues["telephone"])) {
            test = false;
          }
        } else{
            test = false;
        }
      }
      
      if (newMapValues["genre"] !== undefined && pub.genre !== undefined) {
        console.log("GENRE==>", pub.genre);

        if (pub.genre.toUpperCase() !== newMapValues["genre"].toUpperCase()) {
          test = false;
        }
      }

      if (test) {
        pubFiltrer.push(pub);
      }
    });

    setIsFiltering(true);
    setTabValues(pubFiltrer);
  };

  const handleFilter = (mapValues) => {
    let newMapValues = Object.fromEntries(Object.entries(mapValues));
    let keyIndex = Object.keys(newMapValues);

    if (
      (newMapValues.email !== undefined && newMapValues.email !== "") ||
      (newMapValues.displayName !== undefined &&
        newMapValues.displayName !== "")
    ) {
      console.log("enter");
      index
        .search(newMapValues.email ?? newMapValues.displayName)
        .then(({ hits }) => {
          if (hits.length > 0) {
            let stock = [];
            hits.forEach((el) => {
              clientsTab.forEach((pub) => {
                if (el.id === pub.id) {
                  stock.push(pub);
                }
              });
            });
            execFiltre(newMapValues, stock);
          }
        });
    } else {
      console.log("outed");
      execFiltre(newMapValues, clientsTab);
    }
  };

  const cancelFilter = () => {
    /*  setTabValues([]); */
    setIsFiltering(false);
  };

  const handleSelectChange = (value) => {
    setIsPaginated(false);
    setCurrentPage(1);
    if (value > 0) {
      setCount(value);
    } else if (value === "0") {
      setCount(10);
    }
  };
  const handlePageChange = (pageNumber) => {
    if (totalSize / count >= 1) {
      setCurrentPage(pageNumber);
      setIsPaginated(true);
      setNextPage((pageNumber - 1) * totalItems + 1);
    }
  };
  const updateClient = (item) => {
    console.log(item);
    setIsAdd(false);
    setItemToUpdate(item);
    setIsPaginated(false);
    dispatch(showModalAdd());
  };

  return (
    canModule(["list-client"]) && (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        {/*  <!-- Utilisateurs Content --> */}
        <TitleBtnAdd
          showBtn={canModule(["create-client"])}
          icon="/assets/images/ico/ic_users_pub.svg"
          marginTop="mt-20"
          btnClick={() => {
            setIsAdd(true);
            setItemToUpdate(undefined);
            dispatch(showModalAdd());
          }}
          itemsName="Clients"
          count={isFiltering ? tabValues.length : totalSize}
        />

        <Filtres
          cancelFilter={cancelFilter}
          tabFilters={mapFiltersTab}
          handleSubmit={handleFilter}
          marginTop="mt-30"
        />

        <section className="mt-30">
          <div className="row">
            {!isFiltering &&
              clientsTab.map((item) => {
                return newFunction(item);
              })}
            {isFiltering &&
              tabValues.map((item) => {
                //console.log(item.date.toString());
                return newFunction(item);
              })}
          </div>
        </section>
        <section className="mt-30">
          {!isFiltering && (
            <Paginations
              pageSize={[0, 4, 8, 24, 50, 100]}
              activePage={currentPage}
              totalItemsCount={totalSize}
              itemsCountPerPage={totalItems}
              selectedSize={selectedSize}
              handleSelectChange={(event) => {
                handleSelectChange(event.target.value);
                setSelectedSize(event.target.value);
              }}
              handlePageChange={handlePageChange}
            />
          )}
        </section>

        <Modals
          modalTitle="Client"
          titleIcon="/assets/images/ico/usersgroup.png"
          modalBody={
            <ModalBodyClients isAdd={isAdd} itemToUpdate={itemToUpdate} />
          }
          show={show}
          handleClose={() => {
            dispatch(closeModalAdd());
          }}
        />
      </motion.div>
    )
  );

  function newFunction(item) {
    return (
      <div
        key={item.id}
        className="mb-30 col-12 col-sm-6 col-md-6 col-lg-3 col-users"
      >
        <CardUsersBo
          onUpdate={() => {
            updateClient(item);
          }}
          item={item}
        />
      </div>
    );
  }
}
