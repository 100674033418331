import {useEffect, useState} from 'react';
import * as Rb from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import {db} from '../../../core/Firebase/firebase';
import {useWhereDocs} from '../../../core/Firebase/FirebaseHooks';
import TitleBtnAdd from '../../../partials/FrequentlyUsed/TitleBtnAdd';
import FsLightbox from 'fslightbox-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {motion} from 'framer-motion';


const DetailUser = () => {
    const navigate = useNavigate();
    const [item, setItem] = useState({});
    const {id} = useParams();
    const [toggler, setToggler] = useState(false);

    const {data: user} = useWhereDocs({
        db: db,
        collectionName: "users",
        whereToLookInTheCollection: "id",
        whereToLookValue: id
    });

    const {data: profil} = useWhereDocs({
        db: db,
        collectionName: "profils",
        whereToLookInTheCollection: "id",
        whereToLookValue: item["profil"] ? item["profil"]["id"] : ""
    });

    useEffect(() => {
        if (user.length === 1) {
            setItem(user[0])
        }
        if (profil.length === 1) {
            setItem({...item, "fk_profil": profil[0]})
        }
    }, [user, profil])

    const redirectToDetail = (value) => {
        if (value && value["id"]) {
            navigate('/outilsadmin/profilsperms/detailProfil/' + value["id"]);
        }

    }


    return (
        <motion.div
        initial={{opacity: 0, scale: 0.9}}
        animate={{opacity: 1, scale: 1}}
        transition={{duration: 0.3}}
    >
            <TitleBtnAdd showBtn={false} marginTop="mt-20"
                         icon="/assets/images/ico/ic_users_pub.svg" itemsName="Informations Utilisateur"/>
            <div className="mt-30 tab-card">
                <Rb.Tabs className="mb-2 mt-20" defaultActiveKey="detail" justify={true} fill={true} id="tab-detail">

                    <Rb.Tab eventKey="detail" title="Détails" tabClassName='tabHeader textClassInit'>
                        <Rb.ListGroup variant='flush'>
                            <Rb.ListGroupItem className="list-item textClassInit">
                                <Rb.Stack direction="horizontal" gap={3}>
                                <span onClick={() => {
                                    setToggler(!toggler)
                                }} className="avatar cursor-pointer avatar-online box-shadow-1 hpx-40 wdpx-40 p-0 m-0">
                                                        <img alt="" className="wdpx-40 hpx-40"
                                                             src={item?.imgProfil ?? "/assets/images/imgs/empty-avatar.png"}/>
                                                    </span>
                                    <span>
                                                    {item["displayName"] ?? "..."}
                                                    </span>

                                </Rb.Stack></Rb.ListGroupItem>
                            <Rb.ListGroupItem
                                className="list-item textClassInit">Email: {item["email"] ?? "..."}</Rb.ListGroupItem>
                            <Rb.ListGroupItem
                                className="list-item textClassInit">Téléphone: {item["telephone"] ?? "..."}</Rb.ListGroupItem>
                            <Rb.ListGroupItem
                                className="list-item textClassInit">Genre: {item["genre"]}</Rb.ListGroupItem>
                            <Rb.ListGroupItem className="list-item textClassInit">
                                <Rb.Stack onClick={() => {
                                    redirectToDetail(item["fk_profil"]);
                                }} className='cursor-pointer' direction="horizontal" gap={2}>
                                    <span>Profil: {item["fk_profil"] ? item["fk_profil"]["nomProfil"] : ""}</span>
                                    <FontAwesomeIcon title='Détail du profil' className='text-primary fsize-20'
                                                     icon={faInfoCircle}/>
                                </Rb.Stack>

                            </Rb.ListGroupItem>

                        </Rb.ListGroup>
                        <FsLightbox
                            toggler={toggler}
                            disableLocalStorage={true}
                            sources={[
                                <img alt="" className="w-100 h-100"
                                src={item?.imgProfil ?? "/assets/images/imgs/empty-avatar.png"}/>
                            ]
                            }
                        />
                    </Rb.Tab>
                </Rb.Tabs>
            </div>
        </motion.div>
    );

}
export default DetailUser;