import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {db} from '../../core/Firebase/firebase';
import {useDocs, usePreferencesDocs, useTotalSize, useWhereDocs} from '../../core/Firebase/FirebaseHooks';
import {canModule, genericFilter} from '../../core/js/backoffice';
import {closeModalAdd, selectShowModal, showModalAdd} from '../../core/Redux/features/user';
import Filtres from '../../partials/FrequentlyUsed/Filtres';
import TitleBtnAdd from '../../partials/FrequentlyUsed/TitleBtnAdd';
import {Modals} from '../../partials/modals/Modals';
import {ModalPrefs} from './modals/ModalPrefs';
import {motion} from 'framer-motion';
import {Button, SpeedDial, Tooltip} from 'primereact';

export default function Preferences() {
    const mounted = useRef();
    const speedRef = useRef([]);
    const dispatch = useDispatch();
    const show = useSelector(selectShowModal);
    const [itemToUpdate, setItemToUpdate] = useState(undefined);
    const [titleModal, setTitleModal] = useState();
    const [key, setKey] = useState("");
    const [foreignMap, setForeignMap] = useState({});
    const [prefDoc, setPrefDoc] = useState([]);
    const {data} = useWhereDocs({
        db: db,
        collectionName: "preferences",
        whereToLookInTheCollection: "isPrefs",
        whereToLookValue: true
    });
    const {data: preferencesTabs} = usePreferencesDocs({
        db: db,
        converterName: "preferences",
        prefDoc: data[0],
        tabContents: prefDoc,
        whereToOrderBy: "id"
    });

    const mapFiltersTab = [
        {isField: true, name: "Nom", key: "nomPrefs"}
    ];
    const [tabValues, setTabValues] = useState([]);
    const [isFiltering, setIsFiltering] = useState(false);

    const handleFilter = (mapValues) => {
        //console.log(mapValues);
        //console.log(preferencesTabs);
        let valueToSearch = mapValues["nomPrefs"]
        let cc = [];
        cc = preferencesTabs.filter(function (i) {
            let returnValue = false;
            console.log(typeof i);
            if (typeof i === "object") {
                Object.keys(i).forEach((m) => {
                    returnValue = m.indexOf(valueToSearch) !== -1;
                })

            }
            return returnValue;
        })

        setTabValues(cc);
        setIsFiltering(true)
    }
    const cancelFilter = () => {
        setTabValues([]);
        setIsFiltering(false)
    }

    useEffect(() => {
        //console.log(data);
        if (data.length > 0) {
            if (data[0]['id'] !== undefined && data[0]['contents'] !== undefined && Array.isArray(data[0]['contents'])) {
                const allData = [];
                data[0]['contents'].forEach((u) => {
                    allData.push(data[0]['id'] + "/" + u)
                })
                setPrefDoc([...allData])
            }
        }
    }, [data])

    useEffect(() => {
        setForeignMap({})
    }, [preferencesTabs])


    useEffect(() => {
        if (!mounted.current) {
            //did mounted
            document.title = "Préférences";

            mounted.current = true;
        } else {
            //did update
            //console.log(dataLng);

        }
    });

    return (
        canModule(['list-preferences']) && <motion.div
            initial={{opacity: 0, scale: 0.9}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.3}}
        >
            <TitleBtnAdd showBtn={false} marginTop="mt-20"
                         icon="/assets/images/ico/usersgroup.png"
                         btnClick={() => {
                             setItemToUpdate(undefined);
                             dispatch(showModalAdd());
                         }} itemsName="Preferences" count={isFiltering ? tabValues.length : preferencesTabs.length}/>

            <Filtres cancelFilter={cancelFilter} tabFilters={mapFiltersTab} handleSubmit={handleFilter}
                     marginTop="mt-30"/>

            <section className="mt-30">
                <div className="card-table pt-20 pb-20 pl-20 pr-20">
                    <table
                        className="table basic-table table-borderless table-responsive table-striped table-hover">
                        <thead className="thead-table">
                        <tr>
                            <th>Nom</th>
                            <th>Description</th>
                            {canModule(['update-preferences']) &&
                            <th>
                                <div className="d-flex jc-center">
                                    <img className="" alt="" src="/assets/images/ico/ic_settings.png"/>
                                </div>
                            </th>}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !isFiltering && preferencesTabs?.map((item, index) => {

                                return Object.keys(item).map((key) => {
                                    //console.log(item);
                                    return (
                                        newFunction(index, key, item)
                                    );
                                })

                            })
                        }
                        {
                            (isFiltering) && tabValues.map((item, index) => {
                                return Object.keys(item).map((key) => {
                                    //console.log(item);
                                    return (
                                        newFunction(index, key, item)
                                    );
                                })
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </section>

            <Modals size="lg" modalTitle={titleModal} titleIcon="/assets/images/ico/usersgroup.png"
                    modalBody={<ModalPrefs titleModal={titleModal}
                                           itemToUpdate={itemToUpdate}/>}
                    show={show} handleClose={() => {
                dispatch(closeModalAdd())
            }}/>
        </motion.div>
    )

    function newFunction(index, key, item) {
        if (!foreignMap[index]) {
            setForeignMap({...foreignMap, [index]: "refresh"})
        }
        return <tr key={index}>
            <td>{key}</td>
            <td>{item[key][1]}</td>
            <td>
                {(canModule(['update-preferences']) && item[key].length > 0) &&
                <div className="d-flex jc-center">
                    <SpeedDial
                        direction='left'
                        showIcon="pi pi-bars"
                        hideIcon="pi pi-times"
                        buttonClassName='wdpx-35 hpx-35 p-button-outlined p-button-success align-self-center'
                        buttonStyle={{border: "1px solid #337f77"}}
                        id={index}
                        ref={(el) => speedRef.current[index] = el}
                        style={{position: "relative"}}
                        className={speedRef.current[index]?.props?.id === index ? "myCustomHamburger1" : "myCustomHamburger"}
                        model={getMenuItem({data: item, keyData: key})}/>
                </div>

                }
            </td>
        </tr>;
    }

    function getMenuItem({data, keyData}) {
        return [
            {
                id: 1,
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: () => {
                    setItemToUpdate(data[keyData][0] ?? []);
                    setTitleModal(keyData);
                    dispatch(showModalAdd());
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                             className={options.className + " bg-secondary wdpx-35 hpx-35 cursor-pointer myTooltip1"}
                             onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip1"/>
                        </div>
                    );
                },
            },

        ];

    }
}

