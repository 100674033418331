import React, { useState } from 'react';
import Filtres from '../partials/FrequentlyUsed/Filtres';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthState, showModal } from '../core/Redux/features/user';
import { motion } from "framer-motion";
import { customWhereBetweenDate } from "../core/js/backoffice";

import DashboardCard from './components/DashboardCard';

const mapFiltersTab = [
    { isDate: true, name: "Date Début", key: "createdAtBegin" },
    { isDate: true, name: "Date Fin", key: "createdAtEnd" },
];

function DashBoard() {
    const dispatch = useDispatch();
    const authState = useSelector(selectAuthState);
    document.title = "Accueil";
    // const [filtering, setFiltering] = useState(true);
    // const [totalUsers, setTotalUsers] = useState(null);
    // const [createdAtBegin, setCreatedAtBegin] = useState(null);
    // const [createdAtEnd, setCreatedAtEnd] = useState(null);
    const [queries, setQueries] = useState([]);

    // const { numberOfData: totalUsers } = useTotalSize({ db: db, collectionName: "users" });
    // const { numberOfData: totalThemes } = useTotalSize({ db: db, collectionName: "themes" });
    // const { numberOfData: totalPosts } = useTotalSize({ db: db, collectionName: "publications" });

    const cancelFilter = () => {
        setQueries([]);
    }
    const handleFilter = (mapValues) => {
        const createdAtBegin = mapValues.createdAtBegin;
        const createdAtEnd = mapValues.createdAtEnd;
        const tempQueries = customWhereBetweenDate({ whereToLookInTheCollection: "createdAt", whereToLookValue: createdAtBegin, whereToLookValue1: createdAtEnd });
        console.log("TEMP QUERIES ", tempQueries);
        setQueries(tempQueries);
    }

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
        >
            {/* <!-- Dashboard Content --> */}

            <Filtres
                cancelFilter={cancelFilter}
                tabFilters={mapFiltersTab}
                handleSubmit={handleFilter}
                marginTop="mt-30"
            />

            <section className="mt-30">
                <div className="dash-welcome-card r-flexed jc-btwn">
                    <div className="c-flexed jc-btwn-col mt-50 mb-50 mlpx-30">
                        <span className="r-flexed text_1 align-items-center textClassInit"><h1 className="mr-1 textClassInit text-transform-none">Bienvenue</h1>{authState.userHeader ? authState.userHeader.displayName : ''}</span>
                        <div className="lorem-text">
                            <p className="textClassInit">Récap sur le nombre total d'utilisateurs, de thèmes et de publications du système</p>
                        </div>

                        <button onClick={() => {
                            //userTableSeeder()
                            dispatch(showModal())
                        }} className="nm-button">Mon Profil
                        </button>
                    </div>
                    <div className="img-welcome">
                        <img alt="" src="../assets/images/imgs/img_accueil_dash.svg" />
                    </div>
                </div>
            </section>

            <section className="mt-50">
                <div className="row">
                    <DashboardCard
                        queries = {queries} 
                        collection={"users"} 
                        imgSrc="../assets/images/ico/ic_users_dash.svg"
                        label="Utilisateurs"
                        // value={totalUsers ?? "..."}
                        className="card-dash-item"
                        classNames="mr-auto w-100 card-dash-recap c-flexed jc-btwn-col pt-20 pb-20 pl-20"
                    />
                    <DashboardCard 
                        queries = {queries} 
                        collection={"themes"} 
                        imgSrc="../assets/images/ico/ic_theme_dash.svg"
                        label="Themes"
                        // value={totalUsers ?? "..."}
                        className="card-dash-item1"
                        classNames="m-auto w-100 card-dash-recap1 c-flexed jc-btwn-col pt-20 pb-20 pl-20"
                    />
                    <DashboardCard 
                        queries = {queries} 
                        collection={"publications"} 
                        imgSrc="../assets/images/ico/ic_pub_dash.svg"
                        label="Publications"
                        // value={totalUsers ?? "..."}
                        className="card-dash-item2"
                        classNames="ml-auto w-100 card-dash-recap2 c-flexed jc-btwn-col pt-20 pb-20 pl-20"
                    />
                    {/* <div className="d-flex col-12 col-sm-12 col-md-12 col-lg-4 mb-3 col-dash">
                        <div className="mr-auto w-100 card-dash-recap c-flexed jc-btwn-col pt-20 pb-20 pl-20">
                            <div className="card-dash-item">
                                <img alt="" src="../assets/images/ico/ic_users_dash.svg" />
                            </div>
                            <span style={{ fontSize: "30px", fontStyle: "normal", color: "#FFFFFF" }}>Utilisateurs</span>
                            <span style={{ fontSize: "50px", fontStyle: "normal", color: "#FFFFFF" }}>{totalUsers ?? "..."}</span>
                        </div>
                    </div> */}

                    {/* <div className="d-flex col-12 col-sm-12 col-md-12 col-lg-4 mb-3 col-dash">
                        <div className="m-auto w-100 card-dash-recap1 c-flexed jc-btwn-col pt-20 pb-20 pl-20">
                            <div className="card-dash-item1">
                                <img alt="" src="../assets/images/ico/ic_theme_dash.svg" />
                            </div>
                            <span style={{ fontSize: "30px", fontStyle: "normal", color: "#FFFFFF" }}>Thémes</span>
                            <span style={{ fontSize: "50px", fontStyle: "normal", color: "#FFFFFF" }}>{totalThemes ?? "..."}</span>
                        </div>
                    </div>

                    <div className="d-flex col-12 col-sm-12 col-md-12 col-lg-4 mb-3 col-dash">
                        <div className="ml-auto w-100 card-dash-recap2 c-flexed jc-btwn-col pt-20 pb-20 pl-20">
                            <div className="card-dash-item2">
                                <img alt="" src="../assets/images/ico/ic_pub_dash.svg" />
                            </div>
                            <span style={{ fontSize: "30px", fontStyle: "normal", color: "#FFFFFF" }}>Publications</span>
                            <span style={{ fontSize: "50px", fontStyle: "normal", color: "#FFFFFF" }}>{totalPosts ?? "..."}</span>
                        </div>
                    </div> */}
                </div>
            </section>
        </motion.div>
    );

}

export default DashBoard;
