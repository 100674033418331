import React from 'react'

export default function PageNotFound() {
    return (
        <div className="title-animation overflow-hidden">
            <img alt="" src="/assets/images/imgs/page-not-found.svg"
                 style={{width: "100vw", height: "100vh", objectFit: "contain"}}/>
        </div>
    )
}
