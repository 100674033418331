import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {canSousModule} from '../core/js/backoffice';
import Preferences from '../pages/admin/Preferences';
import Modes from '../pages/admin/Modes';
import SousSousThemes from '../pages/backoffice/SousSousThemes';
import SousThemes from '../pages/backoffice/SousThemes';
import Utilisateurs from '../pages/backoffice/Utilisateurs';
import DashBoard from '../pages/DashBoard';
import DashboardV2 from '../pages/DashboardV2';
import {HomeNotFound} from '../pages/Home';
import Publications from '../pages/publications/Publications';
import Themes from '../pages/backoffice/Themes';
import UtilisateursAdmin from '../pages/admin/Utilisateurs';
import ProfilsPerms from '../pages/admin/ProfilsPerms';
import {AnimatePresence} from "framer-motion";
import DetailClient from '../pages/backoffice/modals/DetailClient';
import DetailPublication from '../pages/publications/modals/DetailPublication';
import DetailUser from '../pages/admin/modals/DetailUser';
import {DetailProfil} from '../pages/admin/modals/DetailProfil';
import DetailTheme from '../pages/backoffice/modals/DetailTheme';
import DetailSousTheme from '../pages/backoffice/modals/DetailSousTheme';
import DetailSousSousTheme from '../pages/backoffice/modals/DetailSousSousTheme';

const AnimatedRoutes = () => {
    const location = useLocation();
    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<DashBoard/>}/>
                <Route path="*" element={<HomeNotFound/>}/>
                {canSousModule(['-dashboard']) && <Route path="/dashboard" element={<DashboardV2/>}/>}
                {canSousModule(['-posts']) && <Route path="/publications" element={<Publications/>}/>}
                {canSousModule(['-posts']) &&
                    <Route path="/publications/detailPost" element={<DetailPublication/>}>
                        <Route path=":id" element={<DetailPublication/>}/>
                        <Route path=":id/:key" element={<DetailPublication/>}/>
                    </Route>
                    }
                {/* Backoffice */}
                {canSousModule(['-client']) && <Route path="/backoffice/clients" element={<Utilisateurs/>}/>}
                {canSousModule(['-client']) &&
                <Route path="/backoffice/clients/detailClient/:id" element={<DetailClient/>}/>}
                {canSousModule(['-theme']) && <Route path="/backoffice/themes" element={<Themes/>}/>}
                {canSousModule(['-theme']) &&
                <Route path="/backoffice/themes/detailTheme/:id" element={<DetailTheme/>}/>}
                {canSousModule(['-sous-theme']) && <Route path="/backoffice/sousthemes" element={<SousThemes/>}/>}
                {canSousModule(['-sous-theme']) &&
                <Route path="/backoffice/sousthemes/detailSousTheme/:id" element={<DetailSousTheme/>}/>}
                {canSousModule(['-sous-sous-theme']) &&
                <Route path="/backoffice/soussousthemes" element={<SousSousThemes/>}/>}
                {canSousModule(['-sous-sous-theme']) &&
                <Route path="/backoffice/soussousthemes/detailSousSousTheme/:id" element={<DetailSousSousTheme/>}/>}

                {/* Outils Admin */}
                {canSousModule(['-user']) && <Route path="/outilsadmin/utilisateurs" element={<UtilisateursAdmin/>}/>}
                {canSousModule(['-user']) &&
                <Route path="/outilsadmin/utilisateurs/detailUser/:id" element={<DetailUser/>}/>}
                {canSousModule(['-profil', '-permissions']) &&
                <Route path="/outilsadmin/profilsperms" element={<ProfilsPerms/>}/>}
                {canSousModule(['-profil']) &&
                <Route path="/outilsadmin/profilsperms/detailProfil/:id" element={<DetailProfil/>}/>}
                {canSousModule(['-preferences']) && <Route path="/outilsadmin/preferences" element={<Preferences/>}/>}
                {canSousModule(['-mode']) && <Route path="/outilsadmin/modes" element={<Modes/>}/>}
            </Routes>
        </AnimatePresence>

    );
}

export default AnimatedRoutes;
