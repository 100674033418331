import { doc, getDoc } from "firebase/firestore";
import { db, host, selectedBase } from "../Firebase/firebase";

export class SousThemeModel {
  constructor({
    id = null,
    designation,
    date,
    createdAt,
    description,
    theme,
    descriptionMap,
    designationMap,
    ordreMap,
    usedCount,
    themeId,
    nbSousSousThemes,
    keywords,
    keywordsMap,
    fileUrl,
    eraseImg,
    tokens,
    likedBy,
    ordre
  }) {
    this.id = id;
    this.date = date;
    this.createdAt = createdAt;
    this.designation = designation;
    this.description = description;
    this.usedCount = usedCount;
    this.theme = theme;
    this.themeId = themeId;
    this.nbSousSousThemes = nbSousSousThemes;
    this.designationMap = designationMap;
    this.descriptionMap = descriptionMap;
    this.ordreMap = ordreMap;
    this.keywords = keywords;
    this.keywordsMap = keywordsMap;
    this.fileUrl = fileUrl;
    this.eraseImg = eraseImg;
    this.tokens = tokens;
    this.likedBy = likedBy;
    this.ordre = ordre;
  }

  toString() {
    return this.designation + " , " + this.description;
  }

  toJson() {
    const nameCollection = "themes_" + selectedBase;
    let obj = {
      id: this.id,
      date: this.date,
      designation: this.designation,
      description: this.description,
      theme: doc(db, nameCollection, this.theme),
      usedCount: this.usedCount,
      keywords: this.keywords,
      ordre: this.ordre,
    };
    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  }
}

/* export const mapObjectToSousThemes = {
    toFirestore: (m)=>{
        return {
            id: m.id,
            theme:m.theme,
            designation: m.designation,
            description: m.description,
        };
    },
    fromFirestore:async (snapshot, options)=>{
        const data = snapshot.data(options);
        //console.log(typeof data.theme);
        let p;
        if(data.theme && typeof data.theme !== 'string'){
           
            let x = await getDoc(data.theme) ;
            if(x.exists()){
                p = x.data();
            }
        }
        //console.log(p); 
        return new SousThemeModel({id:data.id,createdAt:data.createdAt,date:new Date(data.createdAt.seconds * 1000),themeId:p !== undefined ? p.id : "",theme:p !== undefined ? p.designation['fr_FR'] ?? "" : "",designationMap:data.designation,descriptionMap:data.description, designation:data.designation ? data.designation['fr_FR'] ?? "" : "",description:data.description ? data.description['fr_FR'] ?? "" : "" });
    }
} */

export const mapObjectToSousThemes = {
  toFirestore: (m) => {
    let mTmp = Object.fromEntries(Object.entries(m));

    Object.keys(mTmp).forEach((key) => {
      if (mTmp[key] === undefined) {
        delete mTmp[key];
      }
    });

    return mTmp;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    let tmp = Object.fromEntries(Object.entries(data.keywords ?? {}));
    if (data.keywords) {
      Object.keys(tmp).forEach((key) => {
        tmp[key] = tmp[key].join(" ; ");
      });
    }
    //let newFileUrl = String(data.fileUrl);
    let newFileUrl = String(data.fileUrl ?? "/assets/images/imgs/upload.png");
    // newFileUrl = newFileUrl.replace("localhost",host)
    return new SousThemeModel({
      tokens: data.tokens ?? [],
      likedBy: data.likedBy ?? [],
      fileUrl: newFileUrl,
      id: data.id,
      keywords: tmp,
      keywordsMap: data.keywords,
      createdAt: data.createdAt,
      date: new Date(data.createdAt?.seconds * 1000),
      themeId: data.theme["id"] ?? "",
      theme: data.theme,
      ordre: data.ordre,
      ordreMap: data.ordre,
      designationMap: data.designation,
      descriptionMap: data.description,
      designation: data.designation ? data.designation["fr_FR"] ?? "" : "",
      description: data.description ? data.description["fr_FR"] ?? "" : "",
    });
  },
};
