import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { db, selectedBase } from '../../core/Firebase/firebase';
import { useDocs, useTotalSize, useWhereDocs_1 } from '../../core/Firebase/FirebaseHooks';
import { deleteData, enableAuthUser, fetchForeignData } from '../../core/Firebase/services/FirebaseService';
import { canModule } from '../../core/js/backoffice';
import { closeModalAdd, selectShowModal, showModalAdd } from '../../core/Redux/features/user';
import Filtres from '../../partials/FrequentlyUsed/Filtres';
import Paginations from '../../partials/FrequentlyUsed/Paginations';
import TitleBtnAdd from '../../partials/FrequentlyUsed/TitleBtnAdd';
import { Modals } from '../../partials/modals/Modals';
import { ModalBodyUsers } from './modals/ModalUsers';
import { motion } from 'framer-motion';
import { doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { SpeedDial, Tooltip } from 'primereact';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrip, faList } from "@fortawesome/free-solid-svg-icons";
import CardUser from "../../partials/FrequentlyUsed/CardUser"

export default function UtilisateursAdmin() {
    const mounted = useRef();
    const speedRef = useRef([]);
    useEffect(() => {
        if (!mounted.current) {
            //did mounted
            document.title = "Utilisateurs";
            dispatch(closeModalAdd())
            mounted.current = true;
        } else {
            //did update
        }
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const show = useSelector(selectShowModal);
    const [isAdd, setIsAdd] = useState(true);
    //const [boolSpeed, setBoolSpeed] = useState({});
    //const [showModalDetail, setShowModalDetail] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPage, setNextPage] = useState(0);
    const [selectedSize, setSelectedSize] = useState(0);
    const [isPaginated, setIsPaginated] = useState(false);
    const [itemToUpdate, setItemToUpdate] = useState(undefined);
    //const [itemDetail, setItemDetail] = useState(undefined);
    const [dataType] = useState("users");
    const [count, setCount] = useState(12);
    const [foreignMap, setForeignMap] = useState({});
    const [viewType, setViewType] = useState("grid"); //list or grid
    const { numberOfData: totalSize } = useTotalSize({ db: db, collectionName: dataType });

    var { data: utilisateursAdmin, numberOfData: totalItems } = useDocs({
        db: db,
        converterName: dataType,
        sort: "desc",
        count: count,
        whereToOrderBy: "createdAt",
        isPaginated: isPaginated,
        page: nextPage
    });

    const { data: profils } = useDocs({
        db: db, converterName: "profils",
        sort: "desc"
    });

    const mapFiltersTab = [
        { isDropdown: true, key: "profil", name: "Profil", placeholder: "Choisir Profil", tabDropdown: profils },
        { isDropdown: true, key: "genre", name: "Genre", placeholder: "Choisir Genre", tabDropdown: ["Homme", "Femme"] },
        { isField: true, name: "Email", key: "email" },
        { isField: true, name: "Téléphone", key: "telephone" },
        { isField: true, name: "Prenom, Nom", key: "displayName" },
    ];
    //const [tabValues, setTabValues] = useState([]);
    const [isFiltering, setIsFiltering] = useState(false);
    const [mapValues, setMapValues] = useState({});
    var { data: tabValues } = useWhereDocs_1({
        db: db,
        collectionName: dataType,
        mapValues: mapValues
    });

    /*   useEffect(() => {
          if(itemToUpdate !== undefined && isFiltering) {
              let c = utilisateursAdmin.filter((i)=> i['id'] === itemToUpdate['id']);
              let cc = tabValues.findIndex((i)=> i['id'] === itemToUpdate['id']);
              if(c.length > 0 && cc !== -1){
                  tabValues[cc] = c[0];
                  setTabValues([...tabValues]);
              }

          }

      },[utilisateursAdmin]) */

    const handleFilter = (mapValues) => {
        /*let cc = [];
        cc = genericFilter({tabItems: utilisateursAdmin, mapValues: mapValues, mapFiltersTab: mapFiltersTab});

        setTabValues(cc); */
        let newMapValues = Object.fromEntries(Object.entries(mapValues));

        if (newMapValues["profil"]) {
            newMapValues["profil"] = doc(db, "profils_" + selectedBase, newMapValues["profil"])
        }
        setMapValues(newMapValues);
        setIsFiltering(true);
    }
    const cancelFilter = () => {
        /*  setTabValues([]); */
        setIsFiltering(false)
    }

    const handleSelectChange = (value) => {
        setIsPaginated(false);
        setCurrentPage(1);
        if (value > 0) {
            setCount(value);
        } else if (value === "0") {
            setCount(10)
        }
    }
    const handlePageChange = (pageNumber) => {
        if ((totalSize / count) >= 1) {
            setCurrentPage(pageNumber);
            setIsPaginated(true);
            setNextPage((pageNumber - 1) * (totalItems) + 1);
        }

    }

    const redirectToDetail = (value) => {
        navigate('/outilsadmin/utilisateurs/detailUser/' + value["id"]);
    }

    useEffect(() => {
        setForeignMap({})
    }, [utilisateursAdmin]);

    const changeViewType = () => {
        if (viewType === "list") {
            setViewType("grid")
        } else {
            setViewType("list")
        }
    }


    return (
        canModule(['list-user']) && <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
        >
            <TitleBtnAdd showBtn={canModule(['create-user'])} marginTop="mt-20"
                icon="/assets/images/ico/usersgroup.png"
                btnClick={() => {
                    setIsAdd(true);
                    setItemToUpdate(undefined);
                    dispatch(showModalAdd())
                }} itemsName="Utilisateurs" count={isFiltering ? tabValues.length : totalSize} />

            <Filtres cancelFilter={cancelFilter} tabFilters={mapFiltersTab} handleSubmit={handleFilter}
                marginTop="mt-30" />

            <div className="d-flex justify-content-end mt-2">
                <a className="border p-1 rounded" onClick={() => changeViewType()}>
                    {
                        viewType === "list" ? <FontAwesomeIcon icon={faGrip} /> : <FontAwesomeIcon icon={faList} />
                    }
                </a>
            </div>
            <section className="mt-30">

                {
                    viewType === "list" && (
                        <div className="card-table pt-20 pb-20 pl-20 pr-20">
                            <table
                                className="table basic-table table-borderless table-responsive table-striped table-hover">
                                <thead className="thead-table">
                                    <tr>
                                        <th>Nom Complet</th>
                                        <th>Email</th>
                                        <th>Telephone</th>
                                        <th>Genre</th>
                                        <th>Profil</th>
                                        <th>Actif</th>
                                        <th>Photo</th>
                                        {canModule(['detail-user', 'update-user', 'delete-user']) &&
                                            <th>
                                                <div className="d-flex jc-center">
                                                    <img className="" alt="" src="/assets/images/ico/ic_settings.png" />
                                                </div>
                                            </th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (!isFiltering) && utilisateursAdmin.map((item, index) => {
                                            return (
                                                newFunction(index, item)
                                            );
                                        })
                                    }
                                    {
                                        (isFiltering) && tabValues.map((item, index) => {
                                            return (
                                                newFunction(index, item)
                                            );
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    )
                }
                {
                    viewType === "grid" && (
                        <div className='row row-cols-3 gap-2'>
                            {
                                (!isFiltering) && utilisateursAdmin.map((item, index) => {
                                    return (
                                        <div className='col'>
                                            <CardUser item={item} getMenuItem={getMenuItem}/>
                                        </div>
                                    );
                                })
                            }
                            {
                                (isFiltering) && tabValues.map((item, index) => {
                                    return (
                                        <div className='col'>
                                            <CardUser item={item} getMenuItem={getMenuItem}/>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                }
            </section>


            <section className="mt-30">
                {!isFiltering &&
                    <Paginations activePage={currentPage} totalItemsCount={totalSize} itemsCountPerPage={totalItems}
                        selectedSize={selectedSize}
                        handleSelectChange={(event) => {
                            handleSelectChange(event.target.value);
                            setSelectedSize(event.target.value);
                        }} handlePageChange={handlePageChange} />}
            </section>

            <Modals modalTitle="Utilisateur" titleIcon="/assets/images/ico/usersgroup.png"
                modalBody={<ModalBodyUsers isAdd={isAdd} profils={profils} itemToUpdate={itemToUpdate} />}
                show={show} handleClose={() => {
                    dispatch(closeModalAdd())
                }} />


        </motion.div>
    )

    function getMenuItem({ data }) {
        let result = [];
        let items = [
            {
                id: 0,
                label: 'Détails',
                icon: 'pi pi-info',
                command: () => {
                    redirectToDetail(data);
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                            className={options.className + " bg-primary wdpx-35 hpx-35 cursor-pointer myTooltip0"}
                            onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip0" />
                        </div>
                    );
                },

            },
            {
                id: 1,
                label: data.actif ? "Désactiver cet utilisateur" : "Activer cet utilisateur",
                icon: 'pi pi-thumbs-' + (data.actif ? 'down' : 'up'),
                command: () => {
                    enableAuthUser({ isEnable: data.actif, id: data.id, dataType: "users" })
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                            className={options.className + " bg-" + (data.actif ? "danger" : "success") + " wdpx-35 hpx-35 cursor-pointer myTooltip2"}
                            onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip2" />
                        </div>
                    );
                },
            },
            {
                id: 2,
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: () => {
                    setIsAdd(false);
                    setItemToUpdate(data);
                    setIsPaginated(false);
                    dispatch(showModalAdd());
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                            className={options.className + " bg-secondary wdpx-35 hpx-35 cursor-pointer myTooltip1"}
                            onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip1" />
                        </div>
                    );
                },
            },
            {
                id: 3,
                label: 'Supprimer',
                icon: 'pi pi-trash',
                command: () => {
                    deleteData({ dataType: "users", id: data.id });
                },
                template: (u, options) => {
                    return (
                        <div data-pr-tooltip={u.label}
                            className={options.className + " bg-danger wdpx-35 hpx-35 cursor-pointer myTooltip3"}
                            onClick={options.onClick}>
                            <span className={u.icon + " text-white"}></span>
                            <Tooltip position="bottom" className="fsize-12" target=".myTooltip3" />
                        </div>
                    );
                },
            }
        ];
        if (canModule(['detail-user'])) {
            result.push(items.filter((i) => (i.id === 0))[0])
        }
        if (canModule(['update-user'])) {
            result.push(items.filter((i) => (i.id === 1))[0])
        }
        if (canModule(['update-user'])) {
            result.push(items.filter((i) => (i.id === 2))[0])
        }
        if (canModule(['delete-user'])) {
            result.push(items.filter((i) => (i.id === 3))[0])
        }
        return result;
    }

    function newFunction(index, item) {
        fetchForeignData({ reference: item.profil, uniqueField: true, field: "nomProfil" }).then((value) => {
            if (!foreignMap[item.id]) {
                setForeignMap({ ...foreignMap, [item.id]: value })
            }
        })
        return <tr key={index + '' + item?.id}>
            <td>{item?.displayName}</td>
            <td>{item?.email}</td>
            <td>{item?.telephone}</td>
            <td>{item?.genre}</td>
            <td>{foreignMap[item.id] ?? ""}</td>
            <td>
                <div className={item?.actif === true ? 'actif-badge' : 'actif-badge1'}>
                    {(item?.actif === true) ? "Oui" : "Non"}
                </div>
            </td>
            <td>
                <span className="jc-center avatar avatar-online box-shadow-1 hpx-45 wdpx-45">
                    <img alt="" className="wdpx-45 hpx-45"
                        src={item?.imgProfil ?? "/assets/images/imgs/empty-avatar.png"} />
                </span>
            </td>
            {(canModule(['detail-user', 'update-user', 'delete-user']) /* && item.id !== this.props.authState.currentUser.id */) &&
                <td>
                    <div className="d-flex jc-center">
                        <SpeedDial
                            direction='left'
                            showIcon="pi pi-bars"
                            hideIcon="pi pi-times"
                            buttonClassName='wdpx-35 hpx-35 p-button-outlined p-button-success align-self-center'
                            buttonStyle={{ border: "1px solid #337f77" }}
                            id={item.id}
                            ref={(el) => speedRef.current[item.id] = el}
                            style={{ position: "relative" }}
                            // className={speedRef.current[item.id]?.props?.id === item.id ? "myCustomHamburger1" : "myCustomHamburger"}
                        className={"myCustomHamburger1"}
                            model={getMenuItem({ data: item })} />
                    </div>
                </td>}
        </tr>;
    }
}
