export class PagesPermissionsModel{
    constructor({id, pages}){
        this.id = id ;
        this.pages = pages;
    }

    toString(){
        return this.pages ;
    }
}

export const mapObjectToPagesPermissions = {
    toFirestore: (p)=>{
        return {
            id:p.id,
            pages:p.pages
        };
    },
    fromFirestore: (snapshot, options)=>{
        const data = snapshot.data(options);
        return new PagesPermissionsModel({id:data.id, pages:data.pages});
    }
}