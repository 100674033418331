import React from "react";
import {useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {canModule, canSousModule, openMenuMobile} from "../../core/js/backoffice";
import {/* selectAuthState, */ selectDataApiState} from "../../core/Redux/features/user";

const myMobileMenu = React.createRef();
const idMobileMenu = React.createRef();

function MenuMobile() {
    const location = useLocation();
    const dataApiState = useSelector(selectDataApiState);
    //const authState = useSelector(selectAuthState);
    var currentUrl = location.pathname;
    const getCurrentPage = (value) => {
        console.log("papa", value);
        return currentUrl.indexOf(value) !== -1;
    }
    return (
        <div className="navmobile" ref={idMobileMenu}>
            <div className="container-fluid d-flex mt-50 mb-50" ref={myMobileMenu}>

                <div className="m-auto">
                    {
                        dataApiState.modules.map((m, index) => {
                            //console.log(tabPerms);
                            return (
                                <div key={index}>
                                    {
                                        (m.modules.length === 0 && canModule(m.keyPage)) && <div className="mb-3">
                                            <Link onClick={openMenuMobile}
                                                  className="r-flexed item-mobile column-gap-10" to={m.link}>
                                                <img alt=""
                                                     className={(getCurrentPage(m.link) ? 'activeImg' : '') + ' menu-img-mobile'}
                                                     src={"/assets/images/imgs/" + m.img}/>
                                                <div
                                                    className={(getCurrentPage(m.link) ? 'activeTitle' : '') + ' mobile-title text-uppercase'}>{m.nom}</div>
                                            </Link>
                                        </div>
                                    }
                                    {
                                        (m.modules.length > 0 && canModule(m.keyPage)) &&
                                        <div className="mb-3 submenu-mobile">
                                        <span className="r-flexed item-mobile mobile-title cursor-pointer column-gap-10"
                                              target="_self">
                                        <img alt=""
                                             className={(getCurrentPage(m.link) ? 'activeImg' : '') + ' menu-img-mobile'}
                                             src={"/assets/images/imgs/" + m.img}/>
                                    <div
                                        className={(getCurrentPage(m.link) ? 'activeTitle' : '') + ' mobile-title text-uppercase'}>{m.nom}</div>
                                        </span>

                                            <div className="items-menu">
                                                {
                                                    m.modules.map((mm, index) => {
                                                        //console.log(mm);

                                                        return (
                                                            canSousModule(mm.keyPage) &&
                                                            <Link key={index} onClick={openMenuMobile}
                                                                  className="r-flexed item-mobile  column-gap-10 mb-1"
                                                                  to={mm.link}>
                                                                <img alt="" className="menu-img-mobile"
                                                                     src={"/assets/images/imgs/" + mm.img1}
                                                                     style={{width: "25px"}}/>
                                                                <div className="fsize-12 text-uppercase">{mm.nom}</div>
                                                            </Link>
                                                        )
                                                    })
                                                }
                                            </div>


                                        </div>
                                    }
                                </div>
                            )
                        })}
                </div>

                {(1 === 0) && <div className="m-auto">
                    <div className="mb-3">
                        <Link onClick={openMenuMobile} className="r-flexed item-mobile column-gap-10" to="/dashboard">
                            <img alt=""
                                 className={(getCurrentPage('dashboard') ? 'activeImg' : '') + ' menu-img-mobile'}
                                 src="/assets/images/imgs/dashboard.png"/>
                            <div
                                className={(getCurrentPage('dashboard') ? 'activeTitle' : '') + ' mobile-title text-uppercase'}>Dashboard
                            </div>
                        </Link>
                    </div>

                    <div className="mb-3 submenu-mobile">
                    <span className="r-flexed item-mobile mobile-title cursor-pointer column-gap-10" target="_self">
                        <img alt="" className={(getCurrentPage('/backoffice/') ? 'activeImg' : '') + ' menu-img-mobile'}
                             src="/assets/images/imgs/backoffice.png"/>
                        <div
                            className={(getCurrentPage('/backoffice/') ? 'activeTitle' : '') + ' mobile-title text-uppercase'}>Backoffice</div>
                    </span>

                        <div className="items-menu">
                            <Link onClick={openMenuMobile} className="r-flexed item-mobile  column-gap-10 mb-1"
                                  to="/backoffice/clients">
                                <img alt="" className="menu-img-mobile"
                                     src="/assets/images/imgs/usersgroup1.png" style={{width: "25px"}}/>
                                <div className="fsize-12 text-uppercase">Clients</div>
                            </Link>

                            <Link onClick={openMenuMobile} className="r-flexed item-mobile column-gap-10 mb-1"
                                  to="/backoffice/themes">
                                <img alt="" className="menu-img-mobile"
                                     src="/assets/images/imgs/theme1.png" style={{width: "25px"}}/>
                                <div className="fsize-12 text-uppercase">Thémes</div>
                            </Link>

                            <Link onClick={openMenuMobile} className="r-flexed item-mobile column-gap-10 mb-1"
                                  to="/backoffice/sousthemes">
                                <img alt="" className="menu-img-mobile"
                                     src="/assets/images/imgs/sous_theme1.svg" style={{width: "25px"}}/>
                                <div className="fsize-12 text-uppercase">Sous thémes</div>
                            </Link>

                            <Link onClick={openMenuMobile} className="r-flexed item-mobile column-gap-10 mb-1"
                                  to="/backoffice/soussousthemes">
                                <img alt="" className="menu-img-mobile"
                                     src="/assets/images/imgs/sous_sous_theme.svg" style={{width: "25px"}}></img>
                                <div className="fsize-12 text-uppercase">Sous sous thémes</div>
                            </Link>
                        </div>


                    </div>

                    <div className="mb-3">
                        <Link onClick={openMenuMobile} className="r-flexed item-mobile mobile-title column-gap-10"
                              to="/publications">
                            <img alt=""
                                 className={(getCurrentPage('publications') ? 'activeImg' : '') + ' menu-img-mobile'}
                                 src="/assets/images/imgs/publication.png"/>
                            <div
                                className={(getCurrentPage('publications') ? 'activeTitle' : '') + ' mobile-title text-uppercase'}>Publications
                            </div>
                        </Link>
                    </div>

                    <div className="mb-3">
                        <div className="r-flexed item-mobile column-gap-10 mobile-title" to="">
                            <img alt="" className="menu-img-mobile"
                                 src="/assets/images/imgs/ccm.png"/>
                            <div className="text-uppercase mobile-title">Comment ça marche</div>
                        </div>
                    </div>

                    <div className="mb-3 submenu-mobile">
                    <span className="r-flexed item-mobile cursor-pointer mobile-title column-gap-10" target="_self">
                        <img alt=""
                             className={(getCurrentPage('/outilsadmin/') ? 'activeImg' : '') + ' menu-img-mobile'}
                             src="/assets/images/imgs/outiladmin.png"/>
                        <div
                            className={(getCurrentPage('/outilsadmin/') ? 'activeTitle' : '') + ' mobile-title text-uppercase'}>Outils Admin</div>
                    </span>

                        <div className="items-menu">
                            <Link onClick={openMenuMobile} className="r-flexed item-mobile column-gap-10 mb-1"
                                  to="/outilsadmin/utilisateurs">
                                <img alt="" className="menu-img-mobile"
                                     src="/assets/images/imgs/usersgroup1.png" style={{width: "25px"}}/>
                                <div className="fsize-12 text-uppercase">Utilisateurs</div>
                            </Link>

                            <Link onClick={openMenuMobile} className="r-flexed item-mobile column-gap-10 mb-1"
                                  to="/outilsadmin/profilsperms">
                                <img alt="" className="menu-img-mobile"
                                     src="/assets/images/imgs/profil_permissions1.svg" style={{width: "25px"}}/>
                                <div className="fsize-12 text-uppercase">Profils et Permissions</div>
                            </Link>

                        </div>

                    </div>
                </div>}
            </div>

        </div>)
}

export {MenuMobile, myMobileMenu, idMobileMenu};