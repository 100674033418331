import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db, selectedBase } from "../../core/Firebase/firebase";
import {
  useDocs,
  useTotalSize,
  useWhereDocs_1,
} from "../../core/Firebase/FirebaseHooks";
import { canModule, customDateFormat } from "../../core/js/backoffice";
import {
  closeModalAdd,
  selectShowModal,
  showModalAdd,
} from "../../core/Redux/features/user";
import Filtres from "../../partials/FrequentlyUsed/Filtres";
import TitleBtnAdd from "../../partials/FrequentlyUsed/TitleBtnAdd";
import {
  deleteData,
  fetchForeignData,
} from "../../core/Firebase/services/FirebaseService";
import Paginations from "../../partials/FrequentlyUsed/Paginations";
import { Modals } from "../../partials/modals/Modals";
import { ModalBodySousSousThemes } from "./modals/ModalSousSousTheme";
import { doc } from "firebase/firestore";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { SpeedDial, Tooltip } from "primereact";
import algoliaSearch from "algoliasearch";
import CardSousSousTheme from "../../partials/FrequentlyUsed/CardSousSousTheme";

export default function SousSousThemes() {
  const dispatch = useDispatch();
  const mounted = useRef();
  const speedRef = useRef([]);
  const navigate = useNavigate();
  const show = useSelector(selectShowModal);
  const [isAdd, setIsAdd] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(0);
  const [isPaginated, setIsPaginated] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState(undefined);
  const [dataType] = useState("sous_sous_themes");
  const [count, setCount] = useState(12);
  const [foreignMap, setForeignMap] = useState({});
  const client = algoliaSearch(
    "UXPOT4CE0L",
    "5510ca81827f20f0b8b8fdc155b3853e"
  );
  const index = client.initIndex("sous_sous_themes_" + selectedBase);

  const { numberOfData: totalSize } = useTotalSize({
    db: db,
    collectionName: dataType,
  });
  var { data: tabSousSousThemes, numberOfData: totalItems } = useDocs({
    db: db,
    converterName: dataType,
    sort: "desc",
    count: count,
    whereToOrderBy: "createdAt",
    isPaginated: isPaginated,
    page: nextPage,
  });

  const { data: sousThemes } = useDocs({
    db: db,
    converterName: "sous_themes",
    sort: "desc",
  });

  let langueToFilter = ".fr_FR";
  const mapFiltersTab = [
    { isDate: true, name: "Date Début", key: "createdAt" },
    { isDate: true, name: "Date Fin", key: "createdAtEnd" },
    {
      isDropdown: true,
      key: "sousTheme",
      name: "Sous Thème",
      placeholder: "Choisir Sous Thème",
      tabDropdown: sousThemes,
    },
    {
      isField: true,
      name: "Mots clés, titres, désignations...",
      key: "motcles",
    },
    //{isField: true, name: "Description", key: "description"+langueToFilter}
  ];
  const [tabValues, setTabValues] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);

  // var {data: tabValues} = useWhereDocs_1({
  //     db: db,
  //     collectionName: dataType,
  //     mapValues: mapValues
  // });

  useEffect(() => {
    if (!mounted.current) {
      //did mounted
      document.title = "Sous Sous Thèmes";
      mounted.current = true;
    } else {
      //did update
    }
  });

  /*    useEffect(() => {
           if(itemToUpdate !== undefined && isFiltering) {
               let c = tabSousSousThemes.filter((i)=> i['id'] === itemToUpdate['id']);
               let cc = tabValues.findIndex((i)=> i['id'] === itemToUpdate['id']);
               if(c.length > 0 && cc !== -1){
                   tabValues[cc] = c[0];
                   setTabValues([...tabValues]);
               }

           }

       },[tabSousSousThemes]) */

  const execFiltre = (newMapValues, tabSousThemes) => {
    const pubFiltrer = [];
    console.log(newMapValues);

    tabSousThemes.forEach((pub) => {
      let test = false;
      //dateDeb & dateFin
      if (
        newMapValues.createdAt !== undefined ||
        newMapValues.createdAtEnd !== undefined
      ) {
        if (
          newMapValues.createdAt !== undefined &&
          newMapValues.createdAtEnd !== undefined
        ) {
          if (
            Date.parse(pub.date) >= newMapValues.createdAt &&
            Date.parse(pub.date) <= newMapValues.createdAtEnd
          ) {
            test = true;
          }
        } else {
          if (
            Date.parse(pub.date) >= newMapValues.createdAt &&
            newMapValues.createdAt !== undefined
          ) {
            test = true;
          }
          if (Date.parse(pub.date) <= newMapValues.createdAtEnd) {
            test = true;
          }
        }
      } else {
        test = true;
      }

      if (newMapValues["disabled"] === "Oui") {
        if (pub.disabled !== false) {
          test = false;
        }
      } else if (newMapValues["disabled"] === "Non") {
        if (pub.disabled !== true) {
          test = false;
        }
      }

      if (newMapValues["type"] !== undefined) {
        if (pub.type !== newMapValues["type"]) {
          test = false;
        }
      }

      if (newMapValues["sousTheme"] !== undefined) {
        console.log(pub);

        if (pub.sousThemeId !== newMapValues["sousTheme"]) {
          test = false;
        }
      }

      if (test) {
        pubFiltrer.push(pub);
      }
    });

    setIsFiltering(true);
    setTabValues(pubFiltrer);
  };

  const handleFilter = (mapValues) => {
    let newMapValues = Object.fromEntries(Object.entries(mapValues));

    if (newMapValues.motcles !== undefined && newMapValues.motcles !== "") {
      console.log("enter", newMapValues, selectedBase);

      index.search(newMapValues.motcles).then(({ hits }) => {
        if (hits.length > 0) {
          let stock = [];
          hits.forEach((el) => {
            tabSousSousThemes.forEach((pub) => {
              if (el.id === pub.id) {
                stock.push(pub);
              }
            });
          });
          execFiltre(newMapValues, stock);
        }
      });
    } else {
      console.log("outed");
      execFiltre(newMapValues, tabSousSousThemes);
    }
  };

  const cancelFilter = () => {
    /* setTabValues([]); */
    setIsFiltering(false);
  };

  const handleSelectChange = (value) => {
    setIsPaginated(false);
    setCurrentPage(1);
    if (value > 0) {
      setCount(value);
    } else if (value === "0") {
      setCount(10);
    }
  };
  const handlePageChange = (pageNumber) => {
    if (totalSize / count >= 1) {
      setCurrentPage(pageNumber);
      setIsPaginated(true);
      setNextPage((pageNumber - 1) * totalItems + 1);
    }
  };

  const redirectToDetail = (value) => {
    if (value && value["id"]) {
      navigate("/backoffice/soussousthemes/detailSousSousTheme/" + value["id"]);
    }
  };
  useEffect(() => {
    setForeignMap({});
  }, [tabSousSousThemes]);
  return (
    canModule(["list-sous-sous-theme"]) && (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <TitleBtnAdd
          showBtn={canModule(["create-sous-sous-theme"])}
          marginTop="mt-20"
          icon="/assets/images/imgs/sous_sous_theme.png"
          btnClick={() => {
            setIsAdd(true);
            setItemToUpdate(undefined);
            //setIsPaginated(false);
            dispatch(showModalAdd());
          }}
          itemsName="Sous Sous Thémes"
          count={isFiltering ? tabValues.length : totalSize}
        />

        <Filtres
          cancelFilter={cancelFilter}
          tabFilters={mapFiltersTab}
          handleSubmit={handleFilter}
          marginTop="mt-30"
        />

{/* OLD table */}
        <section className="mt-30 d-none">
          <div className="card-table pt-20 pb-20 pl-20 pr-20">
            <table className="table basic-table table-borderless table-striped table-responsive table-hover">
              <thead className="thead-table">
                <tr>
                  <th>
                    Date
                    {/*   <Rb.OverlayTrigger placement='right' overlay={renderTooltip}>
                                        <span><img onClick={this.sort.bind(this)}
                                                   className="wdpx-8 cursor-pointer filter-svg-white" alt=""
                                                   src={"/assets/images/ico/ic_" + (this.state.sort ? "up" : "down") + ".png"}/></span>
                                    </Rb.OverlayTrigger> */}
                  </th>
                  <th>Sous Théme</th>
                  <th>Désignation</th>
                  <th>Description</th>
                  <th>Image</th>
                  <th>Ordre</th>
                  {canModule([
                    "detail-sous-sous-theme",
                    "update-sous-sous-theme",
                    "delete-sous-sous-theme",
                  ]) && (
                    <th>
                      <div className="d-flex jc-center">
                        <img
                          className=""
                          alt=""
                          src="/assets/images/ico/ic_settings.png"
                        />
                      </div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {!isFiltering &&
                  tabSousSousThemes.map((item) => {
                    return newFunction(item);
                  })}
                {isFiltering &&
                  tabValues.map((item) => {
                    //console.log(item.date.toString());
                    return newFunction(item);
                  })}
              </tbody>
            </table>
          </div>
        </section>


        <section className="mt-30">
          <div className="row">
            {!isFiltering &&
              tabSousSousThemes.map((item) => {
                return newFunction(item);
              })}
            {isFiltering &&
              tabValues.map((item) => {
                //console.log(item.date.toString());
                return newFunction(item);
              })}
          </div>
        </section>
{/* New table */}
        <section className="mt-30">
          {!isFiltering && (
            <Paginations
              activePage={currentPage}
              totalItemsCount={totalSize}
              itemsCountPerPage={totalItems}
              handleSelectChange={(event) => {
                handleSelectChange(event.target.value);
              }}
              handlePageChange={handlePageChange}
            />
          )}
        </section>

        <Modals
          modalTitle="Sous Sous Théme"
          titleIcon="/assets/images/imgs/sous_theme.png"
          modalBody={
            <ModalBodySousSousThemes
              isAdd={isAdd} /* langues={langues} */
              itemToUpdate={itemToUpdate}
            />
          }
          show={show}
          handleClose={() => {
            dispatch(closeModalAdd());
          }}
        />
      </motion.div>
    )
  );

  function newFunction(item) {
    fetchForeignData({ reference: item.sousTheme, field: "designation" }).then(
      (value) => {
        if (!foreignMap[item.id]) {
          //console.log("dougue na fi");
          setForeignMap({ ...foreignMap, [item.id]: value });
        }
      }
    );
    return (

      <div
        key={item.id}
        className="mb-30 col-12 col-sm-6 col-md-6 col-lg-3 col-users"
      >
        <CardSousSousTheme
          item={item} getMenuItem={getMenuItem} theme={foreignMap[item.id] ?? ""}
        />
      </div>

      // <tr key={item.id}>
      //   <td>{customDateFormat({ date: item.date })}</td>
      //   <td>{foreignMap[item.id]}</td>
      //   <td>{item.designation}</td>
      //   <td>{item.description}</td>
      //   <td>
      //     <span className="jc-center avatar avatar-online box-shadow-1 hpx-45 wdpx-45">
      //       <img
      //         alt=""
      //         className="wdpx-45 hpx-45"
      //         src={item?.fileUrl ?? "/assets/images/imgs/upload.png"}
      //       />
      //     </span>
      //   </td>
      //   <td>{item.ordre}</td>
      //   {canModule([
      //     "detail-sous-sous-theme",
      //     "update-sous-sous-theme",
      //     "delete-sous-sous-theme",
      //   ]) && (
      //     <td>
      //       <div className="d-flex jc-center">
      //         <SpeedDial
      //           direction="left"
      //           showIcon="pi pi-bars"
      //           hideIcon="pi pi-times"
      //           buttonClassName="wdpx-35 hpx-35 p-button-outlined p-button-success align-self-center"
      //           buttonStyle={{ border: "1px solid #337f77" }}
      //           id={item.id}
      //           ref={(el) => (speedRef.current[item.id] = el)}
      //           style={{ position: "relative" }}
      //           // className={speedRef.current[item.id]?.props?.id === item.id ? "myCustomHamburger1" : "myCustomHamburger"}
      //           className={"myCustomHamburger1"}
      //           model={getMenuItem({ data: item })}
      //         />
      //       </div>
      //     </td>
      //   )}
      // </tr>
    );
  }

  function getMenuItem({ data }) {
    let result = [];
    let items = [
      {
        id: 0,
        label: "Détails",
        icon: "pi pi-info",
        command: () => {
          redirectToDetail(data);
        },
        template: (u, options) => {
          return (
            <div
              data-pr-tooltip={u.label}
              className={
                options.className +
                " bg-primary wdpx-35 hpx-35 cursor-pointer myTooltip0"
              }
              onClick={options.onClick}
            >
              <span className={u.icon + " text-white"}></span>
              <Tooltip
                position="bottom"
                className="fsize-12"
                target=".myTooltip0"
              />
            </div>
          );
        },
      },
      {
        id: 1,
        label: "Modifier",
        icon: "pi pi-pencil",
        command: () => {
          setIsAdd(false);
          setItemToUpdate(data);
          setIsPaginated(false);
          dispatch(showModalAdd());
        },
        template: (u, options) => {
          return (
            <div
              data-pr-tooltip={u.label}
              className={
                options.className +
                " bg-secondary wdpx-35 hpx-35 cursor-pointer myTooltip1"
              }
              onClick={options.onClick}
            >
              <span className={u.icon + " text-white"}></span>
              <Tooltip
                position="bottom"
                className="fsize-12"
                target=".myTooltip1"
              />
            </div>
          );
        },
      },
      {
        id: 2,
        label: "Supprimer",
        icon: "pi pi-trash",
        command: () => {
          deleteData({ dataType: "sous_sous_themes", id: data.id });
        },
        template: (u, options) => {
          return (
            <div
              data-pr-tooltip={u.label}
              className={
                options.className +
                " bg-danger wdpx-35 hpx-35 cursor-pointer myTooltip3"
              }
              onClick={options.onClick}
            >
              <span className={u.icon + " text-white"}></span>
              <Tooltip
                position="bottom"
                className="fsize-12"
                target=".myTooltip3"
              />
            </div>
          );
        },
      },
    ];
    if (canModule(["detail-sous-sous-theme"])) {
      result.push(items.filter((i) => i.id === 0)[0]);
    }
    if (canModule(["update-sous-sous-theme"])) {
      result.push(items.filter((i) => i.id === 1)[0]);
    }
    if (canModule(["delete-sous-sous-theme"])) {
      result.push(items.filter((i) => i.id === 2)[0]);
    }
    return result;
  }
}
