import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Rb from "react-bootstrap";
import { ThemeModel } from "../../../core/models/Theme-Model";
import {
  saveData,
  saveDataToDocsAndStorage,
} from "../../../core/Firebase/services/FirebaseService";
import { CustomAlert, customToast } from "../../../core/js/backoffice";
import {
  dirInput,
  mapClassNameToLangue,
  mapLanguesToFlag,
  mapOriginalLanguesToName,
  mapTranslate,
} from "../../../core/localization/map_langues";
import {
  blockUIAction,
  closeModalAdd,
} from "../../../core/Redux/features/user";
import { useDocs, useWhereDocs } from "../../../core/Firebase/FirebaseHooks";
import { db } from "../../../core/Firebase/firebase";
import { motion } from "framer-motion";
import ArabicInput from "../../../partials/FrequentlyUsed/ArabicInput";
import { selectedBase } from "../../../core/Firebase/firebase";

export const ModalBody = (props) => {
  const dispatch = useDispatch();
  const degRef = useRef();
  const descriptionRef = useRef();
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [isErase, setIsErase] = useState(false);
  const [id, setId] = useState();
  const [isUpdate] = useState(props.isAdd === false);
  const [itemToUpdate] = useState(props.itemToUpdate);
  const [langues, setLangues] = useState([]);
  //const [keywords, setKeywords] = useState("");
  const [degMap, setDegMap] = useState({});
  const [descMap, setDescMap] = useState({});
  const [keywordMap, setKeywordMap] = useState({});
  const [layoutName, setLayoutName] = useState("default");

  const [selectedLng, setSelectedLng] = useState();
  const [showArabicInput, setShowArabicInput] = useState(false);
  const [showArabicInput1, setShowArabicInput1] = useState(false);
  const [showArabicInput2, setShowArabicInput2] = useState(false);
  const [ordreMap, setOrdre] = useState({});
  const { data } = useWhereDocs({
    db: db,
    collectionName: "preferences",
    whereToLookInTheCollection: "isPrefs",
    whereToLookValue: true,
  });
  const { data: dataLng, dataLoading } = useDocs({
    db: db,
    collectionName:
      "preferences_" + selectedBase + "/" + data[0]?.id + "/langues",
    whereToOrderBy: "id",
  });

  useEffect(() => {
    dispatch(blockUIAction(dataLoading));
    if (isUpdate === true && itemToUpdate) {
      setId(itemToUpdate.id);
      setDegMap(itemToUpdate.designationMap);
      setDescMap(itemToUpdate.descriptionMap);
      setOrdre(itemToUpdate.ordreMap);
      setKeywordMap(itemToUpdate.keywords);
      setFileUrl(itemToUpdate.fileUrl);
    }
    console.log(ordreMap);
  }, [isUpdate, itemToUpdate, dataLoading]);

  useEffect(() => {
    if (dataLng.length > 0) {
      let aaa = Object.keys(dataLng[0].value ?? {});
      if (isUpdate !== true) {
        aaa.forEach((key) => {
          //degMap[key] = "";
          descMap[key] = "";
          ordreMap[key] = "";
          setDegMap(degMap);
          setOrdre(ordreMap);
          setDescMap(descMap);
        });
      }
      let n = aaa.filter((u) => u.indexOf("fr_FR") !== -1);
      if (n.length === 1) {
        setSelectedLng(n[0]);
      } else {
        setSelectedLng(aaa[0]);
      }
      setLangues(aaa);
    }
  }, [dataLng, isUpdate]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === true) {
      if (
        Object.keys(degMap).length < langues.length &&
        Object.values(degMap).every((u) => u !== "")
      ) {
        customToast({
          message:
            "Désignation Obligatoire pour les " + langues.length + " langues",
          type: "error",
        });
      } else {
        let tabKeywords = Object.fromEntries(Object.entries(keywordMap));

        Object.keys(tabKeywords).forEach((key) => {
          tabKeywords[key] = convertStringToMap({ value: tabKeywords[key] });
        });

        // const theme = new ThemeModel({
        //   id: id,
        //   designation: degMap,
        //   description: descMap,
        //   motcles: descMap,
        //   keywords: tabKeywords,
        //   eraseImg: isErase,
        //   ordre: ordre,
        // });

        let theme = {
          id: id,
          designation: degMap,
          description: descMap,
          motcles: descMap,
          // keywords: tabKeywords,
          eraseImg: isErase,
          ordre: ordreMap,
        };

        //Ajouter les titres et les descriptions dans les keywords

        Object.keys(tabKeywords).forEach((key) => {
          if (degMap[key] && !tabKeywords[key].includes(degMap[key].trim())) {
            tabKeywords[key].push(degMap[key]);
          }

          if (descMap[key] && !tabKeywords[key].includes(descMap[key].trim())) {
            tabKeywords[key].push(descMap[key]);
          }
        });

        theme = { ...theme, ["keywords"]: tabKeywords };

        let themeModel = new ThemeModel(theme);

        saveDataToDocsAndStorage({
          dataType: "themes",
          // jsonData: theme,
          jsonData: themeModel,
          file: file,
        });
      }
    }
    setValidated(true);

    function convertStringToMap({ value }) {
      let keywords = String(value);
      let tabKeywords = [];
      let tmpKeywords = keywords?.trim().split(";");
      tabKeywords = tmpKeywords.map((u) => {
        return u.trim();
      });
      tabKeywords = tabKeywords.filter((u) => u.trim().length > 0);
      return tabKeywords;
    }
  };

  const closeKeyBoardArabic = () => {
    setShowArabicInput(false);
  };
  const closeKeyBoardArabic1 = () => {
    setShowArabicInput1(false);
  };
  const closeKeyBoardArabic2 = () => {
    setShowArabicInput2(false);
  };

  const handleFileChange = (event) => {
    //console.log(event.target.files);
    let filesTab = event.target.files;
    if (filesTab.length > 0) {
      setFile(filesTab[0]);
      setIsErase(false);
      setFileUrl(URL.createObjectURL(event.target.files[0]));
    }
  };
  const eraseImage = () => {
    if (isUpdate === true) {
      if (itemToUpdate.fileUrl) {
        setIsErase(true);
      }
    }
    setFileUrl(undefined);
  };

  return (
    <div className="container-fluid">
      <div className="d-flex mt-20">
        <div className="c-flexed m-auto">
          <label className="cursor-pointer">
            <input type="file" accept="image/*" onChange={handleFileChange} />
            {!fileUrl && (
              <div className="doted-circle cursor-pointer">
                <img alt="" src="/assets/images/ico/upload-pub.svg" />
                <span className="text-indic text-center">
                  Ajouter
                  <br />
                  votre image ici
                </span>
              </div>
            )}

            {fileUrl && (
              <span className="avatar avatar-online d-flex box-shadow-1 hpx-130 wdpx-130">
                <img alt="" className="m-auto hpx-130 wdpx-130" src={fileUrl} />
              </span>
            )}
          </label>
          {fileUrl && (
            <div className="d-flex">
              <button
                style={{ padding: "5px 10px" }}
                onClick={eraseImage}
                className="btn btn-danger m-auto"
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          )}
        </div>
      </div>

      <Rb.Form
        className="mt-30"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Rb.Row>
          {/*  <Rb.Col xs={12}>
                        <Rb.Form.Group className="form-group" controlId="prenom">
                            <Rb.Form.Label className="textClassInit">Mots Clés</Rb.Form.Label>
                            <Rb.Form.Control as="textarea" onKeyDown={(key) => {
                                if (key.code === "Enter") {
                                    key.preventDefault();
                                    if (keywords.trim().length > 0) {
                                        setKeywords(keywords + " ; ")
                                    }

                                }
                            }} value={keywords} onChange={(e) => setKeywords(e.target.value)}
                                             className="modal-form" type="text"
                                             placeholder="Entrer les mots clés séparés par des points virgules ( ; )"/>
                            {keywords.trim().length > 0 &&
                            <Rb.Form.Control.Feedback className="d-flex fsize-10" type="invalid">Appuyer sur Entrée pour
                                des points virgules ( ; ) automatiques</Rb.Form.Control.Feedback>}
                        </Rb.Form.Group>
                    </Rb.Col> */}
          {selectedLng && (
            <Rb.Col xs={6}>
              <Rb.Form.Group className="form-group" controlId="titre">
                <Rb.Form.Label
                  style={{
                    display: "flex",
                    flexDirection:
                      mapClassNameToLangue["flexDirection"][selectedLng],
                  }}
                  className={
                    "textClassInit column-gap-10 " +
                    mapClassNameToLangue["float"][selectedLng]
                  }
                >
                  {mapTranslate["nomTheme"][selectedLng]}
                  <span className="text-danger">*</span>
                </Rb.Form.Label>

                <Rb.InputGroup>
                  <Rb.Form.Control
                    ref={degRef}
                    dir={dirInput[selectedLng]}
                    value={degMap[selectedLng] ?? ""}
                    placeholder={mapTranslate["placeholderTheme"][selectedLng]}
                    autoComplete="off"
                    onKeyDown={(c) => {
                      if (selectedLng.indexOf("ar_AE") !== -1) {
                        //console.log(c.key);
                        if (c.key === "Backspace") {
                          const value = String(degMap[selectedLng]).slice(
                            0,
                            -1
                          );
                          setDegMap({ ...degMap, [selectedLng]: value });
                        }
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setDegMap({ ...degMap, [selectedLng]: value });
                      // if (selectedLng.indexOf("ar_AE") === -1) {
                      //   setDegMap({ ...degMap, [selectedLng]: value });
                      // }
                    }}
                    onBlur={() => {
                      if (selectedLng.indexOf("en_US") !== -1) {
                        setDegMap({
                          ...degMap,
                          ["en_US"]: degMap[selectedLng],
                        });
                      } else if (selectedLng.indexOf("ar_AE") !== -1) {
                        setDegMap({
                          ...degMap,
                          ["ar_AE"]: degMap[selectedLng],
                        });
                      } else {
                        setDegMap({
                          ...degMap,
                          ["en_US"]:
                            degMap["en_US"] == null || degMap["en_US"] == ""
                              ? degMap[selectedLng]
                              : degMap["en_US"],
                          ["ar_AE"]:
                            degMap["ar_AE"] == null || degMap["ar_AE"] == ""
                              ? degMap[selectedLng]
                              : degMap["ar_AE"],
                          ["fr_FR"]:
                            degMap["fr_FR"] == null || degMap["fr_FR"] == ""
                              ? degMap[selectedLng]
                              : degMap["fr_FR"],
                        });
                      }
                    }}
                    required
                    className="modal-form"
                    type="text"
                  />
                  {/* {selectedLng.indexOf("ar_AE") !== -1 && (
                    <Rb.InputGroup.Text
                      onClick={() => {
                        setShowArabicInput(true);
                      }}
                      className="cursor-pointer"
                      title="Ouvrir le clavier"
                      id="basic-addon1"
                    >
                      <i className="fa fa-keyboard"></i>
                    </Rb.InputGroup.Text>
                  )} */}
                </Rb.InputGroup>
              </Rb.Form.Group>
            </Rb.Col>
          )}
          {selectedLng && (
            <Rb.Col xs={6}>
              <Rb.Form.Group className="form-group" controlId="titre">
                <Rb.Form.Label
                  style={{
                    display: "flex",
                    flexDirection:
                      mapClassNameToLangue["flexDirection"][selectedLng],
                  }}
                  className={
                    "textClassInit column-gap-10 " +
                    mapClassNameToLangue["float"][selectedLng]
                  }
                >
                  {mapTranslate["ordreTheme"][selectedLng]}
                  <span className="text-danger">*</span>
                </Rb.Form.Label>

                <Rb.InputGroup>
                  <Rb.Form.Control
                    autoComplete="off"
                    value={typeof ordreMap !== "object" ? ordreMap : ""}
                    placeholder={mapTranslate["ordreplaceholder"][selectedLng]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setOrdre(value);
                    }}
                    required
                    className="modal-form"
                    type="text"
                  />
                  {/* {selectedLng.indexOf("ar_AE") !== -1 && (
                    <Rb.InputGroup.Text
                      onClick={() => {
                        setShowArabicInput(true);
                      }}
                      className="cursor-pointer"
                      title="Ouvrir le clavier"
                      id="basic-addon1"
                    >
                      <i className="fa fa-keyboard"></i>
                    </Rb.InputGroup.Text>
                  )} */}
                </Rb.InputGroup>
              </Rb.Form.Group>
            </Rb.Col>
          )}
          <div
            className="w-100 mb-20 mt-10"
            style={{ borderTop: "1px solid black" }}
          ></div>
          <Rb.Stack className="m-auto" gap={4} direction="horizontal">
            {langues
              .sort()
              .reverse()
              .map((item, index) => {
                return (
                  <div key={index}>
                    <motion.button
                      onClick={() => {
                        setSelectedLng(item);
                      }}
                      style={{
                        color: selectedLng === item ? "white" : "#337F77",
                        background:
                          selectedLng === item
                            ? "#337F77"
                            : "rgba(51, 127, 119, 0.3)",
                      }}
                      type="button"
                      className="btn btn-lng"
                      whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.5 },
                      }}
                      whileTap={{ scale: 0.9 }}
                    >
                      {mapOriginalLanguesToName[item]}{" "}
                      <i className={mapLanguesToFlag[item]}></i>
                    </motion.button>
                  </div>
                );
              })}
          </Rb.Stack>
          {selectedLng && (
            <Rb.Col xs={12}>
              <Rb.Form.Group className="form-group" controlId="description">
                <Rb.Form.Label
                  style={{
                    display: "flex",
                    flexDirection:
                      mapClassNameToLangue["flexDirection"][selectedLng],
                  }}
                  className={
                    "textClassInit column-gap-10 " +
                    mapClassNameToLangue["float"][selectedLng]
                  }
                >
                  {mapTranslate["description"][selectedLng]}
                </Rb.Form.Label>
                <Rb.InputGroup>
                  <Rb.Form.Control
                    ref={descriptionRef}
                    as="textarea"
                    rows={3}
                    dir={dirInput[selectedLng]}
                    value={descMap[selectedLng] ?? ""}
                    placeholder={mapTranslate["description"][selectedLng]}
                    autoComplete="off"
                    onChange={(e) => {
                      const value = e.target.value;
                      setDescMap({ ...descMap, [selectedLng]: value });

                      // if (selectedLng.indexOf("ar_AE") === -1) {
                      //   setDescMap({ ...descMap, [selectedLng]: value });
                      // }
                    }}
                    onBlur={() => {
                      if (selectedLng.indexOf("en_US") !== -1) {
                        setDescMap({
                          ...descMap,
                          ["en_US"]: descMap[selectedLng],
                        });
                      } else if (selectedLng.indexOf("ar_AE") !== -1) {
                        setDescMap({
                          ...descMap,
                          ["ar_AE"]: descMap[selectedLng],
                        });
                      } else {
                        setDescMap({
                          ...descMap,
                          ["en_US"]:
                            descMap["en_US"] == null || descMap["en_US"] == ""
                              ? descMap[selectedLng]
                              : descMap["en_US"],
                          ["ar_AE"]:
                            descMap["ar_AE"] == null || descMap["ar_AE"] == ""
                              ? descMap[selectedLng]
                              : descMap["ar_AE"],
                          ["fr_FR"]:
                            descMap["fr_FR"] == null || descMap["fr_FR"] == ""
                              ? descMap[selectedLng]
                              : descMap["fr_FR"],
                        });
                      }
                    }}
                    className="modal-form"
                    type="text"
                  />
                  {/* {selectedLng.indexOf("ar_AE") !== -1 && (
                    <Rb.InputGroup.Text
                      onClick={() => {
                        setShowArabicInput1(true);
                      }}
                      className="cursor-pointer"
                      title="Ouvrir le clavier"
                      id="basic-addon1"
                    >
                      <i className="fa fa-keyboard"></i>
                    </Rb.InputGroup.Text>
                  )} */}
                </Rb.InputGroup>
              </Rb.Form.Group>
            </Rb.Col>
          )}
          {selectedLng && (
            <Rb.Col xs={12}>
              <Rb.Form.Group className="form-group" controlId="motclei">
                <Rb.Form.Label
                  style={{
                    display: "flex",
                    flexDirection:
                      mapClassNameToLangue["flexDirection"][selectedLng],
                  }}
                  className={
                    "textClassInit column-gap-10 " +
                    mapClassNameToLangue["float"][selectedLng]
                  }
                >
                  {mapTranslate["keywordLabel"][selectedLng]}
                </Rb.Form.Label>
                <Rb.InputGroup>
                  <Rb.Form.Control
                    // ref={descriptionRef}
                    as="textarea"
                    rows={3}
                    dir={dirInput[selectedLng]}
                    value={keywordMap[selectedLng] ?? ""}
                    placeholder={mapTranslate["keyword"][selectedLng]}
                    autoComplete="off"
                    onKeyDown={(key) => {
                      if (key.code === "Enter") {
                        key.preventDefault();
                        if (keywordMap[selectedLng]?.trim().length > 0) {
                          setKeywordMap({
                            ...keywordMap,
                            [selectedLng]: keywordMap[selectedLng] + " ; ",
                          });
                        }
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      console.log(value);

                      setKeywordMap({
                        ...keywordMap,
                        [selectedLng]: value,
                      });

                    }}
                    onBlur={() => {
                      if (selectedLng.indexOf("en_US") !== -1) {
                        setKeywordMap({
                          ...keywordMap,
                          ["en_US"]: keywordMap[selectedLng],
                        });
                      } else if (selectedLng.indexOf("ar_AE") !== -1) {
                        setKeywordMap({
                          ...keywordMap,
                          ["ar_AE"]: keywordMap[selectedLng],
                        });
                      } else {
                        setKeywordMap({
                          ...keywordMap,
                          ["en_US"]:
                            keywordMap["en_US"] == null ||
                            keywordMap["en_US"] == ""
                              ? keywordMap[selectedLng]
                              : keywordMap["en_US"],
                          ["ar_AE"]:
                            keywordMap["ar_AE"] == null ||
                            keywordMap["ar_AE"] == ""
                              ? keywordMap[selectedLng]
                              : keywordMap["ar_AE"],
                          ["fr_FR"]:
                            keywordMap["fr_FR"] == null ||
                            keywordMap["fr_FR"] == ""
                              ? keywordMap[selectedLng]
                              : keywordMap["fr_FR"],
                        });
                      }
                    }}
                    className="modal-form"
                    type="text"
                  />
                  {/* {selectedLng.indexOf("ar_AE") !== -1 && (
                    <Rb.InputGroup.Text
                      onClick={() => {
                        setShowArabicInput2(true);
                      }}
                      className="cursor-pointer"
                      title="Ouvrir le clavier"
                      id="basic-addon1"
                    >
                      <i className="fa fa-keyboard"></i>
                    </Rb.InputGroup.Text>
                  )} */}
                </Rb.InputGroup>
                {selectedLng.indexOf("ar_AE") === -1 &&
                  keywordMap[selectedLng]?.trim().length > 0 && (
                    <Rb.Form.Control.Feedback
                      className="d-flex fsize-10"
                      type="valid"
                    >
                      {mapTranslate["semiColons"][selectedLng]}
                    </Rb.Form.Control.Feedback>
                  )}
              </Rb.Form.Group>
            </Rb.Col>
          )}
        </Rb.Row>

        <div className="float-right r-flexed column-gap-20 mb-20 mt-20">
          <Rb.Button
            onClick={() => {
              let msg =
                "Si vous fermez le modal, vous perderez les données déjà enregistrées !!!";
              CustomAlert({
                message: msg,
                yesBtn: () => {
                  dispatch(closeModalAdd());
                },
              });
            }}
            bsPrefix="btn btn-cancel"
          >
            Annuler
          </Rb.Button>
          <Rb.Button type="submit" bsPrefix="btn btn-save">
            Enregistrer
          </Rb.Button>
        </div>
      </Rb.Form>

      {selectedLng?.indexOf("ar_AE") !== -1 && (
        <ArabicInput
          title={"La désignation en arabe"}
          value={selectedLng?.indexOf("ar_AE") !== -1 ? degMap["ar_AE"] : ""}
          onChange={(v) => {
            setDegMap({ ...degMap, [selectedLng]: v });
          }}
          show={showArabicInput}
          handleClose={closeKeyBoardArabic}
        />
      )}
      {selectedLng?.indexOf("ar_AE") !== -1 && (
        <ArabicInput
          title={"La description en arabe"}
          value={selectedLng?.indexOf("ar_AE") !== -1 ? descMap["ar_AE"] : ""}
          onChange={(v) => {
            setDescMap({ ...descMap, [selectedLng]: v });
          }}
          show={showArabicInput1}
          handleClose={closeKeyBoardArabic1}
        />
      )}

      {selectedLng?.indexOf("ar_AE") !== -1 && (
        <ArabicInput
          title={"Les mots clés en arabe"}
          value={
            selectedLng?.indexOf("ar_AE") !== -1 ? keywordMap["ar_AE"] : ""
          }
          onChange={(v) => {
            setKeywordMap({ ...keywordMap, [selectedLng]: v });
          }}
          show={showArabicInput2}
          isForKeyword={true}
          handleClose={closeKeyBoardArabic2}
        />
      )}
    </div>
  );
};
