import { doc} from "firebase/firestore";
import { db } from "../Firebase/firebase";

export class ProfilModel {
    constructor({id, nomProfil, permissions = [],usedCount, permissionsModal=[],createdAt,createdAtString,date}) {
        this.id = id ;
        this.nomProfil = nomProfil;
        this.permissions = permissions;
        this.usedCount = usedCount;
        this.permissionsModal = permissionsModal;
        this.date = date;
        this.createdAt = createdAt;
        this.createdAtString = createdAtString;
    }

    toString(){
        return this.nomProfil + ' , ' +  this.permissions ;
    }

    toJson(){
        let hh = [] ;
        const nameCollection = "permissions_" + selectedBase;
        this.permissions.forEach(element => {
            let n = this.permissionsModal.find((o)=>(o['designation'] !== undefined && o['designation'] === element));
            if(n){
                hh.push(doc(db,nameCollection,n.id))
            }
        });
        //console.log(hh);
        let obj = {
            id: this.id,
            nomProfil: this.nomProfil,
            permissions: hh,
            permissionsModal:this.permissions,
            usedCount: this.usedCount,
            createdAt: this.createdAt,
            createdAString: this.createdAtString

        }
       //obj = {...obj,permissionsModal:undefined}
        Object.keys(obj).forEach(key => {
            if (obj[key] === undefined) {
                delete obj[key];
              }
        })
        //console.log(obj);
        return obj;
        
    }
}

const selectedBase = process.env.REACT_APP_SELECTED_BASE ;

/* export const mapObjectToProfil = {
    toFirestore: (profil)=>{
        let hh = [] ;
        const nameCollection = "permissions_" + selectedBase;
        profil.permissions.forEach(element => {
            let n = profil.permissionsModal.find((o)=>(o['designation'] !== undefined && o['designation'] === element));
            if(n){
                console.log(n.id);
                hh.push(doc(db,nameCollection,n.id))
            }
        });
        //console.log(hh);
        var objToReturn = {
            nomProfil:profil.nomProfil,
            permissions:hh,
            id:profil.id,
            usedCount:profil.usedCount ?? 0
        };
     
        return profil['createdAt'] !== undefined ? {...objToReturn,createdAt:profil['createdAt']} :objToReturn;
    },
    fromFirestore: async (snapshot, options)=>{
        const data = snapshot.data(options);
        let hh = [] ;
        if(data.permissions){
            for(const i of data.permissions){
                if(typeof i !== 'string'){
                    let x = await getDoc(i);
                    //console.log(x.data());
                    if(x.exists){
                        hh.push(x.data().designation)
                    }
                }else{
                    hh.push(i)
                }
                
            }
            
            
        }
        return new ProfilModel({id:data.id, nomProfil:data.nomProfil, permissions:hh,usedCount:data.usedCount,createdAt:data.createdAt});
    }
} */


export const mapObjectToProfil = {
    toFirestore: (profil)=>{
        let hh = [] ;
        const nameCollection = "permissions_" + selectedBase;
        profil.permissions.forEach(element => {
            let n = profil.permissionsModal.find((o)=>(o['designation'] !== undefined && o['designation'] === element));
            if(n){
                console.log(n.id);
                hh.push(doc(db,nameCollection,n.id))
            }
        });

        profil.permissionsModal = profil.permissions ?? [];
        profil.permissions = hh;

        let mTmp = Object.fromEntries(Object.entries(profil));

        Object.keys(mTmp).forEach(key =>
        {
            if (mTmp[key] === undefined)
            {
                delete mTmp[key];
            }
        });
        
        return mTmp;
    },
    fromFirestore: (snapshot, options)=>{
        const data = snapshot.data(options);
        return new ProfilModel({id:data.id, nomProfil:data.nomProfil, permissions:data.permissions,usedCount:data.usedCount,createdAt:data.createdAt,date:new Date(data.createdAt?.seconds * 1000)});
    }
}