import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../../core/Firebase/firebase";
import { useDocs } from "../../../core/Firebase/FirebaseHooks";
import { blockUIAction, selectAuthState } from "../../../core/Redux/features/user";
import * as Rb from 'react-bootstrap';
import { fetchAnotherData, fetchforeignDataTab, saveData} from "../../../core/Firebase/services/FirebaseService";
import { CheckboxIns } from "../../../partials/FrequentlyUsed/Chechbox";
import { CustomAlert, customToast } from "../../../core/js/backoffice";
import { ProfilModel } from "../../../core/models/Profil-Model";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "primereact";

export const ModalProfil = (props) => {
    const [permsTab, setpermsTab] = useState([]);
    const dispatch = useDispatch();
    const [pages, setPages] = useState([]);
    const [id, setId] = useState(null);
    const [nomProfil, setNomProfil] = useState("");
    const authState = useSelector(selectAuthState);
    const formRef = useRef();
    const {data,pagesLoading} = useDocs({db:db, converterName:"pages_permissions"})

    const {data: permissionsModal} = useDocs({
        db: db,
        converterName: "permissions",
    });
    useEffect(() => {
        if (props.isAdd === false) {
            if (props.itemToUpdate) {
                //console.log("diop log",props.itemToUpdate);
                let b = props.itemToUpdate;
                setNomProfil(b.nomProfil ?? "");
                
                setId(b.id);

                //setpermsTab(b.permissionsModal ?? []);
              
      async function fetchData(){
            //let hh = await fetchforeignDataTab({tab:b.permissions});
            fetchAnotherData({references:b.permissions}).then((hh)=>{
                setpermsTab(hh);
                //dispatch(blockUIAction(false))
            });
            
        }
       fetchData();
    
               
        
            }
        }

    },[props.itemToUpdate, props.isAdd]);
    useEffect(() => {
        //dispatch(blockUIAction(!props.isAdd))
        if(data.length === 1){
            setPages(data[0].pages ?? [])
        }
        //console.log(pages);
    },[data,pages,pagesLoading])

    const addPermsToTable = (value, key) => {
        //console.log(event.target.value);
        let aaa = value + key;
        //console.log(aaa);
        setpermsTab(u => u.includes(aaa) ? u.filter(p => p !== aaa) : [...u, aaa]);
    }

    //per line
    const addAllPermsToTable = (event, item) => {
        if (item.canList) {
            let k = 'list-' + item.key;
            if (!permsTab.includes(k)) {
                setpermsTab(u => u.concat(k));
            }
        }
        if (item.canCreate) {
            let k = 'create-' + item.key;
            if (!permsTab.includes(k)) {
                setpermsTab(u => u.concat(k));
            }
        }
        if (item.canCreateValidated) {
            let k = 'can-create-' + item.key;
            if (!permsTab.includes(k)) {
                setpermsTab(u => u.concat(k));
            }
        }
        if (item.canUpdate) {
            let k = 'update-' + item.key;
            if (!permsTab.includes(k)) {
                setpermsTab(u => u.concat(k));
            }
        }
        if (item.canDelete) {
            let k = 'delete-' + item.key;
            if (!permsTab.includes(k)) {
                setpermsTab(u => u.concat(k));
            }
        }
        if (item.canSeeDetail) {
            let k = 'detail-' + item.key;
            if (!permsTab.includes(k)) {
                setpermsTab(u => u.concat(k));
            }
        }
        if (item.canValidate) {
            let k = 'can-validate-' + item.key;
            if (!permsTab.includes(k)) {
                setpermsTab(u => u.concat(k));
            }
        }
        //console.log(event.target.value);
        if (event.target.value === "true") {
            let itemPerms = ['list-' + item.key, 'create-' + item.key,'can-create-' + item.key, 'update-' + item.key, 'delete-' + item.key, 'detail-' + item.key,'can-validate-'+item.key];
            itemPerms.forEach((b) => {
                setpermsTab(u => u.filter((i) => i !== b));
            });

        }
    }

    //per line
    const allChecked = (item) => {
        let res = false;
        let itemPerms = ['list-' + item.key, 'create-' + item.key,'can-create-' + item.key, 'update-' + item.key, 'delete-' + item.key, 'detail-' + item.key,'can-validate-'+item.key];
        if (!item.canList) {
            itemPerms = itemPerms.filter((i) => i !== 'list-' + item.key);
        }
        if (!item.canCreate) {
            itemPerms = itemPerms.filter((i) => i !== 'create-' + item.key);
        }
        if (!item.canCreateValidated) {
            itemPerms = itemPerms.filter((i) => i !== 'can-create-' + item.key);
        }
        if (!item.canUpdate) {
            itemPerms = itemPerms.filter((i) => i !== 'update-' + item.key);
        }
        if (!item.canDelete) {
            itemPerms = itemPerms.filter((i) => i !== 'delete-' + item.key);
        }
        if (!item.canSeeDetail) {
            itemPerms = itemPerms.filter((i) => i !== 'detail-' + item.key);
        }
        if (!item.canValidate) {
            itemPerms = itemPerms.filter((i) => i !== 'can-validate-' + item.key);
        }
        

        res = itemPerms.every((u) => {
            return permsTab.includes(u);
        });

        return res;
    }

    //per column
    const addColPermsToTable = (value) => {
       
        pages.forEach((u)=>{
           
            if(u.canList && value === "list-"){
                let k = value + u.key;
                if (!permsTab.includes(k)) {
                    setpermsTab(u => u.concat(k));
                }
            }
            if(u.canCreate && value === "create-"){
                let k = value + u.key;
                if (!permsTab.includes(k)) {
                    setpermsTab(u => u.concat(k));
                }
            }
            if(u.canCreateValidated && value === "can-create-"){
                let k = value + u.key;
                if (!permsTab.includes(k)) {
                    setpermsTab(u => u.concat(k));
                }
            }
            if(u.canUpdate && value === "update-"){
                let k = value + u.key;
                if (!permsTab.includes(k)) {
                    setpermsTab(u => u.concat(k));
                }
            }
            if(u.canDelete && value === "delete-"){
                let k = value + u.key;
                if (!permsTab.includes(k)) {
                    setpermsTab(u => u.concat(k));
                }
            }
            if(u.canSeeDetail && value === "detail-"){
                let k = value + u.key;
                if (!permsTab.includes(k)) {
                    setpermsTab(u => u.concat(k));
                }
            }
            if(u.canValidate && value === "can-validate-"){
                let k = value + u.key;
                if (!permsTab.includes(k)) {
                    setpermsTab(u => u.concat(k));
                }
            }
           
            if(allColChecked(value)){
                // console.log(allColChecked(value));
                pages.forEach((u)=>{
                    let k = value + u.key;
                    setpermsTab(u => u.filter((i) => i !== k));
                })
            }
            //console.log(permsTab);
           
        })
    }

     //cocher toutes les permissions
     const addAllColPermsToTable = () => {
        let perms = ['list-','create-','can-create-','update-','delete-','detail-','can-validate-'];
        let isAllCheck = allPermsChecked() ;

            if(isAllCheck){
                setpermsTab([])
            }else{
                setpermsTab([])
                //perms.forEach((value)=>{
                    pages.forEach((u)=>{
                       
                        if(u.canList){
                            addPermsToTable(perms[0],u.key)
                        }
                        if(u.canCreate === true){
                            addPermsToTable(perms[1],u.key)
                        }
                        if(u.canCreateValidated === true){
                            addPermsToTable(perms[2],u.key)
                        }
                        if(u.canUpdate){
                            addPermsToTable(perms[3],u.key)
                        }
                        if(u.canDelete){
                            addPermsToTable(perms[4],u.key)
                        }
                        if(u.canSeeDetail){
                            addPermsToTable(perms[5],u.key)
                        }
                        if(u.canValidate){
                            addPermsToTable(perms[6],u.key)  
                        }
                    })
                //})
            }
        
       
       
      
    }

    const allPermsChecked = () => {
        let res = false;
        let itemPerms = [];
        let perms = ['list-','create-','update-','delete-','detail-','can-validate-'];
        perms.forEach((value)=>{
            pages.forEach((u)=>{
                itemPerms = [...itemPerms,value+ u.key ]
            })
        })

            pages.forEach((u)=>{
                //console.log(value);
                if (!u.canList) {
                    itemPerms = itemPerms.filter((i) => i !== 'list-' + u.key);
                }
                if (!u.canCreate) {
                    itemPerms = itemPerms.filter((i) => i !== 'create-' + u.key);
                }
                if (!u.canCreateValidated) {
                    itemPerms = itemPerms.filter((i) => i !== 'can-create-' + u.key);
                }
                if (!u.canUpdate) {
                    itemPerms = itemPerms.filter((i) => i !== 'update-' + u.key);
                }
                if (!u.canDelete) {
                    itemPerms = itemPerms.filter((i) => i !== 'delete-' + u.key);
                }
                if (!u.canSeeDetail) {
                    itemPerms = itemPerms.filter((i) => i !== 'detail-' + u.key);
                }
                if (!u.canValidate) {
                    itemPerms = itemPerms.filter((i) => i !== 'can-validate-' + u.key);
                }
        
            })
       //console.log(itemPerms);
        
        res = itemPerms.every((u) => {
            return permsTab.includes(u);
        });

        return res;
    }

    //per column
    const allColChecked = (value) => {
        let res = false;
        let itemPerms = [];
        pages.forEach((u)=>{
            //console.log(u);
            itemPerms = [...itemPerms,value+ u.key ]
        })
        pages.forEach((u)=>{
            //console.log(value);
            if (!u.canList) {
                itemPerms = itemPerms.filter((i) => i !== 'list-' + u.key);
            }
            if (!u.canCreate) {
                itemPerms = itemPerms.filter((i) => i !== 'create-' + u.key);
            }
            if (!u.canCreateValidated) {
                itemPerms = itemPerms.filter((i) => i !== 'can-create-' + u.key);
            }
            if (!u.canUpdate) {
                itemPerms = itemPerms.filter((i) => i !== 'update-' + u.key);
            }
            if (!u.canDelete) {
                itemPerms = itemPerms.filter((i) => i !== 'delete-' + u.key);
            }
            if (!u.canSeeDetail) {
                itemPerms = itemPerms.filter((i) => i !== 'detail-' + u.key);
            }
            if (!u.canValidate) {
                itemPerms = itemPerms.filter((i) => i !== 'can-validate-' + u.key);
            }
    
        })
       
        //console.log(itemPerms);
        res = itemPerms.every((u) => {
            return permsTab.includes(u);
        });

        return res;
    }

    const handleSubmit = () => {
            if (nomProfil.trim().length > 0) {
                if (permsTab.length <= 0) {
                    customToast({
                        message:"Cocher au moins une permission",
                        type:"error",
                        duration:3000,
                        icon:<span className="text-warning"><FontAwesomeIcon icon={faWarning} /></span>
                    })
                } else {
                    console.log(permsTab);
                    saveData({
                        dataType: 'profils',
                        dataToSave: new ProfilModel({
                            id: id,
                            nomProfil: nomProfil,
                            permissions: permsTab,
                            permissionsModal: permissionsModal
                        })
                    }).then(()=>{
                        setpermsTab([]);
                        setNomProfil("");
                    })
                }
            }else{
                customToast({message: "Nom du profil obligatoire",type:"error"});
            }

    };
    const getDescriptionPermission = (value) => {
        let res = "";
            let aaa = permissionsModal.filter((i) => (i.designation.indexOf(value) !== -1))
            //console.log(aaa);
            if (aaa && aaa.length > 0) {
                res = aaa[0].description ?? "";
            }
        //console.log(res);
        return res;
    }
    let msg = "Si vous fermez le modal, vous perderez les données déjà enregistrées !!!";
    return (
        <Rb.Modal fullscreen={true}  style={{zIndex:'200000 !important'}} className={(authState.theme === true ? 'dark-mode':'')} show={props.show}>
        <Rb.Modal.Header className="m-20" bsPrefix={'modal-header-profil'} closeButton={false}>
        <Rb.Form ref={formRef} className='w-100'>
                    <Rb.Row >
                        <Rb.Col xs={12} sm={12} md={12} lg={12}>
                            <Rb.Form.Group className="form-group" controlId="nameProfil">
                                <Rb.Form.Label className="textClassInit">Nom du profil<span
                                    className="text-danger">*</span></Rb.Form.Label>
                                <Rb.Form.Control value={nomProfil} min='6'
                                                 onChange={(e) => setNomProfil(e.target.value)}
                                                 required className="modal-form" type="text" autoComplete='off'
                                                 placeholder="Nom du profil"/>
                                <Rb.Form.Control.Feedback className="" type="invalid">Nom de profil
                                    obligatoire</Rb.Form.Control.Feedback>
                            </Rb.Form.Group>
                        </Rb.Col>
                    </Rb.Row>
                    </Rb.Form>
        </Rb.Modal.Header>
        <Rb.Modal.Body className="m-20" >
                    <div className="modal-table-card mt-20">
                        <table
                            className="table perms-table table-bordered mb-30 table-responsive table-hover">
                            <thead>
                            <tr>
                                <th></th>
                                <th>
                                    <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                        <div style={{marginTop: "10px"}}>
                                            <CheckboxIns
                                                value="list-" checked={allColChecked("list-")}
                                                onChange={(event) => {
                                                    addColPermsToTable(event.target.value)
                                                }} type="checkbox"/>
                                        </div>

                                        <span
                                            className="cursor-pointer align-self-center">Lister</span>
                                    </Rb.Stack>

                                </th>
                                <th>
                                    <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                        <div style={{marginTop: "10px"}}>
                                            <CheckboxIns checked={allColChecked("create-")}
                                                value="create-"
                                                onChange={(event) => {
                                                    addColPermsToTable(event.target.value)
                                                }} type="checkbox"/>
                                        </div>

                                        <span
                                            className="cursor-pointer align-self-center">
                                    Créer</span>
                                    </Rb.Stack>
                                </th>
                                <th>
                                    <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                        <div style={{marginTop: "10px"}}>
                                            <CheckboxIns checked={allColChecked("update-")}
                                                value="update-"
                                                onChange={(event) => {
                                                    addColPermsToTable(event.target.value)
                                                }} type="checkbox"/>
                                        </div>

                                        <span
                                            className="cursor-pointer align-self-center">
                                            Modifier</span>
                                    </Rb.Stack>
                                </th>
                                <th>
                                    <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                        <div style={{marginTop: "10px"}}>
                                            <CheckboxIns checked={allColChecked("delete-")}
                                                value="delete-"
                                                onChange={(event) => {
                                                    addColPermsToTable(event.target.value)
                                                }} type="checkbox"/>
                                        </div>

                                        <span
                                            className="cursor-pointer align-self-center">
                                            Supprimer</span>
                                    </Rb.Stack>
                                </th>
                                <th>
                                    <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                        <div style={{marginTop: "10px"}}>
                                            <CheckboxIns checked={allColChecked("detail-")}
                                                value="detail-"
                                                onChange={(event) => {
                                                    addColPermsToTable(event.target.value)
                                                }} type="checkbox"/>
                                        </div>

                                        <span
                                            className="cursor-pointer align-self-center">
                                            Voir Détail</span>
                                    </Rb.Stack>

                                </th>
                                <th>
                                    <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                        <div style={{marginTop: "10px"}}>
                                            <CheckboxIns checked={allColChecked("can-validate-")}
                                                value="can-validate-"
                                                onChange={(event) => {
                                                    addColPermsToTable(event.target.value)
                                                }} type="checkbox"/>
                                        </div>

                                        <span
                                            className="cursor-pointer align-self-center">
                                            Peut Valider</span>
                                    </Rb.Stack>

                                </th>
                                <th>
                                    <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                        <div style={{marginTop: "10px"}}>
                                            <CheckboxIns checked={allColChecked("can-create-")}
                                                value="can-create-"
                                                onChange={(event) => {
                                                    addColPermsToTable(event.target.value)
                                                }} type="checkbox"/>
                                        </div>

                                        <span
                                            className="cursor-pointer align-self-center">
                                            Créer sans validation</span>
                                    </Rb.Stack>

                                </th>
                                <th>
                                    <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                        <div style={{marginTop: "10px"}}>
                                            <CheckboxIns checked={allPermsChecked()}
                                                value="detail-"
                                                onChange={(event) => {
                                                    addAllColPermsToTable()
                                                }} type="checkbox"/>
                                        </div>

                                        <span
                                            className="cursor-pointer align-self-center">
                                            Tout Cocher</span>
                                    </Rb.Stack>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                pages.map((item) => {
                                    return (<tr key={item.id}>
                                        <td>{item.nom}</td>
                                        <td>{item.canList &&
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                            <div style={{marginTop: "10px"}}>
                                                <CheckboxIns value="list-"
                                                             onChange={(event) => addPermsToTable(event.target.value, item.key)}
                                                             checked={permsTab.includes('list-' + item.key)}/>
                                            </div>

                                            <span onClick={() => addPermsToTable("list-", item.key)}
                                                  className="cursor-pointer align-self-center">Liste</span>
                                             <span><i data-pr-tooltip={getDescriptionPermission('list-' + item.key)}
                                                        className={"cursor-pointer text-primary fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12 zi-10090" target={".tooltip"+item.key} />
                                        </Rb.Stack>
                                        }
                                        </td>
                                        <td>{item.canCreate &&
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                            <div style={{marginTop: "10px"}}>
                                                <CheckboxIns checked={permsTab.includes('create-' + item.key)}
                                                             value="create-"
                                                             onChange={(event) => {
                                                                 addPermsToTable(event.target.value, item.key)
                                                             }} type="checkbox"/>
                                            </div>

                                            <span onClick={() => addPermsToTable("create-", item.key)}
                                                  className="cursor-pointer align-self-center">Création</span>
                                            <span><i data-pr-tooltip={getDescriptionPermission('create-' + item.key)}
                                                        className={"cursor-pointer text-success fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12 zi-10090" target={".tooltip"+item.key} />
                                        </Rb.Stack>
                                        }</td>
                                        <td>{item.canUpdate &&
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                            <div style={{marginTop: "10px"}}>
                                                <CheckboxIns checked={permsTab.includes('update-' + item.key)}
                                                             value="update-"
                                                             onChange={(event) => {
                                                                 addPermsToTable(event.target.value, item.key)
                                                             }} type="checkbox"/>
                                            </div>

                                            <span onClick={() => addPermsToTable("update-", item.key)}
                                                  className="cursor-pointer align-self-center">Modification</span>
                                            <span><i data-pr-tooltip={getDescriptionPermission('update-' + item.key)}
                                                        className={"cursor-pointer text-warning fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12 zi-10090" target={".tooltip"+item.key} />
                                        </Rb.Stack>
                                        }</td>
                                        <td>{item.canDelete &&
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                            <div style={{marginTop: "10px"}}>
                                                <CheckboxIns checked={permsTab.includes('delete-' + item.key)}
                                                             value="delete-"
                                                             onChange={(event) => {
                                                                 addPermsToTable(event.target.value, item.key)
                                                             }} type="checkbox"/>
                                            </div>

                                            <span onClick={() => addPermsToTable("delete-", item.key)}
                                                  className="cursor-pointer align-self-center">Suppression</span>
                                            <span><i data-pr-tooltip={getDescriptionPermission('delete-' + item.key)}
                                                        className={"cursor-pointer text-danger fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12 zi-10090" target={".tooltip"+item.key} />
                                        </Rb.Stack>
                                        }</td>
                                        <td>{item.canSeeDetail &&
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                            <div style={{marginTop: "10px"}}>
                                                <CheckboxIns checked={permsTab.includes('detail-' + item.key)}
                                                             value="detail-"
                                                             onChange={(event) => {
                                                                 addPermsToTable(event.target.value, item.key)
                                                             }} type="checkbox"/>
                                            </div>

                                            <span onClick={() => addPermsToTable("detail-", item.key)}
                                                  className="cursor-pointer align-self-center">Détail</span>
                                            <span><i data-pr-tooltip={getDescriptionPermission('detail-' + item.key)}
                                                        className={"cursor-pointer text-primary fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12 zi-10090" target={".tooltip"+item.key} />
                                        </Rb.Stack>
                                        }</td>

                                        <td>{item.canValidate &&
                                        <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                            <div style={{marginTop: "10px"}}>
                                                <CheckboxIns checked={permsTab.includes('can-validate-' + item.key)}
                                                             value="can-validate-"
                                                             onChange={(event) => {
                                                                 addPermsToTable(event.target.value, item.key)
                                                             }} type="checkbox"/>
                                            </div>

                                            <span onClick={() => addPermsToTable("can-validate-", item.key)}
                                                  className="cursor-pointer align-self-center">Peut Valider</span>
                                            <span><i data-pr-tooltip={getDescriptionPermission('can-validate-' + item.key)}
                                                        className={"cursor-pointer text-success fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12 zi-10090" target={".tooltip"+item.key} />
                                        </Rb.Stack>
                                        }</td>

                                        <td>{item.canCreateValidated &&
                                            <Rb.Stack className='jc-start' direction='horizontal' gap={2}>
                                                <div style={{marginTop: "10px"}}>
                                                    <CheckboxIns checked={permsTab.includes('can-create-' + item.key)}
                                                                value="can-create-"
                                                                onChange={(event) => {
                                                                    addPermsToTable(event.target.value, item.key)
                                                                }} type="checkbox"/>
                                                </div>

                                                <span onClick={() => addPermsToTable("can-validate-", item.key)}
                                                    className="cursor-pointer align-self-center">Créer sans validation</span>
                                                <span><i data-pr-tooltip={getDescriptionPermission('can-create-' + item.key)}
                                                        className={"cursor-pointer text-warning fa fa-info-circle"+" tooltip"+item.key}></i></span>
                                                <Tooltip position="right" className="fsize-12 zi-10090" target={".tooltip"+item.key} />
                                            </Rb.Stack>
                                        }</td>


                                        <td>
                                            <Rb.Stack className='jc-center' direction='horizontal' gap={2}>
                                                <div style={{marginTop: "10px"}}>
                                                    <CheckboxIns value={allChecked(item)} checked={allChecked(item)}
                                                                 onChange={(event) => {
                                                                     addAllPermsToTable(event, item)
                                                                 }} type="checkbox"/>
                                                </div>
                                            </Rb.Stack>


                                        </td>
                                    </tr>);
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    </Rb.Modal.Body>
         <Rb.Modal.Footer style={{height: "10%"}}>
                <Rb.Button bsPrefix="btn btn-cancel" onClick={()=>{CustomAlert({message:msg,yesBtn:props.handleClose})}}>
                    Annuler
                </Rb.Button>
                <Rb.Button onClick={handleSubmit} bsPrefix="btn btn-save">
                    Enregistrer
                </Rb.Button>
            </Rb.Modal.Footer>
        </Rb.Modal>
    );
}