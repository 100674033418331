import React from "react";

const Checkbox = ({onChange,checked,value,...props }) => (
  <label className="cursor-pointer">
    <input
      checked={checked}
      value={value}
      onChange={(event) => {
        onChange(event);
      }}
      type="checkbox"
    />
   {/*  <span
      className={`checkbox ${checked ? "checkbox--active" : ""}`}
      // This element is purely decorative so
      // we hide it for screen readers
      aria-hidden="true"
    /> */}
    <svg
        className={`checkbox ${checked ? "checkbox--active" : ""}`}
        // This element is purely decorative so
        // we hide it for screen readers
        aria-hidden="true"
        viewBox="0 0 15 11"
        fill="none"
      >
        <path
          d="M1 4.5L5 9L14 1"
          strokeWidth="2"
          stroke={checked ? "#fff" : "none"} // only show the checkmark when `isCheck` is `true`
        />
      </svg>
  </label>
);

export const CheckboxIns=({onChange = ()=>{},checked,value}) =>(
  <label className="label-checkbox">
  <input checked={checked}   value={value}  onChange={(event) => {
        onChange(event);
      }} className="inputIns w-100" type="checkbox" />
  <span className="checkboxIns"></span>
</label>
)
export default Checkbox;
