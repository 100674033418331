import React from 'react'
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {db, selectedBase} from '../core/Firebase/firebase'
import {selectAuthState} from '../core/Redux/features/user';
import * as Rb from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {doc, updateDoc} from 'firebase/firestore';
import {CustomAlert} from '../core/js/backoffice';
import moment from 'moment';

export default function NotifsList({show, handleClose, notifs = []}) {
    const navigate = useNavigate();
    const authState = useSelector(selectAuthState
    );
    const redirectToDetail = (value) => {
        navigate(value);
    }
    return <Rb.Offcanvas placement='end' style={{backgroundColor: authState.theme === true ? "#1C272C" : "none"}} bsPrefix="zi-10000 offcanvas" show={show} onHide={handleClose}>
        <Rb.Offcanvas.Header closeButton>
            <Rb.Offcanvas.Title className='fsize-18' style={{color: authState.theme === true ? "white" : "none"}}>Notifications<Rb.Badge  bg='warning' style={{
                padding: "5px",
                color: authState.theme === true ? "white" : "none",
                marginLeft: "10px"
            }}>{notifs.length}</Rb.Badge></Rb.Offcanvas.Title>
        </Rb.Offcanvas.Header>
        <Rb.Offcanvas.Body style={{overflowX: "hidden"}}>
            {newFunction_1(notifs, handleClose, redirectToDetail, authState)}
        </Rb.Offcanvas.Body>
    </Rb.Offcanvas>
}

function newFunction_1(notifs, handleClose, redirectToDetail, authState) {
    return <div className="row pl-1 pr-1">
        {notifs.length > 0 &&
        <div className="col-12 mb-1 w-100 d-flex p-0">
            <span className="fsize-14 rounded" style={{
                paddingLeft: "7px",
                paddingRight: "7px",
                paddingBottom: "3px",
                paddingTop: "3px",
                background: "#c9f7f5"
            }}>Lues {notifs.filter((i) => (i["isViewed"] === true)).length} </span>

            <span className="ml-1 fsize-14 rounded" style={{
                paddingLeft: "7px",
                paddingRight: "7px",
                paddingBottom: "3px",
                paddingTop: "3px",
                background: "#FFF4DF"
            }}>Non Lues {notifs.filter((i) => (i["isViewed"] === false)).length} </span>

            <Rb.Button onClick={markArchived({notifs: notifs, authState: authState})}
                       style={{paddingLeft: "7px", paddingRight: "7px", paddingBottom: "3px", paddingTop: "3px"}}
                       className="ml-auto" variant='warning'><FontAwesomeIcon icon={faTrashAlt}/></Rb.Button>
        </div>
        }
        {notifs.map((item, index) => {
            let path = "users_" + selectedBase + "/" + authState["currentUser"]["id"] + "/notifications/" + item["id"];
            let ref = doc(db, path);
            return <div key={index} className="w-100">
                <hr/>
                <div style={{background: item["isViewed"] ? "#c9f7f5" : "#FFF4DF"}}
                     className="card-notifs cursor-pointer col-12 d-flex p-1">
                    <Rb.Stack className="w-100 align-self-center" direction="horizontal">
                        <Rb.Stack onClick={() => {
                            if (item["isArchived"] === false) {
                                updateDoc(ref, {isViewed: true});
                            }
                            handleClose();
                            redirectToDetail(item["url"] ?? "/");
                        }} gap={2}>
                            <span className="textNotif fsize-13">{item["title"]}</span>
                            <span className="textNotif fsize-13">{item["body"]}</span>
                            <span className="dateNotif fsize-13"
                                  style={{color: "#CDA75F"}}>{dateToDuration({timestamp: item["createdAt"]})}</span>
                        </Rb.Stack>

                        <div onClick={markViewed()} className="ml-auto">
                            <FontAwesomeIcon color='red' icon={item["isViewed"] ? faEyeSlash : faEye}/>
                            {/* <img alt="" src="/assets/images/ico/ic_eye1.svg"/> */}
                        </div>
                    </Rb.Stack>
                </div>


            </div>;

            function markViewed() {
                return () => {
                    const func = () => {
                        updateDoc(ref, {isViewed: !item["isViewed"]});
                    };
                    CustomAlert({message: "Marquer comme " + (item["isViewed"] ? "non lue" : "lue"), yesBtn: func});
                };
            }

        })}

        {notifs.length === 0 && <div style={{marginTop: "100%"}} className="text-center col-12">
            <span style={{color: authState.theme === true ? "white" : "none"}}>Aucune notification reçue</span>
        </div>}
    </div>;
}

function markArchived({notifs = [], authState}) {
    return () => {
        const func = () => {
            notifs.forEach((item) => {
                let path = "users_" + selectedBase + "/" + authState["currentUser"]["id"] + "/notifications/" + item["id"];
                let ref = doc(db, path);
                updateDoc(ref, {isArchived: true});
            })

        };
        CustomAlert({message: "Supprimer toutes les notifications ?", yesBtn: func});
    };
}

export function dateToDuration({timestamp}) {
    const startDate = moment(new Date(timestamp?.seconds * 1000));
    const timeEnd = moment(Date.now());
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    let date = "";
    if (diffDuration.years() > 0) {
        date = diffDuration.years() + " ans"
    } else if (diffDuration.months() > 0) {
        date = diffDuration.months() + " mois";
    } else if (diffDuration.days() > 0) {
        date = diffDuration.days() + " jrs";
    } else if (diffDuration.hours() > 0) {
        date = diffDuration.hours() + " h";
    } else if (diffDuration.minutes() > 0) {
        date = diffDuration.minutes() + " mn";
    } else if (diffDuration.seconds() > 0) {
        date = diffDuration.seconds() + " s";
    }
    return date
}

