import {useEffect, useState} from 'react';
import * as Rb from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {db} from '../../../core/Firebase/firebase';
import {useWhereDocs} from '../../../core/Firebase/FirebaseHooks';
import TitleBtnAdd from '../../../partials/FrequentlyUsed/TitleBtnAdd';
import FsLightbox from 'fslightbox-react';
import {motion} from 'framer-motion';


const DetailClient = () => {

    const [item, setItem] = useState({});
    const {id} = useParams();
    const [toggler, setToggler] = useState(false);

    const {data: client} = useWhereDocs({
        db: db,
        collectionName: "clients",
        whereToLookInTheCollection: "id",
        whereToLookValue: id
    });

    useEffect(() => {
        if (client.length === 1) {
            setItem(client[0])
        }
    }, [client])

    return (
        <motion.div
        initial={{opacity: 0, scale: 0.9}}
        animate={{opacity: 1, scale: 1}}
        transition={{duration: 0.3}}
    >
            <TitleBtnAdd showBtn={false} marginTop="mt-20"
                         icon="/assets/images/ico/ic_users_pub.svg" itemsName="Informations Client"/>
            <div className="mt-30 tab-card">

                <Rb.Tabs className="mb-2 mt-20" defaultActiveKey="detail" justify={true} fill={true}
                         id="tab-detail-theme">
                    <Rb.Tab eventKey="detail" title="Détails" tabClassName='tabHeader'>
                        <Rb.ListGroup variant='flush'>
                            <Rb.ListGroupItem className="list-item textClassInit">
                                <Rb.Stack direction="horizontal" gap={3}>
                                <span onClick={() => {
                                    setToggler(!toggler)
                                }} className="avatar cursor-pointer avatar-online box-shadow-1 hpx-40 wdpx-40 p-0 m-0">
                                                        <img alt="" className="wdpx-40 hpx-40" src={item?.imgProfil ?? "/assets/images/imgs/empty-avatar.png"}/>
                                                    </span>
                                    <span>
                                                    {item["displayName"] ?? "..."}
                                                    </span>

                                </Rb.Stack></Rb.ListGroupItem>
                            <Rb.ListGroupItem
                                className="list-item textClassInit">Email: {item["email"] ?? "..."}</Rb.ListGroupItem>
                            <Rb.ListGroupItem
                                className="list-item textClassInit">Téléphone: {item["telephone"] ?? "..."}</Rb.ListGroupItem>
                            <Rb.ListGroupItem
                                className="list-item textClassInit">Genre: {item["genre"]}</Rb.ListGroupItem>

                        </Rb.ListGroup>
                        <FsLightbox
                            toggler={toggler}
                            disableLocalStorage={true}
                            sources={[
                                <img alt="" className="w-100 h-100" src={item?.imgProfil ?? "/assets/images/imgs/empty-avatar.png"}/>
                            ]
                            }
                        />
                    </Rb.Tab>
                </Rb.Tabs>
            </div>
        </motion.div>
    );

}
export default DetailClient;