import { host } from "../Firebase/firebase";

export class ThemeModel {
  constructor({
    id = null,
    designation,
    createdAt,
    designationMap,
    date,
    description,
    descriptionMap,
    usedCount,
    nbSousThemes,
    keywords,
    keywordsMap,
    fileUrl,
    ordreMap,
    ordre,
    eraseImg,
    tokens,
    likedBy,
  }) {
    this.id = id;
    this.date = date;
    this.createdAt = createdAt;
    this.designation = designation;
    this.designationMap = designationMap;
    this.descriptionMap = descriptionMap;
    this.description = description;
    this.usedCount = usedCount;
    this.nbSousThemes = nbSousThemes;
    this.keywords = keywords;
    this.keywordsMap = keywordsMap;
    this.fileUrl = fileUrl;
    this.ordreMap = ordreMap;
    this.ordre = ordre;
    this.eraseImg = eraseImg;
    this.tokens = tokens;
    this.likedBy = likedBy;
  }

  toString() {
    return this.designation + " , " + this.description;
  }

  toJson() {
    let obj = {
      id: this.id,
      //date:this.date,
      designation: this.designation,
      description: this.description,
      //usedCount:this.usedCount,
      keywords: this.keywords,
      likedBy: this.likedBy,
      ordre: this.ordre,
    };
    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  }
}

export const mapObjectToThemes = {

  toFirestore: (m) => {
    let mTmp = Object.fromEntries(Object.entries(m));
    console.log(mTmp);
    Object.keys(mTmp).forEach((key) => {
      if (mTmp[key] === undefined) {
        delete mTmp[key];
      }
      
    });


    return mTmp;
  },

  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    //console.log(data);
    let tmp = Object.fromEntries(Object.entries(data.keywords ?? {}));
    if (data.keywords) {
      Object.keys(tmp).forEach((key) => {
        tmp[key] = tmp[key].join(" ; ");
      });
    }
    let newFileUrl = String(data.fileUrl ?? "/assets/images/imgs/upload.png");
    // newFileUrl = newFileUrl.replace("localhost",host)
    return new ThemeModel({
      tokens: data.tokens ?? [],
      likedBy: data.likedBy ?? [],
      fileUrl: newFileUrl,
      id: data.id,
      keywords: tmp,
      keywordsMap: data.keywords,
      createdAt: data.createdAt,
      date: new Date(data.createdAt?.seconds * 1000),
      designationMap: data.designation,
      descriptionMap: data.description,
      ordreMap: data.ordre,
      ordre: data.ordre,
      designation: data.designation ? data.designation["fr_FR"] ?? "" : "",
      description: data.description ? data.description["fr_FR"] ?? "" : "",
    });
  },
};
