import React, {useEffect, useRef} from 'react';
import './App.css';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import Login from './pages/Login';
import IndexCoreApp from './pages/IndexCoreApp';
import PageNotFound from './pages/PageNotFound';
import {LoginRoute, PrivateRoute} from './core/authGuard/PrivateRoute';
import ResetPwd from './pages/ResetPwd';
import {fetchAnotherData, fetchforeignDataTab, fetchUniqueData} from './core/Firebase/services/FirebaseService';
import {authSuccess, selectAuthState} from './core/Redux/features/user';
import {useDispatch, useSelector} from 'react-redux';
import "react-confirm-alert/src/react-confirm-alert.css";
import "../node_modules/flag-icons/css/flag-icons.min.css";
import "react-simple-keyboard/build/css/index.css";
import { useWhereDocs } from './core/Firebase/FirebaseHooks';
import { db, requestForToken } from './core/Firebase/firebase';
import { getDoc } from 'firebase/firestore';
//import"bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";



//import { query, collection, getDocs, where } from "firebase/firestore";

function App() {
    const authState = useSelector(selectAuthState);
    const dispatch = useDispatch();
    const mounted = useRef();

    //get Current user from docs
    //console.log(authState.currentUser.id);
    const {data} = useWhereDocs({
        db: db,
        collectionName: "users",
        whereToLookInTheCollection: "id",
        whereToLookValue: authState.currentUser?.id
    });

  /*   useEffect(() => {
        if (!mounted.current) {
            requestForToken();
            mounted.current = true;
        }
        
    }) */

    useEffect(() => {
        async function customData()
        {
            if(data.length ===  1)
            {
                let element = data[0];
                /* dispatch(
                    authSuccess({
                        userHeader: { displayName: element.displayName},
                    })
                ); */

                //element["permissions"] = [];
                if (typeof element["profil"] === "object")
                {
                    let x = await getDoc(element["profil"]);
                    if(x.exists())
                    {
                        //console.log(x.data());
                        let newElement1 =Object.assign({}, data[0]);
                        newElement1["nomProfil"] =  x.data()["nomProfil"];
                        dispatch(
                            authSuccess({
                                userHeader: newElement1,
                            })
                        );
                        let p = x.data().permissions;
                        let pp = x.data().permissionsModal;
                        let newElement =Object.assign({}, data[0]);
                        if (Array.isArray(p))
                        {
                            if (Array.isArray(pp) && pp.length === p.length)
                            {
                                newElement["permissions"] = pp ;
                                dispatch(authSuccess({userInfos: newElement}))
                            }
                            else if (p.length > 0)
                            {
                                
                                console.log("diop log",x.data());
                                fetchAnotherData({references:p}).then((hh)=>{
                                    //console.log(hh);
                                    newElement["permissions"] = hh ;
                                    dispatch(authSuccess({userInfos: newElement}))
                                }); 
                                //let hh = await fetchforeignDataTab({tab:p});
                                //console.log(hh);
                                //newElement["permissions"] = hh ;
                                //dispatch(authSuccess({userInfos: newElement}))
                            }
                        }
                    }
                }

                
            } 
        }

        customData();
        
    }
    ,[data,dispatch])
  
    /* useEffect(() => {
        if (authState.currentUser) {
            fetchUniqueData({dataType: "users", id: authState.currentUser.id}).then(async (value) => {
                //console.log(value[0]);
                if (value.length > 0) {
                    dispatch(authSuccess({userInfos: value[0]}))
                }
            })
        }
    }, [authState.currentUser, dispatch]); */

    useEffect(() => {
        if (authState.theme === true) {
            document.body.style.setProperty('background', '#182226', 'important');
        } else {
            document.body.style.setProperty('background', '#F1F4F6', 'important');
        }


    }, [authState.theme])

    return (
        <Routes>
            <Route exact path="/login" replace={true} element={<LoginRoute><Login/></LoginRoute>}/>
            <Route exact path="/resetpwd" replace={true} element={<LoginRoute><ResetPwd/></LoginRoute>}/>
            <Route exact path="/*" element={<PrivateRoute><IndexCoreApp/></PrivateRoute>}/>
            <Route exact replace={true} path="*" element={<PrivateRoute><PageNotFound/></PrivateRoute>}/>
        </Routes>
    );
}


export default App;
