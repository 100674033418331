

export class PublicationModel{
    constructor({fileUrl,likedBy=[],id = null,createdAt,disabled,date,titre,source,link,linkMap,description,motcles,type,theme,sousTheme,sousSousTheme,themeRef,sousThemeRef,sousSousThemeRef,titreMap,descriptionMap,sourceMap,keywordMap,keywords,thumbnailsUrl,createdBy}){
        this.id = id;
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.titre = titre;
        this.date = date;
        this.description = description;
        this.motcles = motcles;
        this.source = source;
        this.link = link;
        this.disabled = disabled;
        this.type = type;
        this.theme = theme;
        this.sousTheme = sousTheme;
        this.sousSousTheme = sousSousTheme;
        this.themeRef = themeRef;
        this.sousThemeRef = sousThemeRef;
        this.sousSousThemeRef = sousSousThemeRef;
        this.titreMap = titreMap;
        this.sourceMap = sourceMap;
        this.linkMap = linkMap;
        this.descriptionMap = descriptionMap;
        this.keywordMap = keywordMap;
        this.fileUrl = fileUrl;
        this.likedBy = likedBy;
        this.keywords = keywords;
        this.thumbnailsUrl = thumbnailsUrl;
    }
    toJson(){
        let obj = {
            id:this.id,
            titre :this.titre,
            description :this.description,
            motcles     :this.motcles,
            source      :this.source,
            link        :this.link,
            type :this.type,
            theme :this.theme,
            sousTheme :this.sousTheme,
            sousSousTheme :this.sousSousTheme,
            keywords :this.keywords,
            thumbnailsUrl :this.thumbnailsUrl
        }
        Object.keys(obj).forEach(key => {
            if (obj[key] === undefined) {
                delete obj[key];
              }
        })
        return obj ;
    }
}

export const mapObjectToPublications = {
    toFirestore: (m)=>{
        let mTmp = Object.fromEntries(Object.entries(m))
        mTmp = {...mTmp,usedCount:undefined}
        Object.keys(mTmp).forEach(key => {
            if (mTmp[key] === undefined) {
                delete mTmp[key];
            }
        });
        
        return mTmp;
    },
    fromFirestore: (snapshot, options)=>{
        const data = snapshot.data(options);
        //console.log(data.theme);
        //let newFileUrl = String(data.fileUrl);
        let newFileUrl = String(data.fileUrl ?? "/assets/images/imgs/upload.png");
        let themeTabId = [], sousThemeTabId = [], sousSousThemeTabId = [];
        // newFileUrl = newFileUrl.replace("localhost",host);
        if(Array.isArray(data.theme))
        {
            themeTabId = data.theme.map((u)=>{
                return u["id"] ?? ""
            });
        }
        if(Array.isArray(data.sousTheme))
        {
            sousThemeTabId = data.sousTheme.map((u)=>{
                return u["id"] ?? ""
            });
        }
        if(Array.isArray(data.sousSousTheme))
        {
            sousSousThemeTabId = data.sousSousTheme.map((u)=>{
                return u["id"] ?? ""
            });
        }
        //return new PublicationModel({id:data.id,createdAt:data.createdAt,date:new Date(data.createdAt?.seconds * 1000),description:data.description ? data.description['fr_FR'] ?? "" : "",descriptionMap:data.description,titreMap:data.titre,titre:data.titre ? data.titre['fr_FR'] ?? "" : "",fileUrl:newFileUrl,theme:data.theme ? data.theme["id"] ?? "":data.theme,sousTheme:data.sousTheme ? data.sousTheme["id"] ?? "" :data.sousTheme,sousSousTheme:data.sousSousTheme ? data.sousSousTheme["id"] ?? "" : data.sousSousTheme,type:data.type,disabled:data.disabled,themeRef:data.theme,sousThemeRef:data.sousTheme,sousSousThemeRef:data.sousSousTheme,likedBy:data.likedBy ?? []});
        return new PublicationModel({id:data.id,createdAt:data.createdAt,date:new Date(data.createdAt?.seconds * 1000),description:data.description ? data.description['fr_FR'] ?? "" : "",motcles:data.motcles ? data.motcles['fr_FR'] ?? "" : "",descriptionMap:data.description,keywordMap:data.motcles,source:data.source ? data.source['fr_FR'] ?? "" : "",sourceMap:data.source,link:data.link ? data.link['fr_FR'] ?? "" : "",linkMap:data.link,titreMap:data.titre,titre:data.titre ? data.titre['fr_FR'] ?? "" : "",fileUrl:newFileUrl,theme:themeTabId,sousTheme:sousThemeTabId,sousSousTheme:sousSousThemeTabId,type:data.type,disabled:data.disabled,themeRef:data.theme,sousThemeRef:data.sousTheme,sousSousThemeRef:data.sousSousTheme,likedBy:data.likedBy ?? []});
    }
}