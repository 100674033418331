import React, { useEffect } from "react";
import { InternetDown } from "../pages/Home";
import { Detector } from "react-detect-offline";
import AnimatedRoutes from "./AnimatedRoutes";
import { customToast } from "../core/js/backoffice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const contentBody = React.createRef();

export default function BodyContent() {
    

  const pushNotificationConnexions = (online) => {
    if (online) {
      customToast({
        message: "Connexion Internet restaurée.",
        position: "top-center",
        duration: 4000,
        className: "mt-100",
        icon: (
          <span class="text-warning">
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
        ),
      });
    }
  };

  useEffect(()=>{
    pushNotificationConnexions()
  })
  return (
    <div className="content-body" ref={contentBody}>
      <div className="body-elt">
        <Detector
          onChange={(online) => pushNotificationConnexions(online)}
          render={({ online }) => (
            <div>
              {online ? (
                <AnimatedRoutes />
              ) : (
                <>
                  <InternetDown />
                  <AnimatedRoutes />
                </>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
}

export { contentBody };
