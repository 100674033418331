import {doc, updateDoc} from 'firebase/firestore'
import React from 'react'
import * as Rb from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {db, logout, selectedBase} from '../core/Firebase/firebase'
import {CustomAlert, hideMyProfil} from '../core/js/backoffice'
import {selectAuthState, showModal} from '../core/Redux/features/user';
import {useNavigate} from 'react-router-dom'
import {dateToDuration} from './NotifsList'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
//import {auth} from "../core/Firebase/firebase";
//import Cookies from 'js-cookie';

export default function MyProfil({notifs = []}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector(selectAuthState);
    const redirectToDetail = (value) => {
        navigate(value);
    }

    //console.log(authState.currentUser.email);   
    return (
        /* <!-- profilnav Notifications --> */
        <div className="r-flexed">
            <div onClick={hideMyProfil} className="profilnav1">
            </div>
            <div className="profilnav r-flexed" id="myProfilnav">
                <div className="container-fluid">
                    <div className="r-flexed jc-end">
                        <Button style={{padding: '5px 10px'}} onClick={() => {
                            logout()
                        }}
                                bsPrefix='btn btn-warning'>{/* <i className="fa fa-sign-out fsize-20" aria-hidden="true"></i> */}Déconnexion</Button>
                    </div>


                    <div className="border-dotted mt-10 mb-10"></div>


                    <div className="row mt-20">
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                        <span className="avatar avatar-online box-shadow-1 hpx-100 wdpx-100">
                                    <img alt="" className="wdpx-100 hpx-100"
                                         src={authState.userHeader ? authState.userHeader.imgProfil ?? "/assets/images/imgs/upload.png" : "/assets/images/imgs/upload.png"}></img>
                                </span>

                        </div>

                        <div className="col-12 col-sm-8 col-md-8 col-lg-8 d-flex">
                            <div className="w-100 c-flexed row-gap-10 m-auto">
                                <span
                                    className="textClassInit">{authState.userHeader ? authState.userHeader.displayName : ''}</span>
                                <span className="textClassInit"><span
                                    className="textClassInit text-underline">Profil:</span> {authState.userHeader ? authState.userHeader.nomProfil : ''}</span>
                                {/*  <span className="textClassInit -center">{authState.userHeader ? authState.userHeader.email : ''}</span> */}

                            </div>
                        </div>

                    </div>

                    <div className="border-dotted mt-30 mb-30"></div>

                    <div className="row mt-20 cursor-pointer">
                        <div className="col-12 col-sm-12 col-md-1 col-lg-1">
                            {/* <div className="w-100 img-profil-user">
                            <img alt="" style={{width:"20px"}}
                                         src="/assets/images/ico/ic_person.svg"></img>
                            </div> */}

                            <div className="count-notifs " style={{display: "flex"}}>
                                <img alt="" style={{width: "20px"}} className="m-auto"
                                     src="/assets/images/ico/ic_person.svg"></img>
                            </div>

                        </div>

                        <div className="col-12 col-sm-12 col-md-11 col-lg-11 d-flex" onClick={() => {
                            hideMyProfil();
                            dispatch(showModal())
                        }}>
                            <div className="w-100 c-flexed row-gap-2 pl-1 m-auto">
                                <span className="textClassInit">Modifier mes informations</span>
                            </div>
                        </div>

                    </div>

                    <div className="border-dotted mt-30 mb-30"></div>

                    <div className="row mt-20">
                        <div className="col-8">
                            <span className="textClassInit font-weight-bold">Notifications Non lues</span>
                        </div>
                        <div className="col-4 d-flex">
                            <div className="column-gap-6 r-flexed ml-auto align-items-center">
                                <div className="count-notifs">
                                    <span>{notifs.length}</span>
                                </div>
                                <div onClick={markallViewed({notifs:notifs,authState:authState})} className="mark-allnotifs-read cursor-pointer">
                                    <img alt="" src="/assets/images/ico/ic_eye.svg"/>
                                </div>
                            </div>
                        </div>

                        {notifs.map((item, index) => {
                            let path = "users_" + selectedBase + "/" + authState["currentUser"]["id"] + "/notifications/" + item["id"];
                            let ref = doc(db, path);
                            return <div key={index} className="col-12">
                                <div className="border-dotted mt-20 mb-20"></div>
                                <div style={{background: item["isViewed"] ? "#c9f7f5" : "#FFF4DF"}}
                                     className="card-notifs cursor-pointer d-flex p-1">
                                    <Rb.Stack className="w-100 align-self-center" direction="horizontal">
                                        <Rb.Stack onClick={() => {
                                            if (item["isArchived"] === false) {
                                                updateDoc(ref, {isViewed: true});
                                            }
                                            redirectToDetail(item["url"] ?? "/");
                                        }} gap={2}>
                                            <span className="textNotif fsize-13">{item["title"]}</span>
                                            <span className="textNotif fsize-13">{item["body"]}</span>
                                            <span className="dateNotif fsize-13"
                                                  style={{color: "#CDA75F"}}>{dateToDuration({timestamp: item["createdAt"]})}</span>
                                        </Rb.Stack>

                                        <div onClick={markViewed()} className="ml-auto">
                                            <FontAwesomeIcon color='red' icon={item["isViewed"] ? faEyeSlash : faEye}/>
                                            {/* <img alt="" src="/assets/images/ico/ic_eye1.svg"/> */}
                                        </div>
                                    </Rb.Stack>
                                </div>


                            </div>;

                            function markViewed() {
                                return () => {
                                    const func = () => {
                                        updateDoc(ref, {isViewed: !item["isViewed"]});
                                    };
                                    CustomAlert({
                                        message: "Marquer comme " + (item["isViewed"] ? "non lue" : "lue"),
                                        yesBtn: func
                                    });
                                };
                            }

                        })}

                        {notifs.length === 0 && <div style={{marginTop: "50%"}} className="text-center col-12">
                            <span className="textClassInit">Aucune notification non lue reçue</span>
                        </div>}
                    </div>


                </div>
            </div>
        </div>
    )

}
function markallViewed({notifs = [], authState}) {
    return () => {
        const func = () => {
            notifs.forEach((item) => {
                let path = "users_" + selectedBase + "/" + authState["currentUser"]["id"] + "/notifications/" + item["id"];
                let ref = doc(db, path);
                updateDoc(ref, {isViewed: true});
            })

        };
        if(notifs.length > 0)
        CustomAlert({message: "Marquer lue à toutes les notifications ?", yesBtn: func});
    };
}
