/* 
On retrouve ce component dans l'ensemble des pages
Il sert a afficher le titre de la page,
le nombre d'elements se trouvant dans la liste
Le bouton qui nous permet d'ajouter un element
 */

import { Button } from 'primereact';
import React, {Component} from 'react';

class TitleBtnAdd extends Component {
    render() {
        return (
            <div>
                <section className={this.props.marginTop ?? "mt-50"}>
                    <div className="r-flexed jc-btwn align-items-center">
                        <div className="r-flexed jc-btwn align-items-center" style={{columnGap: "10px"}}>
                            <div className="card-icon-generic">
                                <img alt="" src={this.props.icon}/>
                            </div>
                            <div className="textClassInit">{this.props.itemsName ?? "Elements"}</div>
                            {this.props.count !== undefined &&<div className="card-number-generic">
                                <span>{this.props.count}</span>
                            </div>}
                        </div>

                        {/* {this.props.showBtn &&
                        <button onClick={this.props.btnClick} className="card-plus-generic" type="button">
                            <i className="fa fa-plus"></i>
                        </button>} */}
                        {this.props.showBtn &&
                            <Button icon="pi pi-plus" onClick={this.props.btnClick} style={{border: "1.5px solid #337f77",color:"#337f77"}} className="p-button-outlined p-button-success bg-white wdpx-37 hpx-35" />
                        }
                    </div>
                </section>
            </div>
        );
    }
}

export default TitleBtnAdd;
