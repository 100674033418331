import { idMobileMenu, myMobileMenu } from '../../partials/menus/MenuMobile';
import { btnMenuPng } from '../../partials/Header';
import { firstMenu, mainMenu } from '../../partials/menus/MenuWeb';
import { contentBody } from '../../partials/BodyContent';
import { store } from '../Redux/store';
import { switchMenuBar } from '../Redux/features/user';
import { Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'
import moment from 'moment';
import { where } from 'firebase/firestore';
import { toast as toastNew } from "react-hot-toast";
import LinesEllipsis from 'react-lines-ellipsis';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Rb from "react-bootstrap";


export const c = console.log.bind(document);

/* Get Current Function */


/* Menu Mobile */
export function closeMenuMobile() {
    myMobileMenu.current.classList.remove("title-animation");
    idMobileMenu.current.style.width = "0";
    //idShortcuts.current.style.display = "none";
    document.body.style.overflow = "auto";
}


export function openMenuMobile() {
    //console.log(myMobileMenu);
    myMobileMenu.current.classList.add("title-animation");
    //(idMobileMenu.current.style.width);

    var shortcutsFixed = document.getElementById("shortcuts-fixed");
    var navMobile = document.getElementsByClassName("navmobile");

    shortcutsFixed.style.color = 'red';
    shortcutsFixed.style.display = 'none';

    var widthNav = idMobileMenu.current.style.width;


    console.log("papa navMobile", widthNav, shortcutsFixed);

    if (widthNav === "100%") {
        closeMenuMobile();
    } else {
        idMobileMenu.current.style.width = "100%";
        document.body.style.overflow = "hidden";

        shortcutsFixed.style.display = 'block';
    }

}

export function closeshortcutsFixed() {
    var shortcutsFixed = document.getElementById("shortcuts-fixed");
    shortcutsFixed.style.display = 'none';

}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

var x = window.matchMedia("(max-width: 767px)");
var y = window.matchMedia("(max-width: 1060px)");
//console.log(x);
x.addEventListener('change', () => {
    if (x.matches) {
        //Set marginLeft Content Body
        contentBody?.current?.style.setProperty('margin-left', '0px', 'important');
    } else {
        contentBody?.current?.style.setProperty('margin-left', '200px', 'important');
    }
});


y.addEventListener('change', () => {
    if (y.matches) {
        //console.log(store.getState().menuBarReducedState.value.isReduced);
        //La taille du menu
        if (store.getState().menuBarReducedState.value.isReduced) {
            reduceMenuBar();
        }
    }
});


export async function reduceMenuBar() {
    const menuTitleClass = document.getElementsByClassName("menu-title");
    const menuImgClass = document.getElementsByClassName("menu-img");
    const submenuClass = document.getElementsByClassName("submenu");

    if (mainMenu) {
        const width = mainMenu.current.style.width;
        var bool = width === "200px" || width === "";
        store.dispatch(switchMenuBar(bool));
        if (bool) {
            //La taille de la barre menu
            mainMenu.current.style.setProperty('width', '100px', 'important');
            mainMenu.current.style.transition = "0.6s";
            firstMenu.current.style.setProperty('width', '100px', 'important');

            //Set marginLeft Content Body
            contentBody.current.style.setProperty('margin-left', '100px', 'important');
            contentBody.current.style.transition = "0.6s";

            //Reduire la position left de la sous menu
            for (let i = 0; i < submenuClass.length; i++) {
                submenuClass[i].style.left = "100px";
            }

            //Cacher les titres
            for (let i = 0; i < menuTitleClass.length; i++) {
                menuTitleClass[i].style.display = "none";
            }
            //La rotation de l'image afin de creer une animation
            btnMenuPng.current.style.transform = "rotateY(180deg)";
            btnMenuPng.current.style.transition = "transform 0.8s";
            btnMenuPng.current.style.transformStyle = "preserve-3d";

            //Augmenter la taille de l'image
            for (let i = 0; i < menuImgClass.length; i++) {
                menuImgClass[i].style.width = "70%";
                menuImgClass[i].style.transition = "1s";
            }

            //enlever la transition phase de test
            for (let i = 0; i < menuImgClass.length; i++) {
                for (let i = 0; i < 1.2; i++) {
                    await sleep(i * 500);
                }
                menuImgClass[i].style.transition = "none";
            }


        } else {
            //La taille du menu
            mainMenu.current.style.setProperty('width', '200px', 'important');
            mainMenu.current.style.transition = "0.8s";
            firstMenu.current.style.setProperty('width', '200px', 'important');

            //ReInit marginLeft Content Body
            contentBody.current.style.setProperty('margin-left', '200px', 'important');

            //augmenter la position left de la sous menu
            for (let i = 0; i < submenuClass.length; i++) {
                submenuClass[i].style.left = "200px";
            }

            //Cacher les titres
            for (let i = 0; i < menuTitleClass.length; i++) {
                menuTitleClass[i].style.display = "block";
            }

            //Augmenter la taille de l'image
            for (let i = 0; i < menuImgClass.length; i++) {
                menuImgClass[i].style.width = "30%";
            }

            //La rotation de l'image afin de creer une animation
            btnMenuPng.current.style.transform = "scaleX(1)";
        }
    }


}

/* Show Notifications List */

const sideNav = document.getElementsByClassName("sidenav");
const sideNav1 = document.getElementsByClassName("sidenav1");

export function showNotifications() {
    sideNav[0].style.width = "400px";
    sideNav1[0].style.width = "100%";
    sideNav1[0].style.backgroundColor = "rgba(0,0,0,0.4)";
    document.body.style.overflow = "hidden";
}

export function hideNotifications() {

    var t = sideNav[0].style.width;
    if (t === "400px" || t === "300px") {
        sideNav[0].style.width = "0";
        sideNav1[0].style.width = "0";
        document.body.style.overflow = "auto";
    }

}


/* Show Profils */

const profilNav = document.getElementsByClassName("profilnav");
const profilNav1 = document.getElementsByClassName("profilnav1");


export function showMyProfil() {
    profilNav[0].style.width = "400px";
    profilNav1[0].style.width = "100%";
    profilNav1[0].style.backgroundColor = "rgba(0,0,0,0.4)";
    document.body.style.overflow = "hidden";
    closeshortcutsFixed();
    closeMenuMobile();

}

export function hideMyProfil() {

    var t = profilNav[0].style.width;
    if (t === "400px" || t === "300px") {
        profilNav[0].style.width = "0";
        profilNav1[0].style.width = "0";
        document.body.style.overflow = "auto";
        closeshortcutsFixed();
        closeMenuMobile();
    }

}


//La fonction can qui gere les permissions ;


export const canModule = (permission) => {
    //console.log(store.getState().authState.value.userInfos);
    let tabPerms = store.getState().authState.value.tabPerms ?? [];
    let returnValue = false;
    if (Array.isArray(permission)) {
        if (tabPerms.length < 1 && permission.length < 1) {
            returnValue = false;
        } else {
            let n = [];
            permission.forEach((l) => {
                if (tabPerms.filter((o) => (o.indexOf(l)) > -1).length > 0) {
                    n = [...n, l];
                }
            });
            returnValue = n.length > 0;
        }
    }


    return returnValue;
}


//La fonction can qui gere les permissions ;

export const canSousModule = (permission) => {
    let tabPerms = store.getState().authState.value.tabPerms ?? [];
    let returnValue = false;
    if (Array.isArray(permission)) {
        if (tabPerms.length < 1 && permission.length < 1) {
            returnValue = false;
        } else {
            let n = [];
            permission.forEach((l) => {
                let itemPerms = ['list' + l, 'create' + l, 'update' + l, 'delete' + l, 'detail' + l];
                itemPerms.forEach((u) => {
                    if (tabPerms.filter((o) => (o.indexOf(u)) > -1).length > 0) {
                        n = [...n, l];
                    }
                });

            });
            returnValue = n.length > 0;
        }
    }


    return returnValue;
}

// Alert generall

export const CustomAlert = ({
    message, yesBtn = () => {
    }
}) => {
    confirmAlert({
        overlayClassName: (store.getState().authState.value.theme === true ? 'dark-mode' : '') + " alert-dialog",
        //closeOnEscape:false,
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                    <div className="d-flex mb-10">
                        <img className="m-auto w-20 img-alert" src="/assets/images/imgs/icon-info.svg" alt="" />
                    </div>
                    <h4 className="text-center textClassInit">{message}</h4>
                    <div className="alert-button-group">
                        <Button onClick={() => {
                            onClose();
                        }} bsPrefix="btn btn-cancel mrpx-20">
                            Non
                        </Button>
                        <Button type="submit" onClick={() => {
                            onClose();
                            yesBtn()
                        }} bsPrefix="btn btn-save">
                            Oui
                        </Button>
                    </div>
                </div>
            );
        }
    })
};

export const customDateFormat = ({ date, format = "DD.MM.YYYY - HH[h] mm" }) => {
    return moment(date).format(format) ?? "...";
}

export const layout = {
    default: [
        "\u0630 \u0661 \u0662 \u0663 \u0664 \u0665 \u0666 \u0667 \u0668 \u0669 \u0660 - = {bksp}",
        "\u0636 \u0635 \u062B \u0642 \u0641 \u063A \u0639 \u0647 \u062E \u062D \u062C \u062F \\",
        "\u0634 \u0633 \u064A \u0628 \u0644 \u0627 \u062A \u0646 \u0645 \u0643 \u0637",
        "{lock} \u0626 \u0621 \u0624 \u0631 \u0644\u0627 \u0649 \u0629 \u0648 \u0632 \u0638",
        "{space}",
    ],
    shift: [
        "\u0651 ! @ # $ % ^ & * ) ( _ + "/* {bksp}"*/,
        "\u064E \u064B \u064F \u064C \u0644\u0625 \u0625 \u2018 \u00F7 \u00D7 \u061B < > |",
        '\u0650 \u064D ] [ \u0644\u0623 \u0623 \u0640 \u060C / : " ',
        "{lock} ~ \u0652 } { \u0644\u0622 \u0622 \u2019 , . \u061F ",
        "{space}",
    ],
}

export const buttonTheme = [
    {
        class: "hg-customBtn",
        buttons: "space lock"
    }
]

export const genericFilter = ({ tabItems, mapValues, mapFiltersTab }) => {
    let tmp = [];
    let cc = [];
    let newMapValues = Object.fromEntries(Object.entries(mapValues).filter(([key]) => (mapValues[key] !== '')));
    let myKeys = Object.keys(newMapValues);
    //console.log(myKeys);
    if (myKeys.length <= 1) {
        cc = tabItems.filter(function (i) {
            let returnValue = false;

            mapFiltersTab.forEach((b) => {
                if (!b.isDate) {
                    if (i[b.key] !== undefined) {
                        if (i[b.key]?.toLowerCase().indexOf(newMapValues[b.key]?.toLowerCase()) !== -1) {
                            returnValue = true;
                        }
                    }
                } else {
                    if (mapValues[b.key]) {
                        let date = moment(i[b.key]).format("DD.MM.YYYY - HH[h] mm");
                        let dateFilter = moment(newMapValues[b.key]).format("DD.MM.YYYY - HH[h] mm");
                        let y = dateFilter.split("-").includes(date.split("-")[0]);
                        returnValue = y;

                    }

                }
            });
            return returnValue;
        })
    } else {
        myKeys.forEach((u) => {
            if (u !== "date") {
                cc = tabItems.filter(function (i) {
                    let returnValue = false;
                    let n;
                    n = i[u].toLowerCase() === newMapValues[u].toLowerCase();
                    if (n) {
                        tmp.push(n);
                        if (newMapValues['date'] !== undefined) {
                            returnValue = (tmp.length + 1 >= myKeys.length)
                        } else {
                            returnValue = (tmp.length >= myKeys.length)
                        }

                    }
                    return returnValue;
                })
            }

            if (newMapValues['date'] !== undefined) {
                let dateFilter = moment(newMapValues["date"]).format("DD.MM.YYYY - HH[h] mm");
                cc = cc.filter(function (i) {
                    let date = moment(i["date"]).format("DD.MM.YYYY - HH[h] mm");
                    let y = dateFilter.split("-")[0] === (date.split("-")[0]);
                    return y;
                })
            }

        })

    }

    return [...cc];
}


//pour filter sur un field de type string c'est comme du indexOf
export const customWhere = ({ operator_1 = ">=", operator_2 = "<=", whereToLookInTheCollection, whereToLookValue }) => {
   
    let x = [where(whereToLookInTheCollection, operator_1, whereToLookValue), where(whereToLookInTheCollection, operator_2, whereToLookValue + '\uf8ff')];
    return x;
}

//pour filter sur un field de type string c'est comme du indexOf
export const customWhereEqual = ({ firstField, firstValue, secondField, secondValue, operator_1 = "==", operator_2 = "==" }) => {
    let x = [where(firstField, operator_1, firstValue), where(secondField, operator_2, secondValue)];
    return x;
}

//pour filter sur un field de type string c'est comme du indexOf
export const customWhereBetweenDate = ({ operator_1 = ">=", operator_2 = "<=", whereToLookInTheCollection, whereToLookValue, whereToLookValue1 }) => {
    let x = [where(whereToLookInTheCollection, operator_1, whereToLookValue), where(whereToLookInTheCollection, operator_2, whereToLookValue1)];
    return x;
}

//la valeur a filtrer doit etre egale a la valeur se trouvant dans le field
export const customWhere_1 = ({ operator = "==", whereToLookInTheCollection, whereToLookValue }) => {
    let x = [where(whereToLookInTheCollection, operator, whereToLookValue)];
    return x;
}

function formatDateToString(date) {
    return moment(date).format("DD.MM.YYYY");
}


export const createMultipleQuery = ({ firstField, firstValue, secondField, secondValue, operator_1 = "==", operator_2 = "==" }) => {
    let result = [];
    result.push(...customWhereEqual({ firstField: firstField, firstValue: firstValue, secondField: secondField, secondValue, operator_1: operator_1, operator_2: operator_2 }))
    return result;
}


export const engineQuery = ({ mapValues = {} }) => {
    
    let myKeys = Object.keys(mapValues);
    let vv = [];
    let hasTwoDates = false;
    if (myKeys.length > 0) {

        myKeys = myKeys.filter((k) => mapValues[k] !== "")

        hasTwoDates = (myKeys.includes("createdAt") && myKeys.includes("createdAtEnd"));

        if (hasTwoDates) {
            vv.push(...customWhereBetweenDate({ whereToLookInTheCollection: "createdAt", whereToLookValue: mapValues["createdAt"], whereToLookValue1: mapValues["createdAtEnd"] }));
            myKeys.forEach((u, i) => {
                if (u !== "createdAt" && u !== "createdAtEnd") {
                    vv.push(...customWhere_1({ whereToLookInTheCollection: u, whereToLookValue: mapValues[u] }))
                }
            })
        } else {
            if (myKeys[0] === "createdAt") {
                vv.push(...customWhere({ whereToLookInTheCollection: "createdAtString", whereToLookValue: formatDateToString(mapValues[myKeys[0]]) }))
            } else {
                if (myKeys[0] !== "createdAtEnd") {
                    if (typeof mapValues[myKeys[0]] === "string") {
                        vv.push(...customWhere({ whereToLookInTheCollection: myKeys[0], whereToLookValue:  mapValues[myKeys[0]] }))
                    } else {
                        vv.push(...customWhere_1({ whereToLookInTheCollection: myKeys[0], whereToLookValue: mapValues[myKeys[0]] }))
                    }

                }

            }

            myKeys.forEach((u, i) => {
                if (i > 0 && u !== "createdAt" && u !== "createdAtEnd") {
                    vv.push(...customWhere_1({ whereToLookInTheCollection: u, whereToLookValue: mapValues[u] }))
                }
            })
        }


    }
    return vv;
}



export function customToast({ message = "", type = "success", duration = 3500, position = "top-right", icon, className = "", style = {} }) {
    return toastNew[type](message, {
        position: position,
        duration: duration,
        icon: icon,
        className: className,
        style: style
    });
}

export function makeToast({ payload, type }) {
    let toastId = toastNew[type](
        <Rb.Stack className='w-100 pt-2 pb-2' direction="horizontal">
            <Rb.Stack className='w-100 h-100' gap={1}>
                <LinesEllipsis className="fsize-13" text={payload?.notification?.title} maxLine={1} ellipsis="..." trimRight basedOn='letters' />
                <LinesEllipsis className="fsize-13" text={payload?.notification?.body} maxLine={2} ellipsis="..." trimRight basedOn='letters' />
            </Rb.Stack>
            <span className="fsize-18 ml-1">
                <FontAwesomeIcon className="cursor-pointer " onClick={() => { toastNew.dismiss(toastId) }} icon={faTimesCircle} />
            </span>
        </Rb.Stack>,
        {
            duration: 7000,
            position: 'top-right',
            style: {
                borderRadius: '10px',
                background: '#337f77',
                color: '#fff',
                width: "100%",
                height: "65px",
            },
            icon: <img width="40" height="40" src="/assets/images/logo/logo.svg" />
        }

    );
}

export function buildGraphicDataSet({data, backgroundColor, labelName}){
    const myArray = Array(11).fill().map((_, index) => index + 1);
    const mapFromArray = new Map(myArray.map(value => [value, 0]));

    data.forEach((u)=>{
        const newValue = mapFromArray.get(u.date.getMonth()) + 1;
        mapFromArray.set(u.date.getMonth(), newValue);
    });

    const datasets = {
        label:labelName,
        data:Array.from(mapFromArray.values()),
        backgroundColor: backgroundColor
    };
    return datasets;

}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');
}

export function getKeywordsMap({tabValue = [], fetchedTab = [], keyFilter = "value", keyValue="id", keyReturn="keywordsMap"})
{
    let returnedValue = [] ;

    tabValue.forEach((item) => {
        if ((fetchedTab ?? []).some((filtre) =>filtre[keyFilter] === item[keyValue]))
        {
            returnedValue.push((item[keyReturn] ??{}));
        }
    });

     // Create an object to store the concatenated arrays
    const concatenatedArrays = {};

    returnedValue.forEach((obj) =>
    {
        Object.keys(obj).forEach(key =>
        {
            if (concatenatedArrays[key])
            {
                concatenatedArrays[key] = (concatenatedArrays[key] ?? []).concat(obj[key]);
            } else
            {
                concatenatedArrays[key] = [...obj[key]];
            }
        }); 
    });

    return Object.keys(concatenatedArrays).length > 0 ? [concatenatedArrays] : [];
}

export function buildKeywordsMap({objectValue, objectReturn})
{
    if (objectValue && objectReturn)
    {
        Object.keys(objectValue).forEach((key) => {
            if (objectReturn[key]) {
                objectValue[key].forEach((i) => {
                    objectReturn[key].push(i)
                })

            }

        })
    }
}