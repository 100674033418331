import { motion } from 'framer-motion';
import React from 'react';

export class Home extends React.Component {
    componentDidMount() {
        document.title = "Bilal";
    }

    render() {
        return (
            <motion.div initial={{width:0}} animate={{width:"100%"}} exit={{x :window.innerWidth , transition:{duration:0.1}}} className="d-flex" style={{height: "calc(100vh - 139.19px)"}}>
                <img alt="" src="/assets/images/logo/logo.svg" style={{margin: "auto", width: "100%", height: "100%"}}/>
            </motion.div>
        );
    }
}

export class HomeNotFound extends React.Component {
    componentDidMount() {
        document.title = "Bilal";
    }

    render() {
        return (
            <motion.div initial={{width:0}} animate={{width:"100%"}} exit={{x :window.innerWidth , transition:{duration:0.1}}} className="d-flex" style={{height: "calc(100vh - 139.19px)"}}>
                <img alt="" src="/assets/images/imgs/page-not-found.svg"
                     style={{margin: "auto", width: "100%", height: "100%"}}/>
            </motion.div>
        );
    }
}

export class InternetDown extends React.Component {
    componentDidMount() {
        document.title = "Bilal";
    }

    render() {
        return (
            <motion.div initial={{width:0}} animate={{width:"100%"}} exit={{x :window.innerWidth , transition:{duration:0.1}}} className="d-flex" style={{height: "calc(100vh - 139.19px)"}}>
                <img alt="" src="/assets/images/imgs/bug_fixing1.svg" style={{margin: "auto", width: "100%", height: "100%"}}/>
            </motion.div>
        );
    }
}


