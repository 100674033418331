import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import {selectAuthState} from "../Redux/features/user";

export const PrivateRoute = ({children}) => {
    const authState = useSelector(selectAuthState);
    //console.log(authState);
    if (authState.isLoggedIn) {
        return children;
    } else {
        return <Navigate to="/login"/>
    }
};

export const LoginRoute = ({children}) => {
    const authState = useSelector(selectAuthState);
    if (authState.isLoggedIn !== true) {
        return children;
    } else {
        return <Navigate to="/"/>
    }
};